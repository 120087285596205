import { Input } from "antd";
import axios from "axios";
import React, { useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { FaUserFriends } from "react-icons/fa";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import FriendsCard from "../../../components/cards/FriendsCard";
import { serverUrl } from "../../../nestserver";
import { selectUser } from "../../../redux/slices/auth.slice";
import InstructorCard from "../../admin/pages/components/cards/InstructorCard";

const { Search } = Input;

export default function FriendsPage() {
  const user = useSelector(selectUser);

  /* -------------------------------------------------------------------------- */
  /*                           Get Instructor Friends                           */
  /* -------------------------------------------------------------------------- */
  async function getFriends() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/instructor/",
      // params: {
      //   role: "instructor",
      // },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const friends = useQuery("friends", getFriends);

  /* -------------------------------------------------------------------------- */
  /*                             Get Student Friends                             */
  /* -------------------------------------------------------------------------- */
  async function getSubjectStudents() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/students/subject",
      params: {
        subjects: user?.student?.subjects?.map((s) => s?._id),
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data?.filter((f) => f?._id !== user?._id);
  }
  const subjectStudents = useQuery("subjectStudents", getSubjectStudents);

  const [search, setSearch] = useState(null);
  const [searchFriend, setSearchFriend] = useState(null);

  const filteredFriends = useMemo(() => {
    let filtered = subjectStudents?.data;
    if (searchFriend) {
      filtered = filtered.filter(
        (f) =>
          f.first_name.toLowerCase().includes(searchFriend.toLowerCase()) ||
          f.last_name.toLowerCase().includes(searchFriend.toLowerCase()) ||
          (f.first_name + " " + f.last_name)
            .toLowerCase()
            .includes(searchFriend.toLowerCase())
      );
    }
    return filtered;
  }, [subjectStudents?.data, searchFriend]);

  const filteredInstructors = useMemo(() => {
    let filtered = friends?.data?.data;

    if (search) {
      filtered = filtered.filter(
        (f) =>
          f?.first_name.toLowerCase().includes(search.toLowerCase()) ||
          f?.last_name.toLowerCase().includes(search.toLowerCase()) ||
          (f?.first_name + " " + f?.last_name)
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }
    return filtered;
  }, [friends?.data?.data, search]);

  return (
    <div className="p-8">
      <div className="bg-white p-6 rounded-2xl">
        <div className="flex items-center justify-between">
          <div className="text-xl text-orange-500 text-medium">Friends</div>
          <div className="w-1/2">
            <Search
              placeholder="input search text"
              enterButton="Search"
              size="large"
              loading={false}
              prefix={<BiSearch className="text-gray-600" />}
              onSearch={(e) => setSearchFriend(e)}
              onChange={(e) => setSearchFriend(e.target.value)}
            />
          </div>
        </div>
        {filteredFriends?.length > 0 ? (
          <>
            <div className="py-8 grid lg:grid-cols-2 xl:grid-cols-3 gap-6">
              {subjectStudents?.isSuccess &&
                filteredFriends
                  ?.filter((f) => f._id !== user._id)
                  ?.map((friend) => (
                    <FriendsCard key={friend._id} data={friend} />
                  ))}
            </div>
          </>
        ) : (
          <div className="py-10 flex flex-col items-center justify-center  text-gray-300">
            <FaUserFriends className="text-5xl" />
            <div>No friends found</div>
          </div>
        )}

        <div className="flex items-center justify-between">
          <div className="text-xl text-orange-500 text-medium">Instructors</div>
          <div className="w-1/2">
            <Search
              placeholder="input search text"
              enterButton="Search"
              size="large"
              loading={false}
              prefix={<BiSearch className="text-gray-600" />}
              onSearch={(e) => setSearch(e)}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        {filteredInstructors?.length > 0 ? (
          <div className="py-8 grid lg:grid-cols-2 xl:grid-cols-3 gap-6">
            {filteredInstructors
              .filter((f) => f._id !== user._id)
              .map((friend) => (
                <InstructorCard key={friend._id} data={friend} />
              ))}
          </div>
        ) : (
          <div className="py-10 flex flex-col items-center justify-center  text-gray-300">
            <FaUserFriends className="text-5xl" />
            <div>No instructors found</div>
          </div>
        )}
      </div>
    </div>
  );
}
