import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { serverUrl } from "../../../../../nestserver";

const DailyScheduleTask = ({
  filterDay,
  filterClassId,
  filterEventId,
  teacherId,
  role,
  filterInstructorId,
}) => {
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const calendarRef = useRef(null);
  const [timeTable, setTimeTable] = useState([]);
  const [events, setEvents] = useState([]);
  const debounceTimeout = useRef(null);
  const getEventsForDate = (date) => {
    const events = [];
    if (Array.isArray(timeTable?.result?.data)) {
      for (const item of timeTable?.result?.data) {
        // Add timetable data
        if (Array.isArray(item?.timetable)) {
          if (filterDay === "weekly") {
            for (let i = 0; i < 7; i++) {
              const currentDay = moment(date).startOf("week").add(i, "days");

              const filteredData = item?.timetable.filter(
                (fItem) => fItem.day === currentDay.format("ddd").toLowerCase()
              );

              for (const scheduleData of filteredData) {
                const startTime = `${scheduleData?.fromTime?.hours}:${scheduleData?.fromTime?.minutes}`;
                const endTime = `${scheduleData?.endTime?.hours}:${scheduleData?.endTime?.minutes}`;
                const event = {
                  title: scheduleData?.name,
                  start: `${currentDay.format("YYYY-MM-DD")}T${startTime}`,
                  end: `${currentDay.format("YYYY-MM-DD")}T${endTime}`,
                  extendedProps: {
                    subjectName: scheduleData?.subject?.name,
                    teacherName: `${scheduleData?.subject?.teacher?.teacher?.first_name} ${scheduleData?.subject?.teacher?.teacher?.last_name}`,
                    timeRange: `${startTime}-${endTime}`,
                    class: item?.class?.name,
                    isTimetable: true,
                  },
                  allDay: false,
                };
                events.push(event);
              }
            }
          } else if (filterDay === "day") {
            const filteredData = item?.timetable.filter(
              (fItem) => fItem.day === moment(date).format("ddd").toLowerCase()
            );
            for (const scheduleData of filteredData) {
              const startTime = `${scheduleData?.fromTime?.hours}:${scheduleData?.fromTime?.minutes}`;
              const endTime = `${scheduleData?.endTime?.hours}:${scheduleData?.endTime?.minutes}`;
              const event = {
                title: scheduleData?.name,
                start: `${date}T${startTime}`,
                end: `${date}T${endTime}`,
                extendedProps: {
                  subjectName: scheduleData?.subject?.name,
                  teacherName: `${scheduleData?.subject?.teacher?.teacher?.first_name} ${scheduleData?.subject?.teacher?.teacher?.last_name}`,
                  timeRange: `${startTime}-${endTime}`,
                  class: item?.class?.name,
                  isTimetable: true,
                },
                allDay: false,
              };
              events.push(event);
            }
          }
        }
      }
    }
    // Add subevents data
    if (Array.isArray(timeTable?.result?.subevents)) {
      for (const item of timeTable?.result?.subevents) {
        const { event, event_date } = item;
        const eventStartDate = event_date;
        const eventObj = {
          title: event?.name,
          start: eventStartDate,
          extendedProps: {
            isSubevent: true,
            colorCode: event?.event_type?.color_code,
            dateRange: `${moment(eventStartDate)}`,
          },
          allDay: true,
        };
        events.push(eventObj);
      }
    }
    return events;
  };

  // const handleDateChange = useCallback(
  //   async (info) => {
  //     try {
  //       let startDate, endDate;

  //       if (filterDay === "day") {
  //         startDate = moment(currentDate).format("YYYY-MM-DD");
  //         endDate = moment(currentDate).format("YYYY-MM-DD");
  //       } else if (filterDay === "weekly") {
  //         const startOfWeek = moment(currentDate).startOf("week");
  //         const endOfWeek = moment(currentDate).endOf("week");

  //         startDate = startOfWeek.format("YYYY-MM-DD");
  //         endDate = endOfWeek.format("YYYY-MM-DD");
  //       } else {
  //         startDate = moment(info.startStr)
  //           .startOf("month")
  //           .format("YYYY-MM-DD");
  //         endDate = moment(info.endStr).endOf("month").format("YYYY-MM-DD");
  //       }

  //       const res = await axios({
  //         method: "get",
  //         url: serverUrl + "/event/schedule",
  //         params: {
  //           class: filterClassId,
  //           startDate,
  //           endDate,
  //           instructor:
  //             filterInstructorId ||
  //             (role === "instructor" && teacherId ? teacherId : null),
  //           event: filterEventId,
  //         },
  //         headers: {
  //           Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
  //         },
  //       });
  //       setTimeTable(res.data);
  //     } catch (error) {
  //       console.error("Error fetching time table data:", error);
  //     }
  //   },
  //   [filterDay, filterClassId, filterInstructorId, filterEventId, currentDate]
  // );

  // useEffect(() => {
  //   if (calendarRef.current) {
  //     const calendarApi = calendarRef.current.getApi();

  //     if (filterDay === "weekly") {
  //       const startOfWeek = moment(currentDate).startOf("week");
  //       calendarApi.gotoDate(startOfWeek.format("YYYY-MM-DD"));
  //       calendarApi.changeView("timeGridWeek");
  //     } else if (filterDay === "day") {
  //       calendarApi.gotoDate(currentDate);
  //       calendarApi.changeView("timeGridDay");
  //     } else {
  //       calendarApi.changeView("dayGridMonth");
  //     }
  //     const handleDatesSet = (info) => {
  //       handleDateChange(info);
  //     };

  //     calendarApi.on("datesSet", handleDatesSet);

  //     return () => {
  //       calendarApi.off("datesSet", handleDatesSet);
  //     };
  //   }
  // }, [filterDay, handleDateChange, currentDate]);
  const handleDateChange = useCallback(
    async (info) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(async () => {
        try {
          let startDate, endDate;

          if (filterDay === "day") {
            startDate = moment(currentDate).format("YYYY-MM-DD");
            endDate = moment(currentDate).format("YYYY-MM-DD");
          } else if (filterDay === "weekly") {
            const startOfWeek = moment(currentDate).startOf("week");
            const endOfWeek = moment(currentDate).endOf("week");

            startDate = startOfWeek.format("YYYY-MM-DD");
            endDate = endOfWeek.format("YYYY-MM-DD");
          } else {
            startDate = moment(info.startStr)
              .startOf("month")
              .format("YYYY-MM-DD");
            endDate = moment(info.endStr).endOf("month").format("YYYY-MM-DD");
          }

          const res = await axios({
            method: "get",
            url: serverUrl + "/event/schedule",
            params: {
              class: filterClassId,
              startDate,
              endDate,
              instructor:
                filterInstructorId ||
                (role === "instructor" && teacherId ? teacherId : null),
              event: filterEventId,
            },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          });
          setTimeTable(res.data);
        } catch (error) {
          console.error("Error fetching time table data:", error);
        }
      }, 500);
    },
    [filterDay, filterClassId, filterInstructorId, filterEventId, currentDate]
  );

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      if (filterDay === "weekly") {
        const startOfWeek = moment(currentDate).startOf("week");
        calendarApi.gotoDate(startOfWeek.format("YYYY-MM-DD"));
        calendarApi.changeView("timeGridWeek");
      } else if (filterDay === "day") {
        calendarApi.gotoDate(currentDate);
        calendarApi.changeView("timeGridDay");
      } else {
        calendarApi.changeView("dayGridMonth");
      }

      const handleDatesSet = (info) => {
        handleDateChange(info);
      };

      calendarApi.on("datesSet", handleDatesSet);

      return () => {
        calendarApi.off("datesSet", handleDatesSet);
      };
    }
  }, [filterDay, handleDateChange, currentDate]);

  const handlePrev = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      let prevDate;

      if (filterDay === "day") {
        prevDate = moment(currentDate).subtract(1, "days").format("YYYY-MM-DD");
        setCurrentDate(prevDate);
      } else if (filterDay === "weekly") {
        prevDate = moment(currentDate)
          .subtract(1, "weeks")
          .startOf("week")
          .format("YYYY-MM-DD");
        setCurrentDate(prevDate);
      } else {
        prevDate = moment(currentDate)
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        setCurrentDate(prevDate);
      }

      calendarApi.gotoDate(prevDate);
      handleDateChange({ startStr: prevDate, endStr: prevDate });
    }
  };

  const handleNext = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      let nextDate;

      if (filterDay === "day") {
        nextDate = moment(currentDate).add(1, "days").format("YYYY-MM-DD");
        setCurrentDate(nextDate);
      } else if (filterDay === "weekly") {
        nextDate = moment(currentDate)
          .add(1, "weeks")
          .startOf("week")
          .format("YYYY-MM-DD");
        setCurrentDate(nextDate);
      } else {
        nextDate = moment(currentDate)
          .add(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        setCurrentDate(nextDate);
      }

      calendarApi.gotoDate(nextDate);
      handleDateChange({ startStr: nextDate, endStr: nextDate });
    }
  };
  useEffect(() => {
    const fetchEvents = () => {
      if (Array.isArray(timeTable?.result?.data)) {
        const events = getEventsForDate(currentDate);
        setEvents(events);
      }
    };
    fetchEvents();
  }, [timeTable, currentDate, filterDay]);

  // useEffect(() => {
  //   if (debounceTimeout.current) {
  //     clearTimeout(debounceTimeout.current);
  //   }
  //   debounceTimeout.current = setTimeout(() => {
  //     if (calendarRef.current) {
  //       handleDateChange({ startStr: currentDate, endStr: currentDate });
  //     }
  //   }, 500);

  //   return () => {
  //     if (debounceTimeout.current) {
  //       clearTimeout(debounceTimeout.current);
  //     }
  //   };
  // }, [currentDate]);

  return (
    <div className="pt-5 react-calendar">
      <div className="flex gap-4 pt-4">
        <button onClick={handlePrev} className="w-fit">
          <AiFillCaretLeft size={35} />
        </button>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={
            filterDay === "weekly"
              ? "timeGridWeek"
              : filterDay === "day"
              ? "timeGridDay"
              : "dayGridMonth"
          }
          headerToolbar={{
            start: "",
            center: "title",
            end: "",
          }}
          events={events}
          eventContent={(arg) => (
            <div>
              {arg?.event?.extendedProps?.isTimetable ? (
                <div style={{ width: "500px" }}>
                  <div className="capitalize">
                    {arg?.event?.extendedProps?.subjectName}
                  </div>
                  <div className="capitalize">
                    {arg?.event?.extendedProps?.teacherName}
                  </div>
                  <div className="capitalize">
                    {arg?.event?.extendedProps?.class}
                  </div>
                  <div className="capitalize">
                    {""} {arg?.event?.extendedProps?.timeRange}
                  </div>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      background: arg?.event?.extendedProps?.colorCode,
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      display: "inline-block",
                      marginRight: "4px",
                      verticalAlign: "middle",
                    }}
                  ></div>{" "}
                  <span className="capitalize">{arg?.event?.title}</span>
                </>
              )}
            </div>
          )}
          // dayMaxEventRows={true}
          // views={{
          //   timeGrid: {
          //     dayMaxEventRows: 3, // adjust to 6 only for timeGridWeek/timeGridDay
          //   },
          // }}
          progressiveEventRendering={true}
          dayMaxEventRows={3}
          eventMaxStack={1}
          dayHeaderFormat={{ weekday: "long" }}
          contentHeight="auto"
          slotMaxTime={"18:00:00"}
          slotMinTime={"07:00:00"}
          slotDuration="00:15:00"
          slotLabelInterval="01:00"
          datesSet={handleDateChange}
        />
        <button onClick={handleNext} className="w-fit">
          <AiFillCaretRight size={35} />
        </button>
      </div>
    </div>
  );
};

export default DailyScheduleTask;
