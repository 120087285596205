import React from "react";
import {
  AiOutlineCalendar,
  AiOutlineCar,
  AiOutlineClockCircle,
  AiOutlineContainer,
  AiOutlineFileText,
  AiOutlineMessage,
} from "react-icons/ai";
import { GoBook } from "react-icons/go";
import { HiOutlineUserGroup } from "react-icons/hi";
import { useHistory, useLocation } from "react-router";

const Menu = () => {
  const history = useHistory();
  const location = useLocation();
  const parentSidemenu = [
    {
      title: "Connections",
      icon: <HiOutlineUserGroup size={35} />,
      onClick: () => {
        history.push("/dashboard/student/menu/friends");
      },
      url: "/dashboard/student/menu/friends",
    },
    {
      title: "Manage Attendance",
      icon: <AiOutlineCalendar size={35} />,
      onClick: () => history.push("/dashboard/student/menu/attendance"),
      url: "/dashboard/student/menu/attendance",
    },
    {
      title: "Manage Report",
      icon: <AiOutlineFileText size={35} />,
      onClick: () => history.push("/dashboard/student/menu/report-card"),
      url: "/dashboard/student/menu/report-card",
    },
    {
      title: "Manage Course Board",
      icon: <GoBook size={35} />,
      onClick: () => history.push("/dashboard/student/menu/courses-board"),
      url: "/dashboard/student/menu/course-board",
    },
    {
      title: "Manage schedule",
      icon: <AiOutlineClockCircle size={35} />,
      onClick: () => history.push("/dashboard/student/menu/schedule"),
      url: "/dashboard/student/menu/schedule",
    },
    {
      title: "Manage Homework",
      icon: <AiOutlineContainer size={35} />,
      onClick: () => history.push("/dashboard/student/menu/homework"),
      url: "/dashboard/student/menu/homework",
    },
    {
      title: "Manage Transportation",
      icon: <AiOutlineCar size={35} />,
      onClick: () => history.push("/dashboard/student/menu/transportation"),
      url: "/dashboard/student/menu/transportation",
    },
  ];
  return (
    <div>
      <div className="p-8">
        <div className="bg-white p-10 rounded-2xl min-h-40">
          <div className="grid-property">
            {parentSidemenu.map((item) => (
              <div
                onClick={item.onClick}
                key={item.title}
                className={`flex items-center space-y-1 p-4 cursor-pointer border-l-4 border-transpagent hover:border-red-400 hover:shadow transition-all duration-100 ease-in-out ${
                  location.pathname === item.url
                    ? "border-red-400 shadow w-fit-content"
                    : ""
                }`}
              >
                <span className="mr-2">{item.icon}</span>
                <div className="text-lg text-center line-clamp-2 menu-spacing font-bold">
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
