import { Button, Form } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../../../nestserver";
import InstructorParentConversation from "./InstructorParentConversation";

const InstructorMessagePage = () => {
  const queryClient = useQueryClient();
  const [messagePopupOpen, setMessagePopupOpen] = useState(false);
  const [form] = Form.useForm();
  const [conversationId, setConversationId] = useState();
  const [conversationMessage, setConversationMessage] = useState();
  const [senderDetails, setSenderDetails] = useState();
  const [instructorAdminChat, setInstructorAdminChat] = useState();
  const [classId, setClassId] = useState();
  const [studentId, setStudentId] = useState();
  const [uploadDoc, setUploadDoc] = useState([]);
  const [messageData, setMessageData] = useState();
  const homework = true;
  const [resetTrigger, setResetTrigger] = useState(0);
  const [parentDetails, setParentDetails] = useState();

  const QUERY_KEY = ["/conversations/messages", parentDetails?._id];

  //fetch class
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/teachers/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  const fetchConversation = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/conversations/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const conversationDetails = useQuery(
    ["conversations"],
    fetchConversation,
    {}
  );

  const instructorMessage = async (conversationId, item) => {
    if (item?.unread_messages_count > 0) {
      await axios({
        method: "patch",
        url: serverUrl + "/conversations/" + conversationId + "/read-message",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      queryClient.invalidateQueries("conversations");
    }
    setSenderDetails(item);
    setMessagePopupOpen(true);
    setConversationId(conversationId);
    const res = await axios({
      method: "get",
      url: serverUrl + "/conversations/" + conversationId + "/message",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    setConversationMessage(res?.data?.result);
    return res.data;
  };
  const fetchInstructorMessage = async () => {
    if (!parentDetails?._id || senderDetails?._id) {
      setInstructorAdminChat(null);
      return;
    }
    let url = `${serverUrl}/conversations/messages/`;
    if (parentDetails?._id) {
      url += `?targetId=${parentDetails?._id}`;
    }
    if (senderDetails?._id) {
      url += `?conversationId=${senderDetails?._id}`;
    }
    const res = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const { data } = useQuery(QUERY_KEY, fetchInstructorMessage, {
    enabled: !!parentDetails?._id,
    onSuccess: (data) => {
      setInstructorAdminChat(data ? data : null);
    },
  });
  const resetEverything = () => {
    setSenderDetails(null);
    setConversationMessage(null);
    setInstructorAdminChat(null);
    setConversationId(null);
    setClassId(null);
    setStudentId(null);
    setMessageData("");
    setUploadDoc([]);
    if (form) {
      form.setFieldsValue({
        class: "",
        student: "",
        parent: "",
      });
    }
  };
  useEffect(() => {
    if (!parentDetails || !senderDetails) {
      setInstructorAdminChat(null);
    }
  }, [parentDetails]);
  return (
    <div className="p-8">
      <div
        style={{ borderRadius: "10px", color: "#616161" }}
        className=" bg-white drop-shadow-md p-8 w-full screen-height "
      >
        <div className="flex gap-4 min-height">
          <div style={{ width: "30%" }} className="message-left-part">
            {homework && (
              <div className="pt-4 text-right">
                <Button
                  onClick={() => {
                    resetEverything();
                    setMessagePopupOpen(true);
                    setResetTrigger((prev) => prev + 1);
                  }}
                  type="primary"
                  size="large"
                >
                  Create New Message
                </Button>
              </div>
            )}
            <div className="h-full">
              <div
                className="pt-4"
                style={{ height: "40%", marginBottom: "20px" }}
              >
                <span className="text-base font-semibold pb-4">
                  Parent Chat History
                </span>
                <div style={{ height: "100%", overflowY: "auto" }}>
                  {conversationDetails?.data?.result?.data.filter(
                    (item) => item?.connection?.parent
                  )
                    ? conversationDetails?.data?.result?.data
                        .filter((item) => item?.connection?.parent)
                        .map((item) => {
                          return (
                            <div className="pt-2 capitalize" key={item._id}>
                              <div
                                onClick={() => {
                                  instructorMessage(item._id, item);
                                  setClassId(null);
                                  setStudentId(null);
                                  setUploadDoc([]);
                                  setMessageData(null);
                                }}
                                type="primary"
                                size="large"
                                className={`cursor-pointer ${
                                  conversationId === item?._id &&
                                  messagePopupOpen
                                    ? "active-message"
                                    : "message-card"
                                }`}
                              >
                                <div className="flex justify-between">
                                  <div>
                                    {item.connection.parent.first_name}{" "}
                                    {item.connection.parent.last_name}
                                  </div>
                                  {item?.unread_messages_count > 0 && (
                                    <div className="rounded-full border flex justify-center items-center unread-message">
                                      <p>{item?.unread_messages_count}</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })
                    : "No chat history found"}
                </div>
              </div>
              <div className="pt-4 message-height">
                <span className="text-base font-semibold pb-4">
                  Admin Chat History
                </span>
                <div style={{ height: "100%", overflowY: "auto" }}>
                  {conversationDetails?.data?.result?.data
                    .filter(
                      (item) =>
                        item?.connection?.admin || item?.connection?.teacher
                    )
                    .map((item) => {
                      return (
                        <div className="pt-2 capitalize" key={item._id}>
                          <div
                            onClick={() => {
                              instructorMessage(item._id, item);
                              setClassId(null);
                              setStudentId(null);
                              setUploadDoc([]);
                              setMessageData(null);
                            }}
                            type="primary"
                            size="large"
                            className={`cursor-pointer ${
                              conversationId === item?._id && messagePopupOpen
                                ? "active-message"
                                : "message-card"
                            }`}
                          >
                            <div className="flex justify-between">
                              <div>
                                {item.connection.admin.first_name}{" "}
                                {item.connection.admin.last_name}
                              </div>
                              {item?.unread_messages_count > 0 && (
                                <div className="rounded-full border flex justify-center items-center unread-message">
                                  <p>{item?.unread_messages_count}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: "70%" }} className="instructor-broadcast">
            {messagePopupOpen ? (
              <InstructorParentConversation
                form={form}
                classes={classes}
                setMessagePopupOpen={setMessagePopupOpen}
                conversationMessage={conversationMessage}
                setConversationMessage={setConversationMessage}
                setSenderDetails={setSenderDetails}
                senderDetails={senderDetails}
                instructorMessage={instructorMessage}
                instructorAdminChat={instructorAdminChat}
                setInstructorAdminChat={setInstructorAdminChat}
                setConversationId={setConversationId}
                setClassId={setClassId}
                classId={classId}
                setStudentId={setStudentId}
                studentId={studentId}
                setUploadDoc={setUploadDoc}
                uploadDoc={uploadDoc}
                setMessageData={setMessageData}
                messageData={messageData}
                resetTrigger={resetTrigger}
                setParentDetails={setParentDetails}
                parentDetails={parentDetails}
              />
            ) : (
              <div className="flex w-full justify-center items-center h-full">
                No message to show
              </div>
            )}
          </div>
        </div>
        <div className="flex"></div>
      </div>
    </div>
  );
};

export default InstructorMessagePage;
