import { Table } from "antd";
import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../nestserver";
import { selectUser } from "../../../../redux/slices/auth.slice";

const CourseBoard = () => {
  const user = useSelector(selectUser);
  const history = useHistory();
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/teachers/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  const columns = [
    {
      title: "Class",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.class?.name}
        </div>
      ),
      filters: classes.isSuccess
        ? classes?.data?.map((c) => ({ text: c.name, value: c?._id }))
        : [],
      onFilter: (value, record) => record?.class?._id === value,
    },
    {
      title: "Subject",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Action",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div
            key={record?._id}
            className=" text-base font-semibold text-center text-blue-500 cursor-pointer"
            onClick={() => {
              history.push(
                "/dashboard/instructor/" +
                  record?.class?.name +
                  "/" +
                  record?.class?._id +
                  "/" +
                  record?._id
              );
            }}
          >
            Click to view
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-6">
            Manage Course Board
          </div>
          <Table
            columns={columns}
            dataSource={
              user?.teacher && user?.teacher?.subjects
                ? user?.teacher?.subjects
                : []
            }
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default CourseBoard;
