import { Button, Form, Input, Select, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { cloudFrontUrl, serverUrl } from "../../../../nestserver";
const { Option } = Select;

const TransferCertificateTemplate = ({ getAdmin, getTemplateDetails, id }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [fields, setFields] = useState([{ label: "", field_name: "" }]);

  const availableOptions = [
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
    { label: "Dob", value: "dob" },
    { label: "gender", value: "gender" },
    { label: "nationality", value: "nationality" },
    { label: "aadhar", value: "aadhar" },
    { label: "ethnicity", value: "ethnicity" },
    { label: "phone", value: "phone" },
    { label: "birth place", value: "birth_place" },
    { label: "religion", value: "religion" },
    { label: "class", value: "class" },
    { label: "parent first name", value: "parent_first_name" },
    { label: "parent phone", value: "parent_phone" },
    { label: "enrollment year", value: "enrollment_year" },
    { label: "mother tongue", value: "mother_tongue" },
    { label: "category", value: "category" },
    { label: "blood group", value: "blood_group" },
    { label: "is disabled", value: "is_disabled" },
    { label: "Other", value: "other" },
  ];

  useEffect(() => {
    if (getTemplateDetails) {
      if (getTemplateDetails?.fields && getTemplateDetails?.fields.length > 0) {
        const updatedFields = getTemplateDetails?.fields.map((field) => ({
          label: field.label,
          field_name: field.field_name,
        }));
        setFields(updatedFields);
      }

      form.setFieldsValue({
        declaration_message: getTemplateDetails?.declaration_message,
      });
    } else setFields([{ label: "", field_name: "" }]);
  }, [getTemplateDetails]);

  const handleInputChange = (index, event) => {
    const updatedFields = [...fields];
    updatedFields[index].label = event?.target?.value;
    setFields(updatedFields);
  };

  const handleSelectChange = (index, value) => {
    const updatedFields = [...fields];
    updatedFields[index].field_name = value;
    setFields(updatedFields);
  };
  const addField = () => {
    setFields([...fields, { label: "", field_name: "" }]);
  };

  const deleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const filteredFields = fields.filter(
    (field) => field.label && field.field_name
  );
  const onFinish = (values) => {
    const payload = {
      form_type: "transfer_certificate",
      school: getAdmin?.data?.result?.data?._id,
      fields: filteredFields && filteredFields.length > 0 ? filteredFields : [],
      declaration_message: values?.declaration_message
        ? values?.declaration_message
        : "I hereby declare",
    };
    if (id) {
      axios
        .put(serverUrl + "/form-template/" + id, payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          queryClient?.invalidateQueries(["form-template"]);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            message.error(err?.response?.data?.message);
          }
        });
    } else if (filteredFields && filteredFields.length > 0) {
      axios
        .post(serverUrl + "/form-template/", payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          queryClient?.invalidateQueries(["form-template"]);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            message.error(err?.response?.data?.message);
          }
        });
    }
  };
  return (
    <div className="tc-doc">
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8  "
        >
          <div className="p-4 text-right">
            <Button size="large" onClick={() => history.goBack()}>
              Back
            </Button>
          </div>
          <div className="border ">
            <div className="upper-part ">
              <div className="flex flex-col">
                <h3 className="text-base pb-3 flex items-center">
                  {" "}
                  {/* <span className="text-base font-semibold">Logo : </span> */}
                  <div className="relative w-20 h-20 rounded-full  border-8 border-white">
                    <img
                      className="w-full h-full rounded-full border"
                      src={
                        getAdmin?.data?.result?.data?.logo
                          ? `${cloudFrontUrl}/common/${getAdmin?.data?.result?.data?.logo}`
                          : "https://ui-avatars.com/api/?name=" +
                            (getAdmin?.data?.result?.data?.name
                              ? getAdmin?.data?.result?.data?.name
                              : getAdmin?.data?.result?.data?.name)
                      }
                      alt="banner"
                    />
                  </div>
                </h3>
              </div>
              <div className="flex flex-col gap-2" style={{ width: "400px" }}>
                <p className="text-base font-semibold pb-2 capitalize">
                  {getAdmin?.data?.result?.data?.name}
                </p>
                <p className="text-base font-semibold capitalize">{`${getAdmin?.data?.result?.data?.address}, ${getAdmin?.data?.result?.data?.city}, ${getAdmin?.data?.result?.data?.state}, ${getAdmin?.data?.result?.data?.pincode} `}</p>
              </div>
            </div>
            <div className="lower-part p-4">
              <h3 className="text-xl font-semibold text-center pb-4">
                Transfer Certificate
              </h3>
              <h6 className="text-base">
                {" "}
                <span className="text-base font-semibold">
                  Aff No./School Code :
                </span>{" "}
                {getAdmin?.data?.result?.data?.refId}
              </h6>
              <div className="flex justify-between items-center pt-4 pb-8">
                <h6 className="text-base">
                  <span className="text-base font-semibold">File No :</span>{" "}
                </h6>
                <h6 className="text-base " style={{ width: "220px" }}>
                  <span className="text-base font-semibold">
                    Admission No :
                  </span>{" "}
                </h6>
              </div>
              <div>
                <Form
                  form={form}
                  name="dynamic_rule"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  {fields &&
                    fields?.map((field, index) => (
                      <div key={index} className="flex gap-4">
                        <span className="pt-2">{index + 1}</span>
                        <Form.Item>
                          <Select
                            value={field.field_name}
                            onChange={(event) =>
                              handleSelectChange(index, event)
                            }
                            // placeholder="Select field type"
                            getPopupContainer={(trigger) => trigger.parentNode}
                            style={{ width: "220px" }}
                            className="capitalize"
                            placeholder="Select field type"
                            allowClear
                          >
                            {availableOptions.map((option) => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item>
                          <Input
                            type="text"
                            value={field.label}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            style={{ width: "220px" }}
                            placeholder="Input Field"
                          />
                        </Form.Item>

                        <Button
                          type="danger"
                          htmlType="button"
                          onClick={() => deleteField(index)}
                        >
                          Delete
                        </Button>
                      </div>
                    ))}
                  <div className="pb-6">
                    <Button type="primary" onClick={addField} htmlType="button">
                      Add Field
                    </Button>
                  </div>
                  <Form.Item name="declaration_message">
                    <Input.TextArea
                      rows={3}
                      placeholder="Declaration"
                      defaultValue="I hereby declare"
                      className="w-full focus:outline-none resize-none"
                    />
                  </Form.Item>
                  <div className="flex justify-between items-center pt-10 pb-4">
                    <div className="flex gap-4  items-center">
                      <h3 className="text-base font-semibold">Date</h3>
                    </div>
                    <div className="flex gap-4 items-center">
                      <h3 className="text-base font-semibold">Signature</h3>
                    </div>
                  </div>
                  <div className="text-center">
                    <Button type="primary" size="large" htmlType="submit">
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferCertificateTemplate;
