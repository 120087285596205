import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message } from "antd";
import medal from "../../resources/medal01.svg";
import AchievementsCard from "../cards/AchievementsCard";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/auth.slice";
import { MinusCircleOutlined } from "@ant-design/icons";
import IconButton from "../buttons/IconButton";
import { BsPencil } from "react-icons/bs";
import Button from "antd-button-color";
import { useQueryClient } from "react-query";
import axios from "axios";
import { serverUrl } from "../../nestserver";

function StudentsAchieve(props) {
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [achievemodal, setAchieveModal] = useState(false);

  const onFinish = (values) => {
    axios({
      method: "patch",
      url: serverUrl + "/students/" + user?.student?._id,
      data: {
        ...values,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Achievements Updated");
        queryClient.invalidateQueries("user");
        setAchieveModal(false);
        form.resetFields();
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
  };

  useEffect(() => {
    form.resetFields();
  }, [user]);

  useEffect(() => {
    if (achievemodal) {
      form.resetFields();
    }
  }, [achievemodal]);

  return (
    <div>
      <div className="flex-grow bg-white rounded-xl pb-8  relative ">
        <div className="p-5 ">
          <div className="right-5 top-5 absolute cursor-pointer">
            <IconButton
              icon={<BsPencil />}
              onClick={() => setAchieveModal(true)}
            />
          </div>
          <div className="text-2xl font-medium text-black text-left px-5">
            Achievements
          </div>
          <div className="flex flex-col space-y-2 divide-y">
            {user?.student?.achievements ? (
              [...user?.student?.achievements]
                .reverse()
                .map((item) => (
                  <AchievementsCard
                    image={medal}
                    title={item.title}
                    content={item.description}
                  />
                ))
            ) : (
              <div className="flex items-center justify-center py-6 ">
                <div className="text-xl font-light ">
                  No Achievements To Show
                </div>
              </div>
            )}
          </div>
          {/* ---------------------------------------------------------- */}
          <Modal
            width={800}
            visible={achievemodal}
            onCancel={() => {
              setAchieveModal(false);
              form.resetFields();
            }}
            footer={null}
          >
            <div className=" px-5 py-6">
              <Form
                layout="vertical"
                name="basic"
                form={form}
                initialValues={user?.student}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div className="text-2xl font-bold text-black ">
                  Achievements
                </div>
                {/* -------------------------------------------------sec 1---------------------------- */}

                <Form.List
                  style={{ marginBottom: "10px", width: "100%" }}
                  label="Achievements"
                  name="achievements"
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields &&
                        fields.length > 0 &&
                        fields.map(({ key, name, fieldKey, ...restField }) => (
                          <div className="border-b py-4  flex">
                            <div className="w-0 flex-grow space-y-2">
                              <Form.Item
                                {...restField}
                                name={[name, "title"]}
                                fieldKey={[fieldKey, "title"]}
                                label="Achievement Title"
                                className="mb-0 w-full"
                                rules={[
                                  {
                                    required: true,
                                    message: "Achievements need a title",
                                  },
                                ]}
                                //   label="Title"
                              >
                                <Input
                                  style={{
                                    backgroundColor: "#E3E3E3",
                                  }}
                                  className="w-full py-2"
                                  placeholder="Title"
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "description"]}
                                fieldKey={[fieldKey, "description"]}
                                label="Description"
                                rules={[
                                  {
                                    required: true,
                                    message: "Achievements need a description",
                                  },
                                ]}
                                className="mb-0 w-full"
                                //   label="Year"
                              >
                                <Input
                                  style={{
                                    backgroundColor: "#E3E3E3",
                                  }}
                                  className="w-full py-2"
                                  placeholder="Description"
                                />
                              </Form.Item>
                            </div>
                            <div className="w-10 flex items-center justify-center">
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </div>
                          </div>
                        ))}
                      <Form.Item>
                        <div className="flex items-center justify-center pt-8">
                          <Button size="large" onClick={() => add()}>
                            Add Achievement
                          </Button>
                        </div>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item className="mb-0">
                  <div className="flex items-center  pt-8">
                    <div className="inline-block ml-auto space-x-3">
                      <Button
                        type="dark"
                        size="large"
                        onClick={() => {
                          setAchieveModal(false);
                          form.resetFields();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="primary" size="large" htmlType="submit">
                        Save
                      </Button>
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Modal>

          {/* ---------------------------------------------------------- */}
        </div>
      </div>
    </div>
  );
}

export default StudentsAchieve;
