import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { cloudFrontUrl } from "../../nestserver";
import { selectUser } from "../../redux/slices/auth.slice";

export default function FriendsCard({ data }) {
  const [url, setUrl] = useState("/");

  const user = useSelector(selectUser);
  const history = useHistory();

  function getUrl() {
    switch (user?.role) {
      case "admin":
        return `/dashboard/admin/instructor/profile/${data?._id}`;

      case "instructor":
        return `/dashboard/instructor/menu/friends/${data?._id}`;

      case "student":
        return `/dashboard/student/menu/friends/${data?._id}`;

      default:
        break;
    }
  }
  useEffect(() => {
    if (user) {
      setUrl(getUrl());
    }
  }, [user]);
  return (
    <div
      className="iskool-friends-card flex space-x-3 cursor-pointer"
      onClick={() => history.push(url)}
    >
      <div className="w-20 h-20">
        <img
          className="w-full h-full object-cover rounded-full"
          src={
            data?.profile_image
              ? cloudFrontUrl + "/" + data?._id + "/" + data?.profile_image
              : "https://ui-avatars.com/api/?name=" + data?.first_name
          }
          alt={data?.first_name}
        />
      </div>
      <div className="w-0 flex-grow flex flex-col justify-center">
        <div className="font-bold">
          {data?.first_name} {data?.last_name}
        </div>
        <div
          className="text-xs text-gray-500 line-clamp-2 pt-2"
          style={{ minHeight: "25px" }}
        >
          {data?.class?.name}
        </div>
      </div>
    </div>
  );
}
