import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { useQuery, useQueryClient } from "react-query";
import CustomWeekCheckBox from "../../../../../components/checkbox/CustomWeekCheckbox";
import { serverUrl } from "../../../../../nestserver";

const HomeSchedule = () => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const queryClient = useQueryClient();
  const [eventHistoryPopup, setEventHistoryPopup] = useState(false);
  const [repeatPopup, setRepeatPopup] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [weekdayStates, setWeekdayStates] = useState({
    Sun: false, // Sunday
    Mon: false, // Monday
    Tue: false, // Tuesday
    Wed: false, // Wednesday
    Thu: false, // Thursday
    Fri: false, // Friday
    Sat: false, // Saturday
  });
  const [attendanceChecked, setAttendanceChecked] = useState(true);
  const [eventTypeId, setEventTypeId] = useState();
  const handleEventType = (id) => {
    setEventTypeId(id);
  };

  //fetch event type
  const fetchEventType = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/event-types/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const getEventType = useQuery("event-types", fetchEventType);
  const fetchEvent = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/event/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const getEvent = useQuery("event", fetchEvent);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${serverUrl}/event/${id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      queryClient.invalidateQueries(["event"]);
      message.success("Event deleted successfully");
    } catch (error) {
      message.error(error?.response?.data?.error?.errors);
    }
  };

  const columns = [
    {
      title: "Name",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.name}
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize "
        >
          {record?.type?.name}
        </div>
      ),
    },
    {
      title: "From Date",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center "
        >
          {moment(record?.from).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: "To Date",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center "
        >
          {moment(record?.to).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: "Repeat",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        const repeatDay = record?.event_dates?.map((repeat) => repeat?.day);
        const uniqueDay = new Set(repeatDay);
        const uniqueDayArray = [...uniqueDay];

        return (
          <div
            key={record?._id}
            style={{ color: "#111111" }}
            className=" text-base font-semibold text-center cursor-pointer "
            onClick={() => {
              setSelectedRecord(uniqueDayArray);
              setIsModalVisible(true);
            }}
          >
            {uniqueDayArray.length > 0 && uniqueDayArray?.length}
          </div>
        );
      },
    },
    {
      title: "Attendance",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center "
        >
          {record?.is_attendance_enabled && record?.is_attendance_enabled ? (
            <span className="flex justify-center">
              <BiCheckCircle className="text-green-500" size={20} />
            </span>
          ) : (
            <span className="flex justify-center">
              <BiXCircle className="text-red-500" size={20} />
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center "
        >
          <div className="ml-14px">
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => handleDelete(record?._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="danger flex items-center justify-center"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];
  const handleSchedule = (values) => {
    let eventOccurrence = values?.name;

    const isAnyDayTrue = Object.values(weekdayStates).some(
      (value) => value === true
    );
    if (isAnyDayTrue) {
      eventOccurrence = "weekly";
    }
    const payload = {
      is_attendance_enabled: attendanceChecked,
      event_type: eventTypeId,
      event_occurrence: isAnyDayTrue ? eventOccurrence : "daily",
      name: values?.name,
      from: moment(values?.date[0]).format("YYYY-MM-DD"),
      to: moment(values?.date[1]).format("YYYY-MM-DD"),
      fromTime: {
        hours: moment(values?.time?.from).format("HH"),
        minutes: moment(values?.time?.from).format("mm"),
      },
      endTime: {
        hours: moment(values?.time?.to).format("HH"),
        minutes: moment(values?.time?.to).format("mm"),
      },
      days: weekdayStates,
    };

    if (payload) {
      axios
        .post(serverUrl + "/event/", payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          form.resetFields();
          queryClient.invalidateQueries(["event"]);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          if (err?.response?.data?.error?.errors) {
            message.error(err?.response?.data?.error?.errors);
          }
        });
    }
  };

  return (
    <div>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex justify-between items-center">
            <div className="text-2xl text-orange-500 font-semibold">
              Manage Schedule Event
            </div>
            <div className="flex justify-between items-center">
              {/* <h5 className="text-base">Schedule</h5> */}
              <Button onClick={() => setEventHistoryPopup(true)}>
                View Events
              </Button>
            </div>
          </div>
          <Form
            form={form}
            name="basic"
            onFinish={handleSchedule}
            autoComplete="off"
            layout="vertical"
            style={{ width: "50%" }}
            className="border p-4 mt-8"
          >
            <div className="">
              <div className="flex gap-x-4">
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the name!",
                    },
                  ]}
                  className="w-full"
                >
                  <Input type="text" placeholder="Enter name" />
                </Form.Item>
                <Form.Item
                  name="type"
                  label="Type"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please select the eventType",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select type"
                    className="w-full bg-gray-300 capitalize"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownClassName="custom-dropdown"
                    onChange={handleEventType}
                  >
                    {getEventType?.isSuccess &&
                      getEventType?.data?.result?.data?.map((data) => {
                        return (
                          <Option
                            key={data._id}
                            value={data._id}
                            className="capitalize"
                          >
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4"></div>
              <Form.Item
                name="date"
                label="Date Range"
                rules={[
                  {
                    required: true,
                    message: "Please select date range",
                  },
                ]}
              >
                <RangePicker
                  format={"DD/MM/YYYY"}
                  disabledDate={(d) =>
                    !d || d.isBefore(moment().startOf("day"))
                  }
                  className="w-full"
                />
              </Form.Item>
              <Button
                type="default "
                onClick={() => setRepeatPopup(true)}
                className={`${
                  Object.values(weekdayStates).some((value) => value === true)
                    ? "bg-primary text-white "
                    : " text-gray-700"
                }`}
              >
                Repeat
              </Button>
            </div>
            <div className="flex gap-4 pt-4">
              <Checkbox
                onChange={(e) => setAttendanceChecked(e.target.checked)}
                checked={attendanceChecked}
              >
                Enable Attendance
              </Checkbox>
            </div>
            <div className="flex justify-center items-center py-5">
              <div className="inline-flex items-center space-x-2">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="text-white"
                >
                  Save
                </Button>
              </div>
            </div>
            <Modal
              width={450}
              visible={repeatPopup}
              onOk={() => setRepeatPopup(false)}
              onCancel={() => {
                setRepeatPopup(false);
              }}
              footer={null}
              forceRender
            >
              <div>
                <h3 className="text-lg font-bold">Repeat</h3>
                <div className="pt-8 w-full">
                  <Form.Item label="Weekly" name="Weekly" className="w-30 pb-2">
                    <CustomWeekCheckBox
                      weekdayStates={weekdayStates}
                      setWeekdayStates={setWeekdayStates}
                    />
                  </Form.Item>
                  <div className="flex justify-center items-center pt-4">
                    <div className="inline-flex items-center space-x-2">
                      <Button
                        size="large"
                        type="primary"
                        htmlType="button"
                        className="text-white"
                        onClick={() => setRepeatPopup(false)}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </Form>
        </div>
      </div>
      {/* repeat modal */}

      <Modal
        width={1000}
        visible={eventHistoryPopup}
        onOk={() => setEventHistoryPopup(false)}
        onCancel={() => {
          setEventHistoryPopup(false);
        }}
        footer={null}
        forceRender
      >
        <div>
          <h3 className="text-lg font-bold pb-3">List Of Events</h3>
          <div className="pt-4 w-full">
            <Table
              columns={columns}
              dataSource={
                getEvent?.isSuccess &&
                getEvent?.data?.result &&
                getEvent?.data?.result?.data
                  ? getEvent?.data?.result?.data
                  : []
              }
              pagination={true}
              loading={getEvent?.isLoading}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="Repeat Event"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
      >
        <ul>
          {selectedRecord
            ?.slice()
            .reverse()
            .map((day, index) => (
              <li key={index} className="capitalize">
                {`${index + 1}. ${day}`}
              </li>
            ))}
        </ul>
      </Modal>
    </div>
  );
};

export default HomeSchedule;
