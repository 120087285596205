import { Skeleton } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import ModuleDetails from "./ModuleDetails";

function SubjectModule({ subjectDetails }) {
  // fetch Modules
  async function getModules() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/modules/",
      params: {
        subject: subjectDetails?._id,
        published: true,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const modules = useQuery(["modules", subjectDetails?._id], getModules);

  // set selected module
  const [moduleDetails, setModuleDetails] = useState(null);

  return (
    <>
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className="bg-white p-8 drop-shadow-md  w-full "
        >
          <>
            {moduleDetails ? (
              <ModuleDetails
                subject={subjectDetails}
                data={moduleDetails}
                goBack={() => setModuleDetails(null)}
              />
            ) : (
              <>
                {modules.isLoading ? (
                  <Skeleton active />
                ) : (
                  <div className="grid grid-cols-4 gap-8">
                    {modules.isSuccess &&
                      modules?.data?.map((module) => (
                        <div
                          key={module._id}
                          onClick={() => setModuleDetails(module)}
                          className="cursor-pointer bg-white p-3 h-56 module-card  "
                          style={{
                            maxHeight: "200px",
                            overflow: "hidden",
                          }}
                        >
                          <div className="flex items-center justify-between gap-x-2">
                            <h1
                              style={{
                                color: "#00627B",
                              }}
                            >
                              {module?.title}
                            </h1>
                          </div>
                          <p
                            style={{ color: "#AEADAE" }}
                            className="font-normal text-sm text-left py-2"
                          >
                            {module?.overview}
                          </p>
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
}

export default SubjectModule;
