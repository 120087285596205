import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { cloudFrontUrl } from "../../../../../nestserver";
import { selectUser } from "../../../../../redux/slices/auth.slice";

function InstructorCard({ data }) {
  const history = useHistory();
  const user = useSelector(selectUser);
  const [url, setUrl] = useState("/");

  function getUrl() {
    switch (user?.role) {
      case "admin":
        return `/dashboard/admin/menu/instructor/profile/${data?._id}`;

      case "instructor":
        return `/dashboard/instructor/menu/friends/${data?._id}`;

      case "student":
        return `/dashboard/student/menu/friends/${data?._id}`;

      default:
        break;
    }
  }
  useEffect(() => {
    if (user) {
      setUrl(getUrl());
    }
  }, [user]);

  return (
    <div>
      <div
        className="iskool-friends-card flex space-x-3 cursor-pointer h-full"
        onClick={() => history.push(url)}
      >
        <div className="w-20 h-20">
          <img
            className="w-full h-full object-cover rounded-full"
            src={
              data?.profile_image
                ? cloudFrontUrl +
                  "/" +
                  data?._id +
                  "/" +
                  data?.profile_image
                : "https://ui-avatars.com/api/?name=" +
                  data?.first_name +
                  " " +
                  data?.last_name
            }
            alt={data?.first_name}
          />
        </div>
        <div className="w-0 flex-grow space-y-1">
          <div className="font-bold flex items-center line-clamp-1 capitalize">
            {data?.first_name} {data?.last_name}
          </div>
          <div className="text-xs text-gray-500 line-clamp-2">
            {data?.title}
          </div>
          <div className="text-xs text-gray-500 line-clamp-2">
            Department of {data?.department?.name}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructorCard;
