import React from "react";

function AchievementsCard(props) {
  return (
    <div>
      <div className="flex flex-row items-start justify-start px-5 pt-6  ">
        <div className="pr-5">
          <img src={props.image} alt="" />
        </div>
        <div className="w-full flex flex-col space-y-2 pb-2">
          <div
            style={{ color: "#111111" }}
            className="font-normal  text-left text-xl"
          >
            {props.title}
          </div>

          <div
            style={{
              color: "#111111",
              wordBreak: "break-word",
            }}
            className="text-left font-light"
          >
            {props.content}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AchievementsCard;
