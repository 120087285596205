import { Button, Input, message, Select, Table, Typography } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../../../../nestserver";
const { Option } = Select;

const TransportationStatus = () => {
  const [routeId, setRouteId] = useState();
  const [alertReason, setAlertReason] = useState("");
  const { Title } = Typography;
  const queryClient = useQueryClient();

  //routes
  async function fetchRoute() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routes = useQuery(["routes"], () => fetchRoute());

  async function fetchIssues(id) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/route-alert/?route_id=${id}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const issueDetails = useQuery(
    ["route-alert", routeId],
    () => routeId && fetchIssues(routeId),
    {
      enabled: !!routeId, // Only fetch if classIdData is truthy
    }
  );


  const columnsLog = [
    {
      title: "Date",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center"
        >
          {record?.createdAt
            ? moment(record?.createdAt).format("DD/MM/YYYY")
            : "-"}
        </div>
      ),
    },
    {
      title: "Alert",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div
            key={record}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {record?.alert}
          </div>
        );
      },
    },
  ];
  const onFinish = (values) => {
    const payload = {
      alert: alertReason,
      route_id: routeId,
    };
    if (routeId) {
      axios
        .post(serverUrl + "/route-alert/", payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          queryClient.invalidateQueries(["route-alert"]);
          message.success(res?.data?.result?.message);
          setAlertReason("");
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          console.log("err", err);
        });
    }
  };

  return (
    <div>
      {" "}
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold">
            Manage Status
          </div>
          <div className="flex gap-10px pt-8 items-center">
            <h6 className=" font-semibold text-base">Route: &nbsp;</h6>
            <Select
              showSearch
              showArrow
              placeholder="Select Route"
              className=" bg-gray-300"
              style={{
                border: "6px",
                backgroundColor: "#EBEBEB",
                width: "24%",
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => setRouteId(value)}
            >
              {routes?.isSuccess &&
                routes?.data?.result?.data?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data?.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <Title level={5} className="pt-4">
            Alert
          </Title>
          <Input.TextArea
            rows={3}
            placeholder="Reason"
            className="w-full border focus:outline-none resize-none"
            maxLength={100} // Limit the input to 100 characters visually
            value={alertReason}
            onChange={(e) => setAlertReason(e.target.value)}
          />
          <div className="pt-4">
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="text-white"
              onClick={onFinish}
            >
              Send
            </Button>
          </div>
          <div className="py-5 table-overflow">
            <Table
              rowKey="_id"
              columns={columnsLog}
              dataSource={
                issueDetails?.isSuccess &&
                issueDetails?.data?.result?.data?.length > 0 &&
                issueDetails?.data?.result?.data
                  ? issueDetails?.data?.result?.data
                  : []
              }
              pagination={true}
              loading={issueDetails?.isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransportationStatus;
