import { Button, Table } from "antd";
import React, { useState } from "react";

import axios from "axios";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import StudentAssignmentSubmissionsTable from "./StudentAssignmentSubmissionsTable";
import StudentExamSubmissionTable from "./StudentExamSubmissionTable";

function StudentSubject({ data }) {
  const [subjectDetails, setSubjectDetails] = useState(null);

  async function getStudent() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/students/" + data?.student?._id,
    });

    return res.data;
  }

  const student = useQuery("student", getStudent);

  const columns = [
    {
      title: "Mandatory",
      dataIndex: "name",
      key: "",
    },
    {
      title: "Evalutions",
      width: "20%",
      render: (record) => (
        <div
          onClick={() => setSubjectDetails(record)}
          className=" text-base font-semibold  text-blue-500 cursor-pointer"
        >
          Click to view
        </div>
      ),
    },
  ];
  const columnsCocurricular = [
    {
      title: "Co Curricular",
      dataIndex: [],
      key: "",
      render: (record) => (
        <div className="">
          {record?.name}
        </div>
      ),
    },
    {
      title: "Evalutions",
      width: "20%",
      render: (record) => (
        <div
          onClick={() => setSubjectDetails(record)}
          className=" text-base font-semibold  text-blue-500 cursor-pointer"
        >
          Click to view
        </div>
      ),
    },
  ];
  const columnsLanguages = [
    {
      title: "Languages",
      dataIndex: [],
      key: "",
      render: (record) => (
        <div className="">
          {record?.name}
        </div>
      ),
    },
    {
      title: "Evalutions",
      width: "20%",
      render: (record) => (
        <div
          onClick={() => setSubjectDetails(record)}
          className=" text-base font-semibold  text-blue-500 cursor-pointer"
        >
          Click to view
        </div>
      ),
    },
  ];

  return (
    <>
      {!subjectDetails ? (
        <div className="pt-8">
          <div
            style={{ borderRadius: "10px", color: "#616161" }}
            className=" bg-white drop-shadow-md p-8 w-full "
          >
            <div className="text-2xl text-orange-500 font-semibold capitalize">
              {data?.student?.first_name} {data?.student?.last_name}
            </div>
            <div className="flex flex-row items-center justify-between w-96 py-3">
              <div className="text-xl">
                Class : {data?.student?.class?.name}
              </div>
            </div>
            {/* -----------------------------------table------------ */}
            <div className="">
              <Table
                bordered
                columns={columns}
                dataSource={student?.isSuccess && student?.data?.subjects}
                pagination={false}
                // style={{ width: "40%" }}
              />
            </div>
            <Table
              bordered
              columns={columnsCocurricular}
              dataSource={student?.isSuccess && student?.data?.co_curricular}
              pagination={false}
              className="pt-4"
              // style={{ width: "50%" }}
            />
            <div className=" pt-4">
              <Table
                bordered
                columns={columnsLanguages}
                dataSource={student?.isSuccess && student?.data?.languages}
                pagination={false}
                // style={{ width: "50%" }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="pt-8">
          <div
            style={{ borderRadius: "10px", color: "#616161" }}
            className=" bg-white drop-shadow-md w-full "
          >
            <div className="px-8 pt-8 pb-4">
              <div className="text-2xl text-orange-500 font-semibold capitalize flex items-center justify-between">
                <div>
                  {student?.data?.first_name} {student?.data?.last_name}
                </div>
                <Button onClick={() => setSubjectDetails(null)}>Back</Button>
              </div>

              <div className="flex flex-row items-center justify-between w-96 py-3">
                <div
                  style={{
                    color: "#09617E",
                    fontSize: "22px",
                  }}
                  className="font-normal"
                >
                  Evaluations
                </div>
                <div className="text-lg">{subjectDetails?.name} </div>
                <div className="text-lg">{subjectDetails?.class?.name}</div>
              </div>
            </div>

            <div className="h-16">
              <div
                style={{ backgroundColor: "#09617E" }}
                className="uppercase rounded-tl-xl text-white rounded-tr-xl h-full 
                            font-normal text-base flex items-center justify-center"
              >
                ASSIGNMENTS
              </div>
            </div>

            <div>
              <StudentAssignmentSubmissionsTable
                subject={subjectDetails?._id}
                student={student?.data?._id}
              />
            </div>

            <div className="pt-8">
              <div className="h-16">
                <div
                  style={{ backgroundColor: "#09617E" }}
                  className="uppercase rounded-tl-xl text-white rounded-tr-xl h-full 
                            font-normal text-base flex items-center justify-center"
                >
                  Exams
                </div>
              </div>

              <div>
                <StudentExamSubmissionTable
                  subject={subjectDetails?._id}
                  student={student?.data?._id}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default StudentSubject;
