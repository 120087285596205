import { Button, Form, Input, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { serverUrl } from "../../../nestserver";
export default function ParentResetPassword() {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const phone = urlParams.get("phone");
  const [parentId, setParentId] = useState();
  useEffect(() => {
    if (phone) {
      axios
        .get(serverUrl + "/parents/by-phone/" + phone)
        .then((res) => {
          setParentId(res?.data?.result?._id);
          form.setFieldsValue({
            first_name: res?.data?.result?.first_name,
            last_name: res?.data?.result?.last_name,
            phone: res?.data?.result?.phone,
            email: res?.data?.result?.email,
            username: res?.data?.result?.username,
          });
        })
        .catch((error) => {
          message.error("Error Occured!");
        });
    }
  }, [phone]);

  const onFinish = (values) => {
    const payload = {
      email: values?.email,
      password: values?.password,
    };
    axios
      .post(
        serverUrl + "/parents/" + parentId + "/register-reset/",
        payload,
        {}
      )
      .then((res) => {
        form.resetFields();
        message.success(res?.data?.result?.message);
        history.replace("/auth/login");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
        }
      });
  };
  return (
    <div
      style={{
        backgroundImage: `url("/assets/login-bg-new.png")`,
      }}
    >
      <div
        style={{
          fontFamily: `'Nunito', sans-serif`,
        }}
        className="h-screen w-full flex flex-col items-center justify-center"
      >
        <div className="md:w-1/3  p-6">
          <div className="p-8">
            <div
              style={{ borderRadius: "10px", color: "#616161" }}
              className=" bg-white drop-shadow-md p-8 w-full border border-2 "
            >
              <h3 className="text-2xl font-bold text-center pb-10">Form</h3>
              <Form
                layout="vertical"
                name="basic"
                form={form}
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={onFinish}
              >
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    className="w-full"
                  >
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item label="Last Name" name="last_name">
                    <Input readOnly />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    className="w-full"
                    rules={[
                      {
                        pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                        message: "Please enter a valid 10-digit phone number!",
                      },
                    ]}
                    getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                  >
                    <Input type="number" maxLength={10} min={0} readOnly />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email",
                      },
                      {
                        required: true,
                        message: "Email is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    label="Username"
                    name="username"
                    className="w-full"
                  >
                    <Input readOnly />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                      {
                        min: 6,
                        message: "Password must be at least 6 characters long!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>
                <div className="flex justify-center items-center pt-10">
                  <div className="inline-flex items-center space-x-2">
                    <Button
                      onClick={() => {
                        // setAcceptAdmissionPopup(false);
                        form.resetFields();
                      }}
                      size="large"
                      type="dark"
                    >
                      Cancel
                    </Button>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="text-white"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
