import { Form, Select } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import DailyScheduleTask from "./DailyScheduleTask";
const EventSchedule = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [filterClassId, setFilterClassId] = useState(null);
  const [filterDay, setFilterDay] = useState(null);
  const [filterInstructorId, setFilterInstructorId] = useState(null);
  const [filterEventId, setFilterEventId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //fetch events
  const fetchEvent = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/event/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const getEvent = useQuery("event", fetchEvent);

  //handle class Select change
  const handleSelectChange = (id) => {
    setFilterClassId(id);
  };
  const handleEventSelectChange = (id) => {
    setFilterEventId(id);
  };

  const handleDayChange = (name) => {
    setFilterDay(name);

    switch (name) {
      case "day":
        // Set startDate and endDate to today's date
        const today = moment().format("YYYY-MM-DD");
        setStartDate(today);
        setEndDate(today);
        break;
      case "weekly":
        // Get the start and end dates of the current week
        const currentDate = moment();
        const startOfWeek = currentDate.clone().startOf("week");
        const endOfWeek = currentDate.clone().endOf("week");
        const weekStartDate = startOfWeek.format("YYYY-MM-DD");
        const weekEndDate = endOfWeek.format("YYYY-MM-DD");
        setStartDate(weekStartDate);
        setEndDate(weekEndDate);
        break;
      case "monthly":
        // Get the start and end dates of the current month
        const currentMonth = moment();
        const startOfMonth = currentMonth.clone().startOf("month");
        const endOfMonth = currentMonth.clone().endOf("month");
        const monthStartDate = startOfMonth.format("YYYY-MM-DD");
        const monthEndDate = endOfMonth.format("YYYY-MM-DD");
        setStartDate(monthStartDate);
        setEndDate(monthEndDate);
        break;
      default:
        break;
    }
  };

  const handleInstructorChange = (id) => {
    setFilterInstructorId(id);
  };

  //fetch class
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  // fetch Instructors
  async function fetchInstructors() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/",
      params: {
        role: "instructor",
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const instructors = useQuery("instructors", fetchInstructors);

  return (
    <div>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Manage Schedule
          </div>
          <div className="flex items-center gap-4">
            <div className="flex gap-4 w-273 items-center">
              <h3 className="text-base font-semibold">Class</h3>
              <Select
                showSearch
                showArrow
                style={{ width: 200 }}
                placeholder="Select Class"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={handleSelectChange}
                allowClear
              >
                {classes?.isSuccess &&
                  classes?.data?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {data.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>

            <div className="flex gap-4 w-273 items-center">
              <h3 className="text-base font-semibold">Period</h3>
              <Select
                showSearch
                showArrow
                style={{ width: 200 }}
                placeholder="Select day"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={handleDayChange}
                allowClear
              >
                <Option value="day">Day</Option>
                <Option value="weekly">Weekly</Option>
                <Option value="monthly">Month</Option>
              </Select>
            </div>
          </div>
          <div className="flex items-center gap-4 pt-4">
            <div className="flex gap-4 w-273 items-center">
              <h3 className="text-base font-semibold">Event</h3>
              <Select
                showSearch
                showArrow
                style={{ width: 200 }}
                placeholder="Select event"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={handleEventSelectChange}
                className="capitalize"
                allowClear
              >
                {getEvent?.isSuccess &&
                  getEvent?.data?.result?.data.length > 0 &&
                  getEvent?.data?.result?.data?.map((data) => {
                    return (
                      <Option
                        key={data._id}
                        value={data._id}
                        className="capitalize"
                      >
                        {data.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div className="flex gap-4 w-273 items-center">
              <h3 className="text-base font-semibold">Instructor</h3>
              <Select
                showSearch
                showArrow
                allowClear
                placeholder="Select Instructor"
                optionFilterProp="children"
                style={{
                  border: "6px",
                  backgroundColor: "#EBEBEB",
                }}
                className="capitalize"
                onChange={handleInstructorChange}
              >
                {instructors.isSuccess &&
                  instructors?.data?.map((data) => {
                    return (
                      <Option
                        key={data._id}
                        value={data._id}
                        className="capitalize"
                      >
                        {data?.teacher?.first_name} {data?.teacher?.last_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div>
            <DailyScheduleTask
              filterDay={filterDay}
              filterClassId={filterClassId}
              filterInstructorId={filterInstructorId}
              filterEventId={filterEventId}
            />
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default EventSchedule;
