import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  message,
} from "antd";
import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { reminder } from "../../../../../helper/options";
import { serverUrl } from "../../../../../nestserver";

const FeeClass = () => {
  const { Option } = Select;
  const history = useHistory();
  const [form] = Form.useForm();

  const [sendReminder, setSendReminder] = useState(false);
  const [selectReminder, setSelectReminder] = useState();
  const [classResponse, setClassResponse] = useState();
  const [classId, setClassId] = useState();
  const [studentFee, setStudentFee] = useState();
  const [searchInput, setSearchInput] = useState(null);
  const [studentReminderIds, setStudentReminderIds] = useState([]);
  const [minRemainingAmount, setMinRemainingAmount] = useState(null);
  const [maxRemainingAmount, setMaxRemainingAmount] = useState(null);

  // max-min filter
  const handleMinChange = (event) => {
    setMinRemainingAmount(event.target.value);
  };

  const handleMaxChange = (event) => {
    setMaxRemainingAmount(event.target.value);
  };
  const [filteredData, setFilteredData] = useState([]);

  //handle class Select change
  const handleSelectChange = (classId) => {
    setClassId(classId);
    const fetchClassStudents = async () => {
      const res = await axios({
        method: "get",
        url: serverUrl + "/admin/fee-template/class/" + classId,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      return res.data;
    };
    fetchClassStudents().then((response) => {
      setClassResponse(response?.result);
    });
  };

  //handleStudent
  const handleStudentFee = (studentId) => {
    const fetchStudentFee = async () => {
      const res = await axios({
        method: "get",
        url:
          serverUrl +
          "/admin/fee-template/class/" +
          classId +
          "/student/" +
          studentId,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      return res.data;
    };
    fetchStudentFee().then((response) => {
      setStudentFee(response?.result);
    });
  };
  const handleExport = async (e) => {
    e.preventDefault();
    if (classId) {
      axios({
        url: serverUrl + "/admin/fee-template/class/" + classId + "/export/",
        method: "GET",
        responseType: "blob",
        headers: {
          Accept: "application/json",
          responseType: "blob",
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }).then((response) => {
        saveAs(response.data, "student-list");
      });
    }
  };

  // fetch classes
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);
  // Table Settings

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search Subject`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Name",
      key: "",
      align: "center",
      ...getColumnSearchProps(["first_name", "last_name"]),
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.student?.first_name + " " + record?.student?.last_name}
        </div>
      ),
      onFilter: (value, record) =>
        (
          record?.student?.first_name?.toLowerCase() +
          " " +
          record?.student?.last_name?.toLowerCase()
        ).includes(value.toLowerCase()),
    },

    {
      title: "Total Amount",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center"
        >
          {record?.total}
        </div>
      ),
    },
    {
      title: "Paid Amount",
      dataIndex: [],
      key: "",
      render: (record) => (
        <div style={{ color: "#616161" }} className=" text-base">
          {record?.paid}
        </div>
      ),
    },
    {
      title: "Remaining Amount",
      dataIndex: [],
      key: "",
      render: (record) => (
        <div style={{ color: "#616161" }} className=" text-base">
          {record?.remaining}
        </div>
      ),
    },
    {
      title: "Due Date",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {moment(record?.due_date).format("Do MMM YYYY")}
        </div>
      ),
    },
    {
      title: "Last Paid",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.last_paid
            ? moment(record?.last_paid).format("Do MMM YYYY")
            : ""}
        </div>
      ),
    },
    {
      title: "Reminder",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.reminder_sent
            ? moment(record?.reminder_sent).format("DD MMM YYYY hh:mm:ss")
            : ""}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          className=" text-base font-semibold text-center text-blue-500 cursor-pointer"
          onClick={() => {
            handleStudentFee(record?.student?._id);
            history.push(
              `/dashboard/admin/menu/class/${classId}/student/${record?.student?._id}`
            );
          }}
        >
          View
        </div>
      ),
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      selectedRows &&
        selectedRows.length > 0 &&
        setStudentReminderIds(selectedRows.map((item) => item?.student?._id));
    },
    getCheckboxProps: (record) => ({}),
  };

  function onFinish(values) {
    let payLoad;

    if (values.reminder === reminder.now) {
      payLoad = {
        now: true,
        daily: false,
        before_due_date: false,
        after_due_date: false,
        till_date: null,
        studentsId: studentReminderIds ? studentReminderIds : null,
      };
    } else if (values.reminder === reminder.daily) {
      payLoad = {
        now: false,
        daily: true,
        before_due_date: false,
        after_due_date: false,
        till_date: values?.tillDate
          ? moment(values?.tillDate).format("YYYY-MM-DD")
          : null,
        studentsId: studentReminderIds ? studentReminderIds : null,
      };
    } else if (
      selectReminder === reminder.dueDate &&
      values?.dueDateOption === reminder.beforeDate
    ) {
      payLoad = {
        now: false,
        daily: false,
        before_due_date: true,
        after_due_date: false,
        till_date: null,
        studentsId: studentReminderIds ? studentReminderIds : null,
      };
    } else if (
      selectReminder === reminder.dueDate &&
      values?.dueDateOption === reminder.afterDate
    ) {
      payLoad = {
        now: false,
        daily: false,
        before_due_date: false,
        after_due_date: true,
        till_date: null,
        studentsId: studentReminderIds ? studentReminderIds : null,
      };
    }

    if (classId && payLoad && studentReminderIds.length > 0) {
      axios
        .post(
          serverUrl + "/admin/fee-template/class/" + classId + "/reminder/",
          payLoad,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
        .then((response) => {
          message.success(response?.data?.result?.message) ||
            message.success("Reminder Sent");
          setSendReminder(false);

          //update the list
          const updatedClassResponse = classResponse.map((student) => {
            if (studentReminderIds.includes(student?.student?._id)) {
              return {
                ...student,
                reminder_sent: new Date().toISOString(),
              };
            }
            return student;
          });
          setClassResponse(updatedClassResponse);
          form.resetFields();
        })
        .catch((error) => {
          message.error(error?.response?.data?.error?.errors);
        });
    }
  }

  useEffect(() => {
    const filtered = classResponse?.filter((record) => {
      const remaining = record?.remaining || 0;
      return (
        (minRemainingAmount === null || remaining >= minRemainingAmount) &&
        (maxRemainingAmount === null || remaining <= maxRemainingAmount)
      );
    });
    setFilteredData(filtered);
    if (minRemainingAmount === "" || maxRemainingAmount === "") {
      setFilteredData(classResponse);
    }
  }, [minRemainingAmount, maxRemainingAmount, classResponse]);

  const handleStopReminder = () => {
    const payload = {
      studentId: studentReminderIds.length > 0 ? studentReminderIds : [],
    };
    if (studentReminderIds.length > 0) {
      axios
        .post(serverUrl + "/admin/fee-template/stop-reminder/", payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((response) => {
          message.success(response?.data?.result?.message) ||
            message.success("Reminder stopped");
          const updatedClassResponse = classResponse.map((student) => {
            if (studentReminderIds.includes(student?.student?._id)) {
              return {
                ...student,
                reminder_sent: new Date().toISOString(),
              };
            }
            return student;
          });
          setClassResponse(updatedClassResponse);
        })
        .catch((error) => {
          message.error(error?.response?.data?.error?.errors);
        });
    }
  };

  return (
    <>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Manage Student Fee
          </div>
          <div className="flex flex-col w-310">
            <h3 className="text-base pb-3"> Class</h3>
            <Select
              showSearch
              showArrow
              placeholder="Select Class"
              className="w-full bg-gray-300"
              style={{
                border: "6px",
                backgroundColor: "#EBEBEB",
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => handleSelectChange(value)}
            >
              {classes.isSuccess &&
                classes?.data?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div>
            <div className="flex items-center gap-3 mb-4 pt-6">
              <div>
                <Input
                  placeholder="Minimum"
                  value={minRemainingAmount}
                  onChange={handleMinChange}
                  className="w-100px"
                />
              </div>
              <div>&gt;= Remains &lt;=</div>
              <div>
                <Input
                  placeholder="Maximum"
                  value={maxRemainingAmount}
                  onChange={handleMaxChange}
                  className="w-100px"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center pt-4 gap-6">
            <div className=" flex items-center gap-3">
              <h5 className="text-base pb-2"> Reminder</h5>
              <Button onClick={() => setSendReminder(true)}>Send</Button>
              <Button onClick={handleStopReminder}>Stop</Button>
            </div>
            <Button
              size="large"
              type="primary"
              htmlType="download"
              className="text-white w-9"
              onClick={(e) => handleExport(e)}
            >
              Export
            </Button>
          </div>
          <div className="py-5 table-overflow">
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              rowKey="_id"
              columns={columns}
              dataSource={
                filteredData && filteredData.length > 0 ? filteredData : []
              }
              pagination={true}
              loading={classResponse?.isLoading}
            />
          </div>
        </div>
      </div>
      {/* ---------------------------------- remindermodal------------------------------------ */}

      <Modal
        width={400}
        visible={sendReminder}
        onCancel={() => {
          setSendReminder(false);
        }}
        footer={null}
      >
        <div>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
          >
            <h3 className="text-base pb-3 pt-4">Reminder</h3>
            <Form.Item name="reminder" className="input-font-weight">
              <Radio.Group
                value={selectReminder}
                onChange={(e) => {
                  setSelectReminder(e.target.value);
                  if (e.target.value !== "dueDate") {
                    form.setFieldsValue({
                      tillDate: undefined,
                      beforeDate: undefined,
                      afterDate: undefined,
                    });
                  }
                  if (e.target.value === "dueDate") {
                    form.setFieldsValue({
                      tillDate: undefined,
                    });
                    form.resetFields(["tillDate", "beforeDate", "afterDate"]);
                  }
                  if (e.target.value === "daily" || e.target.value === "now") {
                    form.setFieldsValue({
                      dueDateOption: undefined,
                    });
                  }
                }}
                className="space-y-4"
                label="selectReminder"
              >
                <div className="flex flex-col">
                  <Radio value="now">Now</Radio>
                  <div className="pt-3 pb-3">
                    <Radio value="daily">Daily</Radio>
                    <Form.Item
                      name="tillDate"
                      noStyle
                      rules={[
                        {
                          required: selectReminder === "daily",
                          message: "Please select till date",
                        },
                      ]}
                    >
                      <DatePicker
                        format={"DD-MM-YYYY"}
                        placeholder="Till Date"
                        style={{ marginLeft: "30px" }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        disabled={selectReminder !== "daily"}
                        disabledDate={(current) => {
                          return current && current < moment().startOf("day");
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="flex  items-center">
                    <Radio value="dueDate">Due Date</Radio>{" "}
                    <div>
                      {selectReminder === reminder.dueDate && (
                        <Form.Item
                          name="dueDateOption"
                          noStyle
                          rules={[
                            {
                              validator: (_, value) => {
                                if (
                                  value !== "beforeDate" &&
                                  value !== "afterDate"
                                ) {
                                  return Promise.reject(
                                    "Please select either before date or after date"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            placeholder="Select mode"
                          >
                            <Select.Option value="beforeDate">
                              Before Date
                            </Select.Option>
                            <Select.Option value="afterDate">
                              After Date
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      )}
                    </div>
                  </div>
                </div>
              </Radio.Group>
            </Form.Item>
            <div className="flex justify-center items-center py-5">
              <div className="inline-flex items-center space-x-2">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="text-white"
                >
                  Send
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default FeeClass;
