import { Button, DatePicker, Form, Select, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import AutosizeInput from "react-input-autosize";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { cloudFrontUrl, serverUrl } from "../../../../nestserver";
const { Option } = Select;

const StudyCertificateTemplate = ({ getAdmin, id }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [data, setData] = useState({
    name: "",
    admission_number: "",
    dob: null,
    parent_name: "",
    class: "",
    academic_year: "",
  });


  const updateDataForm = (paramName, value) => {
    setData({ ...data, [paramName]: value });
  };
  const studyCertificateData = [
    { label: "Name", field_name: "name" },
    { label: "Admission No.", field_name: "admission_number" },
    { label: "Dob", field_name: "dob" },
    { label: "Parent Name", field_name: "parent_name" },
    { label: "Class", field_name: "class" },
    { label: "Academic Year", field_name: "academic_year" },
  ];
  const handleFinish = () => {
    const payload = {
      form_type: "study_certificate",
      school: getAdmin?.data?.result?.data?._id,
      fields:
        studyCertificateData && studyCertificateData.length > 0
          ? studyCertificateData
          : [],
    };
    if (id) {
      axios
        .put(serverUrl + "/form-template/" + id, payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          queryClient?.invalidateQueries(["form-template"]);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            message.error(err?.response?.data?.message);
          }
        });
    } else if (studyCertificateData && studyCertificateData.length > 0) {
      axios
        .post(serverUrl + "/form-template/", payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          queryClient?.invalidateQueries(["form-template"]);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            message.error(err?.response?.data?.message);
          }
        });
    }
  };
  return (
    <>
      <div className="tc-doc">
        <div className="p-8">
          <div
            style={{ borderRadius: "10px", color: "#616161" }}
            className=" bg-white drop-shadow-md p-8  "
          >
            <div className="p-4 text-right">
              {" "}
              <Button size="large" onClick={() => history.goBack()}>
                Back{" "}
              </Button>{" "}
            </div>
            <div className="border ">
              <div className="upper-part ">
                <div className="flex flex-col">
                  <h3 className="text-base pb-3 flex items-center">
                    {" "}
                    <div className="relative w-20 h-20 rounded-full  border-8 border-white">
                      <img
                        className="w-full h-full rounded-full border"
                        src={
                          getAdmin?.data?.result?.data?.logo
                            ? `${cloudFrontUrl}/common/${getAdmin?.data?.result?.data?.logo}`
                            : "https://ui-avatars.com/api/?name=" +
                              (getAdmin?.data?.result?.data?.name
                                ? getAdmin?.data?.result?.data?.name
                                : getAdmin?.data?.result?.data?.name)
                        }
                        alt="banner"
                      />
                    </div>
                  </h3>
                </div>
                <div className="flex flex-col gap-2" style={{ width: "400px" }}>
                  <p className="text-base font-semibold pb-2 capitalize">
                    {getAdmin?.data?.result?.data?.name}
                  </p>
                  <p className="text-base font-semibold capitalize">{`${getAdmin?.data?.result?.data?.address}, ${getAdmin?.data?.result?.data?.city}, ${getAdmin?.data?.result?.data?.state}, ${getAdmin?.data?.result?.data?.pincode} `}</p>
                </div>
              </div>
              <div className="lower-part p-4">
                <h3 className="text-xl font-semibold text-center pb-4">
                  Study Certificate
                </h3>
                <h6 className="text-base">
                  {" "}
                  <span className="text-base font-semibold">
                    Aff No./School Code :
                  </span>{" "}
                  {getAdmin?.data?.result?.data?.refId}
                </h6>
                <div className="flex justify-between items-center pt-4 pb-8">
                  <h6 className="text-base">
                    <span className="text-base font-semibold">File No :</span>{" "}
                  </h6>
                  <h6 className="text-base">
                    <p className="text-base font-semibold">
                      Admission No : --------
                    </p>
                  </h6>
                </div>
                <div className="study-certificate">
                  <Form
                    form={form}
                    name="dynamic_rule"
                    autoComplete="off"
                  >
                    <div>
                      This is to certify that Mr/Miss &nbsp;
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("name", e.target.value);
                        }}
                        value={data?.name}
                        inputClassName="custom-input"
                      />
                      &nbsp; with the Admission No &nbsp;
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("admission_number", e.target.value);
                        }}
                        value={data?.admission_no}
                        inputClassName="custom-input"
                      />
                      &nbsp; DOB &nbsp;
                      <DatePicker
                        format="DD-MM-YYYY"
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                        className="input-field"
                        value={data.dob ? moment(data.dob) : null} // Set the value of the DatePicker
                        onChange={(date) =>
                          updateDataForm(
                            "dob",
                            moment(date).format("DD-MM-YYYY")
                          )
                        }
                        style={{ width: "100px" }}
                        clearIcon
                        suffixIcon={<div></div>}
                      />
                      &nbsp; son/daughter of Mr/Mrs &nbsp;{" "}
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("parent_name", e.target.value);
                        }}
                        value={data?.person_name}
                        inputClassName="custom-input"
                      />
                      &nbsp;
                      <span>
                        was study in &nbsp;{" "}
                        <AutosizeInput
                          type="text"
                          onChange={(e) => {
                            updateDataForm("class", e.target.value);
                          }}
                          value={data?.class}
                          inputClassName="custom-input"
                        />{" "}
                        in the year &nbsp;
                        <DatePicker
                          picker="year"
                          format="YYYY"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          suffixIcon={<div></div>}
                          style={{ width: "100px" }}
                          className="input-field text-black"
                          // inputClassName="custom-input"
                          onChange={(date) =>
                            updateDataForm(
                              "academic_year",
                              moment(date).format("YYYY")
                            )
                          }
                        />.
                      </span>
                    </div>
                    <div className="pt-6">
                      The above details are true to the best of my knowledge.
                    </div>
                    <div className=" pt-10 pb-10">
                      <div className="flex justify-between items-center pt-10">
                        <div className="flex gap-4  items-center">
                          <h3 className="text-base font-semibold">Date</h3>
                        </div>
                        <div className="flex gap-4 items-center">
                          <h3 className="text-base font-semibold">Signature</h3>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        type="primary"
                        size="large"
                        onClick={handleFinish}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyCertificateTemplate;
