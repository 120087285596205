import React, { useState } from "react";
import EventSchedule from "./EventSchedule";
import EventType from "./EventType";
import HomeSchedule from "./HomeSchedule";
import TimeTable from "./TimeTable";

const SwitchSchedule = () => {
  const [step, setStep] = useState(1);

  function switchTab() {
    switch (step) {
      case 1:
        return (
          <div>
            <EventSchedule />
          </div>
        );

      case 2:
        return (
          <div>
            <TimeTable />
          </div>
        );
      case 3:
        return (
          <div>
            <HomeSchedule />
          </div>
        );
      case 4:
        return (
          <div>
            <EventType />
          </div>
        );

      default:
        return (
          <div>
            <HomeSchedule />
          </div>
        );
    }
  }

  return (
    <>
      <div className="p-8">
        <div className="grid grid-cols-5 bg-white rounded-lg ">
          <div
            onClick={() => setStep(1)}
            className={`iskool-tab-item ${
              step === 1 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Home</div>
          </div>
          <div
            onClick={() => setStep(2)}
            className={`iskool-tab-item ${
              step === 2 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Time Table</div>
          </div>
          <div
            onClick={() => setStep(3)}
            className={`iskool-tab-item ${
              step === 3 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Event</div>
          </div>
          <div
            onClick={() => setStep(4)}
            className={`iskool-tab-item ${
              step === 4 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Event Type</div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------- */}
        <div>
          <div>{switchTab()}</div>
        </div>
      </div>
    </>
  );
};

export default SwitchSchedule;
