import React from "react";
import { Calendar } from "antd";
import NotificationWidget from "../widgets/NotificationWidget";

function onPanelChange(value, mode) {
}

function RightSideBar() {
  return (
    <>
      <div className="p-2">
        <div className="site-calendar-demo-card ">
          <Calendar fullscreen={false} onPanelChange={onPanelChange} />
        </div>

        {/* ------------------------------------------------------------------------------------------------ */}
        <div>
          <div className="flex flex-col pt-6">
            <div className="text-left text-black font-bold text-xl px-2">
              Notification
            </div>

            <div className=" p-2">
              <NotificationWidget />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RightSideBar;
