import { Button, Form, Table, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { useQueryClient } from "react-query";
import CustomCheckBox from "../../../../components/checkbox/CustomCheckBox";
import { serverUrl } from "../../../../nestserver";

const AttendanceTable = ({ classId, classDetails, isAdmin }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [checkboxState, setCheckboxState] = useState({});
  const [isToday, setIsToday] = useState({});
  const currentDate = moment().format("yyyy-MM-DD");

  useEffect(() => {
    if (
      classDetails?.isSuccess &&
      classDetails?.data?.isAttendanceEnabled &&
      classDetails?.data?.data?.length > 0
    ) {
      const initialCheckboxState = {};
      classDetails?.data?.isAttendanceEnabled &&
        classDetails?.data?.data?.forEach((student) => {
          const todayAttendance = student?.attendance?.find(
            (attendance) =>
              moment(attendance?.date).format("YYYY-MM-DD") === currentDate
          );
          if (todayAttendance) {
            initialCheckboxState[student?._id] = {
              is_present_morning: todayAttendance?.is_present_morning,
              is_present_afternoon: todayAttendance?.is_present_afternoon,
            };
          } else {
            initialCheckboxState[student?._id] = {
              is_present_morning: true,
              is_present_afternoon: true,
            };
          }
        });
      setCheckboxState(initialCheckboxState);
    }
  }, [ classDetails?.data?.data]);

  const handleCheckboxChange = (record, field, value) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [record._id]: {
        ...prevState[record._id],
        [`is_present_${field}`]: value,
      },
    }));
  };

  const payload = Object.entries(checkboxState).map(
    ([studentId, attendance]) => ({
      student_id: studentId,
      is_present_morning: attendance.is_present_morning,
      is_present_afternoon: attendance.is_present_afternoon,
    })
  );
  const handleSubmit = async () => {
    if (classId) {
      const dataToSend = {
        date: moment().format("YYYY-MM-DD"),
        student_attendance: payload,
      };
      if (!isToday) {
        axios({
          method: "post",
          url: serverUrl + "/attendance/class/" + classId,
          data: dataToSend,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
          .then(async (res) => {
            await queryClient.refetchQueries(["class", classId]);
            message.success(res?.data?.result?.message);
          })
          .catch((err) => {
            message.error(err?.response?.data?.error?.errors);
          });
      }
    }
  };

  const handleEdit = async () => {
    if (classId && isToday) {
      const currentDate = moment().format("YYYY-MM-DD");
      const edittedDataToSend = {
        date: currentDate,
        student_attendance: classDetails?.data?.data?.map((student) => {
          const todayAttendance = student?.attendance?.find(
            (attendance) =>
              moment(attendance?.date).format("YYYY-MM-DD") === currentDate
          );

          return {
            _id: todayAttendance?._id || null, // Use the _id of the attendance record for the current date
            is_present_morning: checkboxState[student?._id]?.is_present_morning,
            is_present_afternoon:
              checkboxState[student?._id]?.is_present_afternoon,
          };
        }),
      };

      axios({
        method: "patch",
        url: serverUrl + "/attendance/class/" + classId,
        data: edittedDataToSend,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
        .then(async (res) => {
          await queryClient.refetchQueries(["class", classId]);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          message.error(err?.response?.data?.error?.errors);
        });
    }
  };
  const columns = [
    {
      title: "Name",

      key: "",
      align: "center",
      render: (record) => (
        <div
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {`${record?.first_name} ${record?.last_name}`}
        </div>
      ),
    },
    {
      title: "Morning",
      key: "morning",
      align: "center",
      render: (record) => {
        const todayAttendance = record?.attendance.find(
          (attendance) =>
            moment(attendance?.date).format("YYYY-MM-DD") === currentDate
        );

        if (!isAdmin) {
          // Render a checkbox if isAdmin is false
          return (
            <CustomCheckBox
              checked={checkboxState[record?._id]?.is_present_morning}
              onChange={(e) =>
                handleCheckboxChange(record, "morning", e.target.checked)
              }
            />
          );
        }

        // Render attendance status if isAdmin is true
        if (!todayAttendance) {
          return <span>N/A</span>;
        }

        return todayAttendance?.is_present_morning ? (
          <span className="flex justify-center">
            <BiCheckCircle className="text-green-500" size={20} />
          </span>
        ) : (
          <span className="flex justify-center">
            <BiXCircle className="text-red-500" size={20} />
          </span>
        );
      },
    },
    {
      title: "Afternoon",
      key: "afternoon",
      align: "center",
      render: (record) => {
        const todayAttendance = record?.attendance.find(
          (attendance) =>
            moment(attendance?.date).format("YYYY-MM-DD") === currentDate
        );
        setIsToday(todayAttendance);

        if (!isAdmin) {
          // Render a checkbox if isAdmin is false
          return (
            <CustomCheckBox
              checked={checkboxState[record?._id]?.is_present_afternoon}
              onChange={(e) =>
                handleCheckboxChange(record, "afternoon", e.target.checked)
              }
            />
          );
        }

        // Render attendance status if isAdmin is true
        if (!todayAttendance) {
          return <span>N/A</span>;
        }

        return todayAttendance?.is_present_afternoon ? (
          <span className="flex justify-center">
            <BiCheckCircle className="text-green-500" size={20} />
          </span>
        ) : (
          <span className="flex justify-center">
            <BiXCircle className="text-red-500" size={20} />
          </span>
        );
      },
    },
    {
      title: "Attendance %",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {`${(record?.attendance_percent || 0).toFixed(2)}%`}
        </div>
      ),
    },
  ];
  return (
    <div className="mt-8">
      <Form form={form} onFinish={isToday ? handleEdit : handleSubmit}>
        <Table
          columns={columns}
          dataSource={
            classDetails?.isSuccess &&
            classDetails?.data &&
            classDetails?.data?.data?.length > 0
              ? classDetails?.data?.data
              : []
          }
          loading={classDetails?.isLoading}
        />
        {!isAdmin && classId && (
          <div className="text-center pt-4">
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="text-white"
            >
              {isToday ? "Save" : "Submit"}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};
export default AttendanceTable;
