import { Modal } from "antd";
import axios from "axios";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import DiscussionCard from "../../../../components/cards/DiscussionCard";
import { serverUrl } from "../../../../nestserver";
import { selectUser } from "../../../../redux/slices/auth.slice";
import getStudentStatusColor from "../../../../services/statusColor.service";
import StudentAttendanceHistory from "../../../admin/pages/components/managestudent/StudentAttendanceHistory";
import UploadImage from "../../../instructor/pages/CourseBoard/subject/maincourse/UploadImage";

function StudentSubjectAbout({ subjectDetails }) {
  const user = useSelector(selectUser);

  async function getDiscussions() {
    const data = await axios({
      method: "get",
      url: serverUrl + "/discussion",
      params: {
        subject: subjectDetails?._id,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return data.data;
  }

  const discussions = useQuery(
    ["discussions", subjectDetails?._id],
    getDiscussions,
    {
      enabled: !!subjectDetails?._id,
    }
  );

  const totalCredit = useMemo(() => {
    let credit = 0;
    credit = subjectDetails?.credits?.reduce((accumulator, current) => {
      return accumulator + parseInt(current.score);
    }, credit);

    return credit;
  }, [subjectDetails?.credits]);

  const getAttendance = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/attendance/",
      params: {
        student: user?.student?._id,
        subject: subjectDetails?._id,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };

  const totalAttendance = useQuery(
    ["attendance", subjectDetails?._id],
    getAttendance
  );

  const [showAttendanceHistory, setShowAttendanceHistory] = useState(false);

  return (
    <>
      <div>
        <div className="pt-8">
          <div className="bg-white w-full rounded-2xl ">
            <div className="flex flex-col items-start justify-start p-6  text-left space-y-4 w-full">
              <div className="grid grid-cols-2 gap-6 w-full">
                <div>
                  <div
                    style={{ color: "#E46E31" }}
                    className="text-2xl font-semibold inline-flex items-center space-x-4"
                  >
                    <div>{subjectDetails?.name}</div>
                    <div
                      className="inline-block px-4 rounded-lg capitalize text-white"
                      style={{
                        background: getStudentStatusColor(
                          user?.student?.subject_status &&
                            (user?.student?.subject_status[
                              subjectDetails?._id
                            ] ??
                              "on track")
                        ),
                        fontSize: "14px",
                      }}
                    >
                      {user?.student?.subject_status
                        ? user?.student?.subject_status[subjectDetails?._id] ??
                          "on track"
                        : "on track"}
                    </div>
                  </div>
                  <div className="flex flex-row space-x-10">
                    <div
                      style={{ color: "#AEADAE" }}
                      className="text-xl font-normal"
                    >
                      Instructor :{" "}
                      {subjectDetails?.teacher?.teacher?.first_name}{" "}
                      {subjectDetails?.teacher?.teacher?.last_name}
                    </div>
                  </div>
                </div>
                {/* <div className="flex justify-end">
                  <div>
                    <div
                      style={{ color: "#E46E31" }}
                      className="text-2xl font-semibold text-left pb-4"
                    >
                      Total Attendance
                    </div>
                    <div className="flex items-center space-x-4 font-bold text-lg text-grey-900">
                      <div>
                        {totalAttendance?.data?.filter(
                          (s) => s?.attended === true
                        )?.length
                          ? (
                              (totalAttendance?.data?.filter(
                                (s) => s?.attended === true
                              )?.length /
                                totalAttendance?.data?.length) *
                              100
                            ).toFixed(2) + "%"
                          : "Not Available"}
                      </div>
                      <div>
                        <Button onClick={() => setShowAttendanceHistory(true)}>
                          View History
                        </Button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div
                style={{ color: "#616161" }}
                className="text-base font-semibold"
              >
                <div className="text-lg font-semibold text-primary">
                  About the course
                </div>
                <div
                  style={{ color: "#AEADAE" }}
                  className="font-normal text-base py-3 pr-12"
                >
                  {subjectDetails?.about}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-10 w-full">
                <div>
                  <div
                    style={{ color: "#E46E31" }}
                    className="text-2xl font-semibold text-left"
                  >
                    Credit Score
                  </div>

                  <div className="py-1">
                    <div className="w-60 border border-gray-400 p-3 rounded-sm space-y-2">
                      {subjectDetails?.credits?.map((credit) => (
                        <div
                          key={credit?._id}
                          className="flex items-center justify-between"
                        >
                          <div className="text-base text-primary font-normal">
                            {credit?.title}
                          </div>
                          <div className="text-base text-primary font-normal">
                            {credit?.score}
                          </div>
                        </div>
                      ))}

                      <div className="flex items-center justify-between">
                        <div className="text-base text-black font-semibold">
                          Total
                        </div>
                        <div className="text-base text-black font-semibold">
                          {totalCredit}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Attendance History Popup */}
        <Modal
          title="Attendance History"
          width={900}
          visible={showAttendanceHistory}
          onOk={() => setShowAttendanceHistory(false)}
          onCancel={() => setShowAttendanceHistory(false)}
          footer={null}
        >
          <StudentAttendanceHistory data={user} subjects={[subjectDetails]} />
        </Modal>
        {/* Attendance History Popup */}

        <div className="py-8">
          {/* This component is allocated for Instructor */}
          {user.role !== "parent" && (
            <UploadImage user={user} data={subjectDetails} />
          )}

          <div className="pt-8">
            <div className="space-y-5">
              {discussions?.isSuccess &&
                discussions?.data?.map((item) => {
                  return (
                    <DiscussionCard
                      enableDelete={item?.user?._id === user?._id}
                      enableDeleteComment={true}
                      key={item?._id}
                      data={item}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentSubjectAbout;
