import { Form, Input, message, Select, Tag } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../nestserver";

function tagRender(props) {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
      className="flex items-center"
    >
      {label}
    </Tag>
  );
}

export default function TransferStudentForm({ data, classDetails, onClose }) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  // fetch classes
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  /* -------------------------------------------------------------------------- */
  /*                              Transfer Students                             */
  /* -------------------------------------------------------------------------- */
  function transferStudents(values) {
    axios({
      method: "post",
      url: serverUrl + "/students/transfer",
      params: values,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Students transferred successfully");
        queryClient.invalidateQueries("students");
        onClose && onClose();
      })
      .catch((err) => {
        message.error("Error transferring students");
      });
  }

  useEffect(() => {
    form.resetFields();
  }, [data]);

  return (
    <div>
      <Form
        onFinish={transferStudents}
        form={form}
        layout="vertical"
        initialValues={{
          students: data.map((s) => s._id),
          current_class:
            classDetails?.name +
            " - " +
            moment(classDetails?.academic_start_date).format("Do MMM YYYY") +
            " to " +
            moment(classDetails?.academic_end_date).format("Do MMM YYYY"),
        }}
      >
        <Form.Item label="Students" name="students">
          <Select
            mode="multiple"
            showArrow
            tagRender={tagRender}
            placeholder="Select students"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{
              border: "6px",
              backgroundColor: "#EBEBEB",
            }}
            className="w-3/4"
          >
            {data?.map((data) => {
              return (
                <Option key={data._id} value={data._id}>
                  {data.student?.first_name + " " + data.student?.last_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Current Class and Year" name="current_class">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label="Transfer Class" name="class">
          <Select
            mode="single"
            showArrow
            tagRender={tagRender}
            placeholder="Select Class"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{
              border: "6px",
              backgroundColor: "#EBEBEB",
            }}
            className="w-3/4"
          >
            {classes?.data?.map((data) => {
              return (
                <Option key={data?._id} value={data?._id}>
                  {data?.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <div className="flex items-center justify-end">
          <Button htmlType="submit" type="primary">
            Transfer
          </Button>
        </div>
      </Form>
    </div>
  );
}
