import { LockOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { serverUrl } from "../nestserver";
import { loginUser } from "../redux/slices/auth.slice";

import comapnyLogo from "../../src/images/TwinBotsLogo.svg";
import appstore from "../../src/images/appStore.svg";
import comapnyName from "../../src/images/comapnyName.svg";
import playStore from "../../src/images/googlePlay.svg";
import isKoolImage from "../../src/images/isKoolImage.png";
import isKoolLogo from "../../src/images/isKoolLogo.png";
import isKoolTagLine from "../../src/images/isKoolTagline.png";
import OtpInput from "../components/otpInput";
import { role } from "../helper/options";

const LoginPage = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState("admin");
  const [otpField, setOtpField] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [loginFeilds, setLoginFeilds] = useState({
    studentId: "",
    email: "",
    phone: "",
    password: "",
    schoolId: "",
    role: "",
  });
  const [otpGenerated, setOtpGenerated] = useState(false);
  const handleLoginField = (paramName, value) => {
    setLoginFeilds({ ...loginFeilds, [paramName]: value });
  };

  function onFinish(values) {
    const data = {
      role: selectedRole,
      schoolId: values.schoolId,
      password: values.password,
    };
    if (selectedRole === role.student) {
      data.studentId = values.studentId;
      data.email = null;
    } else {
      data.email = values.email;
      data.studentId = null;
    }
    if (otpField) {
      const verifyOtpPayload = {
        role: selectedRole,
        schoolId: loginFeilds.schoolId,
        phone: loginFeilds.phone,
        studentId: null,
        otp,
      };

      if (selectedRole === role.student) {
        verifyOtpPayload.studentId = loginFeilds.studentId;
      }
      axios
        .post(serverUrl + "/auth/verify-otp", verifyOtpPayload)
        .then((res) => {
          if (res?.data?.user?.blocked) {
            message.error(
              "Access Denied. Please contact admin to revoke your access"
            );
          } else {
            dispatch(loginUser(res?.data?.result?.user));
            localStorage.setItem("jwt-token", res?.data?.result?.access_token);
            message.success("Login Successful");
            history.push("/dashboard");
          }
        })
        .catch((error) => {
          message.error("Invalid Otp");
        });
      return;
    }
    axios
      .post(serverUrl + "/auth/login", data)
      .then((res) => {
        if (res?.data?.user?.blocked) {
          message.error(
            "Access Denied. Please contact admin to revoke your access"
          );
        } else {
          dispatch(loginUser(res?.data?.result?.user));
          localStorage.setItem("jwt-token", res?.data?.result?.access_token);
          message.success("Login Successful");
          history.push("/dashboard");
        }
      })
      .catch((error) => {
        form.setFieldsValue({
          email: "",
          password: "",
          schoolId: "",
        });
        message.error("Invalid Credentials");
      });
  }

  const handlePhoneLogin = () => {
    setOtpField((prevState) => !prevState);
    setShowOtpInput(false);
  };

  const handleGenerateOtp = () => {
    const sendOtpPayload = {
      role: selectedRole,
      schoolId: loginFeilds.schoolId,
      phone: loginFeilds.phone,
      studentId: null,
    };
    if (selectedRole === role.student) {
      sendOtpPayload.studentId = loginFeilds.studentId;
    }

    axios
      .post(serverUrl + "/auth/send-otp", sendOtpPayload)
      .then((res) => {
        message.success(res?.data?.result?.message);
        setShowOtpInput(true);
        setOtpGenerated(true);
      })
      .catch((error) => {
        message.error("Invalid Credentials");
      });
  };
  const onOtpSubmit = (credential) => {
    setOtp(credential);
  };

  return (
    <div
      style={{
        backgroundImage: `url("/assets/login-bg-new.png")`,
      }}
      className="w-full min-h-screen bg-cover background-attachment"
    >
      <div className=" h-full w-full">
        <div className="flex h-full items-center md:mx-11 mx-4">
          <div className="w-full flex gap-5 justify-between">
            <div className="md:text-5xl text-4xl  md:w-2/3 text-left md:my-0 my-8 relative h-screen">
              <div className="flex justify-between absolute bottom-0 pb-10 w-full items-center">
                <div className="flex gap-x-4">
                  <img src={playStore} alt="logo" width={100} />
                  <img src={appstore} alt="logo" width={100} />
                </div>
                <div className="flex">
                  <img src={comapnyLogo} alt="logo" width={100} />
                  <div className="pt-10">
                    <img src={comapnyName} alt="logo" width={150} />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ borderRadius: "10px" }}
              className="w-96  flex flex-col justify-center"
            >
              <img
                src={isKoolLogo}
                alt="isKool"
                height={150}
                width={150}
                style={{ margin: "0 auto" }}
              />
              <img
                src={isKoolImage}
                alt="isKool"
                height={150}
                width={170}
                style={{ margin: "0 auto" }}
              />
              <img
                src={isKoolTagLine}
                alt="isKool"
                height={200}
                width={400}
                style={{ marginBottom: "50px", marginTop: "-10px" }}
              />
              <Form
                form={form}
                name="normal_login"
                className="login-form pt-3"
                onFinish={onFinish}
                layout="vertical"
              >
                <Form.Item name="role" className="input-font-weight">
                  <Select
                    value={selectedRole}
                    onChange={(value) => {
                      setSelectedRole(value);
                      setOtpField(false); // Reset otpField when role changes
                      setShowOtpInput(false);
                    }}
                    defaultValue={"admin"}
                    placeholder="Select a role"
                  >
                    <Select.Option value="admin">Admin</Select.Option>
                    <Select.Option value="instructor">Instructor</Select.Option>
                    <Select.Option value="student">Student</Select.Option>
                    <Select.Option value="parent">Parent</Select.Option>
                  </Select>
                </Form.Item>
                {selectedRole === "student" && (
                  <Form.Item
                    label="Student Id"
                    name="studentId"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Student Id!",
                      },
                    ]}
                    className="input-font-weight"
                  >
                    <Input
                      placeholder="Student Id"
                      className="rounded-full h-10"
                      onChange={(e) => {
                        handleLoginField("studentId", e.target.value);
                      }}
                    />
                  </Form.Item>
                )}
                {!otpField && selectedRole !== "student" ? (
                  <Form.Item
                    label="Email Id"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please input your Email!",
                      },
                    ]}
                    className="input-font-weight"
                  >
                    <Input
                      placeholder="Email"
                      type="email"
                      className="rounded-full h-10"
                      onChange={(e) => {
                        handleLoginField("email", e.target.value);
                      }}
                    />
                  </Form.Item>
                ) : (
                  <>
                    {otpField && (
                      <>
                        {/* <Form.Item
                          label="Phone Number"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Phone Number!",
                            },
                            {
                              pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                              message:
                                "Please enter a valid 10-digit phone number!",
                            },
                          ]}
                          className="input-font-weight"
                          getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                        >
                          <Input
                            prefix={
                              <PhoneOutlined className="site-form-item-icon" />
                            }
                            placeholder="Phone Number"
                            className="rounded-full h-10"
                            type="number"
                            maxLength={10}
                            min={0}
                            onChange={(e) => {
                              handleLoginField("phone", e.target.value);
                            }}
                          />
                        </Form.Item> */}
                        <Form.Item
                          label="Phone Number"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Phone Number!",
                            },
                            {
                              pattern: /^[0-9]{10}$/,
                              message:
                                "Please enter a valid 10-digit phone number!",
                            },
                          ]}
                          className="input-font-weight"
                          getValueFromEvent={(e) =>
                            e.target.value.replace(/\D/g, "").slice(0, 10)
                          }
                        >
                          <Input
                            prefix={
                              <PhoneOutlined className="site-form-item-icon" />
                            }
                            placeholder="Phone Number"
                            className="rounded-full h-10"
                            type="text"
                            maxLength={10}
                            onChange={(e) => {
                              handleLoginField(
                                "phone",
                                e.target.value.replace(/\D/g, "").slice(0, 10)
                              );
                            }}
                          />
                        </Form.Item>
                      </>
                    )}
                  </>
                )}
                {!otpField && (
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                      {
                        min: 6,
                        message: "Password must be at least 6 characters long!",
                      },
                    ]}
                    className="input-font-weight"
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="Password"
                      className="rounded-full h-10"
                      onChange={(e) => {
                        handleLoginField("password", e.target.value);
                      }}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label="School Id"
                  name="schoolId"
                  rules={[
                    {
                      required: true,
                      message: "Please input your School id !",
                    },
                  ]}
                  className="input-font-weight"
                >
                  <Input
                    type="school"
                    placeholder="School Id"
                    className="rounded-full h-10"
                    onChange={(e) => {
                      handleLoginField("schoolId", e.target.value);
                    }}
                  />
                </Form.Item>
                {showOtpInput && (
                  <div className="flex justify-center pb-4">
                    <OtpInput
                      length={6}
                      onOtpSubmit={onOtpSubmit}
                      otpGenerated={otpGenerated}
                    />
                  </div>
                )}
                {otpField && (
                  <div className="text-center pb-2">
                    <Button
                      htmlType="button"
                      className="rounded-full px-8 input-font-weight"
                      onClick={handleGenerateOtp}
                    >
                      {otpGenerated ? "Resend OTP" : "Generate OTP"}
                    </Button>
                  </div>
                )}
                {selectedRole !== "admin" && (
                  <Form.Item class>
                    <div className="flex flex-col space-y-2">
                      <div className="w-full text-right">
                        <Button
                          type="link"
                          className="underline text-right"
                          onClick={handlePhoneLogin}
                        >
                          {otpField ? "Try using Email" : "Try using OTP"}
                        </Button>
                      </div>
                    </div>
                  </Form.Item>
                )}
                <Form.Item>
                  <div className="flex flex-col space-y-4">
                    <div className="w-full text-center">
                      <Button
                        htmlType="submit"
                        className="rounded-full px-8 input-font-weight"
                      >
                        Login
                      </Button>
                    </div>
                    <a
                      className="underline input-font-weight text-right"
                      href={`/auth/password/forgot-password?role=${encodeURIComponent(
                        selectedRole
                      )}`}
                    >
                      Click here to reset password
                    </a>
                  </div>
                </Form.Item>
              </Form>
              {/* form end  */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
