import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { serverUrl } from "../nestserver";

/* -------------------------------------------------------------------------- */
/*                                 Create Notification                               */
/* -------------------------------------------------------------------------- */

export function useCreateNotification(mutationOptions) {
  // axios post
  function createNotification(data) {
    return axios.post(
      serverUrl + "/notification",
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }
    );
  }

  return useMutation(createNotification, mutationOptions);
}

/* -------------------------------------------------------------------------- */
/*                               Get Single Notification                             */
/* -------------------------------------------------------------------------- */

export function useGetNotification(id, queryOptions) {
  // get notifications
  async function getNotification() {
    const res = await axios.get(serverUrl + "/notification/" + id, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  return useQuery(["notification", id], getNotification, queryOptions);
}

/* -------------------------------------------------------------------------- */
/*                                Get All Notifications                               */
/* -------------------------------------------------------------------------- */

/**
 * Get all notifications
 * @param {import("react-query").UseQueryOptions} queryOptions
 * @returns {import("react-query").UseQueryResult<any>}
 */
export function useNotifications(queryOptions) {
  // get notifications
  async function getNotifications() {
    const res = await axios.get(serverUrl + "/notification/mine", {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  return useQuery("notifications", getNotifications, queryOptions);
}

/* -------------------------------------------------------------------------- */
/*                   Get All announcement Notifications                      */
/* -------------------------------------------------------------------------- */

/**
 * Get all notifications
 * @param {import("react-query").UseQueryOptions} queryOptions
 * @returns {import("react-query").UseQueryResult<any>}
 */
export function useAnnouncementNotifications(queryOptions) {
  // get notifications
  async function getNotifications() {
    const res = await axios.get(
      serverUrl + "/notification/mine/announcements",
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }
    );
    return res.data;
  }

  return useQuery("announcement_notifications", getNotifications, queryOptions);
}
/* -------------------------------------------------------------------------- */
/*                             Update Notification                                   */
/* -------------------------------------------------------------------------- */

export function useUpdateNotification(mutationOptions) {
  // axios patch
  function updateNotification([id, data]) {
    return axios.patch(serverUrl + "/notification/" + id, data, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
  }

  return useMutation(updateNotification, mutationOptions);
}

/* -------------------------------------------------------------------------- */
/*                                 Update Many Notification                               */
/* -------------------------------------------------------------------------- */

export function useUpdateManyNotification(mutationOptions) {
  // axios post
  function updateNotification(data) {
    return axios({
      method: "post",
      url: serverUrl + "/notification/dismiss-many",
      params: data,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
  }

  return useMutation(updateNotification, mutationOptions);
}
export function useUpdateNotificationCount(mutationOptions) {
  // axios post
  function updateNotificationCount(data) {
    return axios({
      method: "post",
      url: serverUrl + "/notification/count",
      params: data,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
  }

  return useMutation(updateNotificationCount, mutationOptions);
}
/* -------------------------------------------------------------------------- */
/*                                 Delete Notification                               */
/* -------------------------------------------------------------------------- */

export function useDeleteNotification(mutationOptions) {
  // axios post
  function deleteNotification(id) {
    return axios.delete(serverUrl + "/notification/" + id, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
  }

  return useMutation(deleteNotification, mutationOptions);
}
