import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/auth.slice";

function EducationList() {
  const user = useSelector(selectUser);

  return (
    <div>
      <div className="flex-grow bg-white rounded-xl pb-8 relative">
        <div className="p-5">
          <div className="text-2xl font-medium text-black text-left px-5 pb-6">
            Education
          </div>
          <div className="flex flex-col space-y-2 px-5">
            {user?.teacher?.educations ? (
              user?.teacher?.educations.map((item) => (
                <div className="">
                  <div className="inline-flex space-x-6 items-center text-xl rounded-lg bg-gray-100 bg-opacity-50 py-2 px-3">
                    <div className="font-bold p-1 px-2 bg-primary-500 bg-opacity-30 rounded-lg">
                      {item?.year}
                    </div>
                    <div>{item?.title}</div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center py-6 ">
                <div className="text-xl font-light ">No Education To Show</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EducationList;
