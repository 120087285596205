import React from "react";
import AssignmentExam from "../../AssignmentExam";

function Evaluation({ subject }) {
  return (
    <div>
      <AssignmentExam
        canCreate={true}
        subjectId={subject?._id}
        classId={subject?.class?._id}
      />
    </div>
  );
}

export default Evaluation;
