import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  message,
} from "antd";
import axios from "axios";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  AiOutlineClose,
  AiOutlineFile,
  AiOutlineFilePdf,
  AiOutlineUpload,
} from "react-icons/ai";
import { useQuery, useQueryClient } from "react-query";
import { cloudFrontUrl, serverUrl } from "../../../../nestserver";
const { Option } = Select;

const InstructorParentConversation = ({
  classes,
  setMessagePopupOpen,
  messagePopupOpen,
  conversationMessage,
  setConversationMessage,
  senderDetails,
  instructorMessage,
  setSenderDetails,
  instructorAdminChat,
  setInstructorAdminChat,
  setConversationId,
  classId,
  setStudentId,
  setClassId,
  studentId,
  setUploadDoc,
  uploadDoc,
  setMessageData,
  messageData,
  resetTrigger,
  parentDetails,
  setParentDetails,
}) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const inputRef = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const messageContainerRef = React.useRef(null);

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  const handleImageClick = (src) => {
    setModalImageSrc(src);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalImageSrc("");
  };

  const handleClassChange = (value) => {
    setClassId(value);
    setStudentId(null);
    setParentDetails(null);
    form.setFieldsValue({
      student: undefined,
      parent: "",
    });
    setConversationMessage(null);
    setInstructorAdminChat(null);
  };

  const handleClick = (conversationId, item) => {
    instructorMessage(conversationId, item);
  };

  const handleStudentChange = (value) => {
    setStudentId(value);
    form.setFieldsValue({ student: value });
  };

  const handleFileSelect = async (event) => {
    const files = event.target.files;

    if (!files || files?.length === 0) return;

    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ];

    const maxTotalFiles = 15;
    const formDataPdf = new FormData();
    const formDataImages = new FormData();
    let validFileCount = 0;
    let invalidTypeFiles = 0;

    // Check total number of files including existing uploads
    const totalFiles =
      (Array.isArray(uploadDoc) ? uploadDoc.length : 0) + files.length;
    if (totalFiles > maxTotalFiles) {
      message.error(`You can only upload ${maxTotalFiles} files`);
      return;
    }

    for (let i = 0; i < files?.length; i++) {
      const file = files[i];

      if (!allowedTypes.includes(file.type)) {
        message.error(
          `Invalid file type for ${file.name}. Please upload PDF, JPG, or PNG files only.`
        );
        invalidTypeFiles++;
        continue;
      }

      if (file.type === "application/pdf") {
        formDataPdf.append("files", file);
      } else {
        formDataImages.append("files", file);
      }
      validFileCount++;
    }

    if (validFileCount === 0 || invalidTypeFiles > 0) {
      return;
    }

    formDataPdf.append("folder", "pdf");
    formDataImages.append("folder", "images");

    try {
      let responses = [];

      if (formDataPdf.has("files")) {
        const resPdf = await axios.post(
          serverUrl + "/uploads/docs",
          formDataPdf,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        responses = responses.concat(resPdf.data);
      }

      if (formDataImages.has("files")) {
        const resImages = await axios.post(
          serverUrl + "/uploads/docs",
          formDataImages,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        responses = responses.concat(resImages?.data);
      }

      message.success("Files Upload Success");
      setUploadDoc((prevDocs) => {
        const newDocs = responses.reduce((acc, item) => {
          if (item?.files?.name && item?.id) {
            acc.push({
              name: item?.files?.name,
              id: item?.id,
            });
          }
          return acc;
        }, []);

        const updatedDocs = Array.isArray(prevDocs)
          ? [...prevDocs, ...newDocs]
          : newDocs;

        // Ensure total number of files doesn't exceed maxTotalFiles
        return updatedDocs.slice(0, maxTotalFiles);
      });
    } catch (error) {
      message.error("Files Upload Failed");
    }
  };

  const handleMessageChange = (e) => {
    setMessageData(e.target.value);
  };
  const QUERY_KEY = ["/conversations/messages", parentDetails?._id];

  const handleSubmit = async () => {
    const payload = {
      type: "personal",
      conversation_id: senderDetails ? senderDetails?._id : null,
      target_id: parentDetails ? parentDetails?._id : null,
      subject_id: null,
      media: uploadDoc && uploadDoc?.length > 0 ? uploadDoc : [],
      message: messageData ? messageData : null,
    };

    axios
      .post(serverUrl + "/conversations", payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success(res?.data?.result?.message);
        queryClient.invalidateQueries(QUERY_KEY);
        queryClient.refetchQueries(QUERY_KEY);
        if (senderDetails) {
          handleClick(senderDetails?._id, senderDetails);
        }
        setMessageData("");
        setUploadDoc(null);
      })
      .catch((err) => {
        if (err?.response?.data?.error?.errors) {
          message.error("Please Select Class and Student");
        }
      });
  };
  const onRemove = (index) => {
    setUploadDoc((oldDoc) => oldDoc.filter((_, i) => i !== index));
    message.success("File Removed");
  };

  const deleteMessage = (messageId) => {
    axios({
      method: "delete",
      url:
        serverUrl +
        "/conversations/" +
        senderDetails?._id +
        "/message/" +
        messageId,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        queryClient.invalidateQueries(QUERY_KEY);
        queryClient.refetchQueries(QUERY_KEY);
        if (senderDetails) {
          handleClick(senderDetails?._id, senderDetails);
        }
        message.success(res?.data?.result?.message);
      })
      .catch((err) => console.error(err));
  };

  const fetchStudentData = async () => {
    const res = await axios.get(serverUrl + "/students/class/" + classId, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  };
  const classDetails = useQuery(["class", classId], fetchStudentData, {
    enabled: !!classId,
  });

  useEffect(() => {
    if (studentId && classDetails?.data) {
      const studentData = classDetails.data.find(
        (student) => student?._id === studentId
      );
      if (studentData?.parent) {
        const parentName = `${studentData?.parent?.first_name || ""} ${
          studentData?.parent?.last_name || ""
        }`.trim();
        form.setFieldsValue({
          parent: parentName || "",
        });
        setParentDetails(studentData?.parent);
      } else {
        form.setFieldsValue({
          parent: "",
        });
        setParentDetails(null);
      }
    } else {
      form.setFieldsValue({
        parent: "",
      });
      setParentDetails(null);
    }
  }, [studentId, classDetails?.data]);

  useEffect(() => {
    scrollToBottom();
  }, [conversationMessage, instructorAdminChat]);

  useEffect(() => {
    if (messagePopupOpen) {
      form.setFieldsValue({
        class: undefined,
        student: undefined,
        parent: "",
      });
      setClassId(null);
      setStudentId(null);
      setParentDetails(null);
    }
  }, [messagePopupOpen]);

  const resetEverything = () => {
    setSenderDetails(null);
    setConversationMessage(null);
    setInstructorAdminChat(null);
    setConversationId(null);
    setParentDetails(null);
    setClassId(null);
    setStudentId(null);
    setMessageData("");
    setUploadDoc(null);

    // Reset form fields
    form.setFieldsValue({
      class: undefined,
      student: undefined,
      parent: "",
    });

    // Force re-render of parent field
    setTimeout(() => {
      form.setFieldsValue({
        parent: "",
      });
    }, 0);
  };

  useEffect(() => {
    if (resetTrigger > 0 || messagePopupOpen) {
      form.resetFields(["class", "student", "parent"]);
      setClassId(null);
      setStudentId(null);
      setParentDetails(null);
    }
  }, [resetTrigger, messagePopupOpen, form]);

    useEffect(() => {
      if (uploadDoc?.length > 0 && inputRef.current) {
        inputRef.current.focus();
      }
    }, [uploadDoc]);
  return (
    <div className="pl-4 h-full">
      <div className="flex justify-between items-center pb-4">
        <h1 className="pb-2 text-base font-semibold">Message</h1>
        <AiOutlineClose
          size={20}
          onClick={() => {
            resetEverything();
            setMessagePopupOpen(false);
          }}
          className="cursor-pointer"
        />
      </div>
      {senderDetails?.connection?.admin || senderDetails?.connection?.parent ? (
        <div className="text-base font-semibold">
          {`${
            senderDetails?.connection?.admin?.first_name ||
            senderDetails?.connection?.parent?.first_name
          } ${
            senderDetails?.connection?.admin?.last_name ||
            senderDetails?.connection?.parent?.last_name
          }`}
        </div>
      ) : (
        <div>
          <Form
            form={form}
            name="dynamic_rule"
            autoComplete="off"
            className="flex"
            style={{ gap: "16px" }}
          >
            <Form.Item name="class" label="Class">
              <Select
                showSearch
                showArrow
                placeholder="Select Class"
                className="w-full bg-gray-300"
                style={{
                  border: "6px",
                  backgroundColor: "#EBEBEB",
                  width: "100px",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={handleClassChange}
              >
                {classes?.isSuccess && classes?.data?.length > 0
                  ? classes.data.map((data) => (
                      <Option key={data._id} value={data._id}>
                        {data.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
            <Form.Item name="student" label="Student">
              <Select
                showSearch
                showArrow
                placeholder="Select "
                className="w-full bg-gray-300"
                style={{
                  border: "6px",
                  backgroundColor: "#EBEBEB",
                  width: "160px",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={handleStudentChange}
              >
                {classId &&
                classDetails?.isSuccess &&
                classDetails?.data?.length > 0
                  ? classDetails.data.map((data) => (
                      <Option key={data?._id} value={data?._id}>
                        {`${data?.first_name} ${data?.last_name}`}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
            <Form.Item
              name="parent"
              label="Parent"
              style={{
                width: "200px",
              }}
            >
              <Input
                readOnly
                value={
                  form.getFieldValue("parent") ||
                  (parentDetails
                    ? `${parentDetails?.first_name} ${parentDetails?.last_name}`
                    : "")
                }
              />
            </Form.Item>
          </Form>
        </div>
      )}
      {conversationMessage?.messages ||
      instructorAdminChat?.result?.messages ? (
        <div
          className={clsx(
            "pt-4",
            uploadDoc && uploadDoc?.length > 0
              ? "message-chat-content-without"
              : "message-chat-content-instructors "
          )}
          ref={messageContainerRef}
        >
          {(
            conversationMessage?.messages ||
            instructorAdminChat?.result?.messages
          )?.map((message) => {
            const isTeacher = message?.sender?.teacher;
            const isAdmin = message?.sender?.admin;
            const isParent = message?.sender?.parent;

            if (isTeacher || isAdmin || isParent) {
              return (
                <div
                  key={message?._id}
                  style={{
                    width: "70%",
                    marginLeft: isTeacher ? "auto" : "0",
                    marginRight: isAdmin || isParent ? "auto" : "0",
                  }}
                >
                  <div
                    className={`flex items-center gap-4 pb-10 pr-2 ${
                      isTeacher ? "justify-end" : "justify-start"
                    }`}
                  >
                    <div className="flex flex-col gap-2">
                      <span>
                        {`${
                          message?.sender?.admin?.first_name ||
                          message?.sender?.parent?.first_name ||
                          message?.sender?.teacher?.first_name
                        } ${
                          message?.sender?.admin?.last_name ||
                          message?.sender?.parent?.last_name ||
                          message?.sender?.teacher?.last_name
                        } `}
                      </span>
                      <span>
                        {moment(message?.createdAt).format("DD-MM-YYYY HH:MM")}
                      </span>
                    </div>
                    <div
                      className={`${
                        isTeacher ? "message-box" : "message-box-other"
                      }`}
                    >
                      {message?.media && (
                        <div className="media-container">
                          {message?.media?.name
                            ?.toLowerCase()
                            .endsWith(".jpeg") ||
                          message?.media.name?.toLowerCase().endsWith(".jpg") ||
                          message?.media.name
                            ?.toLowerCase()
                            .endsWith(".png") ? (
                            <>
                              <img
                                src={`${cloudFrontUrl}/images/${message?.media.id}`}
                                alt={message?.media.name}
                                onClick={() =>
                                  handleImageClick(
                                    `${cloudFrontUrl}/images/${message?.media.id}`
                                  )
                                }
                                className="media-image cursor-pointer"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "fallback-image-url";
                                }}
                              />
                            </>
                          ) : message?.media.name
                              .toLowerCase()
                              .endsWith(".pdf") ? (
                            <a
                              href={`${cloudFrontUrl}/pdf/${message?.media.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="pdf-link"
                            >
                              <AiOutlineFilePdf size={35} />
                              {message?.media.name}
                            </a>
                          ) : (
                            <img
                              src={`${cloudFrontUrl}/images/${message?.media?.id}`}
                              alt={message?.media.name}
                              onClick={() =>
                                handleImageClick(
                                  `${cloudFrontUrl}/images/${message?.media?.id}`
                                )
                              }
                              className="media-image cursor-pointer"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "fallback-image-url";
                              }}
                            />
                          )}
                        </div>
                      )}
                      {message?.message}

                      <Modal
                        visible={isModalVisible}
                        footer={null}
                        onCancel={handleCancel}
                        centered
                      >
                        <img
                          src={modalImageSrc}
                          alt="Preview"
                          style={{
                            width: "100%",
                            marginTop: "20px",
                          }}
                        />
                      </Modal>
                    </div>

                    {isTeacher && (
                      <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => deleteMessage(message?._id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          type="danger flex items-center justify-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    )}
                  </div>
                </div>
              );
            }
          })}
        </div>
      ) : (
        <div
          className={clsx(
            "flex justify-center items-center",
            uploadDoc && uploadDoc?.length > 0
              ? "message-chat-content-without"
              : "message-chat-content"
          )}
        >
          No messages found
        </div>
      )}
      <div>
        {uploadDoc && uploadDoc?.length > 0 && (
          <div className="flex overflow-auto" style={{ marginLeft: "40px" }}>
            {uploadDoc.map((doc, index) => (
              <Card
                key={index}
                size="small"
                className="mb-2"
                bodyStyle={{
                  padding: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="flex items-center">
                  <AiOutlineFile style={{ marginRight: "4px" }} />
                  <span className="truncate">{doc.name}</span>
                </div>
                <Button
                  type="text"
                  icon={<AiOutlineClose style={{ marginLeft: "12px" }} />}
                  onClick={() => onRemove(index)}
                  style={{ marginLeft: "auto" }}
                />
              </Card>
            ))}
          </div>
        )}
        <div className=" flex items-center gap-4">
          <div className="flex  flex-col" style={{ gap: "10px" }}>
            <AiOutlineUpload
              size={25}
              className="cursor-pointer"
              onClick={() => document.getElementById("fileInput").click()}
            />
            <input
              id="fileInput"
              type="file"
              style={{ display: "none" }}
              multiple
              accept=".pdf, .jpeg, .jpg, .png"
              onChange={handleFileSelect}
            />
          </div>
          <Input.TextArea
            ref={inputRef}
            rows={3}
            placeholder="Type message..."
            className="w-full border focus:outline-none resize-none"
            value={messageData}
            onChange={handleMessageChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />
          {((messageData && messageData.trim().length > 0) ||
            uploadDoc?.length > 0) && (
            <Button size="large" type="primary" onClick={handleSubmit}>
              Send
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstructorParentConversation;
