import { Button, Form, Input, Select, Table, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../../../../nestserver";

const AddEditGenerate = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { Option } = Select;
  const queryClient = useQueryClient();
  const [studentId, setStudentId] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [studentTemplate, setStudentTemplate] = useState([]);
  const [languageUpdatedData, setLanguageUpdatedData] = useState([]);
  const [mandatoryUpdatedData, setmandatoryUpdatedData] = useState([]);
  const [curricularUpdatedData, setCurricularUpdatedData] = useState([]);

  useEffect(() => {
    const urlParts = window.location.pathname.split("/");
    const templateIdIndex = urlParts.indexOf("template");
    const studentIdIndex = urlParts.indexOf("student");

    if (templateIdIndex !== -1 && studentIdIndex !== -1) {
      setTemplateId(urlParts[templateIdIndex + 1]);
      setStudentId(urlParts[studentIdIndex + 1]);
    }
  }, []);

  useEffect(() => {
    if (studentId && templateId) {
      fetchStudentTemplate()
        .then((response) => {
          setStudentTemplate(response?.result);
          setLanguageUpdatedData(response?.result?.languages);
          setmandatoryUpdatedData(response?.result?.mandatory_subjects);
          setCurricularUpdatedData(response?.result?.co_curricular);

          // Set initial updated data
          form.setFieldsValue({
            name: `${response?.result?.name}`,
            studentId: response?.result?.student_id,
            class: response?.result?.class,
            parent: `${response?.result?.parent}`,
            phone: response?.result?.phone,
            overall_grade: response?.result?.overall_grade,
            overall_comment: response?.result?.overall_comment,
            total: response?.result?.total_marks,
            status: response?.result?.status,
          });
        })
        .catch((error) => {
          console.error("Error fetching student fee:", error);
        });
    }
  }, [templateId, studentId]);

  // console.log("studentTemplate", studentTemplate);

  const fetchStudentTemplate = async () => {
    const res = await axios({
      method: "get",
      url:
        serverUrl +
        "/progress-card/score/student/" +
        studentId +
        "/template/" +
        templateId,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  //for language
  const handleLanguageMarksChange = (recordIndex, newMarks) => {
    const updatedTemplate = [...languageUpdatedData];
    updatedTemplate[recordIndex].marks = newMarks;
    setLanguageUpdatedData(updatedTemplate);
  };

  const handleLanguageGradeChange = (recordIndex, newGrade) => {
    const updatedTemplate = [...languageUpdatedData];
    updatedTemplate[recordIndex].grade = newGrade;
    setLanguageUpdatedData(updatedTemplate);
  };

  const handleLanguageCommentChange = (recordIndex, newComment) => {
    const updatedTemplate = [...languageUpdatedData];
    updatedTemplate[recordIndex].comment = newComment;
    setLanguageUpdatedData(updatedTemplate);
  };
  //for mandatory
  const handleMandatoryMarksChange = (recordIndex, newMarks) => {
    const updatedTemplate = [...mandatoryUpdatedData];
    updatedTemplate[recordIndex].marks = newMarks;
    setmandatoryUpdatedData(updatedTemplate);
  };

  const handleMandatoryGradeChange = (recordIndex, newGrade) => {
    const updatedTemplate = [...mandatoryUpdatedData];
    updatedTemplate[recordIndex].grade = newGrade;
    setmandatoryUpdatedData(updatedTemplate);
  };

  const handleMandatoryCommentChange = (recordIndex, newComment) => {
    const updatedTemplate = [...mandatoryUpdatedData];
    updatedTemplate[recordIndex].comment = newComment;
    setmandatoryUpdatedData(updatedTemplate);
  };
  // curricular
  const handleCurricularMarksChange = (recordIndex, newMarks) => {
    const updatedTemplate = [...curricularUpdatedData];
    updatedTemplate[recordIndex].marks = newMarks;
    setCurricularUpdatedData(updatedTemplate);
  };

  const handleCurricularGradeChange = (recordIndex, newGrade) => {
    const updatedTemplate = [...curricularUpdatedData];
    updatedTemplate[recordIndex].grade = newGrade;
    setCurricularUpdatedData(updatedTemplate);
  };

  const handleCurricularCommentChange = (recordIndex, newComment) => {
    const updatedTemplate = [...curricularUpdatedData];
    updatedTemplate[recordIndex].comment = newComment;
    setCurricularUpdatedData(updatedTemplate);
  };

  const onFinish = async (values) => {
    const mandatoryData = mandatoryUpdatedData.map((item) => ({
      subject: item?.subject?._id,
      grade: item?.grade,
      marks: parseFloat(item?.marks),
      comment: item?.comment,
    }));
    const languageData = languageUpdatedData.map((item) => ({
      subject: item?.subject?._id,
      grade: item?.grade,
      marks: parseFloat(item?.marks),
      comment: item?.comment,
    }));
    const curricularData = curricularUpdatedData.map((item) => ({
      subject: item?.subject?._id,
      grade: item?.grade,
      marks: parseFloat(item?.marks),
      comment: item?.comment,
    }));
    try {
      const payload = {
        template: templateId,
        student: studentId,
        overall_grade: values?.overall_grade,
        total_marks: values?.total,
        status: values?.status,
        overall_comment: values?.overall_comment,
        languages: languageData,
        mandatory_subjects: mandatoryData,
        co_curricular: curricularData,
      };
      const res = await axios({
        method: "post",
        url: serverUrl + "/progress-card",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
        data: payload,
      });
      await fetchStudentTemplate();
      message.success(res?.data?.result?.message);
    } catch (error) {
      message.error(error?.response?.data?.error?.errors);
    }
  };
  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
      render: (subject) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          {subject?.name}
        </div>
      ),
    },
    {
      title: "Marks",
      dataIndex: "marks",
      key: "marks",
      align: "center",
      render: (marks, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center capitalize"
        >
          <Input
            type="number"
            value={marks}
            onChange={(e) => handleLanguageMarksChange(index, e.target.value)}
            placeholder="Enter marks"
            min={0}
          />
        </div>
      ),
    },
    {
      title: "Grade",
      dataIndex: "subject",
      key: "grade",
      align: "center",
      render: (subject, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          <Input
            type="text"
            value={subject?.grade}
            onChange={(e) => handleLanguageGradeChange(index, e.target.value)}
            placeholder="Enter grade"
          />
        </div>
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      align: "center",
      render: (comment, record, index) => (
        <div style={{ color: "#616161" }} className="text-base">
          <Input.TextArea
            value={comment}
            onChange={(e) => handleLanguageCommentChange(index, e.target.value)}
            placeholder="Type comment"
          />
        </div>
      ),
    },
  ];
  const mandatoryColumns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
      render: (subject) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          {subject?.name}
        </div>
      ),
    },
    {
      title: "Marks",
      dataIndex: "marks",
      key: "marks",
      align: "center",
      render: (marks, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center capitalize"
        >
          <Input
            type="number"
            value={marks}
            onChange={(e) => handleMandatoryMarksChange(index, e.target.value)}
            placeholder="Enter marks"
            min={0}
          />
        </div>
      ),
    },
    {
      title: "Grade",
      dataIndex: "subject",
      key: "grade",
      align: "center",
      render: (subject, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          <Input
            type="text"
            value={subject?.grade}
            onChange={(e) => handleMandatoryGradeChange(index, e.target.value)}
            placeholder="Enter grade"
          />
        </div>
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      align: "center",
      render: (comment, record, index) => (
        <div style={{ color: "#616161" }} className="text-base">
          <Input.TextArea
            value={comment}
            onChange={(e) =>
              handleMandatoryCommentChange(index, e.target.value)
            }
            placeholder="Type comment"
          />
        </div>
      ),
    },
  ];
  const curricularColumns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
      render: (subject) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          {subject?.name}
        </div>
      ),
    },
    {
      title: "Marks",
      dataIndex: "marks",
      key: "marks",
      align: "center",
      render: (marks, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center capitalize"
        >
          <Input
            type="number"
            value={marks}
            onChange={(e) => handleCurricularMarksChange(index, e.target.value)}
            placeholder="Enter marks"
            min={0}
          />
        </div>
      ),
    },
    {
      title: "Grade",
      dataIndex: "subject",
      key: "grade",
      align: "center",
      render: (subject, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          <Input
            type="text"
            value={subject?.grade}
            onChange={(e) => handleCurricularGradeChange(index, e.target.value)}
            placeholder="Enter grade"
          />
        </div>
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      align: "center",
      render: (comment, record, index) => (
        <div style={{ color: "#616161" }} className="text-base">
          <Input.TextArea
            value={comment}
            onChange={(e) =>
              handleCurricularCommentChange(index, e.target.value)
            }
            placeholder="Type comment"
          />
        </div>
      ),
    },
  ];

  return (
    <div className="student-form-fee">
      <div className="p-8">
        <div className="bg-white p-10 rounded-2xl">
          <div className="p-4 text-right">
            <Button size="large" onClick={() => history.goBack()}>
              Back
            </Button>
          </div>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className="form-grid">
              <Form.Item label="Student Id" name="studentId">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Name" name="name">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Class" name="class">
                <Input readOnly />
              </Form.Item>
            </div>
            <div className="form-grid">
              <Form.Item label="Parent" name="parent">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Phone" name="phone">
                <Input readOnly />
              </Form.Item>
            </div>
            <div className="form-grid">
              <Form.Item
                label="Overall Grade"
                name="overall_grade"
                rules={[
                  {
                    required: true,
                    message: "Grade is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Total Marks"
                name="total"
                rules={[
                  {
                    required: true,
                    message: "Total marks is required",
                  },
                  {
                    validator: (_, value) => {
                      if (value && (value < 1 || value > 20000)) {
                        return Promise.reject(
                          "Total mark should not more than 20,000"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input type="number" min={0} max={20000} />
              </Form.Item>
            </div>
            <Form.Item
              name="overall_comment"
              label="Overall Comment"
              rules={[
                {
                  required: true,
                  message: "Comment is required",
                },
              ]}
            >
              <Input.TextArea
                rows={3}
                placeholder="comment"
                className="w-full border focus:outline-none resize-none"
              />
            </Form.Item>
            <div>
              <h6 className="text-sm pb-4">Mandatory Subject</h6>
              <div className="flex flex-col gap-3 border">
                <Table
                  columns={mandatoryColumns}
                  dataSource={
                    studentTemplate?.mandatory_subjects
                      ? studentTemplate?.mandatory_subjects
                      : []
                  }
                  pagination={false}
                />
              </div>
            </div>
            <div>
              <h6 className="text-sm pb-4 pt-4">Language</h6>
              <div className="flex flex-col gap-3 border">
                <Table
                  columns={columns}
                  dataSource={
                    studentTemplate?.languages ? studentTemplate?.languages : []
                  }
                  pagination={false}
                />
              </div>
            </div>
            <div>
              <h6 className="text-sm pb-4 pt-4">Co Curricular</h6>
              <div className="flex flex-col gap-3 border">
                <Table
                  columns={curricularColumns}
                  dataSource={
                    studentTemplate?.co_curricular
                      ? studentTemplate?.co_curricular
                      : []
                  }
                  pagination={false}
                />
              </div>
            </div>
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "Status is required",
                },
              ]}
              className="pt-4 "
              style={{ width: "20%" }}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="select status"
              >
                <Select.Option value="draft">Draft</Select.Option>
                <Select.Option value="ready">Ready</Select.Option>
              </Select>
            </Form.Item>
            <div className="text-center">
              <Button type="primary" size="large" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditGenerate;
