import { Upload, message, Progress } from "antd";
import ImgCrop from "antd-img-crop";
import { useState } from "react";
import axios from "axios";
import { serverUrl } from "../../nestserver";
import { AiOutlineFilePdf } from "react-icons/ai";
import { uploadDoc } from "../../services/upload.service";

export default function FileUploadButton({
  file,
  name,
  onUpload,
  folder = "docs",
  accept,
  children,
}) {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  function beforeUpload(file) {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
    }
    return isPdf && isLt2M;
  }

  const uploadButton = (
    <div>
      {loading ? (
        <Progress type="circle" width={50} percent={progress} />
      ) : (
        children
      )}
    </div>
  );

  async function uploadFile(data) {
    setLoading(true);
    var formData = new FormData();
    var imagefile = data.file;
    formData.append("file", imagefile);
    await axios
      .post(serverUrl + "/uploads", formData, {
        headers: {
          "Content-Type": null,
        },
        onUploadProgress: function (progressEvent) {
          setProgress(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      })
      .then((res) => {
        setLoading(false);
        onUpload(res.data);
        return res.data;
      });
  }

  async function upload(data) {
    try {
      const files = await uploadDoc(data, folder, setProgress);
      setLoading(false);
      await onUpload(files);
      message.success("File uploaded successfully");
    } catch (error) {
      message.error(error.message);
    }
  }

  return (
    <div>
      <Upload
        name={name}
        action={serverUrl + "/uploads"}
        showUploadList={false}
        accept={accept}
        customRequest={upload}
        beforeUpload={beforeUpload}
        multiple={true}
      >
        {file ? (
          <div className="flex flex-col justify-center items-center space-y-3">
            <AiOutlineFilePdf size={60} className="text-gray-500" />

            <div>{file?.filename.split("&&&")[1]}</div>
          </div>
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
}
