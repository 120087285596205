import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DiscussionCard from "../../../../../components/cards/DiscussionCard";
import { serverUrl } from "../../../../../nestserver";
import { selectUser } from "../../../../../redux/slices/auth.slice";
import CreditScore from "./maincourse/CreditScore";
import UploadImage from "./maincourse/UploadImage";

function Course({ data }) {
  const user = useSelector(selectUser);
  const { subjectId } = useParams();

  async function getDiscussions() {
    const data = await axios({
      method: "get",
      url: serverUrl + "/discussion",
      params: {
        subject: subjectId,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return data.data;
  }

  const discussions = useQuery(["discussions", subjectId], getDiscussions, {
    enabled: !!subjectId,
  });

  return (
    <>
      <div className="">
        <div className="pt-8">
          <div className="bg-white w-full rounded-2xl ">
            <div className="flex flex-col items-start justify-start p-6  text-left space-y-4 ">
              <div
                style={{ color: "#E46E31" }}
                className="text-2xl font-semibold"
              >
                About the Course
              </div>
              <div
                style={{ color: "#074559" }}
                className="text-lg font-semibold"
              >
                Instructor :
                {data?.teacher
                  ? `${data?.teacher?.teacher?.first_name} ${data?.teacher?.teacher?.last_name}`
                  : `${user?.teacher?.first_name} ${user?.teacher?.last_name}`}
              </div>
              <div
                style={{ color: "#616161" }}
                className="text-base font-semibold"
              >
                {data?.about}
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8">
          <CreditScore data={data} />
        </div>

        <div className="pt-8">
          <UploadImage user={user} data={data} />
        </div>

        <div className="pt-8">
          <div className="bg-white w-full rounded-2xl bg-opacity-40 px-4 pb-4">
            <div
              style={{ color: "#E46E31" }}
              className=" text-orange-500 text-2xl font-semibold p-4 py-6"
            >
              Discussions
            </div>
            <div className="space-y-5">
              {discussions?.isSuccess &&
                discussions?.data?.map((item) => {
                  return (
                    <DiscussionCard
                      enableDelete={true}
                      enableDeleteComment={true}
                      key={item?._id}
                      data={item}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Course;
