import React from "react";
import * as moment from "moment";

function NotificationCard({ title, description, date }) {
  return (
    <div>
      <div className="border border-gray-300 rounded-xl w-full ">
        <div className="flex flex-col items-start p-2 px-3 space-y-1">
          <div
            style={{ color: "#E46E31" }}
            className="font-semibold line-clamp-1"
          >
            {title}
          </div>

          <div style={{ color: "#616161" }} className="font-normal text-sm">
            {description}
          </div>
          <div className="text-xs text-gray-400">
            posted on {moment(date).format("MMMM Do YYYY")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationCard;
