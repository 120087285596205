import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, Table, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { cloudFrontUrl, serverUrl } from "../../../nestserver";

const SchoolAdminHome = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [addSchool, SetAddSchool] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [searchInput, setSearchInput] = useState(null);

  async function fetchAdmin(id) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/school/${id}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getAdmin = useQuery(["school", id], () => fetchAdmin(id), {
    enabled: !!id,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search school name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });
  const columns = [
    {
      title: "Name",

      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {`${record?.first_name} ${record?.last_name}`}
        </div>
      ),
      onFilter: (value, record) =>
        record?.first_name?.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Phone",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.user?.phone}
        </div>
      ),
    },
    {
      title: "Username",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.user?.username}
        </div>
      ),
    },
    // {
    //   title: "Password",
    //   key: "",
    //   align: "center",
    //   render: (record) => (
    //     <div
    //       key={record?._id}
    //       style={{ color: "#111111" }}
    //       className=" text-base font-semibold text-center capitalize"
    //     >
    //       {record?.user?.password}
    //     </div>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record, index) => (
        <div
          key={record?._id}
          className=" text-base font-semibold text-center text-blue-500 cursor-pointer"
          onClick={() => {
            setIsEditMode(true);
            setEditData({ [index?._id]: record });
            SetAddSchool(true);
          }}
        >
          View
        </div>
      ),
    },
  ];
  const onFinish = async (data) => {
    try {
      const payload = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        username: data?.username,
        phone: data?.phone,
        email: data?.email,
        password: data?.password ? data?.password : null,
      };
      if (payload) {
        if (isEditMode) {
          axios
            .put(
              `${serverUrl}/school/${id}/admin/${Object.keys(editData)[0]}`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "jwt-token"
                  )}`,
                },
              }
            )
            .then((res) => {
              form.resetFields();
              SetAddSchool(false);
              setEditData({});
              setIsEditMode(false);
              queryClient.invalidateQueries(["school", id]);
              message.success(res?.data?.result?.message);
            })
            .catch((err) => {
              if (err?.response?.data?.error?.errors) {
                message.error(err?.response?.data?.error?.errors);
              }
            });
        } else {
          axios
            .post(`${serverUrl}/school/${id}/admin`, payload, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "jwt-token"
                )}`,
              },
            })
            .then((res) => {
              queryClient.invalidateQueries(["school", id]);
              form.resetFields();
              SetAddSchool(false);
              message.success(res?.data?.result?.message);
            })
            .catch((err) => {
              if (err?.response?.data?.error?.errors) {
                message.error(err?.response?.data?.error?.errors);
              }
            });
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        message.error(err?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
    if (isEditMode) {
      form.setFieldsValue({
        first_name: editData[Object.keys(editData)[0]]?.first_name,
        last_name: editData[Object.keys(editData)[0]]?.last_name,
        phone: editData[Object.keys(editData)[0]]?.user?.phone,
        username: editData[Object.keys(editData)[0]]?.user?.username,
        email: editData[Object.keys(editData)[0]]?.user?.email,
      });
    }
  }, [isEditMode]);
  return (
    <div>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex justify-between gap-4">
            <div className="flex flex-col" style={{ width: "550px" }}>
              <h3 className="text-base pb-3 capitalize ">
                {" "}
                <span className="text-base font-semibold">
                  {" "}
                  School Name :
                </span>{" "}
                {getAdmin?.data?.result?.data?.name}{" "}
              </h3>
            </div>
            <div className="flex flex-col" style={{ width: "140px" }}>
              <h3 className="text-base pb-3">
                {" "}
                <span className="text-base font-semibold"> School Id : </span>
                {getAdmin?.data?.result?.data?.refId}{" "}
              </h3>
            </div>
          </div>
          <div className="flex justify-between gap-4 pb-8 items-center">
            <div className="flex flex-col">
              <h3 className="text-base pb-3 capitalize flex">
                {" "}
                <p className="text-base font-semibold">Address&nbsp;:&nbsp;</p>
                <p style={{ width: "500px" }}>
                  {`${getAdmin?.data?.result?.data?.address}, ${getAdmin?.data?.result?.data?.city}, ${getAdmin?.data?.result?.data?.state}, ${getAdmin?.data?.result?.data?.pincode}`}{" "}
                </p>
              </h3>
            </div>
            <div className="flex flex-col">
              <h3 className="text-base pb-3 flex items-center">
                {" "}
                {/* <span className="text-base font-semibold">Logo : </span> */}
                <div className="relative w-20 h-20 rounded-full  border-8 border-white">
                  <img
                    className="w-full h-full rounded-full border"
                    src={
                      getAdmin?.data?.result?.data?.logo
                        ? `${cloudFrontUrl}/common/${getAdmin?.data?.result?.data?.logo}`
                        : "https://ui-avatars.com/api/?name=" +
                          (getAdmin?.data?.result?.data?.name
                            ? getAdmin?.data?.result?.data?.name
                            : getAdmin?.data?.result?.data?.name)
                    }
                    alt="banner"
                  />
                </div>
              </h3>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              size="large"
              type="primary"
              htmlType="download"
              className="text-white w-9"
              onClick={() => SetAddSchool(true)}
            >
              Add
            </Button>
          </div>
          <div className="py-5 table-overflow">
            <Table
              columns={columns}
              dataSource={
                getAdmin?.data?.result?.data?.admins.length > 0 &&
                getAdmin?.data?.result?.data?.admins
                  ? getAdmin?.data?.result?.data?.admins
                  : []
              }
              // dataSource={[]}
              pagination={true}
              loading={getAdmin?.isLoading}
            />
          </div>
        </div>
      </div>

      {/* **********************************Modal********************** */}
      <Modal
        width={600}
        visible={addSchool}
        onCancel={() => {
          SetAddSchool(false);
          setIsEditMode(false);
          form.resetFields();
        }}
        footer={null}
      >
        <div>
          {/* ---------------------------form---------------- */}
          <h3 className="text-lg font-bold pb-3">
            {isEditMode ? "Update Admin Details" : "Add Admin Details"}
          </h3>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                { required: true, message: "Please input First name" },
                {
                  message:
                    "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                  validator: (_, value) => {
                    if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                      );
                    }
                  },
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                { required: true, message: "Please input Last name" },
                {
                  message:
                    "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                  validator: (_, value) => {
                    if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                      );
                    }
                  },
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone"
              className="w-30"
              rules={[
                {
                  pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                  message: "Please enter a valid 10-digit phone number!",
                },
                { required: true, message: "Phone number is required" },
              ]}
              getValueFromEvent={(e) => e.target.value.slice(0, 10)}
            >
              <Input type="number" maxLength={10} min={0} />
            </Form.Item>
            <Form.Item
              name="username"
              label="Username"
              rules={[{ required: true, message: "Please input username" }]}
            >
              <Input className="w-full" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
                { required: true, message: "Email is required" },
              ]}
              className="w-30"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: !isEditMode && true,
                  message: "Password is required",
                },
                {
                  min: 6,
                  message: "Password must be at least 6 characters long!",
                },
              ]}
            >
              <Input.Password placeholder="*********************" />
            </Form.Item>

            <div className="flex justify-end items-center pt-6">
              <div className="inline-flex items-center space-x-2">
                <Button
                  size="large"
                  type="dark"
                  onClick={() => {
                    SetAddSchool(false);
                    form.resetFields();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="text-white"
                >
                  {isEditMode ? "Update" : " Submit"}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default SchoolAdminHome;
