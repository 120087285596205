import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space, Table } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";

const TemplateList = () => {
  const { Search } = Input;
  const { Option } = Select;
  const history = useHistory();
  const [searchInput, setSearchInput] = useState(null);
  const [studentId, setStudentId] = useState(null);

  const handleSelectChange = (value) => {
    setStudentId(value);
  };

  //fetch student
  async function fetchParentStudent() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/students/by-parent/`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data.result;
  }
  const students = useQuery("students/by-parent", fetchParentStudent);

  const getStudent = useQuery(
    ["progress-card", studentId],
    () =>
      axios({
        method: "get",
        url: `${serverUrl}/progress-card/by-student/?studentId=${studentId}`,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }).then((res) => res.data),
    {
      enabled: !!studentId,
    }
  );

  // Table Settings

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search Subject`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Template",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.template?.name}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.status}
        </div>
      ),
    },
    {
      title: "Last Report",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.status === "notified" && record?.notified_at
            ? moment(record?.notified_at).format("DD MMMM YYYY,hh:mm A")
            : "--"}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          className="text-base font-semibold text-center text-blue-500 cursor-pointer"
          key={record}
          onClick={() => {
            history.push(
              `/dashboard/parent/menu/template/report-card/${record?._id}`
            );
          }}
        >
          View
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Manage Report
          </div>
          <div className="flex gap-4" style={{ width: "30%" }}>
            <h5 className="text-base font-semibold">Name</h5>
            <Select
              showSearch
              showArrow
              placeholder="Select name"
              className="w-full bg-gray-300"
              style={{
                border: "6px",
                backgroundColor: "#EBEBEB",
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => handleSelectChange(value)}
            >
              {students &&
                students?.data?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {`${data?.first_name} ${data?.last_name}`}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="py-5 table-overflow">
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={
                getStudent?.isSuccess &&
                getStudent?.data?.result &&
                getStudent?.data?.result.length > 0 &&
                getStudent?.data?.result[0]?.progress_cards
                  ? getStudent?.data?.result[0]?.progress_cards
                  : []
              }
              pagination={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateList;
