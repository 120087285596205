import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { Select, Table } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";

const MangeFormHistory = () => {
  const { Option } = Select;
  const [classId, setClassId] = useState();

  const handleSelectChange = (classId) => {
    setClassId(classId);
  };

  const handlePreview = async (requestId) => {
    try {
      const response = await axios({
        url: serverUrl + "/form-requests/" + requestId + "/download",
        method: "GET",
        responseType: "blob",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });

      // Create a blob URL for the file
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = URL.createObjectURL(blob);

      // Open the file in a new tab
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error fetching the preview:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  //get form list
  async function getFormList(classId) {
    let url = serverUrl + "/form-requests/";
    if (classId) {
      url += `?class=${classId}`;
    }
    const res = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const formList = useQuery(["/form-requests/", { class: classId }], () =>
    getFormList(classId)
  );

  const columns = [
    {
      title: "Date Requested",
      dataIndex: [],
      key: "",
      align: "center",
      sorter: (a, b) => {
        const dateA = moment(a.createdAt).valueOf();
        const dateB = moment(b.createdAt).valueOf();
        return dateA - dateB;
      },
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center"
        >
          {record?.createdAt
            ? moment(record?.createdAt).format("DD-MM-YYYY")
            : "-"}
        </div>
      ),
    },
    {
      title: "Requesters",
      key: "name",
      align: "center",
      render: (record, index) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          <span>{`${record?.student?.student?.parent?.first_name} ${record?.student?.student?.parent?.last_name}`}</span>
        </div>
      ),
      onFilter: (value, record) =>
        (
          record?.first_name?.toLowerCase() +
          " " +
          record?.last_name?.toLowerCase()
        ).includes(value.toLowerCase()),
    },
    {
      title: "Student Details",
      dataIndex: [],
      key: "status",
      align: "center",
      render: (record, _, index) => {
        return (
          <div
            key={index}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            <p className="flex flex-col">
              <span>{`${record?.student?.student?.first_name} ${record?.student?.student?.last_name}`}</span>
              <span>{`${record?.student?.student?.unique_id}`}</span>
              <span>{`${record?.student?.student?.class?.name}`}</span>
            </p>
          </div>
        );
      },
    },
    {
      title: "Form Type",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        const originalString = `${record?.form_type}`;
        const newString = originalString.replace(/_/g, " ");
        return (
          <div
            key={record}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {newString ? newString : "--"}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.status}
        </div>
      ),
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Approved", value: "approved" },
        { text: "Rejected", value: "rejected" },
        { text: "Resubmit", value: "resubmit" },
      ],
      onFilter: (value, record) =>
        record.status.toLowerCase() === value.toLowerCase(),
    },
    {
      title: "Preview",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div className=" text-base text-center">
          {record?.status === "approved" ? (
            <EyeOutlined
              // color="primary"
              size={25}
              className="text-blue-500"
              onClick={(e) => handlePreview(record?._id)}
            />
          ) : (
            "   --"
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Form History
          </div>
          <div className="flex gap-4 w-310 items-center">
            <h3 className="text-base font-semibold">Class</h3>
            <Select
              showSearch
              showArrow
              style={{ width: 200 }}
              placeholder="Select Class"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => handleSelectChange(value)}
              allowClear
            >
              {classes?.isSuccess &&
                classes?.data?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="py-5 table-overflow">
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={
                formList?.isSuccess &&
                formList?.data?.result?.data?.length > 0 &&
                formList?.data?.result?.data
                  ? formList?.data?.result?.data
                  : []
              }
              pagination={true}
              loading={formList?.isLoading}
            />
          </div>
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default MangeFormHistory;
