export default function getStatusColor(status) {
  switch (status) {
    case "submitted":
      return "text-blue-500";

    case "evaluated":
      return "text-green-500";

    case "resubmit":
      return "text-red-500";

    case "requested resubmit":
      return "text-yellow-500";

    default:
      return "text-gray-500";
  }
}
