import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../nestserver";
import StudentEditModule from "./StudentEditModule";

function StudentModule({ data }) {
  async function getModules() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/modules/",
      params: {
        subject: data?._id,
        published: true,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const modules = useQuery(["modules", data?._id], getModules);

  const [moduleDetails, setModuleDetails] = useState(null);

  return (
    <div>
      {moduleDetails ? (
        <StudentEditModule
          goBack={() => setModuleDetails(null)}
          data={modules?.data?.find((i) => i._id === moduleDetails)}
        />
      ) : (
        <div>
          <div className=" pt-8 ">
            <div className="bg-white p-8 rounded-xl">
              <div className="grid grid-cols-4 gap-8">
                {modules.isSuccess
                  ? modules.data.map((module) => (
                      <div
                        key={module._id}
                        onClick={() => setModuleDetails(module._id)}
                        className="cursor-pointer bg-white p-3 h-56 module-card"
                      >
                        <div className="flex items-center justify-between gap-x-2">
                          <h1
                            style={{
                              color: "#00627B",
                            }}
                            className="text-base font-semibold text-left"
                          >
                            {module?.title}
                          </h1>
                        </div>
                        <p
                          style={{
                            color: "#AEADAE",
                            maxHeight: "170px",
                            overflow: "hidden",
                          }}
                          className="font-normal text-sm text-left py-2"
                        >
                          {module?.overview}
                        </p>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StudentModule;
