import { Form, Modal, Select, Tag, Typography, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { serverUrl } from "../../../../../nestserver";
import ViewApplication from "./ViewApplication";

function tagRender(props) {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ margin: 5 }}
      className="flex items-center"
    >
      {label}
    </Tag>
  );
}

const AdmissionProfile = ({ data }) => {
  const { Title } = Typography;
  const { Option } = Select;
  const { confirm } = Modal;
  const history = useHistory();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [rejectAdmissionPopup, setRejectAdmissionPopup] = useState(false);
  const [status, setStatus] = useState(null);
  const [reason, setReason] = useState("");
  const [classId, setClassId] = useState(null);

  const { id } = useParams();

  async function fetchSubjects() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/subjects/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const subjects = useQuery("subjects", fetchSubjects);
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  const handleStatusChange = (value) => {
    setStatus(value);
  };
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleRejection = async () => {
    const data = {
      status: "rejected",
      message: reason,
    };
    try {
      axios
        .patch(serverUrl + "/parent/application/" + id + "/status/", data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          res.data.success === true
            ? message.success(res.data.message)
            : message.error(res.data.message);
          form.resetFields();
        })
        .then(() => {
          setRejectAdmissionPopup(false);
        });
    } catch (err) {
      if (err?.response?.data?.message) {
        message.error(err?.response?.data?.message);
      }
    }
  };
  useEffect(() => {}, []);
  return (
    <>
      <div className="p-8 admission">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md px-5 py-6 w-full "
        >
          <ViewApplication />
        </div>
      </div>
    </>
  );
};

export default AdmissionProfile;
