import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Typography,
  message,
} from "antd";
import axios from "axios";
import { default as React, useState } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";
import { useQuery } from "react-query";
import DownloadApplication from "../apps/admin/pages/components/admission/DownloadApplication";
import generatePDF from "../components/inputs/GeneratePdf";
import MultipleUpload from "../components/inputs/UploadMultipleFile";
import { serverUrl } from "../nestserver";
const { Option } = Select;

const GenderSelect = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Gender"
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <Option value="male">Male</Option>
      <Option value="female">Female</Option>
      <Option value="others">Other</Option>
    </Select>
  );
};

const BloodGroupSelect = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Blood Group"
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <Option value="A+">A+</Option>
      <Option value="B+">B+</Option>
      <Option value="O+">O+</Option>
      <Option value="A-">A-</Option>
      <Option value="B-">B-</Option>
      <Option value="O-">O-</Option>
      <Option value="AB+">AB+</Option>
      <Option value="AB-">AB-</Option>
    </Select>
  );
};

export default function RegisterPage() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [formFields, setFormFields] = useState([{}]);
  const [secondaryContactCheckbox, setSecondaryContactCheckbox] =
    useState(false);
  const [parentFileList, setParentFileList] = useState([]);
  const handleFileListChange = (fileList) => {
    setParentFileList(fileList);
  };
  const handleAddForm = () => {
    setFormFields([...formFields, {}]);
  };
  const validateAlphabetsOnly = (_, value) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(value)) {
      return Promise.reject("Please enter alphabets only");
    }
    return Promise.resolve();
  };
  const handleRemoveForm = (index) => {
    const updatedFields = [...formFields];
    updatedFields.splice(index, 1);
    setFormFields(updatedFields);
  };
  const [referral, setReferral] = useState("");
  const handleReferralChange = (value) => {
    setReferral(value);
  };
  const [friendInfo, setFriendInfo] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleFriendInfoChange = (field, value) => {
    setFriendInfo({ ...friendInfo, [field]: value });
  };
  const [uploadAdmissionPopup, setUploadAdmissionPopup] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    relation: "",
    occupation: "",
    education: "",
    annualIncome: "",
    phone: "",
    email: "",
    aadhar: "",
    address: "",
    emergencyContact: "",
  });
  const [disabilityStatus, setDisabilityStatus] = useState(false);
  const { Title } = Typography;
  function onChange(e) {
    setDisabilityStatus(e.target.value);
  }
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);
  const onFinish = (values) => {
    const existingSiblings = [];
    // formFields.forEach((field, index) => {
    //   const nameValue = values[`name${index + 1}`];
    //   const studentIdValue = values[`student_id${index + 1}`];
    //   if (nameValue.trim() !== "" || studentIdValue.trim() !== "") {
    //     const sibling = {
    //       name: nameValue,
    //       student_id: studentIdValue,
    //     };
    //     existingSiblings.push(sibling);
    //   }
    // });
    formFields.forEach((field, index) => {
      const nameValue = values[`name${index + 1}`] || "";
      const studentIdValue = values[`student_id${index + 1}`] || "";
      if (nameValue.trim() || studentIdValue.trim()) {
        existingSiblings.push({
          name: nameValue,
          student_id: studentIdValue,
        });
      }
    });
    const dataToSend = {
      first_name: values.first_name,
      last_name: values.last_name,
      dob: values.dob,
      gender: values.gender,
      nationality: values.nationality,
      aadhar: values.aadhar,
      email: values.email,
      phone: values.phone,
      birth_place: values.birth_place,
      religion: values.religion,
      ethnicity: values.ethnicity,
      class: values.class,
      enrollment_year: values.enrollment_year,
      mother_tongue: values.mother_tongue,
      referral: {},
      category: values.category,
      primary_contact: {
        first_name: values.primary_contact_first_name,
        last_name: values.primary_contact_last_name,
        relation: values.primary_contact_relation,
        occupation: values.primary_contact_occupation,
        education: values.primary_contact_education,
        annual_income: values.primary_contact_annual_income,
        contact: values.primary_contact_phone,
        email: values.primary_contact_email,
        aadhar: values.primary_contact_aadhar,
        address: values.primary_contact_address,
        emergency_contact: values.primary_emergency_contact,
      },
      is_contacts_matching: secondaryContactCheckbox,
      documents: parentFileList,
      secondary_contact: {
        first_name: values.secondary_contact_first_name,
        last_name: values.secondary_contact_last_name,
        relation: values.secondary_contact_relation,
        occupation: values.secondary_contact_occupation,
        education: values.secondary_contact_education,
        annual_income: values.secondary_contact_annual_income,
        contact: values.secondary_contact_phone,
        email: values.secondary_contact_email,
        aadhar: values.secondary_contact_aadhar,
        address: values.secondary_contact_address,
        emergency_contact: values.secondary_emergency_contact,
      },
      existing_siblings: existingSiblings,
      health_details: {
        blood_group: values.blood_group,
        is_disabled: disabilityStatus,
      },
      previous_school: {
        name: values.school_name,
        grade: values.grade,
        graduated_year: values.graduated_year,
        phone: values.reference,
        email: values.reference,
        class_completed: values.class_completed,
        change_reason: values.change_reason,
      },
    };
    if (referral === "friend") {
      dataToSend.referral = {
        is_friend: true,
        name: friendInfo.name,
        phone: friendInfo.phone,
        email: friendInfo.email,
      };
    } else {
      dataToSend.referral = {
        is_friend: false,
        name: values.referral,
      };
    }

    axios
      .post(serverUrl + "/parent/application/", dataToSend, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        form.resetFields();
        message.success(res?.data?.result?.message);
        history.replace("/auth/login");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
        }
      });
  };
  const handleSaveAsAbove = (value) => {
    setSecondaryContactCheckbox(value);
    if (value) {
      const primaryContactValues = form.getFieldsValue([
        "primary_contact_first_name",
        "primary_contact_last_name",
        "primary_contact_relation",
        "primary_contact_occupation",
        "primary_contact_education",
        "primary_contact_annual_income",
        "primary_contact_phone",
        "primary_contact_email",
        "primary_contact_aadhar",
        "primary_contact_address",
        "primary_emergency_contact",
      ]);

      form.setFieldsValue({
        secondary_contact_first_name:
          primaryContactValues?.primary_contact_first_name,
        secondary_contact_last_name:
          primaryContactValues?.primary_contact_last_name,
        secondary_contact_relation:
          primaryContactValues?.primary_contact_relation,
        secondary_contact_occupation:
          primaryContactValues?.primary_contact_occupation,
        secondary_contact_education:
          primaryContactValues?.primary_contact_education,
        secondary_contact_annual_income:
          primaryContactValues?.primary_contact_annual_income,
        secondary_contact_phone: primaryContactValues?.primary_contact_phone,
        secondary_contact_email: primaryContactValues?.primary_contact_email,
        secondary_contact_aadhar: primaryContactValues?.primary_contact_aadhar,
        secondary_contact_address:
          primaryContactValues?.primary_contact_address,
        secondary_emergency_contact:
          primaryContactValues?.primary_emergency_contact,
      });
    } else {
      form.setFieldsValue({
        secondary_contact_first_name: "",
        secondary_contact_last_name: "",
        secondary_contact_relation: "",
        secondary_contact_occupation: "",
        secondary_contact_education: "",
        secondary_contact_annual_income: "",
        secondary_contact_phone: "",
        secondary_contact_email: "",
        secondary_contact_aadhar: "",
        secondary_contact_address: "",
        secondary_emergency_contact: "",
      });
    }
  };
  const updateDataForm = (paramName, value) => {
    setData({ ...data, [paramName]: value });
  };
  return (
    <div
      style={{
        backgroundImage: `url("/assets/login-bg-new.png")`,
      }}
    >
      <div
        style={{
          fontFamily: `'Nunito', sans-serif`,
        }}
        className="min-h-screen w-full flex flex-col items-center justify-center"
      >
        <div className="md:w-full  p-6">
          <div className="p-8">
            <div
              style={{ borderRadius: "10px", color: "#616161" }}
              className=" bg-white drop-shadow-md p-8 w-full border border-2 "
            >
              <h3 className="text-2xl font-bold text-center pb-10">Form</h3>
              <Form
                form={form}
                name="dynamic_rule"
                onFinish={onFinish}
                autoComplete="off"
              >
                <div className="form-header"></div>
                <div>
                  <Title level={2}>Student Details</Title>

                  <div className="admission-form ">
                    <Form.Item
                      name="first_name"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: "First Name is required",
                        },
                        {
                          message:
                            "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                          validator: (_, value) => {
                            if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                              );
                            }
                          },
                        },
                      ]}
                      className="w-30"
                    >
                      <Input placeholder="Please input your name" />
                    </Form.Item>
                    <Form.Item
                      name="last_name"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: "Last Name is required",
                        },
                        {
                          message:
                            "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                          validator: (_, value) => {
                            if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                              );
                            }
                          },
                        },
                      ]}
                      className="w-30"
                    >
                      <Input placeholder="Please input your name" />
                    </Form.Item>
                    <Form.Item
                      name="dob"
                      label="Date Of Birth"
                      rules={[
                        {
                          required: true,
                          message: "Date of Birth  is required",
                        },
                      ]}
                      className="w-30"
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        placeholder="Please select your date of birth"
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </div>
                  <div className="admission-form ">
                    <Form.Item
                      name="gender"
                      label="Gender"
                      rules={[
                        {
                          required: true,
                          message: "Gender is required",
                        },
                      ]}
                      className="w-30"
                    >
                      <GenderSelect />
                    </Form.Item>
                    <Form.Item
                      name="nationality"
                      label="Nationality"
                      className="w-30"
                      rules={[
                        {
                          required: true,
                          message: "Nationality is required",
                        },
                        {
                          validator: validateAlphabetsOnly,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="aadhar"
                      label="Aadhar"
                      className="w-30"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter your aadhar id",
                        },
                        {
                          pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                          message: "Please enter a valid 12-digit Number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 12)}
                    >
                      <Input maxLength={12} type="number" min={0} />
                    </Form.Item>
                  </div>
                  <div className="admission-form">
                    <Form.Item
                      label="Class"
                      name="class"
                      className="w-full"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please select a class",
                      //   },
                      // ]}
                    >
                      <Select
                        showSearch
                        showArrow
                        placeholder="Select Class"
                        className="w-full bg-gray-300"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {classes.isSuccess &&
                          classes?.data?.map((data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="enrollment_year"
                      label="Enroll Year"
                      className="w-30"
                      rules={[
                        {
                          pattern: /^\d{4}$/,
                          message: "Enroll year must be a 4-digit number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 4)}
                    >
                      <Input type="number" maxLength={4} min={0} />
                    </Form.Item>
                    <Form.Item
                      name="mother_tongue"
                      label="Mother Tongue"
                      className="w-30"
                      rules={[
                        {
                          validator: validateAlphabetsOnly,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>

                  <div className="admission-form ">
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          type: "email",
                          message: "Please enter a valid email",
                        },
                      ]}
                      className="w-30"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label="Phone"
                      className="w-30"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/,
                          message:
                            "Please enter a valid 10-digit phone number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                    >
                      <Input type="number" maxLength={10} min={0} />
                    </Form.Item>
                    <Form.Item
                      name="birth_place"
                      label="Birth Place"
                      className="w-30"
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="admission-form ">
                    <Form.Item
                      name="category"
                      label="Category"
                      className="w-30"
                      rules={[
                        {
                          validator: validateAlphabetsOnly,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="religion"
                      label="Religion"
                      className="w-30"
                      rules={[
                        {
                          validator: validateAlphabetsOnly,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="ethnicity"
                      label="Ethnicity"
                      className="w-30"
                      rules={[
                        {
                          validator: validateAlphabetsOnly,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <Title level={2}>Health Details</Title>
                  <div className="admission-form ">
                    <Form.Item
                      name="blood_group"
                      label="Blood Group"
                      className="w-30"
                    >
                      <BloodGroupSelect />
                    </Form.Item>
                    <div className="group-radio flex">
                      <h5 className="text-sm font-normal">Disabled:</h5>
                      <Radio.Group
                        onChange={onChange}
                        value={disabilityStatus}
                        name="is_disabled"
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div>
                  <Title level={2}>Existing Sibling</Title>
                  <div className="pb-3">
                    {formFields.map((field, index) => (
                      <div
                        key={index}
                        className={`admission-form ${
                          index === 0 ? "w-92" : ""
                        }`}
                      >
                        <Form.Item
                          name={`name${index + 1}`}
                          label="Name"
                          className="w-92"
                          rules={[
                            {
                              message:
                                "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                              validator: (_, value) => {
                                if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                                  );
                                }
                              },
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={`student_id${index + 1}`}
                          label="Student Id"
                          className="w-30"
                        >
                          <Input />
                        </Form.Item>
                        {index > 0 && (
                          <Button
                            type="danger"
                            onClick={() => handleRemoveForm(index)}
                          >
                            {" "}
                            Remove{" "}
                          </Button>
                        )}
                      </div>
                    ))}
                    <Button type="primary" onClick={handleAddForm}>
                      {" "}
                      Add{" "}
                    </Button>
                  </div>
                </div>
                <div>
                  <Title level={2}>Previous School</Title>
                  <div className="admission-form ">
                    <Form.Item
                      name="school_name"
                      label="School Name"
                      className="w-30"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="class_completed"
                      label="Class Completed"
                      className="w-30"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="grade"
                      label="Overall Grade"
                      className="w-30"
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="admission-form ">
                    <Form.Item
                      name="graduated_year"
                      label="Year Completed"
                      className="w-30"
                      rules={[
                        {
                          pattern: /^\d{4}$/, // Ensures exactly 4 digits
                          message: "Enroll year must be a 4-digit number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 4)}
                    >
                      <Input type="number" maxLength={4} min={0} />
                    </Form.Item>
                    <Form.Item
                      name="reference"
                      label="Reference Phone/Email"
                      className="w-30"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              !/^(\+\d{1,3}[- ]?)?\d{10}$/.test(value) &&
                              !/^\S+@\S+\.\S+$/.test(value)
                            ) {
                              return Promise.reject(
                                "Please enter a valid phone number or email"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="text-base font-normal pb-2">
                      Reason For Change:
                    </div>
                    <Form.Item name="change_reason">
                      <Input.TextArea
                        rows={3}
                        placeholder="Reason"
                        className="w-full border focus:outline-none resize-none"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <Title level={2}>Primary Contact</Title>
                  <div className="admission-form ">
                    <Form.Item
                      name="primary_contact_first_name"
                      label="First Name"
                      className="w-30"
                      rules={[
                        {
                          required: true,
                          message: "First name is required",
                        },
                        {
                          message:
                            "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                          validator: (_, value) => {
                            if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          updateDataForm("firstName", e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="primary_contact_last_name"
                      label="Last Name"
                      className="w-30"
                      rules={[
                        {
                          required: true,
                          message: "Last name is required",
                        },
                        {
                          message:
                            "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                          validator: (_, value) => {
                            if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          updateDataForm("lastName", e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="primary_contact_relation"
                      label="Relation"
                      className="w-30"
                      rules={[
                        {
                          required: true,
                          message: "Relation is required",
                        },
                        {
                          validator: validateAlphabetsOnly,
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          updateDataForm("relation", e.target.value);
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="admission-form ">
                    <Form.Item
                      name="primary_contact_occupation"
                      label="Occupation"
                      className="w-30"
                    >
                      <Input
                        onChange={(e) => {
                          updateDataForm("occupation", e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="primary_contact_education"
                      label="Education"
                      className="w-30"
                    >
                      <Input
                        onChange={(e) => {
                          updateDataForm("education", e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="primary_contact_annual_income"
                      label="Annual Income"
                      className="w-30"
                    >
                      <Input
                        onChange={(e) => {
                          updateDataForm("annualIncome", e.target.value);
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="admission-form ">
                    <Form.Item
                      name="primary_contact_phone"
                      label="Phone"
                      className="w-30"
                      rules={[
                        {
                          required: true,
                          message: "Phone is required",
                        },
                        {
                          pattern: /^[0-9]{10}$/,
                          message:
                            "Please enter a valid 10-digit phone number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                    >
                      <Input
                        onChange={(e) => {
                          updateDataForm("phone", e.target.value);
                        }}
                        type="number"
                        maxLength={10}
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item
                      name="primary_contact_email"
                      label="Email"
                      className="w-30"
                      rules={[
                        {
                          type: "email",
                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          updateDataForm("email", e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="primary_contact_aadhar"
                      label="Aadhar"
                      className="w-30"
                      rules={[
                        {
                          pattern: /^[0-9]{12}$/,
                          message: "Please enter a valid 12-digit Number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 12)}
                    >
                      <Input
                        onChange={(e) => {
                          updateDataForm("aadhar", e.target.value);
                        }}
                        maxLength={12}
                        type="number"
                        min={0}
                      />
                    </Form.Item>
                  </div>
                  <div className="admission-form ">
                    <Form.Item
                      name="primary_contact_address"
                      label="Address"
                      className="w-30"
                      rules={[
                        {
                          required: true,
                          message: "Address is required",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          updateDataForm("address", e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="primary_emergency_contact"
                      label="Emergency Contact"
                      className="w-30"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/,
                          message:
                            "Please enter a valid 10-digit phone number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                    >
                      <Input
                        onChange={(e) => {
                          updateDataForm("emergencyContact", e.target.value);
                        }}
                        type="number"
                        maxLength={10}
                        min={0}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <Title level={2}>Secondary Contact</Title>
                  <Form.Item name="addSameAsAbove" valuePropName="checked">
                    <Checkbox
                      onChange={(e) => {
                        handleSaveAsAbove(e.target.checked);
                      }}
                      checked={secondaryContactCheckbox}
                    >
                      Add Same as above
                    </Checkbox>
                  </Form.Item>
                  <div className="admission-form ">
                    <Form.Item
                      name="secondary_contact_first_name"
                      label="First Name"
                      className="w-30"
                      rules={[
                        {
                          message:
                            "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                          validator: (_, value) => {
                            if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="secondary_contact_last_name"
                      label="Last Name"
                      className="w-30"
                      rules={[
                        {
                          message:
                            "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                          validator: (_, value) => {
                            if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="secondary_contact_relation"
                      label="Relation"
                      className="w-30"
                      rules={[
                        {
                          validator: validateAlphabetsOnly,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="admission-form ">
                    <Form.Item
                      name="secondary_contact_occupation"
                      label="Occupation"
                      className="w-30"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="secondary_contact_education"
                      label="Education"
                      className="w-30"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="secondary_contact_annual_income"
                      label="Annual Income"
                      className="w-30"
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="admission-form ">
                    <Form.Item
                      name="secondary_contact_phone"
                      label="Phone"
                      className="w-30"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                          message:
                            "Please enter a valid 10-digit phone number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                    >
                      <Input type="number" maxLength={10} min={0} />
                    </Form.Item>
                    <Form.Item
                      name="secondary_contact_email"
                      label="Email"
                      className="w-30"
                      rules={[
                        {
                          type: "email",
                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="secondary_contact_aadhar"
                      label="Aadhar"
                      className="w-30"
                      rules={[
                        {
                          pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                          message: "Please enter a valid 12-digit Number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 12)}
                    >
                      <Input maxLength={12} type="number" min={0} />
                    </Form.Item>
                  </div>
                  <div className="admission-form ">
                    <Form.Item
                      name="secondary_contact_address"
                      label="Address"
                      className="w-30"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="secondary_emergency_contact"
                      label="Emergency Contact"
                      className="w-30"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                          message:
                            "Please enter a valid 10-digit phone number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                    >
                      <Input type="number" maxLength={10} min={0} />
                    </Form.Item>
                  </div>
                  <div className="flex justify-between items-start">
                    <div className="flex space-x-6">
                      <Button
                        size="large"
                        type="primary"
                        htmlType="button"
                        className="text-white w-9"
                        onClick={() => setUploadAdmissionPopup(true)}
                      >
                        Upload Document
                      </Button>
                      <Form.Item
                        name="referral"
                        label="Referral"
                        className="w-30"
                      >
                        <Select
                          placeholder="Select Referral"
                          onChange={handleReferralChange}
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          <Option value="advertisement">Advertisement</Option>
                          <Option value="social">
                            Social Networking Community
                          </Option>
                          <Option value="newspaper">Newspaper</Option>
                          <Option value="friend">Friend</Option>
                        </Select>
                      </Form.Item>
                      {referral === "friend" && (
                        <div>
                          <Form.Item
                            name="friendName"
                            label="Name"
                            className="w-30"
                          >
                            <Input
                              onChange={(e) =>
                                handleFriendInfoChange("name", e.target.value)
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            name="friendEmail"
                            label="Email"
                            className="w-30"
                            rules={[
                              {
                                type: "email",
                                message: "Please enter a valid email address",
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) =>
                                handleFriendInfoChange("email", e.target.value)
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            name="friendPhone"
                            label="Phone"
                            className="w-30"
                            rules={[
                              {
                                pattern: /^[0-9]{10}$/,
                                message:
                                  "Please enter a valid 10-digit phone number!",
                              },
                            ]}
                            getValueFromEvent={(e) =>
                              e.target.value.slice(0, 10)
                            }
                          >
                            <Input
                              onChange={(e) =>
                                handleFriendInfoChange("phone", e.target.value)
                              }
                            />
                          </Form.Item>
                        </div>
                      )}
                    </div>
                    <div className="hidden">
                      <DownloadApplication />
                    </div>

                    <Button
                      size="large"
                      type="primary"
                      htmlType="button"
                      className="text-white w-9"
                      onClick={() => generatePDF("applicationForm")}
                    >
                      Download
                    </Button>
                  </div>
                  <div className="pt-4">
                    {parentFileList && parentFileList.length > 0 && (
                      <h5 className="pb-3 font-semibold text-base">
                        Upload Preview
                      </h5>
                    )}
                    {parentFileList &&
                      parentFileList?.length > 0 &&
                      parentFileList?.map((data, index) => {
                        return (
                          <ui key={index}>
                            <li className="pb-2 capitalize">
                              {index + 1}.&nbsp;
                              {data?.files?.name}
                            </li>
                          </ui>
                        );
                      })}
                  </div>
                  <div className="flex justify-center items-center py-5">
                    <div className="inline-flex items-center space-x-2">
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="text-white"
                      >
                        Submit
                      </Button>
                      {/* <Button
                        size="large"
                        type="dark"
                        onClick={() => history.replace("/auth/login")}
                      >
                        Cancel
                      </Button> */}
                    </div>
                  </div>

                  <Modal
                    width={800}
                    visible={uploadAdmissionPopup}
                    onOk={() => setUploadAdmissionPopup(false)}
                    onCancel={() => {
                      setUploadAdmissionPopup(false);
                    }}
                    footer={null}
                  >
                    <div>
                      <MultipleUpload
                        onFileListChange={handleFileListChange}
                        parentResponse={parentFileList}
                      />
                    </div>
                  </Modal>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
