import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { serverUrl } from "../../../../../nestserver";
import InstructorClass from "./InstructorClass";
import InstructorProfile from "./InstructorProfile";
import InstructorSetting from "./InstructorSetting";

function SwitchInstructor() {
  const [step, setStep] = useState(1);
  const { id } = useParams();

  async function fetchInstructor() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/" + id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const instructor = useQuery(["instructor", id], fetchInstructor, {
    enabled: !!id,
  });

  function switchTab() {
    switch (step) {
      case 1:
        return (
          <div>
            <InstructorProfile data={instructor.data} />
          </div>
        );

      case 2:
        return (
          <div>
            <InstructorClass data={instructor.data} />
          </div>
        );

      case 3:
        return (
          <div>
            <InstructorSetting data={instructor.data} />
          </div>
        );

      default:
        return (
          <div>
            <InstructorProfile data={instructor.data} />
          </div>
        );
    }
  }

  return (
    <>
      {/* {subject.isSuccess ? ( */}
      <div className="p-8">
        <div className="grid grid-cols-5 bg-white rounded-lg ">
          <div
            onClick={() => setStep(1)}
            className={`iskool-tab-item ${
              step === 1 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div>
              <div className="text-xs line-clamp-1">
                {instructor?.data?.teacher?.first_name}{" "}
                {instructor?.data?.teacher?.last_name}
              </div>
              <div className="text-lg">Profile</div>
            </div>
          </div>
          <div
            onClick={() => setStep(2)}
            className={`iskool-tab-item ${
              step === 2 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Classes</div>
          </div>
          <div
            onClick={() => setStep(3)}
            className={`iskool-tab-item ${
              step === 3 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Settings</div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------- */}
        <div>
          <div>{switchTab()}</div>
        </div>
      </div>
      {/* ) : (
        ""
      )} */}
    </>
  );
}

export default SwitchInstructor;
