import { Button, DatePicker, Form, Input, message } from "antd";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useReactToPrint } from "react-to-print";
import { cloudFrontUrl, serverUrl } from "../../../../../../nestserver";

const TransferCertificate = ({
  getStudentTemplate,
  formType,
  studentId,
  templateId,
}) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [fields, setFields] = useState([
    { label: "", field_name: "", value: "" },
  ]);

  const pdfRef = useRef();
  const componentRef = useRef();

  const downloadPDF = async () => {
    const input = pdfRef.current;

    // Wait for all images to load
    await Promise.all(
      Array.from(input.querySelectorAll("img")).map((img) => {
        if (img.complete) {
          return Promise.resolve();
        } else {
          return new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
          });
        }
      })
    );

    html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0); // Maximum quality
      const pdf = new jsPDF("p", "mm", "a4", true);

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;

      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio,
        undefined,
        "FAST" // Change this to 'SLOW' or 'FAST' depending on the quality/speed trade-off you prefer
      );

      pdf.save("transferCertificate.pdf");
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (getStudentTemplate) {
      if (getStudentTemplate?.fields && getStudentTemplate?.fields.length > 0) {
        const updatedFields = getStudentTemplate?.fields.map((field) => ({
          label: field.label,
          field_name: field?.field_name,
          value: field.value,
        }));
        setFields(updatedFields);
      }

      form.setFieldsValue({
        declaration_message: getStudentTemplate?.declaration_message,
      });
    } else setFields([{ label: "", field_name: "", value: "" }]);
  }, [getStudentTemplate]);

  const handleInputChange = (index, event) => {
    const { value } = event.target;

    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index] = {
        ...updatedFields[index],
        value: value,
      };
      return updatedFields;
    });
  };

  const onFinish = (values) => {
    const filteredFields = fields.filter(
      (field) => field.label && field.field_name
    );
    const formValues = form.getFieldsValue();
    const declarationMessage = formValues.declaration_message;
    const payload = {
      form_type: formType ? formType : null,
      student: studentId ? studentId : null,
      form_template: templateId ? templateId : null,
      fields: filteredFields && filteredFields.length > 0 ? filteredFields : [],
      declaration_message: declarationMessage,
    };
    if (studentId && templateId) {
      axios
        .post(serverUrl + "/student-form/", payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          queryClient?.invalidateQueries(["form-template"]);
          queryClient?.invalidateQueries(["student-form"]);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            message.error(err?.response?.data?.message);
          }
        });
    }
  };

  return (
    <>
      <div
        className="tc-doc"
        ref={(el) => {
          componentRef.current = el;
          pdfRef.current = el;
        }}
      >
        <div className="p-8">
          <div
            style={{ borderRadius: "10px", color: "#616161" }}
            className=" bg-white drop-shadow-md p-8  "
          >
            <div className="border ">
              <div className="upper-part ">
                <div className="flex flex-col">
                  <h3 className="text-base pb-3 flex items-center">
                    {" "}
                    <div className="relative w-20 h-20 rounded-full  border-8 border-white">
                      <img
                        className="w-full h-full rounded-full border"
                        src={
                          getStudentTemplate?.school?.logo
                            ? `${cloudFrontUrl}/common/${getStudentTemplate?.school?.logo}`
                            : "https://ui-avatars.com/api/?name=" +
                              (getStudentTemplate?.school?.name
                                ? getStudentTemplate?.school?.name
                                : getStudentTemplate?.school?.name)
                        }
                        alt="banner"
                      />
                    </div>
                  </h3>
                </div>
                <div className="flex flex-col gap-2" style={{ width: "400px" }}>
                  <p className="text-base font-semibold pb-2 capitalize">
                    {getStudentTemplate?.school &&
                      getStudentTemplate?.school?.name}
                  </p>
                  <p className="text-base font-semibold capitalize">
                    {getStudentTemplate?.school &&
                      `${getStudentTemplate?.school?.address}, ${getStudentTemplate?.school?.city}, ${getStudentTemplate?.school?.state}, ${getStudentTemplate?.school?.pincode}`}
                  </p>
                </div>
              </div>
              <div className="lower-part p-4">
                <h3 className="text-xl font-semibold text-center pb-4">
                  Transfer Certificate
                </h3>
                <h6 className="text-base">
                  {" "}
                  <span className="text-base font-semibold">
                    Aff No./School Code :
                  </span>{" "}
                  {getStudentTemplate?.school?.refId}
                </h6>
                <div className="flex justify-between items-center pt-4 pb-8">
                  <h6 className="text-base">
                    <span className="text-base font-semibold">
                      File No :{" "}
                      {getStudentTemplate?.file_number &&
                        getStudentTemplate?.file_number}
                    </span>{" "}
                  </h6>
                  <h6 className="text-base ">
                    <p className="text-base font-semibold">
                      Admission No :{" "}
                      {getStudentTemplate?.admission_number
                        ? getStudentTemplate?.admission_number
                        : "--------"}
                    </p>{" "}
                  </h6>
                </div>
                <div className="tc">
                  <Form
                    form={form}
                    name="dynamic_rule"
                    // onFinish={onFinish}
                    autoComplete="off"
                  >
                    {fields &&
                      fields?.map((field, index) => (
                        <div key={index} className="flex gap-4 capitalize">
                          <span className="pt-2">{index + 1}</span>
                          <Form.Item className="capitalize">
                            <Input
                              type="text"
                              value={field.label}
                              style={{ minWidth: "220px" }}
                              placeholder="Input Field"
                              className="disabled-input capitalize outline-none"
                            />
                          </Form.Item>
                          <p className="text-base">:</p>
                          <Form.Item>
                            <Input
                              value={fields[index].value}
                              className="capitalize disabled-input"
                              style={{ minWidth: "220px", maxWidth: "500px" }}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                            />
                          </Form.Item>
                        </div>
                      ))}
                    <Form.Item name="declaration_message">
                      <Input.TextArea
                        rows={3}
                        placeholder="Declaration"
                        defaultValue="I hereby declare"
                        className="w-full focus:outline-none resize-none border-none border-0px"
                      />
                    </Form.Item>
                    <div className="flex justify-between items-center download-pdf">
                      <div className="flex gap-4  items-center pb-4">
                        <h3 className="text-base font-semibold pl-30px">
                          Date{" "}
                          {getStudentTemplate?.generated_date &&
                            moment(getStudentTemplate?.generated_date).format(
                              "YYYY-MM-DD"
                            )}
                          <DatePicker
                            format="DD-MM-YYYY"
                            placeholder="Select date"
                            disabledDate={(current) =>
                              current && current > moment().endOf("day")
                            }
                            suffixIcon={null}
                            className="disabled-input"
                            getPopupContainer={(trigger) => trigger.parentNode}
                          />
                        </h3>
                      </div>
                      <div className="flex gap-4 items-center">
                        <h3 className="text-base font-semibold pr-30px">
                          Signature
                        </h3>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          onClick={onFinish}
        >
          Submit
        </Button>
      </div>
      {getStudentTemplate?.file_number && (
        <div className="flex justify-center gap-4 pt-4">
          <Button size="large" onClick={handlePrint}>
            Print
          </Button>
          <Button size="large" onClick={downloadPDF}>
            Download
          </Button>
        </div>
      )}
    </>
  );
};

export default TransferCertificate;
