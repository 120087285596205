import { Button, Form, Select, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import AutosizeInput from "react-input-autosize";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { cloudFrontUrl, serverUrl } from "../../../../nestserver";
const { Option } = Select;

const ConductCertificateTemplate = ({ getAdmin, getTemplateDetails, id }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [data, setData] = useState({
    name: "",
    parent_name: "",
    address: "",
    district: "",
    year: "",
    conduct: "",
  });

  const updateDataForm = (paramName, value) => {
    setData({ ...data, [paramName]: value });
  };
  const conductCertificateData = [
    { label: "Name", field_name: "name" },
    { label: "Parent Name", field_name: "parent_name" },
    { label: "Address", field_name: "address" },
    { label: "District", field_name: "district" },
    { label: "Year", field_name: "year" },
    { label: "Conduct", field_name: "conduct" },
  ];
  const handleFinish = () => {
    const payload = {
      form_type: "conduct_certificate",
      school: getAdmin?.data?.result?.data?._id,
      fields:
        conductCertificateData && conductCertificateData.length > 0
          ? conductCertificateData
          : [],
    };
    if (id) {
      axios
        .put(serverUrl + "/form-template/" + id, payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          queryClient?.invalidateQueries(["form-template"]);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            message.error(err?.response?.data?.message);
          }
        });
    } else if (conductCertificateData && conductCertificateData.length > 0) {
      axios
        .post(serverUrl + "/form-template/", payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          queryClient?.invalidateQueries(["form-template"]);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            message.error(err?.response?.data?.message);
          }
        });
    }
  };
  return (
    <>
      <div className="tc-doc">
        <div className="p-8">
          <div
            style={{ borderRadius: "10px", color: "#616161" }}
            className=" bg-white drop-shadow-md p-8  "
          >
            <div className="p-4 text-right">
              {" "}
              <Button size="large" onClick={() => history.goBack()}>
                Back{" "}
              </Button>{" "}
            </div>
            <div className="border ">
              <div className="upper-part ">
                <div className="flex flex-col">
                  <h3 className="text-base pb-3 flex items-center">
                    {" "}
                    <div className="relative w-20 h-20 rounded-full  border-8 border-white">
                      <img
                        className="w-full h-full rounded-full border"
                        src={
                          getAdmin?.data?.result?.data?.logo
                            ? `${cloudFrontUrl}/common/${getAdmin?.data?.result?.data?.logo}`
                            : "https://ui-avatars.com/api/?name=" +
                              (getAdmin?.data?.result?.data?.name
                                ? getAdmin?.data?.result?.data?.name
                                : getAdmin?.data?.result?.data?.name)
                        }
                        alt="banner"
                      />
                    </div>
                  </h3>
                </div>
                <div className="flex flex-col gap-2" style={{ width: "400px" }}>
                  <p className="text-base font-semibold pb-2 capitalize">
                    {getAdmin?.data?.result?.data?.name}
                  </p>
                  <p className="text-base font-semibold capitalize">{`${getAdmin?.data?.result?.data?.address}, ${getAdmin?.data?.result?.data?.city}, ${getAdmin?.data?.result?.data?.state}, ${getAdmin?.data?.result?.data?.pincode} `}</p>
                </div>
              </div>
              <div className="lower-part p-4">
                <h3 className="text-xl font-semibold text-center pb-4">
                  Conduct Certificate
                </h3>
                <h6 className="text-base">
                  {" "}
                  <span className="text-base font-semibold">
                    Aff No./School Code :
                  </span>{" "}
                  {getAdmin?.data?.result?.data?.refId}
                </h6>
                <div className="flex justify-between items-center pt-4 pb-8">
                  <h6 className="text-base">
                    <span className="text-base font-semibold">File No :</span>{" "}
                  </h6>
                  <h6 className="text-base">
                    <p className="text-base font-semibold">
                      Admission No : --------
                    </p>
                  </h6>
                </div>
                <div className="study-certificate">
                  <Form form={form} name="dynamic_rule" autoComplete="off">
                    <div>
                      This is to certify that Mr/Miss &nbsp;
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("name", e.target.value);
                        }}
                        value={data?.name}
                        inputClassName="custom-input"
                      />
                      &nbsp; son/daughter of Mr/Mrs &nbsp;{" "}
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("parent_name", e.target.value);
                        }}
                        value={data?.person_name}
                        inputClassName="custom-input"
                      />
                      &nbsp;
                      <span>
                        House &nbsp;{" "}
                        <AutosizeInput
                          type="text"
                          onChange={(e) => {
                            updateDataForm("address", e.target.value);
                          }}
                          value={data?.class}
                          inputClassName="custom-input"
                        />{" "}
                        post&nbsp;
                        <AutosizeInput
                          type="text"
                          onChange={(e) => {
                            updateDataForm("district", e.target.value);
                          }}
                          value={data?.class}
                          inputClassName="custom-input"
                        />{" "}
                        &nbsp;district is known to me personally for the last
                        &nbsp;
                        <AutosizeInput
                          type="text"
                          onChange={(e) => {
                            updateDataForm("year", e.target.value);
                          }}
                          value={data?.class}
                          inputClassName="custom-input"
                        />{" "}
                        Year (s). .
                      </span>
                      His/Her Conduct and character are{" "}
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("conduct", e.target.value);
                        }}
                        value={data?.class}
                        inputClassName="custom-input"
                      />
                    </div>
                    <div className="pt-4">He/She is not related to me.</div>
                    <div className=" pt-10 pb-10">
                      <div className="flex justify-between items-center pt-10">
                        <div className="flex gap-4  items-center">
                          <h3 className="text-base font-semibold">Date</h3>
                        </div>
                        <div className="flex gap-4 items-center">
                          <h3 className="text-base font-semibold">Signature</h3>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        type="primary"
                        size="large"
                        onClick={handleFinish}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConductCertificateTemplate;
