import { Table } from "antd";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import getStatusColor from "../../../../../services/tagColor.service";

export default function StudentExamSubmissionTable({ student, subject }) {
  // fetch exams
  async function fetchExams() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/exam/submissions",
      params: {
        subject: subject,
        published: true,
        student: student,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const exams = useQuery("exams", fetchExams);

  const columns = [
    {
      title: "Exam Name & Module",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div style={{ color: "#00627B" }} className="text-left text-sm">
          {text}- {record?.modules[0]?.title}
        </div>
      ),
    },

    {
      title: "Due Date",
      dataIndex: "last_date",
      key: "last_date",
      render: (text) => (
        <div style={{ color: "#00627B" }} className=" text-base">
          {moment(text).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "Submitted Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div style={{ color: "#00627B" }} className=" text-base ">
          {record?.submissions[0]?.submitted_date
            ? moment(record?.submissions[0]?.submitted_date).format(
                "DD MMM YYYY"
              )
            : moment(record?.submissions[0]?.createdAt).format("DD MMM YYYY")}
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div className=" text-sm font-semibold">
          <div
            className={
              record?.submissions[0]?.status
                ? getStatusColor(record?.submissions[0]?.status)
                : "text-gray-600"
            }
          >
            {record?.submissions[0]?.status ?? "Not Submitted"}
          </div>
        </div>
      ),
    },
    {
      title: "Total Score",
      dataIndex: "score",
      key: "score",
      render: (text, record) => (
        <div
          style={{ color: "#00627B" }}
          className=" text-base font-semibold capitalize "
        >
          {record.submissions[0]?.score ?? "_"} / {text}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={exams.isSuccess && (exams.data ? exams.data : [])}
        pagination={false}
      />
    </div>
  );
}
