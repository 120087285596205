import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Upload, message } from "antd";
import axios from "axios"; // Import axios for making HTTP requests
import React, { useEffect, useState } from "react";
import { serverUrl } from "../../nestserver";

const MultipleUpload = ({
  onFileListChange,
  parentResponse,
  isDisabled,
  isParentView,
}) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  async function uploadDoc(data, folder = "docs", setProgress = console.log) {
    try {
      const formData = new FormData();
      formData.append("files", data.file);
      formData.append("folder", folder);

      const res = await axios.post(serverUrl + "/uploads/docs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          setProgress(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      });
      if (res && res?.data) {
        res?.data?.map((data) =>
          setFileList((oldArray) => [...oldArray, data])
        );
      }
      return res.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
  useEffect(() => {
    onFileListChange(fileList);
  }, [fileList, onFileListChange]);
  useEffect(() => {
    if (parentResponse) {
      setFileList(parentResponse);
    }
  }, []);

  function beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 1;
    const isPdf = file.type === "application/pdf";
    if (!isLt2M) {
      message.error("File must be smaller than 1MB!");
    }
    if (!isPdf) {
      message.error(`${file.name} is not a PDF file`);
    }
    return isLt2M && isPdf;
  }

  async function handleUpload(data) {
    try {
      setLoading(true);
      await uploadDoc(data);
      setLoading(false);
      message.success("File uploaded successfully");
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  }

  const handleDelete = (file) => {
    const newFileList = fileList.filter((item) => item.id !== file.id);
    setFileList(newFileList);
  };

  const name = "yourFileName";

  return (
    <div>
      <Upload
        name={name}
        action={serverUrl + "/uploads/docs"}
        multiple
        showUploadList={false}
        customRequest={handleUpload}
        beforeUpload={beforeUpload}
      >
        {loading ? (
          "Uploading..."
        ) : (
          <div className="pt-2">
            <Button
              size="large"
              type="primary"
              htmlType="button"
              disabled={isParentView && isDisabled}
            >
              <UploadOutlined /> Click to Upload
            </Button>
          </div>
        )}
      </Upload>
      {fileList && fileList.length > 0 && (
        <div>
          <p className="pt-2 text-base">Selected Files:</p>
          <ul>
            {fileList &&
              fileList?.length > 0 &&
              fileList?.map((file) => (
                <>
                  <li
                    key={file?.id}
                    className="flex justify-between items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      href={file?.files.original}
                      target="_blank"
                      rel="noreferrer"
                      className="text-base"
                    >
                      {" "}
                      {file?.files?.name ? file?.files?.name : "none"}
                    </a>
                    <Button
                      type="danger flex items-center justify-center mb-3"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      disabled={isParentView && isDisabled}
                      onClick={() => handleDelete(file)}
                    />
                  </li>
                </>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultipleUpload;
