import { Button } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import InstructorBroadcast from "./InstructorBroadcast";

const ManageMessage = () => {
  const queryClient = useQueryClient();
  const [messagePopupOpen, setMessagePopupOpen] = useState(false);
  const [conversationId, setConversationId] = useState();
  const [conversationMessage, setConversationMessage] = useState();
  const [senderDetails, setSenderDetails] = useState();
  const [instructorAdminChat, setInstructorAdminChat] = useState();
  const [teacherId, setTeacherId] = useState();
  const [classId, setClassId] = useState(null);
  const homework = true;

  //fetch class
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  const fetchConversation = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/conversations/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const conversationDetails = useQuery(
    ["conversations"],
    fetchConversation,
    {}
  );

  const instructorMessage = async (conversationId, item) => {
    if (item?.unread_messages_count > 0) {
      await axios({
        method: "patch",
        url: serverUrl + "/conversations/" + conversationId + "/read-message",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      queryClient.invalidateQueries("conversations");
    }

    setSenderDetails(item);
    setMessagePopupOpen(true);
    setConversationId(conversationId);
    const res = await axios({
      method: "get",
      url: serverUrl + "/conversations/" + conversationId + "/message",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    setConversationMessage(res?.data?.result);
    return res.data;
  };

  return (
    <div className="p-8">
      <div
        style={{ borderRadius: "10px", color: "#616161" }}
        className=" bg-white drop-shadow-md p-8 w-full screen-height "
      >
        <div className="flex gap-4 min-height">
          <div style={{ width: "30%" }} className="message-left-part-admin">
            {homework && (
              <div className="pt-4 pb-4 text-right">
                <Button
                  onClick={() => {
                    setClassId(null);
                    setMessagePopupOpen(true);
                    setSenderDetails(null);
                    setConversationMessage(null);
                    setInstructorAdminChat(null);
                    setConversationId(null);
                    setTeacherId(null);
                  }}
                  type="primary"
                  size="large"
                >
                  Create New Message
                </Button>
              </div>
            )}
            <div className="text-base font-semibold">Chat History</div>
            <div className="h-full">
              <div style={{ height: "80%", overflowY: "auto" }}>
                {conversationDetails?.data?.result?.data
                  .filter((item) => item.connection.teacher)
                  .map((item) => {
                    return (
                      <div
                        className="pt-2 text-base cursor-pointer"
                        key={item._id}
                      >
                        <div
                          onClick={() => {
                            instructorMessage(item._id, item);
                            setTeacherId(null);
                          }}
                          type="primary"
                          size="large"
                          className={`cursor-pointer ${
                            conversationId === item?._id
                              ? "active-message"
                              : "message-card"
                          }`}
                        >
                          <div className="flex justify-between">
                            <div>
                              {item.connection.teacher.first_name}{" "}
                              {item.connection.teacher.last_name}
                            </div>
                            {item?.unread_messages_count > 0 && (
                              <div className="rounded-full border flex justify-center items-center unread-message">
                                <p>{item?.unread_messages_count}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div style={{ width: "70%" }} className="instructor-broadcast">
            {messagePopupOpen ? (
              <InstructorBroadcast
                classes={classes}
                setMessagePopupOpen={setMessagePopupOpen}
                conversationMessage={conversationMessage}
                setConversationMessage={setConversationMessage}
                setSenderDetails={setSenderDetails}
                senderDetails={senderDetails}
                instructorMessage={instructorMessage}
                setInstructorAdminChat={setInstructorAdminChat}
                instructorAdminChat={instructorAdminChat}
                setConversationId={setConversationId}
                teacherId={teacherId}
                setTeacherId={setTeacherId}
                classId={classId}
                setClassId={setClassId}
              />
            ) : (
              <div className="flex w-full justify-center items-center h-full">
                No message to show
              </div>
            )}
          </div>
        </div>
        <div className="flex"></div>
      </div>
    </div>
  );
};

export default ManageMessage;
