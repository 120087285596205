import axios from "axios";
import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { AiOutlineMenuUnfold, AiOutlineQuestionCircle } from "react-icons/ai";
import { IoClose, IoSettingsOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { cloudFrontUrl, serverUrl } from "../../nestserver";
import { selectUser } from "../../redux/slices/auth.slice";
import Accord from "../../utils/Accord";
import FadeRight from "../animations/FadeRight";

function LeftSidebar() {
  const [dropRight, setDropRight] = useState(false);
  const [dropRightStudent, setDropRightStudent] = useState(false);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const user = useSelector(selectUser);
  const history = useHistory();
  const location = useLocation();
  const instructorSideMenu = [
    {
      title: user?.teacher?.first_name,
      icon: (
        <img
          className="w-12 h-12 rounded-full"
          src={
            user?.teacher?.profile_image
              ? cloudFrontUrl +
                "/" +
                user?._id +
                "/" +
                user?.teacher?.profile_image
              : "https://ui-avatars.com/api/?name=" +
                user?.teacher?.first_name +
                " " +
                user?.teacher?.last_name
          }
          alt="profile"
        />
      ),
      onClick: () => {
        history.push("/dashboard/instructor/home");
        setDropRight(0);
      },
      url: "/dashboard/instructor/home",
    },
    {
      title: "Menu",
      icon: <AiOutlineMenuUnfold size={35} />,
      onClick: () => history.push("/dashboard/instructor/menu"),
      url: "/dashboard/instructor/menu",
    },
    // {
    //   title: "Courses Board",
    //   icon: <GoBook size={35} />,
    //   onClick: () => setDropRight(!dropRight),
    // },
    {
      title: "Settings",
      icon: <IoSettingsOutline size={35} />,
      onClick: () => history.push("/dashboard/instructor/menu/setting"),
      url: "/dashboard/instructor/menu/setting",
    },
    {
      title: "Help",
      icon: <AiOutlineQuestionCircle size={35} />,
      onClick: () => {
        history.push("/dashboard/instructor/help");
        setDropRight(0);
      },
      url: "/dashboard/instructor/help",
    },
    // {
    //   title: "Assignments & Exams",
    //   icon: <GoBook size={35} />,
    //   onClick: () => {
    //     history.push("/dashboard/instructor/assignments-and-exams");
    //     setDropRight(0);
    //   },
    //   url: "/dashboard/instructor/assignments-and-exams",
    // },
  ];

  const superAdminSidemenu = [
    // {
    //   title: user?.student?.first_name,
    //   icon: (
    //     <img
    //       className="w-12 h-12 rounded-full"
    //       src={
    //         user?.student?.profile_image
    //           ? cloudFrontUrl +
    //             "/" +
    //             user?._id +
    //             "/" +
    //             user?.student?.profile_image
    //           : "https://ui-avatars.com/api/?name=" +
    //             user?.student?.first_name +
    //             " " +
    //             user?.student?.last_name
    //       }
    //       alt="profile"
    //     />
    //   ),
    //   onClick: () => {
    //     history.push("/dashboard/student/home");
    //     setDropRightStudent(0);
    //   },
    //   url: "/dashboard/student/home",
    // },
    {
      title: "School",
      icon: <AiOutlineMenuUnfold size={35} />,
      onClick: () => history.push("/dashboard/super-admin/school"),
      url: "/dashboard/student/super-admin/school",
    },
    // {
    //   title: "Courses Board",
    //   icon: <GoBook size={35} />,
    //   onClick: () => setDropRightStudent(!dropRightStudent),
    //   // url: "/dashboard/student/",
    // },

    {
      title: "Settings",
      icon: <IoSettingsOutline size={35} />,
      onClick: () => {
        history.push("/dashboard/super-admin/settings");
        setDropRightStudent(0);
      },
      url: "/dashboard/super-admin/settings",
    },
  ];
  const studentSidemenu = [
    {
      title: user?.student?.first_name,
      icon: (
        <img
          className="w-12 h-12 rounded-full"
          src={
            user?.student?.profile_image
              ? cloudFrontUrl +
                "/" +
                user?._id +
                "/" +
                user?.student?.profile_image
              : "https://ui-avatars.com/api/?name=" +
                user?.student?.first_name +
                " " +
                user?.student?.last_name
          }
          alt="profile"
        />
      ),
      onClick: () => {
        history.push("/dashboard/student/home");
        setDropRightStudent(0);
      },
      url: "/dashboard/student/home",
    },
    {
      title: "Menu",
      icon: <AiOutlineMenuUnfold size={35} />,
      onClick: () => history.push("/dashboard/student/menu"),
      url: "/dashboard/student/menu",
    },
    // {
    //   title: "Courses Board",
    //   icon: <GoBook size={35} />,
    //   onClick: () => setDropRightStudent(!dropRightStudent),
    //   // url: "/dashboard/student/",
    // },
    {
      title: "Settings",
      icon: <IoSettingsOutline size={35} />,
      onClick: () => history.push("/dashboard/student/menu/setting"),
      url: "/dashboard/student/menu/setting",
    },

    {
      title: "Help",
      icon: <AiOutlineQuestionCircle size={35} />,
      onClick: () => {
        history.push("/dashboard/student/help");
        setDropRightStudent(0);
      },
      url: "/dashboard/student/help",
    },
  ];

  const adminSidemenu = [
    {
      title: user?.admin?.first_name,
      icon: (
        <img
          className="w-12 h-12 rounded-full"
          src={
            user?.admin?.profile_image
              ? cloudFrontUrl +
                "/" +
                user?._id +
                "/" +
                user?.admin?.profile_image
              : "https://ui-avatars.com/api/?name=" +
                (user?.admin
                  ? user.admin?.first_name + "+" + user.admin?.last_name
                  : user?.username)
          }
          alt="profile"
        />
      ),
      onClick: () => history.push("/dashboard/admin/home"),
      url: "/dashboard/admin/home",
    },
    {
      title: "Menu",
      icon: <AiOutlineMenuUnfold size={35} />,
      onClick: () => history.push("/dashboard/admin/menu"),
      url: "/dashboard/admin/menu",
    },
    {
      title: "Help",
      icon: <AiOutlineQuestionCircle size={35} />,
      onClick: () => history.push("/dashboard/admin/help"),
      url: "/dashboard/admin/help",
    },
  ];
  const parentSidemenu = [
    {
      title: user?.parent?.first_name,
      icon: (
        <img
          className="w-12 h-12 rounded-full"
          src={
            user?.parent?.profile_image
              ? cloudFrontUrl +
                "/" +
                user?._id +
                "/" +
                user?.parent?.profile_image
              : "https://ui-avatars.com/api/?name=" +
                (user?.parent
                  ? user.parent?.first_name + "+" + user.parent?.last_name
                  : user?.username)
          }
          alt="profile"
        />
      ),
      onClick: () => history.push("/dashboard/parent/home"),
      url: "/dashboard/parent/home",
    },

    {
      title: "Menu",
      icon: <AiOutlineMenuUnfold size={35} />,
      onClick: () => history.push("/dashboard/parent/menu"),
      url: "/dashboard/parent/menu",
    },
    {
      title: "Settings",
      icon: <IoSettingsOutline size={35} />,
      onClick: () => history.push("/dashboard/parent/menu/setting"),
      url: "/dashboard/parent/menu/setting",
    },
    {
      title: "Help",
      icon: <AiOutlineQuestionCircle size={35} />,
      onClick: () => history.push("/dashboard/parent/help"),
      url: "/dashboard/parent/help",
    },
  ];

  function getClasses() {
    axios({
      method: "get",
      url: serverUrl + "/teachers/classes",
      data: {},
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        setClasses(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getSubjects() {
    axios({
      method: "get",
      url: serverUrl + "/students/" + user?.student?._id,
      data: {},
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        setSubjects(res?.data?.subjects);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    if (user) {
      if (user.role === "instructor") {
        getClasses();
      }
      if (user.role === "student") {
        getSubjects();
      }
    }
  }, [user]);

  function showSidebar(role) {
    switch (role) {
      case "instructor":
        return (
          <>
            {instructorSideMenu.map((item) => (
              <div
                onClick={() => {
                  item.onClick();
                }}
                key={item.title}
                className={`w-full overflow-x-hidden flex flex-col items-center space-y-1 p-2 cursor-pointer border-l-4 border-transpagent hover:border-red-400 hover:shadow transition-all duration-100 ease-in-out ${
                  location.pathname.includes(item.url)
                    ? "border-red-400 shadow"
                    : ""
                }`}
              >
                {item.icon}
                <div className="text-xs text-center line-clamp-1">
                  {item.title}
                </div>
              </div>
            ))}
          </>
        );
      case "student":
        return (
          <>
            {studentSidemenu.map((item) => (
              <div
                onClick={item.onClick}
                key={item.title}
                className={`w-full overflow-x-hidden flex flex-col items-center space-y-1 p-2 cursor-pointer border-l-4 border-transpagent hover:border-red-400 hover:shadow transition-all duration-100 ease-in-out ${
                  location.pathname === item.url ? "border-red-400 shadow" : ""
                }`}
              >
                {item.icon}
                <div className="text-xs text-center line-clamp-1">
                  {item.title}
                </div>
              </div>
            ))}
          </>
        );
      case "super_admin":
        return (
          <>
            {superAdminSidemenu.map((item) => (
              <div
                onClick={item.onClick}
                key={item.title}
                className={`w-full overflow-x-hidden flex flex-col items-center space-y-1 p-2 cursor-pointer border-l-4 border-transpagent hover:border-red-400 hover:shadow transition-all duration-100 ease-in-out ${
                  location.pathname === item.url ? "border-red-400 shadow" : ""
                }`}
              >
                {item.icon}
                <div className="text-xs text-center line-clamp-1">
                  {item.title}
                </div>
              </div>
            ))}
          </>
        );
      case "admin":
        return (
          <>
            {adminSidemenu.map((item) => (
              <div
                onClick={item.onClick}
                key={item.title}
                className={`w-full overflow-x-hidden flex flex-col items-center space-y-1 p-2 cursor-pointer border-l-4 border-transpagent hover:border-red-400 hover:shadow transition-all duration-100 ease-in-out ${
                  location.pathname === item.url ? "border-red-400 shadow" : ""
                }`}
              >
                {item.icon}
                <div className="text-xs text-center line-clamp-2">
                  {item.title}
                </div>
              </div>
            ))}
          </>
        );
      case "parent":
        return (
          <>
            {parentSidemenu.map((item) => (
              <div
                onClick={item.onClick}
                key={item.title}
                className={`w-full overflow-x-hidden flex flex-col items-center space-y-1 p-2 cursor-pointer border-l-4 border-transpagent hover:border-red-400 hover:shadow transition-all duration-100 ease-in-out ${
                  location.pathname === item.url ? "border-red-400 shadow" : ""
                }`}
              >
                {item.icon}
                <div className="text-xs text-center line-clamp-2">
                  {item.title}
                </div>
              </div>
            ))}
          </>
        );

      default:
        break;
    }
  }

  return (
    <>
      <div className="flex relative h-full z-50">
        <div className="w-28 overflow-y-auto ">
          {user && showSidebar(user?.role)}
        </div>
        {/* --------------------------side-nav-slider-instructor------------------------- */}
        <AnimatePresence>
          {dropRight && (
            <FadeRight>
              <div
                className={`absolute`}
                style={{
                  left: "100%",
                  top: "0",
                  height: "100%",
                  zIndex: "1001",
                }}
              >
                <div
                  style={{ backgroundColor: "#122025" }}
                  className="w-60 h-full flex"
                >
                  <div className="absolute right-3 top-3">
                    <IoClose
                      onClick={() => setDropRight(false)}
                      className="cursor-pointer text-white "
                    />
                  </div>

                  <div className="flex flex-col space-y-3">
                    <div className="pt-10 p-3 space-y-4">
                      <div
                        style={{ color: "#59F5BC" }}
                        className="text-xl text-left capitalize"
                      >
                        All Classes
                      </div>
                      <div className="space-y-2">
                        {classes?.length
                          ? classes?.map((item) => (
                              <Accord key={item._id} title={item?.name}>
                                {item?.subjects
                                  .filter((s) =>
                                    user?.teacher?.subjects
                                      ?.map((s) => s._id)
                                      ?.includes(s._id)
                                  )
                                  .map((subject) => (
                                    <div
                                      onClick={() => {
                                        history.push(
                                          "/dashboard/instructor/" +
                                            item.name +
                                            "/" +
                                            item._id +
                                            "/" +
                                            subject._id
                                        );
                                        setDropRight(false);
                                      }}
                                      key={subject._id}
                                      className="flex flex-col text-left text-white font-light text-sm"
                                    >
                                      <div className="accord-content text-sm px-2 py-1 cursor-pointer hover:text-white">
                                        {subject?.name}
                                      </div>
                                    </div>
                                  ))}
                              </Accord>
                            ))
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FadeRight>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {dropRightStudent && (
            <FadeRight>
              <div
                className={`absolute z-50`}
                style={{
                  left: "100%",
                  top: "0",
                  height: "100%",
                }}
              >
                <div
                  style={{ backgroundColor: "#122025" }}
                  className="w-60 h-full flex"
                >
                  <div className="absolute right-3 top-3">
                    <IoClose
                      onClick={() => setDropRightStudent(false)}
                      className="cursor-pointer text-white "
                    />
                  </div>

                  <div className="flex flex-col space-y-3 w-full">
                    <div className="pt-10 p-3 space-y-4">
                      <div
                        style={{ color: "#59F5BC" }}
                        className="text-xl text-left capitalize"
                      >
                        All Subjects
                      </div>

                      <div className="space-y-2 w-full">
                        {subjects?.length
                          ? subjects?.map((item) => (
                              <div
                                onClick={() => {
                                  history.push(
                                    "/dashboard/student/" +
                                      item.name +
                                      "/" +
                                      item.class +
                                      "/" +
                                      item._id
                                  );
                                  setDropRightStudent(false);
                                }}
                                key={item._id}
                                className="flex flex-col text-left text-white font-light text-sm"
                              >
                                <div className="accord-content text-sm px-2 py-1 cursor-pointer hover:text-white w-full">
                                  {item?.name}
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FadeRight>
          )}
        </AnimatePresence>
        {/* --------------------------------------------------------------------------------------- */}
      </div>
    </>
  );
}

export default LeftSidebar;
