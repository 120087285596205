import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { serverUrl } from "../../../nestserver";
import ConductCertificate from "./component/ConductCertificate";
import StudyCertificateTemplate from "./component/StudyCertificate";
import TransferCertificateTemplate from "./component/TransferCertificate";

const DocTemplate = () => {
  const { id } = useParams();
  const location = useLocation();
  const schoolId = location.state?.additionalData;
  let form_type_Name;

  //fetch school
  async function fetchAdmin(schoolId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/school/${schoolId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getAdmin = useQuery(["school", schoolId], () => fetchAdmin(schoolId), {
    enabled: !!schoolId,
  });

  switch (id) {
    case "1":
      form_type_Name = "transfer_certificate";
      break;
    case "2":
      form_type_Name = "study_certificate";
      break;
    case "3":
      form_type_Name = "conduct_certificate";
      break;
    default:
      form_type_Name = null;
  }

  //fetch school template
  async function fetchAllSchoolTemplate(schoolId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/form-template`,
      params: {
        school: schoolId,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getAllSchoolTemplateDetails = useQuery(
    ["form-template"],
    () => fetchAllSchoolTemplate(schoolId),
    {
      enabled: !!schoolId,
    }
  );

  const transferCertificateId =
    getAllSchoolTemplateDetails &&
    getAllSchoolTemplateDetails?.data?.result?.data.find(
      (templateId) => templateId?.form_type === form_type_Name
    );

  async function fetchTemplate(id) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/form-template/${id}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getTemplateDetails = useQuery(
    ["form-template", transferCertificateId?._id],
    () => fetchTemplate(transferCertificateId?._id),
    {
      enabled: !!transferCertificateId?._id,
    }
  );

  return (
    <div className="tc-doc">
      {id === "1" && (
        <TransferCertificateTemplate
          getAdmin={getAdmin}
          getTemplateDetails={getTemplateDetails?.data?.result?.data}
          id={transferCertificateId?._id}
        />
      )}
      {id === "2" && (
        <StudyCertificateTemplate
          getAdmin={getAdmin}
          getTemplateDetails={getTemplateDetails?.data?.result?.data}
          id={transferCertificateId?._id}
        />
      )}
      {id === "3" && (
        <ConductCertificate
          getAdmin={getAdmin}
          getTemplateDetails={getTemplateDetails?.data?.result?.data}
          id={transferCertificateId?._id}
        />
      )}
    </div>
  );
};

export default DocTemplate;
