import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm, Select, Table, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import UploadFile from "../../../../../components/inputs/UploadFile";
import { serverUrl } from "../../../../../nestserver";

const ManageFormRequest = () => {
  const { Option } = Select;
  const queryClient = useQueryClient();
  const [classId, setClassId] = useState();
  const [status, setStatus] = useState();
  const [uploadPopup, setUploadPopup] = useState(false);
  const [formSave, setFormSave] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [formRequestId, setFormRequestId] = useState();

  const handleSelectChange = (classId) => {
    setClassId(classId);
  };
  const handleSelectStatusChange = (status) => {
    setStatus(status);
  };

  const handleDelete = () => {
    setIsDelete(true);
    setPdf(null);
    setFormSave(false);
  };

  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  //get form list
  async function getFormList(classId) {
    let url = serverUrl + "/form-requests/";
    if (classId) {
      url += `?class=${classId}`;
    }
    const res = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const formList = useQuery(["/form-requests/", { class: classId }], () =>
    getFormList(classId)
  );

  const columns = [
    {
      title: "Date Requested",
      dataIndex: [],
      key: "",
      align: "center",
      sorter: (a, b) => {
        const dateA = moment(a.createdAt).valueOf();
        const dateB = moment(b.createdAt).valueOf();
        return dateA - dateB;
      },
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center"
        >
          {record?.createdAt
            ? moment(record?.createdAt).format("DD-MM-YYYY")
            : "-"}
        </div>
      ),
    },
    {
      title: "Requesters",
      key: "name",
      align: "center",
      render: (record, index) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          <span>{`${record?.student?.student?.parent?.first_name} ${record?.student?.student?.parent?.last_name}`}</span>
        </div>
      ),
      onFilter: (value, record) =>
        (
          record?.first_name?.toLowerCase() +
          " " +
          record?.last_name?.toLowerCase()
        ).includes(value.toLowerCase()),
    },
    {
      title: "Student Details",
      dataIndex: [],
      key: "status",
      align: "center",
      render: (record, _, index) => {
        return (
          <div
            key={index}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            <p className="flex flex-col">
              <span>{`${record?.student?.student?.first_name} ${record?.student?.student?.last_name}`}</span>
              <span>{`${record?.student?.student?.unique_id}`}</span>
              <span>{`${record?.student?.student?.class?.name}`}</span>
            </p>
          </div>
        );
      },
    },
    {
      title: "Form Type",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        const originalString = `${record?.form_type}`;
        const newString = originalString.replace(/_/g, " ");
        return (
          <div
            key={record}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {newString ? newString : "--"}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "",
      align: "center",
      render: (text) => (
        <div
          key={text?._id}
          style={{ color: "#616161" }}
          className=" text-base capitalize"
        >
          {text?.status}
        </div>
      ),
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Approved", value: "approved" },
        { text: "Rejected", value: "rejected" },
        { text: "Resubmit", value: "resubmit" },
      ],
      onFilter: (value, record) =>
        record.status.toLowerCase() === value.toLowerCase(),
    },
    {
      title: "Action",
      dataIndex: [],
      key: "action",
      align: "center",
      render: (record, _, index) => (
        <Button
          className=" text-center capitalize cursor-pointer"
          onClick={() => {
            setUploadPopup(true);
            setFormRequestId(record?._id);
          }}
          disabled={record?.status === "approved"}
        >
          Upload
        </Button>
      ),
    },
  ];

  const handleStatus = () => {
    const dataToSend = {
      doc: pdf ? pdf : [],
      status: status ? status : null,
    };

    if (status) {
      axios
        .patch(
          serverUrl + "/form-requests/status/" + formRequestId,
          dataToSend,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
        .then((res) => {
          queryClient.invalidateQueries("/form-requests/");
          setStatus(null);
          setPdf(null);
          setUploadPopup(false);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        });
    }
  };
  return (
    <div>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Manage Form Request
          </div>
          <div className="flex gap-4 w-310 items-center">
            <h3 className="text-base font-semibold">Class</h3>
            <Select
              showSearch
              showArrow
              style={{ width: 200 }}
              placeholder="Select Class"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => handleSelectChange(value)}
              allowClear
            >
              {classes?.isSuccess &&
                classes?.data?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="py-5 table-overflow">
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={
                formList?.isSuccess &&
                formList?.data?.result?.data?.length > 0 &&
                formList?.data?.result?.data
                  ? formList?.data?.result?.data
                  : []
              }
              pagination={true}
              loading={formList?.isLoading}
            />
          </div>
          {uploadPopup && (
            <Modal
              width={450}
              visible={uploadPopup}
              onOk={() => setUploadPopup(false)}
              onCancel={() => {
                setUploadPopup(false);
                setPdf(null);
                setStatus(null);
              }}
              footer={null}
              forceRender
            >
              <div className="">
                <div className="flex gap-4 w-full items-center pt-8">
                  <h3 className="text-base font-semibold">Status</h3>
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select status"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(value) => handleSelectStatusChange(value)}
                    allowClear
                    className="w-full"
                  >
                    <Option value="approved">Approved</Option>
                    <Option value="rejected">Rejected</Option>
                    <Option value="resubmit">Resubmit</Option>
                  </Select>
                </div>
                {status === "approved" && (
                  <div className="">
                    <div className="">
                      <div className="flex justify-between pt-4 items-center">
                        <UploadFile
                          onUpload={(data) => setPdf(data)}
                          formStatus={formSave}
                          formSave={(formSave) => setFormSave(formSave)}
                          isDelete={isDelete}
                          setIsDelete={() => setIsDelete(false)}
                        >
                          <div className="w-full rounded flex items-center justify-center text-primary-500">
                            {pdf ? pdf?.files?.name : "Choose File"}
                          </div>
                        </UploadFile>
                        {pdf && (
                          <Popconfirm
                            title="Are you sure to delete?"
                            onConfirm={handleDelete}
                            okText="Yes"
                            cancelText="No"
                            className="flex-1"
                          >
                            <Button
                              type="danger flex items-center justify-center"
                              shape="circle"
                              icon={<DeleteOutlined />}
                            />
                          </Popconfirm>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="pt-8 text-center">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                    onClick={handleStatus}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default ManageFormRequest;
