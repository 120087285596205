import { Table } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import React, { useState } from "react";
import { BsEyeFill } from "react-icons/bs";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../../../nestserver";
import { selectUser } from "../../../../redux/slices/auth.slice";
import getStatusColor from "../../../../services/tagColor.service";
import AssignmentEvaluationView from "./AssignmentEvaluationView";

export default function StudentAssignmentsTable({ subjectId }) {
  const user = useSelector(selectUser);
  const history = useHistory();
  const [assignment, setAssignment] = useState(null);

  // fetch modules
  async function fetchAssignments() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/assignment/submissions",
      params: {
        subject: subjectId,
        published: true,
        assigned_date: { $lte: new Date().toISOString() },
        student: user?._id,
      },
      paramsSerializer: qs.stringify,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const assignments = useQuery(["assignments", subjectId], fetchAssignments);

  const columns = [
    {
      title: "Assignment Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div style={{ color: "#00627B" }} className="text-left text-sm">
          <div>{text}</div>
          <div className="text-xs font-light pt-2 flex flex-wrap">
            {record?.modules?.map((module) => (
              <div
                className="px-2 bg-gray-400 text-white rounded-full m-1"
                key={module?._id}
              >
                {module.title}
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "last_date",
      key: "last_date",
      render: (text) => (
        <div style={{ color: "#00627B" }} className=" text-base">
          {moment(text).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "Submitted Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div style={{ color: "#00627B" }} className=" text-base ">
          {record?.submissions[0]?.submitted_date
            ? moment(record?.submissions[0]?.submitted_date).format(
                "DD MMM YYYY"
              )
            : "Not Submitted"}
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div className=" text-sm font-semibold capitalize">
          <div
            className={
              record?.submissions[0]?.status
                ? getStatusColor(record?.submissions[0]?.status)
                : "text-gray-600"
            }
          >
            {record?.submissions[0]?.status ?? "Not Submitted"}
          </div>
        </div>
      ),
      filters: [
        {
          text: "Submitted",
          value: "submitted",
        },
        {
          text: "Evaluated",
          value: "evaluated",
        },
        {
          text: "Resubmit",
          value: "resubmit",
        },
        {
          text: "Not Submitted",
          value: "not submitted",
        },
      ],
      onFilter: (value, record) =>
        record?.submissions[0]?.status === value ||
        (!record?.submissions[0] && value === "not submitted"),
    },
    {
      title: "Total Score",
      dataIndex: "score",
      key: "score",
      render: (text, record) => (
        <div
          style={{ color: "#00627B" }}
          className=" text-base font-semibold capitalize "
        >
          {record.submissions[0]?.score ?? "_"} / {text}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div className="flex flex-row items-center  space-x-6 pr-4">
          <Button
            shape="circle"
            className="flex items-center justify-center"
            // onClick={() =>
            //   history.push("/dashboard/student/assignment/" + record._id)
            // }
            onClick={() => setAssignment(record?._id)}
          >
            <BsEyeFill className="cursor-pointer text-blue-500 " />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      {assignment ? (
        <AssignmentEvaluationView
          id={assignment}
          onBack={() => setAssignment(null)}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={
            assignments.isSuccess && (assignments.data ? assignments.data : [])
          }
          pagination={false}
        />
      )}
    </>
  );
}
