import { Button, Form, Input, message } from "antd";
import axios from "axios";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import comapnyName from "../../../../src/images/isKoolImage.png";
import comapnyLogo from "../../../../src/images/isKoolLogo.png";
import { serverUrl } from "../../../nestserver";
export default function ResetPassword() {
  const history = useHistory();
  const { token } = useParams();

  const onReset = (values) => {
    values.token = token;
    axios
      .post(serverUrl + "/password/reset", values)
      .then((res) => {
        message.success({ content: "Password Changed" });
        history.push("/auth/login");
      })
      .catch((error) => {
        message.error("Error Occured!");
      });
  };
  return (
    <div
      style={{
        backgroundImage: `url("/assets/login-bg-new.png")`,
      }}
    >
      <div
        style={{
          fontFamily: `'Nunito', sans-serif`,
        }}
        className="h-screen w-full flex flex-col items-center justify-center"
      >
        <div className="flex justify-center">
          <img src={comapnyLogo} alt="logo" width={130} />
        </div>
        <div className="mb-20px  ">
          <img src={comapnyName} alt="logo" width={150} />
        </div>
        <div className="md:w-1/3  p-6">
          <div className="text-center pb-6 text-xl text-typography">
            Send Reset Link
          </div>

          <Form onFinish={onReset}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
                {
                  min: 6,
                  message: "Password must be at least 6 characters long!",
                },
              ]}
            >
              <Input
                type="password"
                placeholder="Enter new password"
                className="rounded-full"
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input
                type="password"
                placeholder="Confirm password"
                className="rounded-full"
              />
            </Form.Item>
            <div className="flex justify-between">
              <Button
                type=""
                onClick={() => history.push("/auth/login")}
                className="botton-color rounded-full"
              >
                Go Home
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="color rounded-full"
              >
                Update Password
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
