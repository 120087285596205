import { Button, Form, Input, Select, Tag, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { serverUrl } from "../../nestserver";
import { selectUser } from "../../redux/slices/auth.slice";

function tagRender(props) {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
      className="flex items-center"
    >
      {label}
    </Tag>
  );
}
export default function CreateExamForm({ onCancel, subject_id, class_id }) {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useSelector(selectUser);

  const { Option } = Select;
  const onFinish = (values) => {
    axios({
      method: "post",
      url: serverUrl + "/exam/",
      data: {
        ...values,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Exam Created");
        queryClient.invalidateQueries("exams");
        form.resetFields();
        onCancel(false);
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const [classId, setClassId] = useState(null);
  const [subjectId, setSubjectId] = useState(null);

  // fetch Class
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/teachers/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  // fetch subjects
  async function fetchSubjects() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/subjects/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const subjects = useQuery("subjects", fetchSubjects);

  // fetch modules
  async function fetchModules() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/modules/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const modules = useQuery("modules", fetchModules);

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    form.resetFields();
    if (subject_id) {
      setSubjectId(subject_id);
    }
    if (class_id) {
      setClassId(class_id);
    }
  }, [subject_id, class_id]);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          class: class_id,
          subject: subject_id,
        }}
      >
        <div>
          <Form.Item
            label="Exam Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input  Exam Name !",
              },
            ]}
          >
            <Input
              maxLength={130}
              style={{
                backgroundColor: "#FFFFFF",
              }}
              className="w-full py-2"
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            label="Select Class"
            name="class"
            rules={[
              {
                required: true,
                message: "Please input Select Class!",
              },
            ]}
          >
            <Select
              showSearch
              showArrow
              disabled
              placeholder="Select Class"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              className="w-full bg-gray-300"
              style={{
                border: "6px",
                backgroundColor: "#EBEBEB",
              }}
              onChange={(e) => setClassId(e)}
            >
              {classes.isSuccess &&
                classes?.data?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </div>

        {classId && (
          <>
            <Form.Item label="Subject" name="subject">
              <Select
                showSearch
                showArrow
                disabled
                tagRender={tagRender}
                placeholder="Select Subject"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{
                  border: "6px",
                  backgroundColor: "#EBEBEB",
                }}
                className="w-3/4"
                onChange={(e) => setSubjectId(e)}
              >
                {subjects.isSuccess &&
                  subjects?.data
                    ?.filter((s) => s.class?._id === classId)
                    ?.filter((s) =>
                      user?.teacher?.subjects?.map((s) => s._id).includes(s._id)
                    )
                    ?.map((data) => {
                      return (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      );
                    })}
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item
          label="Select Modules"
          name="modules"
          rules={[
            {
              required: true,
              message: "Please Select atleast one Module",
            },
          ]}
        >
          <Select
            mode="multiple"
            showArrow
            tagRender={tagRender}
            placeholder="Select modules"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{
              border: "6px",
              backgroundColor: "#EBEBEB",
            }}
            className="w-3/4"
          >
            {modules.isSuccess &&
              modules?.data
                ?.filter((s) => s.subject === subjectId)
                ?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data.title}
                    </Option>
                  );
                })}
          </Select>
        </Form.Item>

        <Form.Item className="mb-0">
          <div className="flex items-center  pt-8">
            <div className="inline-block ml-auto space-x-3">
              <Button
                type="dark"
                size="large"
                onClick={() => {
                  onCancel();
                  form.resetFields();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" size="large" htmlType="submit">
                Save
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
