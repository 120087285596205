import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import ImagePost from "../../../../../../components/inputs/imagePost";
import { cloudFrontUrl, serverUrl } from "../../../../../../nestserver";
import fupload from "../../../../../../resources/feather-upload.svg";
import axios from "axios";
import { useQueryClient } from "react-query";

function UploadImage({ user, data }) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [image, setImage] = useState(null);

  const onFinish = (values) => {
    values.subject = data._id;
    values.user = user._id;
    if (image !== null) {
      values.image = image;
    }
    axios({
      method: "post",
      url: serverUrl + "/discussion",
      data: { ...values, details: values?.details?.trim() },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Created the post");
        setImage(null);
        form.resetFields();
        queryClient.invalidateQueries("discussions");
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  return (
    <>
      <div>
        <div className="bg-white rounded-2xl w-full">
          <div className="p-8 flex flex-col justify-start w-full">
            <div className="flex  space-x-4">
              <div className="w-16">
                <img
                  className="w-16 h-16 rounded-full"
                  src={
                    user?.[user?.role === "instructor" ? "teacher" : user?.role]
                      ?.profile_image
                      ? cloudFrontUrl +
                        "/" +
                        user?._id +
                        "/" +
                        user[
                          user?.role === "instructor" ? "teacher" : user?.role
                        ]?.profile_image
                      : "https://ui-avatars.com/api/?name=" +
                        user[
                          user?.role === "instructor" ? "teacher" : user?.role
                        ]?.first_name +
                        " " +
                        user[
                          user?.role === "instructor" ? "teacher" : user?.role
                        ]?.last_name
                  }
                  alt=""
                />
              </div>
              <div className=" items-start justify-center w-0 flex-grow">
                <Form
                  name="basic"
                  form={form}
                  onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    name="details"
                    rules={[
                      {
                        required: true,
                        message: "Please input here!",
                      },
                    ]}
                  >
                    <textarea
                      className="appearance-none border rounded w-full py-4 px-3 leading-tight focus:outline-none focus:shadow-outline resize-none"
                      id="username"
                      type="text"
                      // placeholder={placeholder}
                    />
                  </Form.Item>
                  <div className="flex items-center justify-between pt-5 ">
                    <div>
                      <div
                        // type="file"
                        className=" w-40 border rounded-md py-2 upload-box px-4 cursor-pointer"
                      >
                        <ImagePost
                          folder={user?._id}
                          onUpload={(e) => setImage(e)}
                        >
                          <div
                            style={{ color: "#616161" }}
                            className="flex cursor-pointer space-x-3"
                          >
                            <img className="h-4" src={fupload} alt="" />
                            <div className="text-md">Upload Image</div>
                          </div>
                        </ImagePost>
                      </div>

                      {image !== null && <div>{image}</div>}
                    </div>

                    <Form.Item className="mb-0">
                      <div className="flex items-center ">
                        <div className="inline-block ml-auto space-x-3">
                          <Button type="primary" size="large" htmlType="submit">
                            Post
                          </Button>
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadImage;
