import React, { useState } from "react";
import { Form, Input, message, Select } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import { serverUrl } from "../../nestserver";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "react-query";
import { useCountries, useGetCity } from "../../services/location.service";
import { useEffect } from "react";

const { Option } = Select;
export default function EditProfile({ data, closeModel }) {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const onFinish = (values) => {
    if (data != null) {
      axios({
        method: "patch",
        url: serverUrl + "/teachers/" + data._id,
        data: {
          ...values,
          social_links: [
            {
              name: "facebook",
              link: values.facebook,
            },
            {
              name: "twitter",
              link: values.twitter,
            },
            {
              name: "github",
              link: values.github,
            },
            {
              name: "instagram",
              link: values.instagram,
            },
          ],
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
        .then((res) => {
          message.success("Profile Updated");
          queryClient.invalidateQueries("user");
          closeModel();
        })
        .catch((e) => {
          message.error(e.message);
        });
    } else {
      axios({
        method: "post",
        url: serverUrl + "/teachers/",
        data: values,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
        .then((res) => {
          message.success("Instructor Updated");
          queryClient.invalidateQueries("user");
          closeModel && closeModel();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const countries = useCountries();

  const [selectedCountry, setSelectedCountry] = useState(null);

  const cities = useGetCity(
    countries?.data?.find((c) => c.name === selectedCountry)?.iso2,
    {
      enabled: !!selectedCountry && !!countries?.data,
    }
  );

  useEffect(() => {
    if (data?.country) {
      setSelectedCountry(data?.country);
      queryClient?.invalidateQueries("cities");
    }
  }, [data?.country]);

  return (
    <div>
      <div>
        <Form
          layout="vertical"
          name="basic"
          initialValues={{
            ...data,
            facebook: data?.social_links.find((s) => s.name === "facebook")
              ?.link,
            instagram: data?.social_links.find((s) => s.name === "instagram")
              ?.link,
            twitter: data?.social_links.find((s) => s.name === "twitter")?.link,
            github: data?.social_links.find((s) => s.name === "github")?.link,
          }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <div className="text-2xl font-bold text-black px-5">Edit intro </div>
          <div className=" flex flex-col  px-5 py-6">
            <div className="grid grid-cols-2 gap-6 ">
              <Form.Item
                style={{ marginBottom: "10px" }}
                label="First name "
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your First name!",
                  },
                  {
                    message:
                      "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                    validator: (_, value) => {
                      if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                        );
                      }
                    },
                  },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>

              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Last name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Last name!",
                  },
                  {
                    message:
                      "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                    validator: (_, value) => {
                      if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                        );
                      }
                    },
                  },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Headline"
                name="headline"
                rules={[
                  {
                    required: true,
                    message: "Please describe yourself",
                  },
                  {
                    max: 255, // Set the maximum number of characters allowed
                    message: "Maximum 255 characters allowed",
                  },
                ]}
              >
                <TextArea rows={2} className="w-full py-1 resize-none" />
              </Form.Item>
            </div>

            <div>
              <div className="pb-1">Education</div>
              <Form.List
                style={{ marginBottom: "10px", width: "100%" }}
                label="Education"
                name="educations"
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <div className="flex flex-1 w-full">
                        <div className="flex items-center space-x-2 w-full">
                          <Form.Item
                            {...restField}
                            name={[name, "title"]}
                            fieldKey={[fieldKey, "title"]}
                            className="w-0 flex-grow"
                            rules={[
                              {
                                required: true,
                                message: "Missing Heading",
                              },
                            ]}
                            //   label="Title"
                          >
                            <Input className="w-full" placeholder="Title" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "year"]}
                            fieldKey={[fieldKey, "year"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Year",
                              },
                              {
                                pattern: /^[1-9]\d*$/,
                                message: "Invalid Year!",
                              },
                            ]}
                            className="w-0 flex-grow"
                            //   label="Year"
                          >
                            <Input className="w-full" placeholder="Year" />
                          </Form.Item>
                        </div>
                        <div className="w-10 items-start mt-8px flex justify-center">
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      </div>
                    ))}
                    <Form.Item>
                      <div
                        style={{ color: "#3688A3" }}
                        className="flex justify-end"
                      >
                        <div
                          className="text-xs font-semibold pt-2 cursor-pointer"
                          onClick={() => add()}
                        >
                          Add Education +
                        </div>
                      </div>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-6 ">
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Country/Region "
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Country/Region !",
                    },
                  ]}
                >
                  <Select
                    className="w-full"
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setSelectedCountry(value);
                    }}
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {countries?.data?.map((country) => (
                      <Option key={country?.name} value={country?.name}>
                        {country?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {selectedCountry && (
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="City/Town"
                    name="region"
                    rules={[
                      {
                        required: true,
                        message: "Please input your City/Town!",
                      },
                    ]}
                  >
                    <Select
                      className="w-full"
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          .toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {cities?.data?.map((city) => (
                        <Option key={city?.id} value={city?.name}>
                          {city?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </div>
            </div>

            <div className="text-2xl font-bold text-black pt-5 ">
              Edit social links
            </div>

            <div className="pt-5">
              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Facebook"
                name="facebook"
              >
                <Input className="w-full" />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Twitter"
                name="twitter"
              >
                <Input className="w-full" />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Github"
                name="github"
              >
                <Input className="w-full" />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Instagram"
                name="instagram"
              >
                <Input className="w-full" />
              </Form.Item>
            </div>
            <div>
              <Form.Item className="mb-0">
                <div className="flex items-center pt-3">
                  <div className="inline-flex ml-auto space-x-2">
                    <Button
                      key="back"
                      type="dark"
                      size="large"
                      onClick={closeModel}
                    >
                      Cancel
                    </Button>
                    <Button
                      key="submit"
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="text-white"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
