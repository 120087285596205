import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Select, Space, Table } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";

const StudentParentList = () => {
  const { Option } = Select;
  const history = useHistory();
  const [classId, setClassId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const handleSelectChange = (id) => {
    setClassId(id);
  };

  // fetch parents
  const fetchParentStudentList = async () => {
    const res = await axios.get(
      serverUrl + "/students/class/" + classId + "/parent/",
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }
    );

    return res.data;
  };
  const parentStudentList = useQuery(
    ["class", classId],
    fetchParentStudentList,
    {
      enabled: !!classId,
    }
  );

  // fetch subjects
  async function fetchSubjects() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/subjects/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const subjects = useQuery("subjects", fetchSubjects);

  // fetch classes
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  // Table Settings

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [searchInput, setSearchInput] = useState(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Student Id",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.unique_id}
        </div>
      ),
    },
    {
      title: "Name",
      key: "",
      align: "center",
      ...getColumnSearchProps(["first_name", "last_name"]),
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {`${record?.first_name} ${record?.last_name}`}
        </div>
      ),
      onFilter: (value, record) =>
        (
          record?.first_name?.toLowerCase() +
          " " +
          record?.last_name?.toLowerCase()
        ).includes(value.toLowerCase()),
    },
    {
      title: "Class",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.class?.name}
        </div>
      ),
    },
    {
      title: "Parent",
      dataIndex: [],
      ...getColumnSearchProps(["first_name", "last_name"]),
      render: (record) => (
        <div style={{ color: "#616161" }} className=" text-base capitalize">
          {record?.parent
            ? `${record?.parent?.first_name} ${record?.parent?.last_name}`
            : "-"}
        </div>
      ),
      onFilter: (value, record) =>
        (
          record?.parent?.first_name?.toLowerCase() +
          " " +
          record?.parent?.last_name?.toLowerCase()
        ).includes(value.toLowerCase()),
    },
    {
      title: "Sibling",
      dataIndex: [],
      key: "",
      render: (record) => (
        <div
          style={{ color: "#616161" }}
          className=" text-base cursor-pointer"
          onClick={() => {
            if (record?.existing_siblings?.length > 0) {
              setSelectedRecord(record?.existing_siblings);
              setIsModalVisible(true);
            }
          }}
        >
          {record?.existing_siblings?.length}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          className=" text-base font-semibold text-center text-blue-500 cursor-pointer"
          onClick={() =>
            history.push(`/dashboard/admin/menu/parent/${record?._id}`)
          }
        >
          View
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Manage Parent
          </div>
          <div className="flex gap-10px">
            <h6 className=" font-semibold text-base">Class: &nbsp;</h6>
            <Select
              showSearch
              showArrow
              placeholder="Select Class"
              className="w-1/2 bg-gray-300"
              style={{
                border: "6px",
                backgroundColor: "#EBEBEB",
                width: "24%",
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => handleSelectChange(value)}
            >
              {classes.isSuccess &&
                classes?.data?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="flex items-center justify-between"></div>
          <div className="py-5 table-overflow ">
            <Table
              columns={columns}
              dataSource={
                parentStudentList?.isSuccess &&
                parentStudentList?.data?.result?.length > 0
                  ? parentStudentList?.data?.result
                  : []
              }
              pagination={true}
              loading={subjects.isLoading}
            />
          </div>
        </div>
        <Modal
          title="Siblings"
          visible={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        >
          <ul>
            {selectedRecord?.map((sibling, index) => (
              <li key={sibling?.id} cla>
                {index + 1}.{""}
                {`${sibling?.name}`}
              </li>
            ))}
          </ul>
        </Modal>
      </div>
    </>
  );
};

export default StudentParentList;
