import React, { useState } from "react";

import SubjectModule from "./SubjectModule";
import SubjectProfile from "./SubjectProfile";
import SubjectSetting from "./SubjectSetting";
import SubjectSyllabus from "./SubjectSyllabus";

import axios from "axios";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { serverUrl } from "../../../../../nestserver";

function SwitchSubject() {
  const [step, setStep] = useState(1);
  const { id } = useParams();

  async function getSubject() {
    const data = await axios({
      method: "get",
      url: serverUrl + "/subjects/" + id,
      data: {},
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return data.data;
  }

  const subject = useQuery(["subject", id], getSubject);

  function switchTab() {
    switch (step) {
      case 1:
        return (
          <div>
            <SubjectProfile subjectDetails={subject?.data} />
          </div>
        );

      case 2:
        return (
          <div>
            <SubjectSyllabus subjectDetails={subject?.data} />
          </div>
        );

      case 3:
        return (
          <div>
            <SubjectModule subjectDetails={subject?.data} />
          </div>
        );

      // case 4:
      //   return (
      //     <div>
      //       <SubjectDiscussion subjectDetails={subject?.data} />
      //     </div>
      //   );

      case 5:
        return (
          <div>
            <SubjectSetting subjectDetails={subject?.data} />
          </div>
        );

      default:
        return (
          <div>
            <SubjectProfile subjectDetails={subject?.data} />
          </div>
        );
    }
  }

  return (
    <>
      {/* {subject.isSuccess ? ( */}
      <div className="p-8">
        <div className="grid grid-cols-4 bg-white rounded-lg ">
          <div
            onClick={() => setStep(1)}
            className={`iskool-tab-item ${
              step === 1 ? "bg-gray-600 text-white shadow text-left " : ""
            }`}
          >
            <div className="text-left">
              <div className=" text-left">{subject?.data?.name}</div>
              <div className="text-xs text-left">Overview</div>
            </div>
          </div>
          <div
            onClick={() => setStep(2)}
            className={`iskool-tab-item ${
              step === 2 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Syllabus</div>
          </div>

          <div
            onClick={() => setStep(3)}
            className={`iskool-tab-item ${
              step === 3 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Module</div>
          </div>
          {/* <div
            onClick={() => setStep(4)}
            className={`iskool-tab-item ${
              step === 4 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Discussions</div>
          </div> */}
          <div
            onClick={() => setStep(5)}
            className={`iskool-tab-item ${
              step === 5 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Settings</div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------- */}
        <div>
          <div>{switchTab()}</div>
        </div>
      </div>
      {/* ) : (
        ""
      )} */}
    </>
  );
}

export default SwitchSubject;
