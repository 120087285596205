import React from "react";
import { useSelector } from "react-redux";
import DiscussionCard from "../../../../../components/cards/DiscussionCard";
import { selectUser } from "../../../../../redux/slices/auth.slice";
import { useDiscussionsBySubject } from "../../../../../services/discussion.service";

function SubjectProfile({ subjectDetails }) {
  const user = useSelector(selectUser);
  const discussions = useDiscussionsBySubject(subjectDetails?._id, {
    enabled: !!subjectDetails?._id,
  });

  return (
    <>
      <div>
        <div className="pt-8">
          <div className="bg-white w-full rounded-2xl ">
            <div className="flex flex-col items-start justify-start p-6  text-left space-y-4 ">
              <div
                style={{ color: "#E46E31" }}
                className="text-2xl font-semibold"
              >
                About the Course
              </div>
              <div className="flex flex-row space-x-10">
                <div
                  style={{ color: "#074559" }}
                  className="text-xl font-semibold"
                >
                  Class : {subjectDetails?.class?.name}
                </div>
                <div
                  style={{ color: "#074559" }}
                  className="text-xl font-semibold"
                >
                  Instructor : {subjectDetails?.teacher?.teacher?.first_name}{" "}
                  {subjectDetails?.teacher?.teacher?.last_name}
                </div>
              </div>
              <div
                style={{ color: "#616161" }}
                className="text-base font-semibold"
              >
                {subjectDetails?.about}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8">
          <div className="bg-white w-full rounded-2xl">
            <div className="p-8 ">
              <div
                style={{ color: "#E46E31" }}
                className="text-2xl font-semibold text-left"
              >
                Credit Score
              </div>

              {subjectDetails?.credits?.length ? (
                <div className="md:w-1/2 space-y-3 pt-4">
                  {subjectDetails?.credits?.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-center space-x-4 text-lg font-medium text-gray-900"
                    >
                      <div>{item?.title}</div>
                      <div>{item?.score}</div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="py-8">
          <div className="bg-white w-full rounded-2xl bg-opacity-40 px-4 pb-4">
            <div
              style={{ color: "#E46E31" }}
              className=" text-orange-500 text-2xl font-semibold p-4 py-6"
            >
              Discussions
            </div>
            <div className="space-y-5 ">
              {discussions?.isSuccess &&
                discussions?.data?.map((item) => {
                  return (
                    <DiscussionCard
                      enableDelete={true}
                      enableComment={false}
                      enableDeleteComment={true}
                      key={item?._id}
                      data={item}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubjectProfile;
