import { SearchOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from "antd";
import Button from "antd-button-color";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import React, { useMemo, useState } from "react";
import { BiCheckCircle, BiCircle, BiXCircle } from "react-icons/bi";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { serverUrl } from "../../../../../nestserver";
import { selectUser } from "../../../../../redux/slices/auth.slice";
/**
 *
 * @param {object} props
 * @param {object} props.data subject details
 * @param {object} props.students Students opted for the subject
 * @returns
 */
export default function AttendanceHistory({ data, students, admin = false }) {
  const { RangePicker } = DatePicker;

  const [attendances, setAttendances] = useState(null);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const user = useSelector(selectUser);

  const attendanceMap = useMemo(() => {
    return (attendances || []).reduce((map, attendance) => {
      const stid = attendance.student;
      const date = moment(attendance.date).format("DD-MM-YYYY");

      if (map[stid] === undefined) {
        map[stid] = {};
      }
      if (map[stid][date] === undefined) {
        map[stid][date] = {};
      }
      map[stid][date]["attended"] = attendance.attended;
      map[stid][date]["id"] = attendance._id;
      return map;
    }, {});
  }, [attendances]);

  const onFinish = (values) => {
    axios({
      method: "get",
      url: serverUrl + "/attendance/",
      params: {
        subject: data?._id,
        date: {
          from: values.date[0].toISOString(),
          to: values.date[1].toISOString(),
        },
      },
      paramsSerializer: qs.stringify,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((res) => {
      setDateRange({
        from: values.date[0],
        to: values.date[1],
      });
      setAttendances(res.data);
    });
  };

  // Create Attendance

  function createAttendance(studentId, value, subjectId, date) {
    axios({
      method: "post",
      url: serverUrl + "/attendance/",
      data: {
        subject: subjectId,
        student: studentId,
        date: date,
        attended: value,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((res) => {
      queryClient.invalidateQueries(["attendances"]);
      form.submit();
    });
  }

  // Update Attendance
  function updateAttendance(attendanceId, value) {
    axios({
      method: "patch",
      url: serverUrl + "/attendance/" + attendanceId,
      data: {
        attended: value,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((res) => {
      queryClient.invalidateQueries(["attendances"]);
      form.submit();
    });
  }

  // filter
  function strcmp(a, b) {
    if (a === b) {
      return 0;
    }

    if (a > b) {
      return 1;
    }

    return -1;
  }

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [searchInput, setSearchInput] = useState(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? // <Highlighter
          //   highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          //   searchWords={[searchText]}
          //   autoEscape
          //   textToHighlight={text ? text.toString() : ''}
          // />
          text
        : text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = useMemo(() => {
    return [
      {
        title: "First Name",
        dataIndex: "first_name",
        width: 150,
        fixed: "left",
        sorter: {
          compare: (a, b) => strcmp(a.first_name, b.first_name),
          multiple: 3,
        },
        ...getColumnSearchProps("first_name"),
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        width: 150,
        fixed: "left",
        sorter: {
          compare: (a, b) => strcmp(a.last_name, b.last_name),
          multiple: 3,
        },
        ...getColumnSearchProps("last_name"),
      },
      {
        title: "Percentage",
        width: 150,
        fixed: "left",
        render: (text, record) => {
          const attendedCount = record?.attendance?.filter(
            (a) => a.attended === true
          ).length;
          const totalSessions = record?.attendance?.length;

          if (totalSessions && attendedCount) {
            const attendancePercentage = (
              (attendedCount * 100) /
              totalSessions
            ).toFixed(2);
            return <div>{attendancePercentage}%</div>;
          } else {
            return "N/A";
          }
        },
      },
      ...Array.from({
        length: moment(dateRange.to).diff(moment(dateRange.from), "day") + 1,
      }).map((_, index) => {
        const date = moment(dateRange.from)
          .add(index, "days")
          .format("DD-MM-YYYY");

        return {
          title: date,
          dataIndex: "_id",
          align: "center",
          width: 200,
          render: (id, record) => {
            const attendanceId = attendanceMap[record._id]?.[date]?.id;
            const attendanceValue = attendanceMap[record._id]?.[date]?.attended;
            const isAttendanceNull =
            attendanceMap[record._id]?.[date]?.attended === undefined;

            return (
              <div>
                {isAttendanceNull ? (
                  <div className="flex items-center justify-center cursor-pointer">
                    <Tooltip title="Click to toggle attendance">
                      <Popconfirm
                        disabled={user?.role === "admin"}
                        title="Are you sure to update attendance?"
                        onConfirm={() =>
                          createAttendance(
                            record?._id,
                            true,
                            data?._id,
                            moment(date, "DD-MM-YYYY")
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                        placement="bottom"
                      >
                        <BiCircle className="text-gray-600 " size={20} />
                      </Popconfirm>
                    </Tooltip>
                  </div>
                ) : attendanceValue ? (
                  <div className="flex items-center justify-center cursor-pointer">
                    <Popconfirm
                      disabled={user?.role === "admin"}
                      title="Are you sure to mark as leave?"
                      onConfirm={() => updateAttendance(attendanceId, false)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <BiCheckCircle className="text-green-500" size={20} />
                    </Popconfirm>
                  </div>
                ) : (
                  <div className="flex items-center justify-center cursor-pointer">
                    <Popconfirm
                      disabled={user?.role === "admin"}
                      title="Are you sure to mark as attended?"
                      onConfirm={() => updateAttendance(attendanceId, true)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <BiXCircle className="text-red-500 " size={20} />
                    </Popconfirm>
                  </div>
                )}
              </div>
            );
          },
        };
      }),
    ];
  }, [dateRange, attendanceMap]);

  const onFinishFailed = (errorInfo) => {};

  return (
    <div className=" bg-white">
      {data?.name ? (
        <div className="pt-2 text-lg font-bold text-primary-500">
          {data?.name}
        </div>
      ) : (
        ""
      )}
      <div className="py-3 text-lg ">Choose a Range</div>
      <Form
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <div className="flex items-center justify-between">
          <Form.Item name="date">
            <RangePicker
              format={"DD/MM/YYYY"}
              disabledDate={(d) =>
                !d ||
                d.isAfter(moment().endOf("day")) ||
                d.isSameOrBefore(moment().subtract(admin ? 360 : 7, "days"))
              }
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Apply
            </Button>
          </Form.Item>
        </div>
      </Form>

      <div>
        <Table
          pagination={{ pageSize: 20, position: ["bottomCenter"] }}
          scroll={{ y: 350, x: 100 }}
          className="rounded-2xl"
          columns={columns}
          dataSource={Object.keys(attendanceMap).length ? students : []}
          rowKey={(record) => record._id}
        />
      </div>
    </div>
  );
}
