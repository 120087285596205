import { Input } from "antd";
import axios from "axios";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import NotificationListCard from "../../../components/cards/NotificationListCard";
import { serverUrl } from "../../../nestserver";
import { selectUser } from "../../../redux/slices/auth.slice";

const { Search } = Input;

function NotificationList({ data }) {
  const user = useSelector(selectUser);

  async function getFriends() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/",
      params: {
        role: "instructor",
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  async function getSubjectStudents() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/",
      params: {
        role: "student",
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const friends = useQuery("friends", getFriends);

  const subjectStudents = useQuery("subjectStudents", getSubjectStudents);

  const [search, setSearch] = useState(null);

  const filteredFriends = useMemo(() => {
    let filtered = friends.data;

    if (search) {
      filtered = filtered.filter(
        (f) =>
          f.teacher.first_name.toLowerCase().includes(search) ||
          f.teacher.last_name.toLowerCase().includes(search)
      );
    }
    return filtered;
  }, [friends.data, search]);

  return (
    <>
      <div className="p-4 py-8">
        <div className="bg-white rounded-xl shadow-lg p-5 py-8">
          <div className="text-xl text-orange-500 text-medium">
            Notifications
          </div>
          <div className="py-6">
            <NotificationListCard data={friends} className="py-4" />
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationList;
