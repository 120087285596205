import { Button, Form, Input, message, Modal, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";
import plusicon from "../../../../../resources/plus.svg";

const AddEditUser = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [addTemplate, setAddTemplate] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [timer, setTimer] = useState(0);

  const handleVerifyPhone = async () => {
    const phone = form.getFieldValue("phone");
    if (!phone) {
      message.error("Please enter your phone number first");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        serverUrl + "/onelap-users/token",
        { phone },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );
      console.log("sddsddrespons", response);
      if (response.status === 200) {
        message.success("OTP sent to your phone");
        setIsVerified(true);
      }
    } catch (error) {
      message.error("Failed to send OTP");
    } finally {
      setLoading(false);
    }
  };
  const handleResendOtp = async () => {
    const phone = form.getFieldValue("phone");
    if (!phone) {
      message.error("Please enter your phone number first");
      return;
    }

    try {
      setResendLoading(true);
      const response = await axios.post(
        serverUrl + "/onelap-users/token",
        {
          phone,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );
      if (response.status === 200) {
        message.success("OTP resent to your phone");
        setTimer(30);
      }
    } catch (error) {
      message.error("Failed to resend OTP");
    } finally {
      setResendLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    console.log("sddsdsdds", values);
    const { phone, otp, name, password } = values;
    try {
      const response = await axios.post(
        serverUrl + "/onelap-users/verify-phone",
        {
          phone,
          otp,
          name,
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );
      if (response.status === 200) {
        message.success("Phone number verified successfully");
        form.resetFields();
        setIsVerified(false);
        setAddTemplate(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.error?.errors);
    }
  };
  async function fetchOneLapUser() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/onelap-users`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const oneLapUser = useQuery(["onelap-user"], () => fetchOneLapUser());

  const columns = [
    {
      title: "Phone Number",

      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.phone}
        </div>
      ),
    },
    {
      title: "Registered",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.status === "registered" ? "Yes" : "No"}
        </div>
      ),
    },
  ];
  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
    }
    return () => clearInterval(countdown);
  }, [timer]);
  return (
    <div className="mt-8">
      <div
        style={{ borderRadius: "10px", color: "#616161" }}
        className=" bg-white drop-shadow-md p-8 w-full "
      >
        <div className="flex justify-between items-center pb-4">
          <div className="text-2xl text-orange-500 font-semibold">
            Register User
          </div>
          <div
            onClick={() => setAddTemplate(true)}
            className="cursor-pointer p-2 add-module flex items-center justify-center mt-2"
          >
            <div className="flex flex-col items-center space-y-3">
              {/* <div className="flex items-center justify-center cursor-pointer">
                <img className="w-6 h-6" src={plusicon} alt="icon" />
              </div> */}
              <div className="text-white text-sm ">
                <div>Add new</div>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={
            oneLapUser?.isSuccess ? oneLapUser?.data?.result?.data : []
          }
          pagination={true}
          loading={oneLapUser?.isLoading}
        />
        <Modal
          width={400}
          visible={addTemplate}
          onOk={() => setAddTemplate(false)}
          onCancel={() => {
            form.resetFields();
            setAddTemplate(false);
            setIsVerified(false);
            form.setFieldsValue({ total: "" });
            // setFeeComponents();
          }}
          footer={null}
          forceRender
        >
          <div>
            <h3 className="text-lg font-bold pb-3"> Register User</h3>
            <div className="pt-4 w-4/5">
              <Form
                form={form}
                name="basic"
                onFinish={handleSubmit}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Phone number is required",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Phone number must be 10 digits",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter phone number"
                    disabled={isVerified}
                    suffix={
                      <Button
                        type="link"
                        onClick={handleVerifyPhone}
                        loading={loading}
                        disabled={isVerified}
                      >
                        Send Otp
                      </Button>
                    }
                  />
                </Form.Item>

                {isVerified && (
                  <>
                    <Form.Item
                      label="OTP"
                      name="otp"
                      rules={[
                        {
                          required: true,
                          message: "OTP is required",
                        },
                        {
                          len: 4,
                          message: "OTP must be 4 digits",
                        },
                      ]}
                    >
                      <Input placeholder="Enter OTP" />
                    </Form.Item>

                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Name is required",
                        },
                      ]}
                    >
                      <Input placeholder="Enter name" />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Password is required",
                        },
                        {
                          min: 6,
                          message: "Password must be at least 6 characters",
                        },
                      ]}
                    >
                      <Input.Password placeholder="Enter password" />
                    </Form.Item>
                    
                  <div className="flex justify-end items-center mb-4">
                    <Button
                      type="link"
                      onClick={handleResendOtp}
                      loading={resendLoading}
                      disabled={timer > 0}
                    >
                      Resend OTP {timer > 0 ? `(${timer}s)` : ""}
                    </Button>
                  </div>
                  </>
                )}
                <div className="flex justify-center items-center py-5">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                    disabled={!isVerified}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default AddEditUser;
