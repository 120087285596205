import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Form, Input, Space, Table } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import React, { useMemo, useState } from "react";
import { BiCheckCircle, BiCircle, BiXCircle } from "react-icons/bi";
import { serverUrl } from "../../../../../nestserver";
/**
 *
 * @param {object} props
 * @param {object} props.data Student details

 * @returns
 */
export default function StudentAttendanceHistory({
  data,
  subjects,
  admin = false,
}) {
  const { RangePicker } = DatePicker;

  const [attendances, setAttendances] = useState(null);
  const [dateRange, setDateRange] = useState({ from: null, to: null });

  const attendanceMap = useMemo(() => {
    return (attendances || []).reduce((map, attendance) => {
      const stid = attendance.subject;
      const date = moment(attendance.date).format("DD-MM-YYYY");

      if (map[stid] === undefined) {
        map[stid] = {};
      }
      map[stid][date] = attendance.attended;

      return map;
    }, {});
  }, [attendances]);

  const onFinish = (values) => {
    axios({
      method: "get",
      url: serverUrl + "/attendance/",
      params: {
        student: data?._id,
        date: {
          from: values.date[0].toISOString(),
          to: values.date[1].toISOString(),
        },
      },
      paramsSerializer: qs.stringify,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((res) => {
      setDateRange({
        from: values.date[0],
        to: values.date[1],
      });
      setAttendances(res.data);
    });
  };

  // filter
  function strcmp(a, b) {
    if (a === b) {
      return 0;
    }

    if (a > b) {
      return 1;
    }

    return -1;
  }

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [searchInput, setSearchInput] = useState(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? // <Highlighter
          //   highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          //   searchWords={[searchText]}
          //   autoEscape
          //   textToHighlight={text ? text.toString() : ''}
          // />
          text
        : text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = useMemo(() => {
    return [
      {
        title: "Subject",
        dataIndex: "name",
        width: 200,
        fixed: "left",
        sorter: {
          compare: (a, b) => strcmp(a.name, b.name),
          multiple: 3,
        },
        ...getColumnSearchProps("name"),
      },
      {
        title: "Percentage",
        width: 200,
        fixed: "left",
        render: (text, record) => {
          Object.filter = (obj, predicate) =>
            Object.keys(obj)
              .filter((key) => predicate(obj[key]))
              .reduce((res, key) => ((res[key] = obj[key]), res), {});
          return (
            <div>
              {attendanceMap[record._id]
                ? (
                    (Object.keys(
                      Object.filter(
                        attendanceMap[record._id],
                        (a) => a === true
                      )
                    ).length *
                      100) /
                    Object.keys(attendanceMap[record._id]).length
                  )?.toFixed(2) + "%"
                : "N/A"}
            </div>
          );
        },
      },
      ...Array.from({
        length: moment(dateRange.to).diff(moment(dateRange.from), "day") + 1,
      }).map((_, index) => {
        const date = moment(dateRange.from)
          .add(index, "days")
          .format("DD-MM-YYYY");

        return {
          title: date,
          dataIndex: "_id",
          align: "center",
          width: 200,
          render: (id, record) => {
            const attendanceValue = attendanceMap[record._id]?.[date];
            const isAttendanceNull =
              attendanceMap[record._id]?.[date] === undefined;

            return (
              <div>
                {isAttendanceNull ? (
                  <div className="flex items-center justify-center cursor-pointer">
                    <BiCircle className="text-gray-600 " size={20} />
                  </div>
                ) : attendanceValue ? (
                  <div className="flex items-center justify-center cursor-pointer">
                    <BiCheckCircle className="text-green-500" size={20} />
                  </div>
                ) : (
                  <div className="flex items-center justify-center cursor-pointer">
                    <BiXCircle className="text-red-500 " size={20} />
                  </div>
                )}
              </div>
            );
          },
        };
      }),
    ];
  }, [dateRange, attendanceMap]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className=" bg-white">
      <div className="py-3 text-lg ">Choose a Range</div>
      <Form
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="flex items-center justify-between">
          <Form.Item name="date">
            <RangePicker
              format={"DD/MM/YYYY"}
              disabledDate={(d) =>
                !d ||
                d.isAfter(moment().endOf("day")) ||
                d.isSameOrBefore(moment().subtract(admin ? 360 : 7, "days"))
              }
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Apply
            </Button>
          </Form.Item>
        </div>
      </Form>

      <div>
        {attendances && (
          <Table
            pagination={{ pageSize: 20, position: ["bottomCenter"] }}
            scroll={{ y: 350, x: 100 }}
            className="rounded-2xl"
            columns={columns}
            dataSource={attendances ? subjects : []}
            rowKey={(record) => record._id}
          />
        )}
      </div>
    </div>
  );
}
