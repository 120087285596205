import { Form, Input, message } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { serverUrl } from "../../../../nestserver";

export default function DepartmentForm({ department, setDepartment }) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  // Create department
  function createDepartment(values) {
    axios
      .post(serverUrl + "/department", values, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("Department Created");
        form.resetFields();
        setDepartment(null);
        queryClient.invalidateQueries("departments");
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  }

  // Update Department
  function updateDepartment(values) {
    axios
      .patch(serverUrl + "/department/" + department._id, values, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("Department Updated");
        form.resetFields();
        setDepartment(null);
        queryClient.invalidateQueries("departments");
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  }

  useEffect(() => {
    form.resetFields();
  }, [department]);

  return (
    <div>
      <div className="py-2">
        <div className="text-2xl font-bold text-black pb-4 capitalize">
          {department?.name ? "Edit Department" : "Create Department"}
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="create-department"
        onFinish={department?.name ? updateDepartment : createDepartment}
        // onFinishFailed={onFinishFailed}
        initialValues={department}
        autoComplete="off"
      >
        <Form.Item
          label="Department Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input department name!",
            },
            {
              max: 200,
              message: "Department name cannot exceed 200 characters.",
            },
          ]}
        >
          <Input className="w-full" />
        </Form.Item>
        <div className="flex justify-end items-center pt-6">
          <div className="inline-flex items-center space-x-2">
            <Button
              size="large"
              type="dark"
              onClick={() => setDepartment(null)}
            >
              Cancel
            </Button>

            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="text-white"
            >
              {department?.name ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}
