import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space, Table } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";

const HistoryCard = () => {
  const { Option } = Select;
  const history = useHistory();

  const [classId, setClassId] = useState();
  const [searchInput, setSearchInput] = useState(null);
  const [templateId, setTemplateId] = useState("");

  //handle class Select change
  const handleSelectChange = (classId) => {
    setClassId(classId);
  };
  const handleTemplateChange = (id) => {
    setTemplateId(id);
  };
  // fetch student
  async function fetchStudent(classId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/progress-card/?class=${classId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getStudent = useQuery(
    ["progress-card", classId],
    () => fetchStudent(classId),
    {
      enabled: !!classId,
    }
  );
  //fetch template
  async function fetchTemplate(classId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/progress-card-template/?class=${classId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getTemplate = useQuery(
    ["progress-card-template", classId],
    () => fetchTemplate(classId),
    {
      enabled: !!classId,
    }
  );
  async function fetchTemplateStudent(classId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/progress-card/class/${classId}/template/${templateId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getTemplateStudent = useQuery(
    ["progress-card/class", classId, templateId],
    () => fetchTemplateStudent(classId, templateId),
    {
      enabled: !!(classId && templateId),
    }
  );

  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  // Table Settings

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search Subject`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Student Id",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center"
        >
          {record?.unique_id}
        </div>
      ),
    },
    {
      title: "Name",
      key: "",
      align: "center",
      ...getColumnSearchProps(["name"]),
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.name}
        </div>
      ),
      onFilter: (value, record) =>
        record?.name?.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Status",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.status}
        </div>
      ),
    },
    {
      title: "Last Report",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.status === "notified" && record?.notified_at
            ? moment(record?.notified_at).format("DD MMMM YYYY,hh:mm A")
            : "--"}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          className="text-base font-semibold text-center text-blue-500 cursor-pointer"
          key={record}
          onClick={() =>
            history.push(
              `/dashboard/admin/menu/student/template-history/${record?._id}`
            )
          }
        >
          View
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Manage Report History
          </div>
          <div className="flex gap-4 items-center">
            <div className="flex gap-4 w-310 items-center">
              <h3 className="text-base font-semibold"> Class</h3>
              <Select
                showSearch
                showArrow
                placeholder="Select Class"
                className="w-full"
                style={{
                  border: "6px",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => handleSelectChange(value)}
              >
                {classes.isSuccess &&
                  classes?.data?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {data.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div className="flex gap-4 w-310 items-center">
              <h3 className="text-base  font-semibold"> Template</h3>
              <Select
                showSearch
                showArrow
                placeholder="Select template"
                className="w-full bg-gray-300"
                style={{
                  border: "6px",
                  backgroundColor: "#EBEBEB",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => handleTemplateChange(value)}
              >
                {getTemplate?.isSuccess &&
                  getTemplate?.data?.result?.map((data) => {
                    return (
                      <Option
                        key={data._id}
                        value={data._id}
                        className="capitalize"
                      >
                        {data.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className="py-5 table-overflow">
            <Table
              columns={columns}
              dataSource={
                getTemplateStudent?.isSuccess &&
                getTemplateStudent?.data?.result.length > 0
                  ? getTemplateStudent?.data?.result
                  : []
              }
              pagination={true}
              loading={getTemplateStudent?.isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryCard;
