import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { serverUrl } from "../nestserver";

/* -------------------------------------------------------------------------- */
/*                                 Create Comment                               */
/* -------------------------------------------------------------------------- */

export function useCreateComment(mutationOptions) {
  // axios post
  function createComment(data) {
    return axios.post(
      serverUrl + "/comments",
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }
    );
  }

  return useMutation(createComment, mutationOptions);
}

/* -------------------------------------------------------------------------- */
/*                               Get Single Comment                             */
/* -------------------------------------------------------------------------- */

export function useGetComment(id, queryOptions) {
  // get comments
  async function getComment() {
    const res = await axios.get(serverUrl + "/comments/" + id, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  return useQuery(["comment", id], getComment, queryOptions);
}
/* -------------------------------------------------------------------------- */
/*                                Get All Comments                               */
/* -------------------------------------------------------------------------- */

/**
 * Get all comments
 * @param {import("react-query").UseQueryOptions} queryOptions
 * @returns {import("react-query").UseQueryResult<any>}
 */
export function useComments(queryOptions) {
  // get comments
  async function getComments() {
    const res = await axios.get(serverUrl + "/comments", {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  return useQuery("comments", getComments, queryOptions);
}

/* -------------------------------------------------------------------------- */
/*                             Update Comment                                   */
/* -------------------------------------------------------------------------- */

export function useUpdateComment(mutationOptions) {
  // axios patch
  function updateComment([id, data]) {
    return axios.patch(serverUrl + "/comments/" + id, data, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
  }

  return useMutation(updateComment, mutationOptions);
}

/* -------------------------------------------------------------------------- */
/*                                 Delete Comment                               */
/* -------------------------------------------------------------------------- */

export function useDeleteComment(mutationOptions) {
  // axios post
  function deleteComment(id) {
    return axios.delete(serverUrl + "/comments/" + id, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
  }

  return useMutation(deleteComment, mutationOptions);
}
