const options = {
  defaultStatus: {
    approved: "approved",
    rejected: "rejected",
    pending: "pending",
    withdraw: "withdraw",
    active: "active",
    applied: "applied",
    resubmit: "resubmit",
  },
  role: {
    admin: "admin",
    instructor: "instructor",
    student: "student",
    parent: "parent",
  },
  feeType: {
    tution: "tution",
    other: "other",
  },
  reminder: {
    now: "now",
    daily: "daily",
    dueDate: "dueDate",
    beforeDate: "beforeDate",
    afterDate: "afterDate",
  },
};
module.exports = options;
