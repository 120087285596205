import { Table } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import React, { useEffect } from "react";
import { BsEye } from "react-icons/bs";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../nestserver";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/auth.slice";

export default function AssignmentsTable({ subjectId, onClick }) {
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();

  // fetch Assignments
  async function fetchAssignments() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/assignment/",
      params: {
        subject: subjectId ?? user?.teacher?.subjects?.map((s) => s._id),
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const assignments = useQuery(["assignments", subjectId], fetchAssignments);

  const columns = [
    {
      title: "Assignment Name & Module",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div style={{ color: "#00627B" }} className="text-left text-sm">
          <div>{text}</div>
          <div className="text-xs font-light pt-2 flex flex-wrap">
            {record?.modules?.map((module) => (
              <div
                className="px-2 bg-gray-400 text-white rounded-full m-1"
                key={module?._id}
              >
                {module.title}
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: "Class & Subject",
      dataIndex: "class",
      key: "class",
      render: (text, record) => (
        <div style={{ color: "#00627B" }} className=" text-sm">
          {record.class?.name} {record.subject?.name}
        </div>
      ),
    },
    {
      title: "Assigned Date",
      dataIndex: "assigned_date",
      key: "assigned_date",
      render: (text) => (
        <div style={{ color: "#00627B" }} className=" text-base ">
          {text && moment(text).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "last_date",
      key: "last_date",
      render: (text) => (
        <div style={{ color: "#00627B" }} className=" text-base">
          {text && moment(text).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "published",
      key: "_id",
      render: (text) => (
        <div className=" text-sm uppercase font-medium">
          {text ? (
            <div className="text-green-500">Published</div>
          ) : (
            <div className="text-yellow-500">Draft</div>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div className="flex flex-row items-center  space-x-6 pr-4">
          <Button
            shape="circle"
            className="flex items-center justify-center"
            onClick={() => onClick({ id: record._id, type: "assignment" })}
          >
            <BsEye className="cursor-pointer " />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    queryClient?.invalidateQueries("assignments");
  }, [subjectId]);

  return (
    <Table
      columns={columns}
      dataSource={
        assignments.isSuccess && (assignments.data ? assignments.data : [])
      }
      pagination={false}
    />
  );
}
