import { DownloadOutlined, FileDoneOutlined } from "@ant-design/icons";
import { Form, Modal, Popconfirm, message } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import UploadFile from "../../../../../components/inputs/UploadFile";
import { cloudFrontUrl, serverUrl } from "../../../../../nestserver";

function Syllabus({ data }) {
  const [editpdfmodal, setEditPdfModal] = useState(false);
  const [pdf, setPdf] = useState(null);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [formSave, setFormSave] = useState(false);

  function patchSyllabus(value) {
    axios({
      method: "patch",
      url: serverUrl + "/subjects/" + data?._id,
      data: {
        syllabus: value,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success(value ? "Syllabus Updated" : "Syllabus Removed");
        queryClient.invalidateQueries(["subject", data?._id]);
        setEditPdfModal(false);
        setFormSave(true);
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  const onFinish = (values) => {
    patchSyllabus(pdf);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const cancelModal = () => {
    setFormSave(true);
    setEditPdfModal(false);
  };

  return (
    <div className=" pt-8 ">
      <div className="bg-white p-6 rounded-xl">
        <div className="flex items-center justify-end pb-6 space-x-2">
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              patchSyllabus(null);
              setPdf(null);
            }}
          >
            {data?.syllabus && <Button type="danger">Delete</Button>}
          </Popconfirm>

          <Button onClick={() => setEditPdfModal(true)} type="primary">
            {data?.syllabus ? "Edit" : "Upload"}
          </Button>
        </div>

        {/* --------------------------------------------------------- */}

        <Modal
          width={800}
          visible={editpdfmodal}
          onOk={() => setEditPdfModal(false)}
          onCancel={cancelModal}
          footer={null}
        >
          <div>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              initialValues={data}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div>
                <Form.Item
                  className="mb-0"
                  label="Upload Pdf"
                  name="syllabus"
                  rules={[
                    {
                      required: true,
                      message: "Please add a file",
                    },
                  ]}
                >
                  <div className="w-full  flex items-center justify-center p-3 bg-gray-50 rounded-lg">
                    <UploadFile
                      onUpload={(data) => {
                        setPdf(data);
                      }}
                      formStatus={formSave}
                      formSave={(formSave) => setFormSave(formSave)}
                    >
                      <div className="w-full rounded flex items-center justify-center text-primary-500">
                        {pdf ? pdf?.files?.name : "Choose File"}
                      </div>
                    </UploadFile>
                  </div>
                </Form.Item>
              </div>
              <Form.Item className="mb-0">
                <div className="flex items-center pt-8">
                  <div className="inline-block ml-auto space-x-3">
                    <Button
                      type="dark"
                      size="large"
                      onClick={() => setEditPdfModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" size="large" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>

        {/* --------------------------------------------------------------- */}

        <div className="">
          {data?.syllabus ? (
            <div>
              {data?.syllabus?.files?.name?.split(".")?.pop() === "pdf" ? (
                <iframe
                  src={cloudFrontUrl + "/docs/" + data?.syllabus?.id}
                  width="100%"
                  height="1000px"
                ></iframe>
              ) : (
                <div
                  className="flex items-center justify-between p-3 rounded"
                  style={{ border: "1px solid gray" }}
                >
                  <div className="flex items-center space-x-6">
                    <FileDoneOutlined className="text-xl" />
                    <p>{data?.name + "-" + data?.class?.name + " Syllabus"}</p>
                  </div>
                  <a
                    href={cloudFrontUrl + "/docs/" + data?.syllabus?.filename}
                    target="_blank"
                    download
                    rel="noreferrer"
                  >
                    <DownloadOutlined className="text-xl cursor-pointer" />
                  </a>
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center justify-center p-4 text-gray-500">
              <div>No Syllabus Uploaded</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Syllabus;
