import { Select, Table } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";

const CourseBoard = () => {
  const history = useHistory();
  const { Option } = Select;
  const [studentId, setStudentId] = useState(null);

  const handleSelectChange = (id) => {
    setStudentId(id);
  };

  async function fetchStudent() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/students/by-parent/`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data.result;
  }
  const students = useQuery("students/by-parent", fetchStudent);

  //fetch student subjects
  function getSubjects() {
    const res = axios({
      method: "get",
      url: `${serverUrl}/students/${studentId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.then((response) => response.data);
  }
  const subjects = useQuery(["students", studentId], getSubjects, {
    enabled: !!studentId,
  });

  const columns = [
    {
      title: "Subject",
      dataIndex: [],
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.name}
        </div>
      ),
    },
    {
      title: "Instructor",
      dataIndex: [],
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {`${record?.teacher?.teacher?.first_name} ${record?.teacher?.teacher?.last_name}`}
        </div>
      ),
    },
    {
      title: "Action",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div
            key={record?._id}
            className=" text-base font-semibold text-center text-blue-500 cursor-pointer"
            onClick={() => {
              history.push(
                "/dashboard/parent/" +
                  record?.name +
                  "/" +
                  record?.class +
                  "/" +
                  record?._id
              );
            }}
          >
            Click to view
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-6">
            Manage Course Board
          </div>

          <div className="flex gap-4 pb-4" style={{ width: "30%" }}>
            <h5 className="text-base font-semibold">Name</h5>
            <Select
              showSearch
              showArrow
              placeholder="Select name"
              className="w-full bg-gray-300"
              style={{
                border: "6px",
                backgroundColor: "#EBEBEB",
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => handleSelectChange(value)}
              allowClear
            >
              {students &&
                students?.data?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {`${data?.first_name} ${data?.last_name}`}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <Table
            columns={columns}
            dataSource={
              subjects && subjects?.data?.subjectArray
                ? subjects?.data?.subjectArray
                : []
            }
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default CourseBoard;
