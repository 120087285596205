
import html2pdf from 'html2pdf.js';

export default function generatePDF (id){

    const element = document.getElementById(id);
    const options = {
      margin: 10,
      filename: 'admission-form.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(options).save();
  };