import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { serverUrl } from "../nestserver";
import { loginUser } from "../redux/slices/auth.slice";

import comapnyLogo from "../../src/images/TwinBotsLogo.svg";
import appstore from "../../src/images/appStore.svg";
import comapnyName from "../../src/images/comapnyName.svg";
import playStore from "../../src/images/googlePlay.svg";
import isKoolImage from "../../src/images/isKoolImage.png";
import isKoolLogo from "../../src/images/isKoolLogo.png";
import isKoolTagLine from "../../src/images/isKoolTagline.png";

const SuperAdminLogin = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  function onFinish(values) {
    const data = {
      role: "super_admin",
      username: values?.username,
      password: values.password,
    };
    axios
      .post(serverUrl + "/auth/login", data)
      .then((res) => {
        if (res?.data?.user?.blocked) {
          message.error(
            "Access Denied. Please contact admin to revoke your access"
          );
        } else {
          dispatch(loginUser(res?.data?.result?.user));
          localStorage.setItem("jwt-token", res?.data?.result?.access_token);
          message.success("Login Successful");
          history.push("/dashboard");
        }
      })
      .catch((error) => {
        form.setFieldsValue({
          username: "",
          password: "",
        });
        message.error("Invalid Credentials");
      });
  }
  return (
    <div
      style={{
        backgroundImage: `url("/assets/login-bg-new.png")`,
      }}
      className="w-full min-h-screen bg-cover background-attachment"
    >
      <div className=" h-full w-full">
        <div className="flex h-full items-center md:mx-11 mx-4">
          <div className="w-full flex gap-5 justify-between">
            <div className="md:text-5xl text-4xl  md:w-2/3 text-left md:my-0 my-8 relative h-screen">
              <div className="flex justify-between absolute bottom-0 pb-10 w-full items-center">
                <div className="flex gap-x-4">
                  <img src={playStore} alt="logo" width={100} />
                  <img src={appstore} alt="logo" width={100} />
                </div>
                <div className="flex">
                  <img src={comapnyLogo} alt="logo" width={100} />
                  <div className="pt-10">
                    <img src={comapnyName} alt="logo" width={150} />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ borderRadius: "10px" }}
              className="w-96  flex flex-col justify-center"
            >
              <img
                src={isKoolLogo}
                alt="isKool"
                height={150}
                width={150}
                style={{ margin: "0 auto" }}
              />
              <img
                src={isKoolImage}
                alt="isKool"
                height={150}
                width={170}
                style={{ margin: "0 auto" }}
              />
              <img
                src={isKoolTagLine}
                alt="isKool"
                height={200}
                width={400}
                style={{ marginBottom: "50px", marginTop: "-10px" }}
              />
              <Form
                form={form}
                name="normal_login"
                className="login-form pt-3"
                onFinish={onFinish}
                layout="vertical"
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                  className="input-font-weight"
                >
                  <Input placeholder="Username" className="rounded-full h-10" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                    {
                      min: 6,
                      message: "Password must be at least 6 characters long!",
                    },
                  ]}
                  className="input-font-weight"
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                    className="rounded-full h-10"
                  />
                </Form.Item>
                <div className="w-full text-center pt-4">
                  <Button
                    htmlType="submit"
                    className="rounded-full px-8 input-font-weight"
                  >
                    Login
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminLogin;
