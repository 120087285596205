import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Popconfirm, Table, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../../../../nestserver";

const EventType = () => {
  const { Search } = Input;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [eventPopup, setEventPopup] = useState(false);
  const [currentColor, setCurrentColor] = useState();
  const handleColorChange = (color) => {
    setCurrentColor(color?.hex);
  };

  const fetchEventType = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/event-types/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const getEventType = useQuery("event-types", fetchEventType);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${serverUrl}/event-types/${id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      queryClient.invalidateQueries(["event-types"]);
      message.success("Event type deleted successfully");
    } catch (error) {
      message.error(error?.response?.data?.error?.errors);
    }
  };
  const columns = [
    {
      title: "Event Name",
      key: "",
      align: "start",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold capitalize"
        >
          {record?.name}
        </div>
      ),
    },
    {
      title: "Color",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center "
        >
          <div className="flex justify-center items-center gap-4">
            <div
              style={{
                background: `${record?.color_code}`,
                width: "20px",
                height: "20px",
              }}
            ></div>
            <span>{record?.color_code}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center "
        >
          <div className="ml-14px flex justify-center">
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => handleDelete(record?._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="danger flex items-center justify-center"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];
  const onFinish = (values) => {
    const payload = {
      name: values?.name,
      color_code: values?.color?.hex,
    };
    if (payload) {
      axios
        .post(serverUrl + "/event-types/", payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          form.resetFields();
          queryClient.invalidateQueries(["event-types"]);
          setEventPopup(false);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          if (err?.response?.data?.error?.errors) {
            message.error(err?.response?.data?.error?.errors);
          }
        });
    }
  };

  return (
    <div>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex justify-between items-center">
            <div className="text-2xl text-orange-500 font-semibold">
              Manage Event Type
            </div>
            <div className="text-right">
              <Button
                size="large"
                type="primary"
                className="text-end"
                onClick={() => setEventPopup(true)}
              >
                Add Event Type
              </Button>
            </div>
          </div>
          <div className="">
            <div className="py-5 table-overflow">
              <Table
                columns={columns}
                dataSource={
                  getEventType?.isSuccess &&
                  getEventType?.data?.result &&
                  getEventType?.data?.result?.data
                    ? getEventType?.data?.result?.data
                    : []
                }
                pagination={false}
                loading={getEventType?.isLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        width={350}
        visible={eventPopup}
        onOk={() => setEventPopup(false)}
        onCancel={() => {
          form.resetFields();
          setEventPopup(false);
        }}
        footer={null}
        forceRender
      >
        <div>
          <h3 className="text-lg font-bold pb-3">Add Event Type</h3>
          <div className="pt-4 w-full">
            <Form
              form={form}
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label="Event Name"
                name="name"
                className="w-30"
                rules={[
                  {
                    required: true,
                    message: "Event  Name is required",
                  },
                ]}
              >
                <Input placeholder="Enter event name " />
              </Form.Item>
              <Form.Item
                label="Event Color"
                name="color"
                rules={[
                  {
                    required: true,
                    message: "Event Color is required",
                  },
                ]}
              >
                <SketchPicker
                  color={currentColor}
                  onChange={handleColorChange}
                  width={284}
                  height={284}
                />
              </Form.Item>
              <div className="flex justify-center items-center py-5">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EventType;
