import React from "react";
import { cloudFrontUrl } from "../../nestserver";

function NotificationListCard({ data }) {
  // const history = useHistory();
  return (
    <>
      <div>
        <div
          className="iskool-student-card flex space-x-3 cursor-pointer h-full"
          // onClick={() =>
          //   history.push(`/dashboard/admin/instructor/profile/${data._id}`)
          // }
        >
          <div className="w-16 h-16 ">
            <img
              className="w-full h-full object-cover rounded-full"
              src={
                data?.student?.profile_image
                  ? cloudFrontUrl +
                    "/" +
                    data._id +
                    "/" +
                    data.teacher?.profile_image
                  : "https://ui-avatars.com/api/?name=" +
                    data.student?.first_name +
                    " " +
                    data.student?.last_name
              }
              alt={data.student?.first_name}
            />
          </div>
          <div className="w-0 flex-grow space-y-1">
            <div className="font-bold h-8 flex items-center text-base ">
              {/* {data?.student?.first_name} {data?.student?.last_name} */}
              Name ,has posted
            </div>
            <div className="text-[#616161] text-sm font-normal">
              Lorem ipsum dolor sit amet, consectetur adipiscing....
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationListCard;
