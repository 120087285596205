import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import StudentAttendanceHistory from "./StudentAttendanceHistory";

function StudentAttendance({ data }) {
  const getAttendance = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/attendance/",
      params: {
        student: data?.student?._id,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };

  const totalAttendance = useQuery(
    ["totalAttendance", data?.student?._id],
    getAttendance
  );

  return (
    <>
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold capitalize">
            {data?.student?.first_name} {data?.student?.last_name}
          </div>

          <div className="text-xl py-2">{data?.student?.class?.name}</div>

          <div className="text-xl py-2 text-primary-500">
            Total Attendance:
            {totalAttendance?.data?.length > 0
              ? `${(
                  (totalAttendance.data.filter((s) => s?.attended === true)
                    .length /
                    totalAttendance.data.length) *
                  100
                ).toFixed(2)}%`
              : " Not Available"}
          </div>

          <div>
            <StudentAttendanceHistory
              data={data}
              subjects={data?.student?.subjects}
              admin={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentAttendance;
