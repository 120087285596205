import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { serverUrl } from "../../../../nestserver";

const ViewReportTemplate = () => {
  const { Search } = Input;
  const { Option } = Select;
  const { id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  async function fetchTemplate(id) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/progress-card-template/${id}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getTemplate = useQuery(
    ["progress-card-template", id],
    () => fetchTemplate(id),
    {
      enabled: !!id,
    }
  );
  useEffect(() => {
    if (id && getTemplate?.data?.result?.data) {
      form.setFieldsValue({
        title: getTemplate?.data?.result?.data?.name,
        year: getTemplate?.data?.result?.data?.year,
        class: getTemplate?.data?.result?.data?.class?.name,
      });
    }
  }, [id, getTemplate]);
  return (
    <div>
      <div className="p-8">
        <div className="bg-white p-10 rounded-2xl">
          <div className="form-header">
            <div className="text-right">
              <Button size="large" onClick={() => history.goBack()}>
                Back
              </Button>
            </div>
            {/* <h3 className="text-2xl font-bold pb-3">View Template</h3> */}
            <div className="pt-4 w-1/2">
              <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item label="Template Name" name="title" className="w-30">
                  <Input
                    placeholder="Enter name"
                    readOnly
                    className="capitalize"
                  />
                </Form.Item>
                <Form.Item name="year" label="Year" className="w-30">
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="Class" name="class" className="w-full">
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="Mandatory Subject" className="w-full">
                  <div>
                    <div
                      className={clsx(
                        getTemplate?.isSuccess &&
                          getTemplate?.data?.result?.data &&
                          getTemplate?.data?.result?.data?.mandatory_subjects
                            .length > 0
                          ? "flex flex-col gap-3 border"
                          : "flex flex-col gap-3",
                        ""
                      )}
                    >
                      {getTemplate.isSuccess &&
                        getTemplate?.data?.result?.data?.mandatory_subjects?.map(
                          (data) => (
                            <div
                              key={data._id}
                              className="bg-gray-200 px-4 py-2 border-b border-gray-500 last-border text-gray-700  capitalize flex items-center flex-row justify-between"
                            >
                              {data?.subject?.name}
                              <div className="border"></div>
                              <Select
                                showArrow
                                placeholder="Select exam"
                                style={{
                                  border: "6px",
                                  backgroundColor: "#EBEBEB",
                                  width: "50%",
                                }}
                                className=""
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                value={data?.exam?.name}
                                disabled
                              ></Select>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </Form.Item>
                <Form.Item name="language" label="Language">
                  <div>
                    <div
                      className={clsx(
                        getTemplate?.isSuccess &&
                          getTemplate?.data?.result?.data &&
                          getTemplate?.data?.result?.data?.languages.length > 0
                          ? "flex flex-col gap-3 border"
                          : "flex flex-col gap-3",
                        ""
                      )}
                    >
                      {getTemplate.isSuccess &&
                        getTemplate?.data?.result?.data?.languages?.map(
                          (data) => (
                            <div
                              key={data._id}
                              className="bg-gray-200 px-4 py-2 border-b border-gray-500 last-border text-gray-700  capitalize flex items-center flex-row justify-between"
                            >
                              {data?.subject?.name}
                              <div className="border"></div>
                              <Select
                                showArrow
                                placeholder="Select exam"
                                style={{
                                  border: "6px",
                                  backgroundColor: "#EBEBEB",
                                  width: "50%",
                                }}
                                className=""
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                value={data?.exam?.name}
                                disabled
                              ></Select>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </Form.Item>
                <Form.Item name="curricular" label="Co-Curricular">
                  <div>
                    <div
                      className={clsx(
                        getTemplate?.isSuccess &&
                          getTemplate?.data?.result?.data?.co_curricular &&
                          getTemplate?.data?.result?.data?.co_curricular
                            .length > 0
                          ? "flex flex-col gap-3 border"
                          : "flex flex-col gap-3",
                        ""
                      )}
                    >
                      {getTemplate?.isSuccess &&
                        getTemplate?.data?.result?.data?.co_curricular?.map(
                          (data) => (
                            <div
                              key={data._id}
                              className="bg-gray-200 px-4 py-2 border-b border-gray-500 last-border text-gray-700  capitalize flex items-center flex-row justify-between"
                            >
                              {data?.subject?.name}
                              <div className="border"></div>
                              <Select
                                showArrow
                                placeholder="Select exam"
                                style={{
                                  border: "6px",
                                  backgroundColor: "#EBEBEB",
                                  width: "50%",
                                }}
                                className=""
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                value={data?.exam?.name}
                                disabled
                              ></Select>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewReportTemplate;
