import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import AdminRoutes from "../apps/admin/Routes";
import InstructorRoutes from "../apps/instructor/Routes";
import StudentRoutes from "../apps/student/Routes";

import ParentRoutes from "../apps/parent/Routes";
import SuperAdminRoutes from "../apps/superAdmin/Route";
import DashboardLayout from "../layout/DashboardLayout";
import { selectUser } from "../redux/slices/auth.slice";

const DashboardRoutes = () => {
  const history = useHistory();

  const user = useSelector(selectUser);
  const role = useMemo(() => user?.role, [user]);

  useEffect(() => {
    if (user === null) {
      history.replace("/auth/login");
    }
  }, [user, history]);

  return (
    <DashboardLayout>
      {(() => {
        switch (role) {
          case "student":
            return <StudentRoutes />;
          case "instructor":
            return <InstructorRoutes />;
          case "admin":
            return <AdminRoutes />;
          case "parent":
            return <ParentRoutes />;
          case "super_admin":
            return <SuperAdminRoutes />;
          default:
            return null;
        }
      })()}
    </DashboardLayout>
  );
};

export default DashboardRoutes;
