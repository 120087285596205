import { Button, Form, Input, message } from "antd";
import axios from "axios";
import React from "react";
import { useQueryClient } from "react-query";
import { cloudFrontUrl, serverUrl } from "../../nestserver";

import * as moment from "moment";
import { FiPaperclip } from "react-icons/fi";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/auth.slice";
import { useCreateNotification } from "../../services/notification.service";

export default function AssignmentEvaluationForm({ data, onClose }) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const user = useSelector(selectUser);

  const createNotificationMutation = useCreateNotification();

  const onFinish = (values) => {
    axios({
      method: "patch",
      url: serverUrl + "/assignment-submissions/" + data?._id,
      data: {
        ...values,
        status: values.status ?? "evaluated",
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Submission Updated");
        queryClient.invalidateQueries([
          "assignment-submissions",
          data?.assignment?._id,
        ]);
        createNotificationMutation.mutate({
          title: values?.status
            ? "Assignment needs to resubmit"
            : "Assignment evaluated",
          description: `${
            values?.status
              ? `${user?.teacher?.first_name} requested you to resubmit ${
                  data?.assignment?.subject?.name
                } ${data?.assignment?.name} on ${moment().format(
                  "MMM Do YYYY"
                )}`
              : `${user?.teacher?.first_name} evaluated your ${
                  data?.assignment?.subject?.name
                } ${data?.assignment?.name} on ${moment().format(
                  "MMM Do YYYY"
                )}`
          }`,
          user: data?.student?._id,
          type: "assignment",
        });
        onClose && onClose();
      })
      .catch((e) => {
        message.error(e.message);
      });
  };
  return (
    <div>
      <div className="text-xl font-bold text-black1 pb-6">
        {data?.student?.first_name} {data?.student?.last_name}
      </div>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="pb-2 space-y-1">
          <div className="text-primary text-base">Assignment Name</div>
          <div className="font-medium text-base">
            {data?.assignment?.name}
          </div>
        </div>

        <div className="flex flex-col space-y-1 pb-2">
          <div className="text-base font-normal text-primary">
            Class & Subject
          </div>
          <div className="text-base font-normal ">
            {data?.assignment?.class?.name}, {data?.assignment?.subject?.name}
          </div>
        </div>
        <div className="flex flex-col space-y-1 pb-2">
          <div className="text-base font-normal text-primary pb-1">Modules</div>
          <div className="flex flex-row space-x-4">
            {data?.assignment?.modules?.map((module) => (
              <Button
                key={module?._id}
                type="primary"
                className="cursor-default"
              >
                {module?.title}
              </Button>
            ))}
          </div>
        </div>

        <div className="capitalize py-4">
          <div>Attachments : </div>
          <div>
            {data?.file.map((item) => (
              <div
                key={item?.id}
                className="flex flex-row items-center space-x-3 py-2 "
              >
                <FiPaperclip />
                <a href={cloudFrontUrl + "/docs/" + item?.id} target="_blank">
                  {item?.files?.name}
                </a>
              </div>
            ))}
          </div>
        </div>

        <Form.Item
          label={`Score out of ${data?.assignment?.score}`}
          name="score"
          rules={[
            {
              required: true,
              message: "Please input  score !",
            },
            {
              pattern: /^[1-9]\d*$/,
              message: "Please enter a positive number ",
            },
          ]}
        >
          <Input
            type="number"
            max={data?.assignment?.score}
            className="w-36 "
            placeholder={data?.assignment?.score}
          />
        </Form.Item>

        <Form.Item label="Remarks" name="remarks">
          <Input className="w-full py-1" />
        </Form.Item>

        <Form.Item className="mb-0 pt-2">
          <div className="flex items-center">
            <div className="inline-block ml-auto space-x-4">
              <Button
                type="primary"
                size="large"
                disabled={data?.enable_resubmission}
                onClick={() =>
                  onFinish({
                    score: null,
                    enable_resubmission: true,
                    status: "resubmit",
                  })
                }
              >
                Resubmit
              </Button>
              <Button type="success" size="large" htmlType="submit">
                Mark As Complete
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
