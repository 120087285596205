import { Button, Form, Input, message } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";
import { useLocation } from "react-router-dom";
import { default as isKoolImage } from "../../../src/images/isKoolImage.png";
import { default as isKoolLogo } from "../../../src/images/isKoolLogo.png";
import isKoolTagLine from "../../../src/images/isKoolTagline.png";
import { serverUrl } from "../../nestserver";

const ForgotPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const role = queryParams.get("role");
  const onRecovery = (values) => {
    const forgotPayload = {
      email: values?.email,
      role: role,
      student_id: values?.student_id ? values?.student_id : null,
    };
    axios
      .post(serverUrl + "/password/forgot", forgotPayload)
      .then((res) => {
        message.info(
          { content: "Reset link has sent to your verified email for registered user" },
          10
        );
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <div
      style={{
        backgroundImage: `url("/assets/login-bg-new.png")`,
      }}
      className="w-full min-h-screen bg-cover background-attachment"
    >
      <div className=" h-full w-full">
        <div className="flex min-h-screen items-center justify-end md:mx-11 mx-4">
          <div className="md:text-5xl text-4xl  md:w-full text-left md:my-0 my-8 h-screen flex">
            <div className="flex justify-end">
              <div
                style={{ borderRadius: "10px" }}
                className="w-96  flex flex-col justify-center"
              >
                <img
                  src={isKoolLogo}
                  alt="isKool"
                  height={150}
                  width={150}
                  style={{ margin: "0 auto" }}
                />
                <img
                  src={isKoolImage}
                  alt="isKool"
                  height={150}
                  width={170}
                  style={{ margin: "0 auto" }}
                />
                <img
                  src={isKoolTagLine}
                  alt="isKool"
                  height={200}
                  width={400}
                  style={{ marginBottom: "50px", marginTop: "-10px" }}
                />

                <div className="sm:">
                  <div className="text-center pb-6 text-xl text-typography">
                    Send Reset Link
                  </div>

                  <Form onFinish={onRecovery}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        placeholder="Enter your email id"
                        className="rounded-full"
                      />
                    </Form.Item>
                    {role === "student" && (
                      <Form.Item
                        name="student_id"
                        rules={[
                          {
                            required: true,
                            message: "Student ID must be provided",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter your student id"
                          className="rounded-full"
                        />
                      </Form.Item>
                    )}
                    <div className="flex justify-between">
                      <Button
                        type=""
                        onClick={() => history.push("/auth/login")}
                        className="botton-color rounded-full"
                      >
                        Go back
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="color rounded-full"
                      >
                        Send Link
                      </Button>
                    </div>
                  </Form>
                </div>

                {/* form end  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
