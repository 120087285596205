import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Table,
  Typography,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import MultipleUpload from "../../../../../components/inputs/UploadMultipleFile";
import { serverUrl } from "../../../../../nestserver";

const AddEditDriver = () => {
  const { Title } = Typography;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [applyLeavePopup, setApplyLeavePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [uploadAdmissionPopup, setUploadAdmissionPopup] = useState(false);
  const [parentFileList, setParentFileList] = useState([]);

  async function getFormList() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/driver/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const formList = useQuery("/driver/", getFormList);

  const handleFileListChange = (fileList) => {
    setParentFileList(fileList);
  };
  async function fetchSubjects(id) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/driver/${id}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const busDetails = useQuery(
    ["driver", editData],
    () => editData && fetchSubjects(editData),
    {
      enabled: !!editData,
    }
  );

  const columns = [
    {
      title: "Name",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {`${record?.first_name} ${record.last_name}`}
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div
            key={record}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {record?.user?.phone}
          </div>
        );
      },
    },
    {
      title: "Date of Birth",
      dataIndex: [],
      key: "status",
      align: "center",
      render: (record, _, index) => {
        return (
          <div
            key={index}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {moment(record?.dob).format("DD-MM-yyyy")}
          </div>
        );
      },
    },
    {
      title: "DL Number",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.dl_number}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record, index) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center cursor-pointer text-blue-500 "
          onClick={() => {
            setIsEditMode(true);
            setEditData(record?._id);
            setApplyLeavePopup(true);
          }}
        >
          <span className="text-blue-500">Edit</span>
        </div>
      ),
    },
  ];

  const onFinish = (values) => {
    const payload = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      phone: values?.phone,
      aadhar: values?.aadhar,
      dob: values?.dob ? moment(values?.dob).format("YYYY/MM/DD") : null,
      address: values?.address,
      dl_number: values?.dl_number,
      dl_issued_at: values?.dl_issued_at,
      dl_expires_at: values?.dl_expires_at,
      emergency_contact: {
        first_name: values?.owner_first_name,
        last_name: values?.owner_contact_last_name,
        contact: values?.owner_contact_phone,
        relation: values?.owner_relation,
      },
      documents:
        parentFileList && parentFileList.length > 0 ? parentFileList : [],
    };
    if (payload) {
      if (isEditMode) {
        axios
          .put(`${serverUrl}/driver/${editData ? editData : null}`, payload, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          })
          .then((res) => {
            form.resetFields();
            setApplyLeavePopup(false);
            setEditData(null);
            setIsEditMode(false);
            queryClient.invalidateQueries("/driver/");
            message.success(res?.data?.result?.message);
          })
          .catch((err) => {
            if (err?.response?.data?.error?.errors) {
              message.error(err?.response?.data?.error?.errors);
            }
          });
      } else {
        axios({
          method: "post",
          url: serverUrl + "/driver/",
          data: payload,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
          .then((res) => {
            queryClient.invalidateQueries("/driver/");
            form.resetFields();
            setApplyLeavePopup(false);
            message.success(res?.data?.result?.message);
          })
          .catch((error) => {
            message.error(error?.response?.data?.error?.errors);
          });
      }
    }
  };

  useEffect(() => {
    if (isEditMode && busDetails?.data?.result?.data) {
      const data = busDetails.data.result.data;

      form.setFieldsValue({
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.user?.phone,
        aadhar: data.aadhar,
        dob: data.dob ? moment(data.dob, "YYYY-MM-DD") : null,
        address: data?.address,
        dl_number: data?.dl_number,
        dl_issued_at: data.dl_issued_at
          ? moment(data.dl_issued_at, "YYYY-MM-DD")
          : null,
        dl_expires_at: data.dl_expires_at
          ? moment(data.dl_expires_at, "YYYY-MM-DD")
          : null,
        owner_first_name: data.emergency_contact?.first_name,
        owner_contact_last_name: data.emergency_contact?.last_name,
        owner_contact_phone: data.emergency_contact?.contact,
        owner_relation: data.emergency_contact?.relation,
        route: data?.route ? data?.route?.name : null,
      });
      if (Array.isArray(data.documents)) {
        setParentFileList(data.documents);
      }
    }
  }, [isEditMode, busDetails]);

  return (
    <div>
      {" "}
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex justify-between items-center">
            <div className="text-2xl text-orange-500 font-semibold">
              Manage Driver
            </div>
            <div className="text-right pt-4">
              <Button
                type="primary"
                size="large"
                onClick={() => setApplyLeavePopup(true)}
              >
                Add
              </Button>
            </div>
          </div>
          <div className="py-5 table-overflow">
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={
                formList?.isSuccess &&
                formList?.data?.result?.data?.length > 0 &&
                formList?.data?.result?.data
                  ? formList?.data?.result?.data
                  : []
              }
              pagination={true}
              loading={formList?.isLoading}
            />
          </div>

          <Modal
            width={800}
            visible={applyLeavePopup}
            onOk={() => setApplyLeavePopup(false)}
            onCancel={() => {
              form.resetFields();
              setApplyLeavePopup(false);
              setEditData(null);
              setIsEditMode(false);
              setParentFileList([]);
              form.resetFields();
            }}
            footer={null}
            forceRender
          >
            <h3 className="text-lg font-bold pb-3">
              {isEditMode ? "Update Driver" : " Add Driver"}
            </h3>
            <div className="bg-white rounded-2xl ">
              <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
              >
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please enter First Name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Last Name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    name="phone"
                    label="Phone"
                    className="w-30"
                    rules={[
                      {
                        pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                        message: "Please enter a valid 10-digit phone number!",
                      },
                      {
                        required: true,
                        message: "Please enter phone number",
                      },
                    ]}
                    getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                  >
                    <Input type="number" maxLength={10} min={0} />
                  </Form.Item>
                  <Form.Item
                    name="dob"
                    label="Date Of Birth"
                    rules={[
                      {
                        required: true,
                        message: "Date of Birth  is required",
                      },
                    ]}
                    className="w-full"
                  >
                    <DatePicker
                      format="DD-MM-YYYY"
                      placeholder="Please select your date of birth"
                      disabledDate={(current) =>
                        current && current > moment().endOf("day")
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                      className="w-full"
                    />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    name="aadhar"
                    label="Aadhar"
                    className="w-30"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter your aadhar id",
                      },
                      {
                        pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                        message: "Please enter a valid 12-digit Number!",
                      },
                    ]}
                    getValueFromEvent={(e) => e.target.value.slice(0, 12)}
                  >
                    <Input maxLength={12} type="number" min={0} />
                  </Form.Item>
                  <Form.Item
                    label="DL Number"
                    name="dl_number"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your DL number",
                      },
                      {
                        pattern: /^[A-Z0-9]{6,16}$/,
                        message:
                          "DL number must be 6-16 characters long and contain only uppercase letters and numbers",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    label="DL Issue Date"
                    name="dl_issued_at"
                    rules={[
                      {
                        required: true,
                        message: "Dl issue date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD-MM-YYYY"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      className="w-full"
                      disabledDate={(current) =>
                        current && current >= moment().startOf("day")
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="DL expire Date"
                    name="dl_expires_at"
                    rules={[
                      {
                        required: true,
                        message: "DL expire date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD-MM-YYYY"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      className="w-full"
                      disabledDate={(current) =>
                        current && current <= moment().endOf("day")
                      }
                    />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  {isEditMode && busDetails?.data?.result?.data?.route && (
                    <Form.Item
                      label="Route Name"
                      name="route"
                      className="w-full"
                    >
                      <Input readOnly />
                    </Form.Item>
                  )}
                </div>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value || value.length <= 100) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Description must be at most 100 characters"
                        );
                      },
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={3}
                    placeholder="Address"
                    className="w-full border focus:outline-none resize-none"
                    maxLength={100} // Limit the input to 100 characters visually
                  />
                </Form.Item>

                <Title level={2}>Emergency Contact</Title>
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    name="owner_first_name"
                    label="First Name"
                    className="w-30"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="owner_contact_last_name"
                    label="Last Name"
                    className="w-30"
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    name="owner_contact_phone"
                    label="Phone"
                    className="w-30"
                    rules={[
                      {
                        pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                        message: "Please enter a valid 10-digit phone number!",
                      },
                    ]}
                    getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                  >
                    <Input type="number" maxLength={10} min={0} />
                  </Form.Item>
                  <Form.Item
                    name="owner_relation"
                    label="Relation"
                    className="w-30"
                  >
                    <Input />
                  </Form.Item>
                </div>
                <Button
                  size="large"
                  type="primary"
                  htmlType="button"
                  className="text-white w-9"
                  onClick={() => setUploadAdmissionPopup(true)}
                >
                  Upload Document
                </Button>
                <div className="pt-4">
                  {parentFileList && parentFileList.length > 0 && (
                    <h5 className="pb-3 font-semibold text-sm">
                      Upload Preview
                    </h5>
                  )}
                  {parentFileList &&
                    parentFileList?.length > 0 &&
                    parentFileList?.map((data, index) => {
                      return (
                        <ui key={index}>
                          <li className="pb-2 capitalize">
                            {index + 1}.&nbsp;
                            {data?.files?.name}
                          </li>
                        </ui>
                      );
                    })}
                </div>

                <div className="text-center">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    {isEditMode ? "Update" : " Submit"}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <Modal
            width={800}
            visible={uploadAdmissionPopup}
            onOk={() => setUploadAdmissionPopup(false)}
            onCancel={() => {
              setUploadAdmissionPopup(false);
            }}
            footer={null}
          >
            <div>
              <MultipleUpload
                onFileListChange={handleFileListChange}
                parentResponse={parentFileList}
              />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddEditDriver;
