import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  message,
} from "antd";
import axios from "axios";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";

function ClassSetting({ classDetails }) {
  const { Option } = Select;
  const queryClient = useQueryClient();
  const history = useHistory();
  const [form] = Form.useForm();
  const [academicLastDate, setAcademicLastDate] = useState(null);
  const [academicStartDate, setAcademicStartDate] = useState(null);
  const [transitionStartDate, setTransitionStart] = useState(null);

  // fetch Instructors
  async function fetchInstructors() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/",
      params: {
        role: "instructor",
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const instructors = useQuery("instructors", fetchInstructors);

  // update class
  function updateClass(values) {
    axios
      .patch(serverUrl + "/classes/" + classDetails?._id, values, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("Class Updated");
        queryClient.invalidateQueries("classes");
        queryClient.invalidateQueries(["class", classDetails?._id]);
      })
      .catch((error) => {
        if (error?.response?.data) {
          message.error(error?.response?.data.message);
        } else {
          message.error(error.message);
        }
      });
  }

  // delete class
  function deleteClass() {
    axios
      .delete(serverUrl + "/classes/" + classDetails?._id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("Class Deleted");
        queryClient.invalidateQueries("classes");
        queryClient.invalidateQueries(["class", classDetails?._id]);
        history.push("/dashboard/admin/menu/class");
      });
  }

  useEffect(() => {
    if (classDetails) {
      setAcademicLastDate(moment(classDetails?.academic_end_date));
      setTransitionStart(classDetails?.transition_start_date);
    }
  }, [classDetails]);

  useEffect(() => {
    if (
      academicLastDate &&
      moment(academicLastDate).isAfter(classDetails?.transition_start_date)
    ) {
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        transition_start_date: null,
        transition_end_date: null,
      });
    }

    if (
      academicStartDate &&
      moment(academicStartDate).isAfter(classDetails?.transition_start_date)
    ) {
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        transition_start_date: null,
        transition_end_date: null,
      });
    }
  }, [academicLastDate, academicStartDate]);

  useEffect(() => {
    if (
      transitionStartDate &&
      !moment(transitionStartDate).isSame(classDetails?.transition_start_date)
    ) {
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        transition_end_date: null,
      });
    }
  }, [transitionStartDate]);

  useEffect(() => {
    if (
      academicStartDate &&
      moment(academicStartDate).isAfter(classDetails?.academic_end_date)
    ) {
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        academic_end_date: null,
        transition_start_date: null,
        transition_end_date: null,
      });
    }
  }, [academicStartDate]);

  const generateRollNumber = (classId) => {
    if (classId) {
      axios
        .post(
          serverUrl + "/students/roll-number/class/" + classId,
          {},
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
        .then((res) => {
          queryClient.invalidateQueries(["class", classDetails?._id]);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          message.error(err?.response?.data?.error?.errors);
        });
    }
  };

  return (
    <>
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          {classDetails && (
            <Form
              form={form}
              layout="vertical"
              name="basic"
              initialValues={{
                name: classDetails?.name,
                subjects: classDetails?.subjects?.map((s) => s._id),
                in_charge: classDetails?.in_charge?._id,
                academic_start_date: moment(classDetails?.academic_start_date),
                academic_end_date: moment(classDetails?.academic_end_date),
                transition_start_date: moment(
                  classDetails?.transition_start_date
                ),
                transition_end_date: moment(classDetails?.transition_end_date),
              }}
              onFinish={updateClass}
            >
              <div className="md:w-3/4">
                <Form.Item
                  label="Class Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input class name",
                    },
                  ]}
                >
                  <Input className="w-full" />
                </Form.Item>

                <Form.Item label="Class In Charge" name="in_charge">
                  <Select allowClear placeholder="Please select">
                    {instructors?.isSuccess
                      ? instructors?.data
                          ?.filter((t) => !t.blocked)
                          ?.map((i) => (
                            <Option key={i?._id} value={i?._id}>
                              {i?.teacher?.first_name} {i?.teacher?.last_name}
                            </Option>
                          ))
                      : []}
                  </Select>
                </Form.Item>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Academic Start Date"
                      name="academic_start_date"
                      rules={[
                        { required: true, message: "Please input class name" },
                      ]}
                    >
                      <DatePicker
                        className="w-full"
                        disabledDate={(d) =>
                          d.isBefore(moment().subtract(1, "d"))
                        }
                        onChange={(d) => setAcademicStartDate(d)}
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Academic End Date"
                      name="academic_end_date"
                      rules={[
                        { required: true, message: "Please input class name" },
                      ]}
                    >
                      <DatePicker
                        className="w-full"
                        disabledDate={(d) =>
                          d.isBefore(
                            academicStartDate
                              ? academicStartDate
                              : classDetails?.academic_start_date
                          )
                        }
                        onChange={(d) => setAcademicLastDate(d)}
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </Col>

                  {academicLastDate && (
                    <>
                      <Col span={12}>
                        <Form.Item
                          label="Transition Start Date"
                          name="transition_start_date"
                          tooltip="Transistion period is the phase where Instructor/Admin can complete the Evalauations and Transfers to the next class"
                          rules={[
                            {
                              required: true,
                              message: "Please input class name",
                            },
                          ]}
                        >
                          <DatePicker
                            className="w-full"
                            disabledDate={(d) =>
                              d.isBefore(moment(academicLastDate).add(1, "d"))
                            }
                            onChange={(d) => setTransitionStart(d)}
                            format="DD/MM/YYYY"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Transition End Date"
                          name="transition_end_date"
                          tooltip="Transistion period is the phase where Instructor/Admin can complete the Evalauations and Transfers to the next class"
                          rules={[
                            {
                              required: true,
                              message: "Please input class name",
                            },
                          ]}
                        >
                          <DatePicker
                            className="w-full"
                            disabledDate={(d) =>
                              d.isBefore(
                                moment(transitionStartDate).add(1, "d")
                              )
                            }
                            format="DD/MM/YYYY"
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </div>

              <div>
                <Button
                  onClick={() => generateRollNumber(classDetails?._id)}
                  disabled={classDetails?.is_roll_number_generated}
                >
                  Generate Roll Number
                </Button>
              </div>
              <div className="flex items-center justify-between pt-10 ">
                <div>
                  <Popconfirm
                    title="Are you sure to delete this class?"
                    onConfirm={deleteClass}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="large" type="danger">
                      Delete Class
                    </Button>
                  </Popconfirm>
                </div>
                <div className="flex flex-row space-x-4">
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => form?.resetFields()}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    </>
  );
}

export default ClassSetting;
