import React from "react";// Import CSS file for styling

const CustomCheckBox = ({ checked, onChange }) => {
  return (
    <label className="custom-checkbox-container">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="custom-checkbox-input"
      />
      <span className="custom-checkbox-checkmark"></span>
    </label>
  );
};

export default CustomCheckBox;
