import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  AiOutlineClose,
  AiOutlineFile,
  AiOutlineFilePdf,
  AiOutlineUpload,
} from "react-icons/ai";
import { useQuery, useQueryClient } from "react-query";
import { cloudFrontUrl, serverUrl } from "../../../../nestserver";
const { Option } = Select;

const InstructorClassBroadcast = () => {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const queryClient = useQueryClient();
  const [classId, setClassId] = useState();
  const [subjectId, setSubjectId] = useState();
  const [uploadDoc, setUploadDoc] = useState([]);
  const [messageData, setMessageData] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [classSubjectData, setClassSubjectData] = useState([]);

  const handleClassChange = (values) => {
    form.resetFields(["subject"]);
    setClassId(values);
    setSubjectId(null);
  };
  const handleImageClick = (src) => {
    setModalImageSrc(src);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalImageSrc("");
  };

  const handleSubjectChange = (subjectId) => {
    setSubjectId(subjectId);
  };
  //fetch class
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/teachers/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  //fetch class subjects
  const fetchClassSubject = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/" + classId,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    setClassSubjectData(res?.data);
    return res.data;
  };

  //get class broadcasting
  const fetchClassConversation = async (id) => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/conversations/subject/" + id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classConversationDetails = useQuery(
    ["conversations", subjectId],
    () => fetchClassConversation(subjectId),
    { enabled: !!subjectId }
  );

  const handleFileSelect = async (event) => {
    const files = event.target.files;

    if (!files || files?.length === 0) return;

    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ];

    const maxTotalFiles = 15;
    const formDataPdf = new FormData();
    const formDataImages = new FormData();
    let validFileCount = 0;
    let invalidTypeFiles = 0;

    // Check total number of files including existing uploads
    const totalFiles =
      (Array.isArray(uploadDoc) ? uploadDoc.length : 0) + files.length;

    if (totalFiles > maxTotalFiles) {
      message.error(`You can only upload ${maxTotalFiles} files`);
      return;
    }

    for (let i = 0; i < files?.length; i++) {
      const file = files[i];

      if (!allowedTypes.includes(file.type)) {
        message.error(
          `Invalid file type for ${file.name}. Please upload PDF, JPG, or PNG files only.`
        );
        invalidTypeFiles++;
        continue;
      }

      if (file.type === "application/pdf") {
        formDataPdf.append("files", file);
      } else {
        formDataImages.append("files", file);
      }
      validFileCount++;
    }

    if (validFileCount === 0 || invalidTypeFiles > 0) {
      return;
    }

    formDataPdf.append("folder", "pdf");
    formDataImages.append("folder", "images");

    try {
      let responses = [];

      if (formDataPdf.has("files")) {
        const resPdf = await axios.post(
          serverUrl + "/uploads/docs",
          formDataPdf,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        responses = responses.concat(resPdf.data);
      }

      if (formDataImages.has("files")) {
        const resImages = await axios.post(
          serverUrl + "/uploads/docs",
          formDataImages,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        responses = responses.concat(resImages?.data);
      }

      message.success("Files Upload Success");
      setUploadDoc((prevDocs) => {
        const newDocs = responses.reduce((acc, item) => {
          if (item?.files?.name && item?.id) {
            acc.push({
              name: item?.files?.name,
              id: item?.id,
            });
          }
          return acc;
        }, []);

        const updatedDocs = Array.isArray(prevDocs)
          ? [...prevDocs, ...newDocs]
          : newDocs;

        // Ensure total number of files doesn't exceed maxTotalFiles
        return updatedDocs.slice(0, maxTotalFiles);
      });
    } catch (error) {
      message.error("Files Upload Failed");
    }
  };
  const handleMessageChange = (e) => {
    setMessageData(e.target.value);
  };

  const handleSubmit = async () => {
    const payload = {
      type: "broadcast",
      conversation_id: classConversationDetails?.data?.result?._id
        ? classConversationDetails?.data?.result?._id
        : null,
      target_id: null,
      subject_id: subjectId ? subjectId : null,
      media: uploadDoc && uploadDoc?.length > 0 ? uploadDoc : [],
      message: messageData ? messageData : null,
    };
    axios
      .post(serverUrl + "/conversations", payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success(res?.data?.result?.message);
        setMessageData("");
        setUploadDoc(null);
        queryClient.invalidateQueries(["conversations", subjectId]);
      })
      .catch((err) => {
        if (err?.response?.data?.error?.errors) {
          message.error(err?.response?.data?.error?.errors);
        }
      });
  };
  const onRemove = (index) => {
    setUploadDoc((oldDoc) => oldDoc.filter((_, i) => i !== index));
    message.success("File Removed");
  };

  const deleteMessage = (messageId) => {
    axios({
      method: "delete",
      url:
        serverUrl +
        "/conversations/" +
        classConversationDetails?.data?.result?._id +
        "/message/" +
        messageId,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        queryClient.invalidateQueries(["conversations", subjectId]);
        message.success(res?.data?.result?.message);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    if (classId) {
      fetchClassSubject();
    }
  }, [classId]);
  useEffect(() => {
    if (uploadDoc?.length > 0 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [uploadDoc]);
  return (
    <div>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full screen-height "
        >
          <div className="flex gap-4 min-height">
            <div style={{ width: "30%" }} className="message-left-part">
              <Form
                form={form}
                name="dynamic_rule"
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item name="class" label="Class" className="w-30">
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select Class"
                    className="w-full bg-gray-300"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={handleClassChange}
                  >
                    {classes?.isSuccess &&
                      classes?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item name="subject" label="Subject" className="w-30">
                  {classSubjectData &&
                    classSubjectData?.subjects?.map((data) => (
                      <div
                        className="pt-2 text-base cursor-pointer"
                        key={data?._id}
                      >
                        <div
                          onClick={() => handleSubjectChange(data?._id)}
                          type="primary"
                          size="large"
                          className={`cursor-pointer ${
                            subjectId === data?._id
                              ? "active-message"
                              : "message-card"
                          }`}
                        >
                          <div className="flex justify-between">
                            <div>{data?.name}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Form.Item>
              </Form>
            </div>
            <div style={{ width: "70%" }} className="instructor-broadcast">
              {subjectId ? (
                <>
                  <div className="flex justify-between items-center pb-4">
                    <h1 className="pb-2 text-base font-semibold">
                      Class BroadCast
                    </h1>
                    <AiOutlineClose
                      size={20}
                      onClick={() => {
                        setSubjectId(null);
                        setUploadDoc([]);
                        setClassSubjectData([]);
                        form.resetFields(["subject", "class"]);
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div
                    className="message-chat-content pt-8"
                    style={{ width: "70%", marginLeft: "auto" }}
                  >
                    {classConversationDetails &&
                      classConversationDetails?.data?.result?.messages?.map(
                        (message) => {
                          return (
                            <div className="pt-4" key={message?._id}>
                              <div className="flex items-center gap-4 pb-4 justify-end">
                                <div className="flex flex-col gap-4">
                                  <span>
                                    {moment(message?.createdAt).format(
                                      "DD-MM-YYYY HH:MM"
                                    )}
                                  </span>
                                </div>

                                <div className="message-box ">
                                  {message?.media && (
                                    <div className="media-container">
                                      {message?.media.name
                                        ?.toLowerCase()
                                        .endsWith(".jpeg") ||
                                      message?.media.name
                                        ?.toLowerCase()
                                        .endsWith(".jpg") ||
                                      message?.media.name
                                        ?.toLowerCase()
                                        .endsWith(".png") ? (
                                        <>
                                          <img
                                            src={`${cloudFrontUrl}/images/${message?.media.id}`}
                                            alt={message?.media.name}
                                            onClick={() =>
                                              handleImageClick(
                                                `${cloudFrontUrl}/images/${message?.media.id}`
                                              )
                                            }
                                            className="media-image cursor-pointer"
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                "fallback-image-url";
                                            }}
                                          />
                                        </>
                                      ) : message?.media.name
                                          .toLowerCase()
                                          .endsWith(".pdf") ? (
                                        <a
                                          href={`${cloudFrontUrl}/pdf/${message?.media.id}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="pdf-link"
                                        >
                                          <AiOutlineFilePdf size={35} />
                                          {message?.media.name}
                                        </a>
                                      ) : (
                                        <img
                                          src={`${cloudFrontUrl}/images/${message?.media?.id}`}
                                          alt={message?.media.name}
                                          onClick={() =>
                                            handleImageClick(
                                              `${cloudFrontUrl}/images/${message?.media?.id}`
                                            )
                                          }
                                          className="media-image cursor-pointer"
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = "fallback-image-url";
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                  {message?.message}

                                  <Modal
                                    visible={isModalVisible}
                                    footer={null}
                                    onCancel={handleCancel}
                                    centered
                                  >
                                    <img
                                      src={modalImageSrc}
                                      alt="Preview"
                                      style={{
                                        width: "100%",
                                        marginTop: "20px",
                                      }}
                                    />
                                  </Modal>
                                </div>
                                <Popconfirm
                                  title="Are you sure to delete?"
                                  onConfirm={() => deleteMessage(message?._id)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button
                                    type="danger flex items-center justify-center"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                  />
                                </Popconfirm>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                  <div>
                    {uploadDoc && uploadDoc?.length > 0 && (
                      <div
                        className="flex overflow-auto"
                        style={{ marginLeft: "40px" }}
                      >
                        {uploadDoc.map((doc, index) => (
                          <Card
                            key={index}
                            size="small"
                            className="mb-2"
                            bodyStyle={{
                              padding: "8px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div className="flex items-center">
                              <AiOutlineFile style={{ marginRight: "4px" }} />
                              <span className="truncate">{doc.name}</span>
                            </div>
                            <Button
                              type="text"
                              icon={
                                <AiOutlineClose
                                  style={{ marginLeft: "12px" }}
                                />
                              }
                              onClick={() => onRemove(index)}
                              style={{ marginLeft: "auto" }}
                            />
                          </Card>
                        ))}
                      </div>
                    )}
                    <div className=" flex items-center gap-4">
                      <div className="flex  flex-col" style={{ gap: "10px" }}>
                        <AiOutlineUpload
                          size={25}
                          className="cursor-pointer"
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        />
                        <input
                          id="fileInput"
                          type="file"
                          style={{ display: "none" }}
                          multiple
                          accept=".pdf, .jpeg, .jpg, .png"
                          onChange={handleFileSelect}
                        />
                      </div>
                      <Input.TextArea
                        rows={3}
                        ref={inputRef}
                        placeholder="Type message..."
                        className="w-full border focus:outline-none resize-none"
                        value={messageData}
                        onChange={handleMessageChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            handleSubmit();
                          }
                        }}
                      />
                      {((messageData && messageData.trim().length > 0) ||
                        uploadDoc?.length > 0) && (
                        <Button
                          size="large"
                          type="primary"
                          onClick={handleSubmit}
                        >
                          Send
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center h-full w-full">
                  No message found
                </div>
              )}
            </div>
          </div>
          <div className="flex"></div>
        </div>
      </div>
    </div>
  );
};

export default InstructorClassBroadcast;
