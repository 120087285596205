import { Button, Form, Input, message, Radio } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../../../nestserver";
import { selectUser } from "../../../../redux/slices/auth.slice";

const Settings = () => {
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [updateType, setUpdateType] = useState("username");

  const onFinish = (values) => {
    const userData = {
      type: updateType,
      username: updateType === "username" ? values?.username : null,
      password: updateType === "password" ? values?.password : null,
      old_password: updateType === "password" ? values?.old_password : null,
    };
    if (userData) {
      axios
        .patch(serverUrl + "/users/password/" + user._id, userData, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          message.success("Credentials Updated");
          queryClient.invalidateQueries("user");
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            message.error(err?.response?.data?.message);
          }
        });
    }
  };

  return (
    <div>
      <div className="p-8">
        <div className="bg-white p-10 rounded-2xl">
          <div className=" pb-4 flex justify-between items-center">
            <div className="text-2xl font-semibold">Change Settings</div>
            <Button size="large" onClick={() => history.goBack()}>
              Back
            </Button>
          </div>

          <Form
            layout="vertical"
            name="basic"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            className="w-1/2"
            initialValues={{ username: user?.username }}
          >
            <Form.Item>
              <Radio.Group
                onChange={(e) => setUpdateType(e.target.value)}
                value={updateType}
              >
                <Radio value="username">Username</Radio>
                <Radio value="password">Password</Radio>
              </Radio.Group>
            </Form.Item>

            {updateType === "username" && (
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  {
                    required: true,
                    message: "Please input username",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {updateType === "password" && (
              <>
                <Form.Item
                  label="Old Password"
                  name="old_password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your old Password!",
                    },
                    {
                      min: 6,
                      message: "Password must be at least 6 characters long!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Input old password" />
                </Form.Item>
                <Form.Item
                  label="New Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new Password!",
                    },
                    {
                      min: 6,
                      message: "Password must be at least 6 characters long!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Input new password" />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label="Confirm New Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                    {
                      required: true,
                      message: "Please input confirm password",
                    },
                    {
                      min: 6,
                      message: "Password must be at least 6 characters long!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Confirm password" />
                </Form.Item>
              </>
            )}

            <div className="text-center pt-4">
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="text-white text-center"
              >
                Update
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
