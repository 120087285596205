import React from 'react'

const AuthLayout = ({ children }) => {
  return (
    <div>
      { children }
    </div>
  )
}

export default AuthLayout
