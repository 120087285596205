import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../nestserver";

const HomePage = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { Title } = Typography;
  const [form] = Form.useForm();

  const [addSchool, SetAddSchool] = useState(false);
  const [viewMessage, setViewMessage] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // fetch subjects

  // fetch applications
  async function fetchSchool() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/school/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const schools = useQuery("applications", fetchSchool);

  // Table Settings

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [searchInput, setSearchInput] = useState(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "School Id",
      key: "",
      align: "center",
      ...getColumnSearchProps(["refId"]),
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.refId}
        </div>
      ),
      onFilter: (value, record) =>
        record?.refId?.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Name",
      key: "name",
      align: "center",
      ...getColumnSearchProps(["name"]),
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.name}
        </div>
      ),
      onFilter: (value, record) =>
        record?.name?.toLowerCase().includes(value.toLowerCase()),
    },

    {
      title: "Address",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        if (!record?.address) {
          return (
            <div className="text-base text-gray-400 cursor-not-allowed">
              View
            </div>
          );
        }
        return (
          <div
            className="text-base font-semibold text-center text-blue-500 cursor-pointer"
            onClick={() => {
              setSelectedRecord(record?.address);
              setViewMessage(true);
            }}
          >
            View
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          className=" text-base font-semibold text-center text-blue-500 cursor-pointer"
          onClick={() =>
            history.push({
              pathname: `/dashboard/super-admin/school/${record?._id}`,
              state: { record },
            })
          }
        >
          View
        </div>
      ),
    },
  ];

  const onFinish = async (data) => {
    try {
      const payload = {
        name: data?.name,
        address: data?.address,
        state: data?.state,
        city: data?.city,
        pincode: data?.pincode,
        admin: {
          first_name: data?.first_name,
          last_name: data?.last_name,
          username: data?.username,
          phone: data?.phone,
          email: data?.email,
          password: data?.password,
        },
      };
      await axios
        .post(serverUrl + "/school/", payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })

        .then((res) => {
          queryClient.invalidateQueries(["school"]);
          form.resetFields();
          message.success(res?.data?.result?.message);
          SetAddSchool(false);
        });
    } catch (err) {
      console.log('error',err)
      message.error(err?.response?.data?.error?.errors);
    }
  };

  return (
    <>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex justify-end">
            <Button
              size="large"
              type="primary"
              htmlType="download"
              className="text-white w-9"
              onClick={() => SetAddSchool(true)}
            >
              Add
            </Button>
          </div>
          <div className="py-5 table-overflow">
            <Table
              columns={columns}
              dataSource={
                schools?.isSuccess
                  ? schools?.data?.result?.data?.sort((a, b) =>
                      moment(a?.createdAt).isBefore(b?.createdAt) ? 1 : -1
                    )
                  : []
              }
              pagination={true}
              loading={schools?.isLoading}
            />
          </div>
        </div>
      </div>
      {/* ----------------------------------modal------------------------------------ */}

      <Modal
        width={800}
        visible={viewMessage}
        onCancel={() => {
          setViewMessage(false);
        }}
        footer={null}
      >
        {selectedRecord ? (
          <div className="pt-4 text-base capitalize">{selectedRecord}</div>
        ) : (
          <div className="pt-5 text-base">No Address Present</div>
        )}
      </Modal>

      <Modal
        width={600}
        visible={addSchool}
        onCancel={() => {
          SetAddSchool(false);
          form.resetFields();
        }}
        footer={null}
      >
        <div>
          <div className="py-2">
            <div className="text-2xl font-bold text-black pb-4 capitalize">
              Add School
            </div>
          </div>
          {/* ---------------------------form---------------- */}
          <div>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="School Name"
                name="name"
                rules={[
                  { required: true, message: "Please input school name" },
                ]}
              >
                <Input className="w-full" placeholder="Name" />
              </Form.Item>
              <Form.Item
                label="School Address"
                name="address"
                rules={[
                  { required: true, message: "Please input school address" },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Address"
                  className="w-full border focus:outline-none resize-none"
                />
              </Form.Item>
              <Form.Item
                name="state"
                label="School State"
                rules={[{ required: true, message: "Please input state" }]}
              >
                <Input placeholder="State" />
              </Form.Item>
              <Form.Item
                name="city"
                label="School City"
                rules={[{ required: true, message: "Please input city" }]}
              >
                <Input placeholder="School" />
              </Form.Item>
              <Form.Item
                name="pincode"
                label="School Pincode"
                rules={[
                  { required: true, message: "Please input pincode" },
                  {
                    pattern: /^[0-9]{6}$/, // Adjust the regex pattern as needed
                    message: "Please enter a valid 6-digit pincode !",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 6)}
              >
                <Input
                  type="number"
                  maxLength={6}
                  min={0}
                  placeholder="Pincode"
                />
              </Form.Item>
              <Title level={2}>Admin Details</Title>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[{ required: true, message: "Please input First name" }]}
              >
                <Input className="w-full" placeholder="First name" />
              </Form.Item>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[{ required: true, message: "Please input Last name" }]}
              >
                <Input className="w-full" placeholder="Last name" />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone"
                className="w-30"
                rules={[
                  {
                    pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                    message: "Please enter a valid 10-digit phone number!",
                  },
                  { required: true, message: "Phone number is required" },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 10)}
              >
                <Input
                  type="number"
                  maxLength={10}
                  min={0}
                  placeholder="Phone"
                />
              </Form.Item>
              <Form.Item
                name="username"
                label="Username"
                rules={[{ required: true, message: "Please input username" }]}
              >
                <Input className="w-full" placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                  { required: true, message: "Email is required" },
                ]}
                className="w-30"
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                  {
                    min: 6,
                    message: "Password must be at least 6 characters long!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <div className="flex justify-end items-center pt-6">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => {
                      SetAddSchool(false);
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          {/* ----------------------------------------------- */}
        </div>
      </Modal>
    </>
  );
};

export default HomePage;
