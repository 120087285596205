import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import AttendanceHistory from "../../../../instructor/pages/CourseBoard/students/AttendanceHistory";
/**
 *
 * @param {object} props
 * @param {object} props.subjectDetails Data of the subject
 * @returns
 */
export default function SubjectAttendance({ subjectDetails }) {
  // Fetch Students
  async function getStudents() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/students/subject/" + subjectDetails._id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const students = useQuery(["students", subjectDetails?._id], getStudents);

  return (
    <div>
      <AttendanceHistory
        data={subjectDetails}
        students={students?.data}
        admin={true}
      />
    </div>
  );
}
