import { DownloadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Table, message } from "antd";
import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { serverUrl } from "../../../../../nestserver";
import { selectUser } from "../../../../../redux/slices/auth.slice";
const { Option } = Select;

const ParentManageForm = () => {
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [applyLeavePopup, setApplyLeavePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [viewMessage, setViewMessage] = useState(false);
  const [selectStudent, setSelectStudent] = useState();

  async function getFormList() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/form-requests/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const formList = useQuery("/form-requests/", getFormList);

  async function fetchStudent() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/students/by-parent/`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data.result;
  }
  const students = useQuery("students/by-parent", fetchStudent);

  const handleDownload = async (e, requestId) => {
    e.preventDefault();
    axios({
      url: serverUrl + "/form-requests/" + requestId + "/download",
      method: "GET",
      responseType: "blob",
      headers: {
        Accept: "application/json",
        responseType: "blob",
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((response) => {
      saveAs(response.data, "student template");
    });
  };

  const columns = [
    {
      title: "Date Requested",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center"
        >
          {record?.createdAt
            ? moment(record?.createdAt).format("DD-MM-YYYY")
            : "-"}
        </div>
      ),
    },
    {
      title: "Form Type",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        const originalString = `${record?.form_type}`;
        const newString = originalString.replace(/_/g, " ");
        return (
          <div
            key={record}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {newString ? newString : "--"}
          </div>
        );
      },
    },
    {
      title: "Student Details",
      dataIndex: [],
      key: "status",
      align: "center",
      render: (record, _, index) => {
        return (
          <div
            key={index}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            <p className="flex flex-col">
              <span>{`${record?.student?.student?.first_name} ${record?.student?.student?.last_name}`}</span>
              <span>{`${record?.student?.student?.unique_id}`}</span>
              <span>{`${record?.student?.student?.class?.name}`}</span>
            </p>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.status}
        </div>
      ),
    },
    {
      title: "Reason",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        if (!record) {
          return (
            <div className="text-base text-gray-400 cursor-not-allowed">
              View
            </div>
          );
        }

        return (
          <div
            className="text-base font-semibold text-center text-blue-500 cursor-pointer"
            onClick={() => {
              setSelectedRecord(record);
              setViewMessage(true);
            }}
          >
            View
          </div>
        );
      },
    },
    {
      title: "Download",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div className=" text-base text-center">
          {record?.status === "approved" ? (
            <DownloadOutlined
              color="primary"
              onClick={(e) => handleDownload(e, record?._id)}
            />
          ) : (
            "   --"
          )}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record, index) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center cursor-pointer text-blue-500 "
          onClick={() => {
            setIsEditMode(true);
            setEditData({ [index?._id]: record });
            setApplyLeavePopup(true);
          }}
        >
          <span className="text-blue-500">
          Edit

          </span>
        </div>
      ),
    },
  ];
  const handleSelectClassChange = (studentId) => {
    const student =
      students?.data &&
      students?.data.length > 0 &&
      students?.data?.find((data) => data?._id === studentId);
    if (student) {
      setSelectStudent(student?.class?.name);
      form.setFieldsValue({
        student_name: studentId, // Set the student ID here
      });
    }
  };
  const onFinish = (values) => {
    const payload = {
      student: values?.student_name,
      form_type: values?.type,
      reason: values?.description,
    };
    if (payload) {
      if (isEditMode) {
        axios
          .put(
            `${serverUrl}/form-requests/${Object.keys(editData)[0]}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "jwt-token"
                )}`,
              },
            }
          )
          .then((res) => {
            form.resetFields();
            setApplyLeavePopup(false);
            setEditData({});
            setIsEditMode(false);
            queryClient.invalidateQueries("/form-requests/");
            message.success(res?.data?.result?.message);
          })
          .catch((err) => {
            if (err?.response?.data?.error?.errors) {
              message.error(err?.response?.data?.error?.errors);
            }
          });
      } else {
        axios({
          method: "post",
          url: serverUrl + "/form-requests/",
          data: payload,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
          .then((res) => {
            queryClient.invalidateQueries("/form-requests/");
            form.resetFields();
            setApplyLeavePopup(false);
            message.success(res?.data?.result?.message);
          })
          .catch((error) => {
            message.error(error?.response?.data?.error?.errors);
          });
      }
    }
  };

  useEffect(() => {
    if (isEditMode) {
      const { form_type, reason, student } = editData[Object.keys(editData)[0]];
      const selectedStudent =
        students?.data?.find((data) => data?._id === student?.student?._id) ||
        {};
      form.setFieldsValue({
        student_name: student?.student?._id,
        type: form_type,
        description: reason,
        class: student?.student?.class?.name,
        request_name: `${student?.student?.parent?.first_name} ${student?.student?.parent?.last_name}`,
      });

      setSelectStudent(selectedStudent?.class?.name);
    } else setSelectStudent("");
  }, [isEditMode]);

  async function fetchFormType(id) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/form-template/?school=${id}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const formTypeList = useQuery(
    ["subjects", user?.school?._id],
    () => user?.school?._id && fetchFormType(user?.school?._id),
    {
      enabled: !!user?.school?._id,
    }
  );

  return (
    <div>
      {" "}
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex justify-between items-center">
            <div className="text-2xl text-orange-500 font-semibold">
              Manage Form
            </div>
            <div className="text-right pt-4">
              <Button
                type="primary"
                size="large"
                onClick={() => setApplyLeavePopup(true)}
              >
                Add
              </Button>
            </div>
          </div>
          <div className="py-5 table-overflow">
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={
                formList?.isSuccess &&
                formList?.data?.result?.data?.length > 0 &&
                formList?.data?.result?.data
                  ? formList?.data?.result?.data
                  : []
              }
              pagination={true}
              loading={formList?.isLoading}
            />
          </div>

          <Modal
            width={450}
            visible={applyLeavePopup}
            onOk={() => setApplyLeavePopup(false)}
            onCancel={() => {
              form.resetFields();
              setApplyLeavePopup(false);
              setEditData({});
              setIsEditMode(false);
              setSelectStudent("");
              form.resetFields();
            }}
            footer={null}
            forceRender
          >
            <h3 className="text-lg font-bold pb-3">
              {isEditMode ? "Update Request Form" : " Request Form"}
            </h3>
            <div className="bg-white rounded-2xl ">
              <Form
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{
                  request_name: `${user?.parent?.first_name} ${user?.parent?.last_name}`,
                }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  label="Name"
                  name="student_name"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please select date",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select name"
                    className="w-full bg-gray-300"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(value) => handleSelectClassChange(value)}
                  >
                    {students &&
                      students?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {`${data?.first_name} ${data?.last_name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item label="Class" className="w-full">
                  <Input readonly value={selectStudent} placeholder="Class"/>
                </Form.Item>
                <Form.Item
                  label="Request Name"
                  name="request_name"
                  className="w-full"
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Application type is required",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    placeholder="Select type"
                    className="capitalize"
                  >
                    {formTypeList?.isSuccess &&
                      formTypeList?.data?.result?.data?.map((data) => {
                        const originalString = `${data?.form_type}`;
                        const newString = originalString.replace(/_/g, " ");
                        return (
                          <Option key={data._id} value={data.form_type}>
                            {newString}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Reason"
                  name="description"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value && value.length <= 150) {
                          return Promise.resolve(); // Validation passed
                        }
                        return Promise.reject(
                          "Description must be at most 150 characters"
                        );
                      },
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={3}
                    placeholder="Reason"
                    className="w-full border focus:outline-none resize-none"
                    maxLength={150} // Limit the input to 150 characters visually
                  />
                </Form.Item>
                <div className="text-center">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    {isEditMode ? "Update" : " Submit"}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <Modal
            width={800}
            visible={viewMessage}
            onCancel={() => {
              setViewMessage(false);
            }}
            footer={null}
          >
            {selectedRecord?.reason ? (
              <div className="pt-4 text-base">{selectedRecord?.reason}</div>
            ) : (
              <div className="pt-5 text-base">No Message</div>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ParentManageForm;
