import { DeleteOutlined } from "@ant-design/icons";
import { Input, Modal, Select, message } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsPencil } from "react-icons/bs";
import { HiOutlineCamera } from "react-icons/hi";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IconButton from "../../../components/buttons/IconButton";
import ImageUploadButton from "../../../components/inputs/ImageUploadButton";
import FeedSection from "../../../components/sections/FeedSection";
import { cloudFrontUrl, serverUrl } from "../../../nestserver";
import { selectUser } from "../../../redux/slices/auth.slice";
import fupload from "../../../resources/feather-upload.svg";
import AdminProfile from "./form/AdminProfile";
import Announcement from "./form/Announcement";

function MainProfile() {
  const { TextArea } = Input;
  const { Option } = Select;
  const history = useHistory();
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();

  const [images, setImages] = useState({
    profile_image: null,
    banner_image: null,
  });

  const [bgmodal, setBgModal] = useState(false);
  const [profilemodal, setProfileModal] = useState(false);

  const [editmodal, setEditModal] = useState(false);
  function updateProfile() {
    axios({
      method: "patch",
      url: serverUrl + "/admin/" + user?.admin?._id,
      data: {
        ...images,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Profile Updated");
        queryClient.invalidateQueries("user");
        setProfileModal(false);
        setBgModal(false);
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  async function getAnnouncement() {
    const data = await axios({
      method: "get",
      url: serverUrl + "/announcement",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return data.data;
  }

  const announcement = useQuery("announcement", getAnnouncement);

  useEffect(() => {
    if (user?.admin) {
      setImages((i) => ({
        ...i,
        profile_image: user?.admin?.profile_image,
        banner_image: user?.admin?.banner_image,
      }));
    }
  }, [user]);

  return (
    <>
      <div>
        <div
          style={{ borderRadius: "10px" }}
          className=" bg-white drop-shadow-md"
        >
          <div
            style={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              backgroundImage: `url("${
                images.banner_image
                  ? cloudFrontUrl + "/" + user?._id + "/" + images.banner_image
                  : "https://ui-avatars.com/api/?name=" +
                    (user.admin
                      ? user.admin?.first_name + "+" + user.admin?.last_name
                      : user?.username)
              }")`,
            }}
            className="h-52 bg-no-repeat bg-cover relative"
          >
            <div className="absolute top-5 right-5 ">
              <IconButton
                icon={<BsPencil />}
                onClick={() => setBgModal(true)}
              />
            </div>

            {/* -------------------------------------------background modal---------------------------------- */}
            <Modal
              width={800}
              visible={bgmodal}
              onOk={() => setBgModal(false)}
              onCancel={() => setBgModal(false)}
              footer={null}
            >
              <div>
                <div className="py-2">
                  <div className="text-2xl font-bold text-black pb-4 capitalize">
                    Cover Photo
                  </div>

                  <div className="flex items-center justify-center">
                    <div className="relative w-full h-42 rounded-lg">
                      <img
                        className="w-full h-full rounded-lg object-contain"
                        src={
                          images?.banner_image
                            ? cloudFrontUrl +
                              "/" +
                              user?._id +
                              "/" +
                              images?.banner_image
                            : "https://ui-avatars.com/api/?name=" +
                              (user.admin
                                ? user.admin?.first_name +
                                  "+" +
                                  user.admin?.last_name
                                : user?.username)
                        }
                        alt="banner"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center pt-3">
                  <div className="flex flex-row space-x-5">
                    <ImageUploadButton
                      ratio={9 / 2.5}
                      folder={user?._id}
                      onUpload={(e) =>
                        setImages((i) => ({
                          ...i,
                          banner_image: e,
                        }))
                      }
                    >
                      <div
                        style={{ color: "#616161" }}
                        className="flex flex-row space-x-4 cursor-pointer"
                      >
                        <img src={fupload} alt="" />
                        <div className="text-lg">Upload photo</div>
                      </div>
                    </ImageUploadButton>
                    <Button
                      onClick={(e) => {
                        setImages((i) => ({
                          ...i,
                          banner_image: null,
                        }));
                        message.success("Cover Photo Removed");
                      }}
                      type="danger"
                      className="flex items-center"
                      icon={<DeleteOutlined />}
                    >
                      Remove
                    </Button>
                  </div>

                  <div className="inline-flex items-center space-x-2">
                    <Button
                      type="dark"
                      onClick={() => {
                        setBgModal(false);
                        setImages((i) => ({
                          ...i,
                          banner_image: user?.admin?.banner_image,
                        }));
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="text-white"
                      onClick={() => updateProfile()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>

          {/* ---------------------------------------------------- */}
          <div className="w-full h-55 md:px-8 px-4 py-4 relative pt-16 z-0">
            <div
              className="absolute left-5"
              style={{ transform: `translateY(-110%)` }}
            >
              <div className="relative w-44 h-44 rounded-full  border-8 border-white">
                <img
                  className="w-full h-full rounded-full"
                  src={
                    user?.admin?.profile_image
                      ? cloudFrontUrl +
                        "/" +
                        user?._id +
                        "/" +
                        user?.admin?.profile_image
                      : "https://ui-avatars.com/api/?name=" +
                        (user.admin
                          ? user.admin?.first_name + "+" + user.admin?.last_name
                          : user?.username)
                  }
                  alt="banner"
                />
                <div
                  className="absolute bottom-0"
                  style={{
                    left: "35%",
                    transform: "translateX(-50%)",
                    transform: "translateY(50%)",
                  }}
                >
                  <IconButton
                    icon={<HiOutlineCamera size={20} />}
                    onClick={() => setProfileModal(true)}
                  />
                </div>
              </div>
            </div>
            <div className="md:text-xl text-lg font-bold py-4">
              {user?.admin?.first_name} &nbsp;
              {user?.admin?.last_name}{" "}
            </div>
            <div className="font-normal md:text-lg text-base w-full">
              {user?.admin?.headline}
            </div>
            <div className="font-bold text-sm">
              {user?.admin?.region && user?.admin?.region + ", "}{" "}
              {user?.admin?.country}
            </div>
            <div className="absolute top-5 right-5 ">
              <IconButton
                icon={<BsPencil />}
                onClick={() => setEditModal(true)}
              />
            </div>
          </div>
          {/* --------------------------------------------profile modal--------------------------------------- */}

          <Modal
            width={800}
            visible={profilemodal}
            onOk={() => setProfileModal(false)}
            onCancel={() => setProfileModal(false)}
            footer={null}
          >
            <div>
              <div className="py-2">
                <div className="text-2xl font-bold text-black pb-4 capitalize">
                  profile photo
                </div>

                <div className="flex items-center justify-center">
                  <div className="relative w-44 h-44 rounded-full  border-8 border-white">
                    <img
                      className="w-full h-full rounded-full"
                      src={
                        images?.profile_image
                          ? cloudFrontUrl +
                            "/" +
                            user?._id +
                            "/" +
                            images?.profile_image
                          : "https://ui-avatars.com/api/?name=" +
                            (user.admin
                              ? user.admin?.first_name +
                                "+" +
                                user.admin?.last_name
                              : user?.username)
                      }
                      alt="banner"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center pt-3">
                <div className="flex flex-row space-x-5">
                  <ImageUploadButton
                    folder={user?._id}
                    onUpload={(e) =>
                      setImages((i) => ({
                        ...i,
                        profile_image: e,
                      }))
                    }
                  >
                    <div
                      style={{ color: "#616161" }}
                      className="flex flex-row space-x-4 cursor-pointer"
                    >
                      <img src={fupload} alt="" />
                      <div className="text-lg">Upload photo</div>
                    </div>
                  </ImageUploadButton>
                  <Button
                    onClick={(e) => {
                      setImages((i) => ({
                        ...i,
                        profile_image: null,
                      }));
                      message.success("Profile Photo Removed");
                    }}
                    type="danger"
                    className="flex items-center"
                    icon={<DeleteOutlined />}
                  >
                    Remove
                  </Button>
                </div>

                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => {
                      setProfileModal(false);
                      setImages((i) => ({
                        ...i,
                        profile_image: user?.admin?.profile_image,
                      }));
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                    onClick={() => updateProfile()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            width={800}
            visible={editmodal}
            footer={null}
            onOk={() => setEditModal(false)}
            onCancel={() => setEditModal(false)}
          >
            <AdminProfile
              data={user?.admin}
              closeModel={() => setEditModal(false)}
            />
          </Modal>
          {/* ------------------------------------------------------------ */}
        </div>
      </div>
      {/* --------------------------------------second section---------------------------------------------------------- */}

      <Announcement />
      <div className="py-10">
        <div className="bg-white w-full rounded-2xl bg-opacity-40 px-4 pb-4">
          <div
            style={{ color: "#E46E31" }}
            className=" text-orange-500 text-2xl font-semibold p-4 py-6"
          >
            School Post
          </div>
          <div className="space-y-5 ">
            {announcement?.isSuccess &&
              announcement?.data?.map((item, index) => {
                return (
                  <FeedSection
                    enableDelete={true}
                    key={item?._id}
                    data={item}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default MainProfile;
