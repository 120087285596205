import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import { useQuery } from "react-query";
import MultipleUpload from "../../../../../components/inputs/UploadMultipleFile";
import { serverUrl } from "../../../../../nestserver";
const { Option } = Select;
const ViewEditStudentDetails = () => {
  let { id } = useParams();
  const history = useHistory();
  const [formFields, setFormFields] = useState([]);
  const [form] = Form.useForm();
  const { Title } = Typography;
  const [secondaryContactCheckbox, setSecondaryContactCheckbox] =
    useState(false);
  const [parentFileList, setParentFileList] = useState([]);
  const [responseData, setResponseData] = useState();
  const handleFileListChange = (fileList) => {
    setParentFileList(fileList);
  };

  const handleAddForm = () => {
    setFormFields([...formFields, {}]);
  };

  const handleRemoveForm = (index) => {
    const updatedFields = [...formFields];
    updatedFields.splice(index, 1);
    setFormFields(updatedFields);
  };
  const validateAlphabetsOnly = (_, value) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(value)) {
      return Promise.reject("Please enter alphabets only");
    }
    return Promise.resolve();
  };

  const [uploadAdmissionPopup, setUploadAdmissionPopup] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    relation: "",
    occupation: "",
    education: "",
    annualIncome: "",
    phone: "",
    email: "",
    aadhar: "",
    address: "",
    emergencyContact: "",
  });

  useEffect(() => {
    if (id) {
      const fetchApplication = async () => {
        const res = await axios({
          method: "get",
          url: serverUrl + "/students/" + id + "/parent",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        });
        return res.data;
      };
      fetchApplication().then((response) => {
        setResponseData(response?.result);
        form.setFieldsValue({
          studentId: response?.result?.unique_id,
          name: `${response?.result?.first_name} ${response?.result?.last_name}`,
          class: response?.result?.class?.name,
          address: response?.result?.primary_contact?.address,
          registered: response?.result?.parent?.user[0]?.is_registered,
          addSameAsAbove: response?.result?.is_contacts_matching,
          primary_contact_first_name:
            response?.result?.primary_contact?.first_name,
          primary_contact_last_name:
            response?.result?.primary_contact?.last_name,
          primary_contact_relation: response?.result?.primary_contact?.relation,
          primary_contact_education:
            response?.result?.primary_contact?.education,
          primary_contact_occupation:
            response?.result?.primary_contact?.occupation,
          primary_contact_annual_income:
            response?.result?.primary_contact?.annual_income,
          primary_contact_phone: response?.result?.primary_contact?.contact,
          primary_contact_email: response?.result?.primary_contact?.email,
          primary_contact_aadhar: response?.result?.primary_contact?.aadhar,
          primary_contact_address: response?.result?.primary_contact?.address,
          primary_emergency_contact:
            response?.result?.primary_contact?.emergency_contact,
          secondary_contact_first_name:
            response?.result?.secondary_contact?.first_name,
          secondary_contact_last_name:
            response?.result?.secondary_contact?.last_name,
          secondary_contact_relation:
            response?.result?.secondary_contact?.relation,
          secondary_contact_education:
            response?.result?.secondary_contact?.education,
          secondary_contact_annual_income:
            response?.result?.secondary_contact?.annual_income,
          secondary_contact_occupation:
            response?.result?.secondary_contact?.occupation,
          secondary_contact_phone: response?.result?.secondary_contact?.contact,
          secondary_contact_email: response?.result?.secondary_contact?.email,
          secondary_contact_aadhar: response?.result?.secondary_contact?.aadhar,
          secondary_emergency_contact:
            response?.result?.secondary_contact?.emergency_contact,
          secondary_contact_address:
            response?.result?.secondary_contact?.address,
        });
        setSecondaryContactCheckbox(response?.result?.is_contacts_matching);
        response?.result?.existing_siblings?.forEach((sibling, index) => {
          form.setFieldsValue({
            [`name${index + 1}`]: sibling?.name,
            [`student_id${index + 1}`]: sibling?.student_id,
            [`class${index + 1}`]: sibling?.class?.name,
          });
        });
        if (
          response?.result?.documents &&
          response?.result?.documents?.length > 0
        ) {
          setParentFileList(response?.result?.documents);
        }
        setFormFields(response?.result?.existing_siblings);
        if (response?.result?.is_contacts_matching) {
          setData({
            firstName: response?.result?.primary_contact?.first_name,
            lastName: response?.result?.primary_contact?.last_name,
            relation: response?.result?.primary_contact?.relation,
            occupation: response?.result?.primary_contact?.occupation,
            education: response?.result?.primary_contact?.education,
            annualIncome: response?.result?.primary_contact?.annual_income,
            phone: response?.result?.primary_contact?.contact,
            email: response?.result?.primary_contact?.email,
            aadhar: response?.result?.primary_contact?.aadhar,
            address: response?.result?.primary_contact?.address,
            emergencyContact:
              response?.result?.primary_contact?.emergency_contact,
          });
        }
      });
    }
  }, [id]);
  const handleRegister = () => {
    const phoneNumber = responseData?.primary_contact?.contact;
    axios
      .post(
        `${serverUrl}/parents/send-reset-link/?phone=${phoneNumber}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res?.data?.result?.message);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
        }
      });
  };

  const onFinish = (values) => {
    const existingSiblings = [];
    formFields.forEach((field, index) => {
      const sibling = {
        name: values[`name${index + 1}`],
        student_id: values[`student_id${index + 1}`],
        class: values[`class${index + 1}`],
      };
      existingSiblings.push(sibling);
    });
    // const existingSiblings = [];
    // formFields.forEach((field, index) => {
    //   const nameValue = values[`name${index + 1}`];
    //   const studentIdValue = values[`student_id${index + 1}`];
    //   if (nameValue.trim() !== "" || studentIdValue.trim() !== "") {
    //     const sibling = {
    //       name: nameValue,
    //       student_id: studentIdValue,
    //     };
    //     existingSiblings.push(sibling);
    //   }
    // });

    const dataToSend = {
      documents: parentFileList,
      primary_contact: {
        first_name: values.primary_contact_first_name,
        last_name: values.primary_contact_last_name,
        relation: values.primary_contact_relation,
        occupation: values.primary_contact_occupation,
        education: values.primary_contact_education,
        annual_income: values.primary_contact_annual_income,
        contact: values.primary_contact_phone,
        email: values.primary_contact_email,
        aadhar: values.primary_contact_aadhar,
        address: values.primary_contact_address,
        emergency_contact: values.primary_emergency_contact,
      },
      is_contacts_matching: secondaryContactCheckbox,
      // secondary_contact: {
      //   first_name: values.secondary_contact_first_name,
      //   last_name: values.secondary_contact_last_name,
      //   relation: values.secondary_contact_relation,
      //   occupation: values.secondary_contact_occupation,
      //   education: values.secondary_contact_education,
      //   annual_income: values.secondary_contact_annual_income,
      //   contact: values.secondary_contact_phone,
      //   email: values.secondary_contact_email,
      //   aadhar: values.secondary_contact_aadhar,
      //   address: values.secondary_contact_address,
      //   emergency_contact: values.secondary_emergency_contact,
      // },
      secondary_contact:
        values.secondary_contact_first_name ||
        values.secondary_contact_last_name ||
        values.secondary_contact_relation ||
        values.secondary_contact_occupation ||
        values.secondary_contact_education ||
        values.secondary_contact_annual_income ||
        values.secondary_contact_phone ||
        values.secondary_contact_email ||
        values.secondary_contact_aadhar ||
        values.secondary_contact_address ||
        values.secondary_emergency_contact
          ? {
              first_name: values.secondary_contact_first_name,
              last_name: values.secondary_contact_last_name,
              relation: values.secondary_contact_relation,
              occupation: values.secondary_contact_occupation,
              education: values.secondary_contact_education,
              annual_income: values.secondary_contact_annual_income,
              contact: values.secondary_contact_phone,
              email: values.secondary_contact_email,
              aadhar: values.secondary_contact_aadhar,
              address: values.secondary_contact_address,
              emergency_contact: values.secondary_emergency_contact,
            }
          : null,
      existing_siblings: existingSiblings,
    };
    if (id) {
      axios
        .put(serverUrl + "/students/" + id + "/parent", dataToSend, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          if (err?.response?.data?.error?.errors) {
            message.error(err?.response?.data?.error?.errors);
          }
        });
    }
  };
  //fetch classes
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  // const handleSaveAsAbove = (value) => {
  //   setSecondaryContactCheckbox(value);
  //   if (value) {
  //     form.setFieldsValue({
  //       secondary_contact_first_name: data?.firstName,
  //       secondary_contact_last_name: data?.lastName,
  //       secondary_contact_occupation: data?.occupation,
  //       secondary_contact_education: data?.education,
  //       secondary_contact_annual_income: data?.annualIncome,
  //       secondary_contact_phone: data?.phone,
  //       secondary_contact_email: data?.email,
  //       secondary_contact_aadhar: data?.aadhar,
  //       secondary_contact_address: data?.address,
  //       secondary_emergency_contact: data?.emergencyContact,
  //       secondary_contact_relation: data?.relation,
  //     });
  //   } else {
  //     form.setFieldsValue({
  //       secondary_contact_first_name: "",
  //       secondary_contact_last_name: "",
  //       secondary_contact_occupation: "",
  //       secondary_contact_education: "",
  //       secondary_contact_annual_income: "",
  //       secondary_contact_phone: "",
  //       secondary_contact_email: "",
  //       secondary_contact_aadhar: "",
  //       secondary_contact_address: "",
  //       secondary_emergency_contact: "",
  //       secondary_contact_relation: "",
  //     });
  //   }
  // };
  const handleSaveAsAbove = (value) => {
    setSecondaryContactCheckbox(value);
    if (value) {
      const primaryContactValues = form.getFieldsValue([
        "primary_contact_first_name",
        "primary_contact_last_name",
        "primary_contact_relation",
        "primary_contact_occupation",
        "primary_contact_education",
        "primary_contact_annual_income",
        "primary_contact_phone",
        "primary_contact_email",
        "primary_contact_aadhar",
        "primary_contact_address",
        "primary_emergency_contact",
      ]);

      form.setFieldsValue({
        secondary_contact_first_name:
          primaryContactValues?.primary_contact_first_name,
        secondary_contact_last_name:
          primaryContactValues?.primary_contact_last_name,
        secondary_contact_relation:
          primaryContactValues?.primary_contact_relation,
        secondary_contact_occupation:
          primaryContactValues?.primary_contact_occupation,
        secondary_contact_education:
          primaryContactValues?.primary_contact_education,
        secondary_contact_annual_income:
          primaryContactValues?.primary_contact_annual_income,
        secondary_contact_phone: primaryContactValues?.primary_contact_phone,
        secondary_contact_email: primaryContactValues?.primary_contact_email,
        secondary_contact_aadhar: primaryContactValues?.primary_contact_aadhar,
        secondary_contact_address:
          primaryContactValues?.primary_contact_address,
        secondary_emergency_contact:
          primaryContactValues?.primary_emergency_contact,
      });
    } else {
      form.setFieldsValue({
        secondary_contact_first_name: "",
        secondary_contact_last_name: "",
        secondary_contact_relation: "",
        secondary_contact_occupation: "",
        secondary_contact_education: "",
        secondary_contact_annual_income: "",
        secondary_contact_phone: "",
        secondary_contact_email: "",
        secondary_contact_aadhar: "",
        secondary_contact_address: "",
        secondary_emergency_contact: "",
      });
    }
  };
  const updateDataForm = (paramName, value) => {
    setData({ ...data, [paramName]: value });
  };
  return (
    <div>
      <div className="bg-white p-8 rounded-2xl m-32px">
        <div className="p-4 text-right">
          <Button size="large" onClick={() => history.goBack()}>
            Back
          </Button>
        </div>
        <Form
          form={form}
          name="dynamic_rule"
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="bg-white  rounded-2xl">
            <Title level={2}>Student</Title>

            <div className="admission-form ">
              <Form.Item name="studentId" label="Student Id" className="w-30">
                <Input placeholder="Please input your name" readOnly />
              </Form.Item>
              <Form.Item name="name" label="Name" className="w-30">
                <Input placeholder="Please input your name" readOnly />
              </Form.Item>
              <Form.Item label="Class" name="class" className="w-full">
                <Input readOnly />
              </Form.Item>
            </div>
            <div className="admission-form ">
              <Form.Item name="address" label="Address" className="w-30">
                <Input readOnly />
              </Form.Item>
            </div>
          </div>
          <div>
            <Title level={2}>Family(Primary)</Title>
            <div className="admission-form ">
              <Form.Item
                name="primary_contact_first_name"
                label="First Name"
                className="w-30"
                rules={[
                  {
                    required: true,
                    message: "First name is required",
                  },
                  {
                    validator: validateAlphabetsOnly,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    updateDataForm("firstName", e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="primary_contact_last_name"
                label="Last Name"
                className="w-30"
                rules={[
                  {
                    required: true,
                    message: "Last name is required",
                  },
                  {
                    validator: validateAlphabetsOnly,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    updateDataForm("lastName", e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="primary_contact_relation"
                label="Relation"
                className="w-30"
                rules={[
                  {
                    required: true,
                    message: "Relation is required",
                  },
                  {
                    validator: validateAlphabetsOnly,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    updateDataForm("relation", e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div className="admission-form ">
              <Form.Item
                name="primary_contact_occupation"
                label="Occupation"
                className="w-30"
              >
                <Input
                  onChange={(e) => {
                    updateDataForm("occupation", e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="primary_contact_education"
                label="Education"
                className="w-30"
              >
                <Input
                  onChange={(e) => {
                    updateDataForm("education", e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="primary_contact_annual_income"
                label="Annual Income"
                className="w-30"
                
              >
                <Input
                type="number"
                  onChange={(e) => {
                    updateDataForm("annualIncome", e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div className="admission-form ">
              <Form.Item
                name="primary_contact_phone"
                label="Phone"
                className="w-30"
                rules={[
                  {
                    required: true,
                    message: "Phone is required",
                  },
                  {
                    pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                    message: "Please enter a valid 10-digit phone number!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 10)}
              >
                <Input
                  onChange={(e) => {
                    updateDataForm("phone", e.target.value);
                  }}
                  type="number"
                  maxLength={10}
                  min={0}
                />
              </Form.Item>
              <Form.Item
                name="primary_contact_email"
                label="Email"
                className="w-30"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    updateDataForm("email", e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="primary_contact_aadhar"
                label="Aadhar"
                className="w-30"
                rules={[
                  {
                    pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                    message: "Please enter a valid 12-digit Number!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 12)}
              >
                <Input
                  onChange={(e) => {
                    updateDataForm("aadhar", e.target.value);
                  }}
                  maxLength={12}
                  type="number"
                  min={0}
                />
              </Form.Item>
            </div>
            <div className="admission-form ">
              <Form.Item
                name="primary_contact_address"
                label="Address"
                className="w-30"
                rules={[
                  {
                    required: true,
                    message: "Address is required",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    updateDataForm("address", e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="primary_emergency_contact"
                label="Emergency Contact"
                className="w-30"
                rules={[
                  {
                    pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                    message: "Please enter a valid 10-digit phone number!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 10)}
              >
                <Input
                  onChange={(e) => {
                    updateDataForm("emergencyContact", e.target.value);
                  }}
                  type="number"
                  maxLength={10}
                  min={0}
                />
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <Form.Item name="registered" valuePropName="checked">
              <Checkbox>Registered</Checkbox>
            </Form.Item>
            <Button htmlType="button" onClick={handleRegister}>
              Register/Reset
            </Button>
          </div>

          <div>
            <Title level={2}>Secondary Contact</Title>
            <Form.Item name="addSameAsAbove" valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  handleSaveAsAbove(e.target.checked);
                }}
                checked={secondaryContactCheckbox}
              >
                Add Same as above
              </Checkbox>
            </Form.Item>
            <div className="admission-form ">
              <Form.Item
                name="secondary_contact_first_name"
                label="First Name"
                className="w-30"
                rules={[
                  {
                    validator: validateAlphabetsOnly,
                  },
                ]}
                >
                <Input />
              </Form.Item>
              <Form.Item
                name="secondary_contact_last_name"
                label="Last Name"
                className="w-30"
                rules={[
                  {
                    validator: validateAlphabetsOnly,
                  },
                ]}
                >
                <Input />
              </Form.Item>
              <Form.Item
                name="secondary_contact_relation"
                label="Relation"
                className="w-30"
                rules={[
                  {
                    validator: validateAlphabetsOnly,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="admission-form ">
              <Form.Item
                name="secondary_contact_occupation"
                label="Occupation"
                className="w-30"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="secondary_contact_education"
                label="Education"
                className="w-30"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="secondary_contact_annual_income"
                label="Annual Income"
                className="w-30"
              >
                <Input  type="number"/>
              </Form.Item>
            </div>
            <div className="admission-form ">
              <Form.Item
                name="secondary_contact_phone"
                label="Phone"
                className="w-30"
                rules={[
                  {
                    pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                    message: "Please enter a valid 10-digit phone number!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 10)}
              >
                <Input type="number" maxLength={10} min={0} />
              </Form.Item>
              <Form.Item
                name="secondary_contact_email"
                label="Email"
                className="w-30"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="secondary_contact_aadhar"
                label="Aadhar"
                className="w-30"
                rules={[
                  {
                    pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                    message: "Please enter a valid 12-digit Number!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 12)}
              >
                <Input maxLength={12} type="number" min={0} />
              </Form.Item>
            </div>
            <div className="admission-form ">
              <Form.Item
                name="secondary_contact_address"
                label="Address"
                className="w-30"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="secondary_emergency_contact"
                label="Emergency Contact"
                className="w-30"
                rules={[
                  {
                    pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                    message: "Please enter a valid 10-digit phone number!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 10)}
              >
                <Input type="number" maxLength={10} min={0} />
              </Form.Item>
            </div>

            {/* modal */}
            <Modal
              width={800}
              visible={uploadAdmissionPopup}
              onOk={() => setUploadAdmissionPopup(false)}
              onCancel={() => {
                setUploadAdmissionPopup(false);
              }}
              footer={null}
            >
              <div>
                <MultipleUpload
                  onFileListChange={handleFileListChange}
                  parentResponse={parentFileList}
                />
              </div>
            </Modal>
          </div>
          <div>
            <Title level={2}>Sibling</Title>
            <div className="pb-3">
              {formFields?.length > 0 &&
                formFields.map((field, index) => (
                  <div
                    key={index}
                    className={`admission-form ${index === 0 ? "w-92" : ""}`}
                  >
                    <Form.Item
                      name={`name${index + 1}`}
                      label="Name"
                      className="w-92"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={`student_id${index + 1}`}
                      label="Student Id"
                      className="w-30"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={`class${index + 1}`}
                      label="Class"
                      className="w-30"
                    >
                      <Select
                        showSearch
                        showArrow
                        placeholder="Select Class"
                        className="w-full bg-gray-300"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {classes?.isSuccess &&
                          classes?.data?.map((data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    {index > 0 && (
                      <Button
                        type="danger"
                        onClick={() => handleRemoveForm(index)}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                ))}
              <Button type="primary" onClick={handleAddForm}>
                Add
              </Button>
            </div>
          </div>
          <Title level={2} className="pt-4">
            Document
          </Title>
          <div className="flex justify-between items-start">
            <div className="flex space-x-6">
              <Button
                size="large"
                type="primary"
                htmlType="button"
                className="text-white w-9"
                onClick={() => setUploadAdmissionPopup(true)}
              >
                Upload Document
              </Button>
            </div>
          </div>
          <div className="pt-4">
            {parentFileList && parentFileList.length > 0 && (
              <h5 className="pb-3 font-semibold text-base">Upload Preview</h5>
            )}
            {parentFileList &&
              parentFileList?.length > 0 &&
              parentFileList?.map((data, index) => {
                return (
                  <ui key={index}>
                    <li className="pb-2 capitalize">
                      {index + 1}.&nbsp;
                      {data?.files?.name}
                    </li>
                  </ui>
                );
              })}
          </div>
          <div className="flex justify-center items-center py-5">
            <div className="inline-flex items-center space-x-2">
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="text-white"
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ViewEditStudentDetails;
