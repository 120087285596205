import { DeleteOutlined } from "@ant-design/icons";
import { message, Popconfirm } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { FiPaperclip } from "react-icons/fi";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import FileUploadButton from "../../../../components/inputs/FileUploadButton";
import { cloudFrontUrl, serverUrl } from "../../../../nestserver";
import { selectUser } from "../../../../redux/slices/auth.slice";

function AssignmentEvaluationView({ id, onBack }) {
  const [openmodal, setOpenModal] = useState(false);
  // const { id } = useParams();
  const user = useSelector(selectUser);
  const [file, setFile] = useState([]);
  const queryClient = useQueryClient();

  // Assignment Submission
  async function getAssignmentSubmission() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/assignment-submissions/",
      params: {
        assignment: id,
        student: user?._id,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const assignmentSubmission = useQuery(
    ["assignment-submissions", id],
    getAssignmentSubmission,
    {
      enabled: !!id,
    }
  );

  // Fetch Assignment Details
  async function fetchAssignment() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/assignment/" + id,

      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const assignmentDetails = useQuery(["assignments", id], fetchAssignment, {
    enabled: !!id,
  });

  function handleSetFiles(files) {
    // append files to current file so that all the files have distinct id
    setFile((file) => {
      return [
        ...file,
        ...files.filter((f) => file.findIndex((f2) => f2.id === f.id) === -1),
      ];
    });
  }

  function removeFileItem(id) {
    const newFile = file.filter((f) => f.id !== id);
    setFile(newFile);
  }

  // Submit Assignment
  function submitAssignment() {
    const values = {
      assignment: id,
      student: user?._id,
      file,
      submitted_date: new Date().toISOString(),
    };
    if (assignmentSubmission.data?.length > 0) {
      axios
        .patch(
          serverUrl +
            "/assignment-submissions/" +
            assignmentSubmission.data[0]._id,
          {
            file,
            status: "submitted",
            enable_resubmission: false,
            request_resubmission: false,
            submitted_date: new Date().toISOString(),
          },
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
        .then((res) => {
          setOpenModal(false);
          queryClient.invalidateQueries("assignment-submissions");
          message.success("Assignment Submitted Successfully");
        })
        .catch((error) => {
          message.error("Error Submitting Assignment");
        });
    } else {
      axios
        .post(serverUrl + "/assignment-submissions", values, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          setOpenModal(false);
          queryClient.invalidateQueries("assignment-submissions");
          message.success("Assignment Submitted Successfully");
        })
        .catch((error) => {
          message.error("Error Submitting Assignment");
        });
    }
  }

  useEffect(() => {
    assignmentSubmission.data &&
      setFile(assignmentSubmission?.data[0]?.file ?? []);

    return () => {
      setFile([]);
    };
  }, [assignmentSubmission?.data]);

  return (
    <>
      <div>
        <div>
          <div className="pt-4 px-8">
            <div className="flex items-center justify-end">
              <Button onClick={onBack}>back</Button>
            </div>
          </div>
          <div className=" p-8 pt-0">
            {assignmentDetails.isSuccess && assignmentSubmission.isSuccess && (
              <div className="bg-white rounded-2xl p-8 space-y-5">
                {/* -------------------------------------------------sec 1---------------------------- */}
                <div>
                  <div className="flex flex-row items-start justify-between">
                    <div>
                      <div className="text-xl text-orange-500 text-medium">
                        {assignmentDetails?.data?.name}
                      </div>
                      <div className="pt-4 pb-2">
                        <h6 className="text-black font-normal text-base">
                          {assignmentDetails?.data?.modules?.map((module) => (
                            <span className="pr-2">{module?.title}</span>
                          ))}
                        </h6>
                        <p
                          style={{ color: "#616161" }}
                          className="text-sm font-normal pt-2"
                        >
                          Due for{" "}
                          {moment(assignmentDetails?.data?.last_date).format(
                            "Do MMMM YYYY"
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="space-y-3">
                      <div className="font-normal text-sm text-black text-right">
                        Score:{" "}
                        {assignmentSubmission?.isSuccess &&
                          (assignmentSubmission?.data[0]?.score ?? "_") +
                            " / " +
                            (assignmentDetails?.data?.score ?? "_")}
                      </div>
                      <div className="flex flex-row items-center justify-center space-x-3">
                        {assignmentSubmission?.isSuccess &&
                          assignmentSubmission?.data[0]?.submitted_date && (
                            <p className="text-black font-normal text-sm">
                              Last Submitted:{" "}
                              {assignmentSubmission?.data[0]?.submitted_date &&
                                moment(
                                  assignmentSubmission?.data[0]?.submitted_date
                                ).format("Do MMMM YYYY")}
                            </p>
                          )}
                        {moment()
                          .subtract(1, "d")
                          .isSameOrBefore(
                            assignmentDetails?.data?.last_date
                          ) ? (
                          <>
                            {assignmentSubmission?.isSuccess &&
                            assignmentSubmission?.data?.length > 0 ? (
                              <Button
                                disabled={
                                  !assignmentSubmission?.data[0]
                                    ?.enable_resubmission || !file?.length
                                }
                                type="primary"
                                onClick={() => submitAssignment(true)}
                              >
                                {assignmentSubmission?.data[0]
                                  ?.enable_resubmission
                                  ? "Re submit"
                                  : "Submitted"}
                              </Button>
                            ) : (
                              <Button
                                disabled={!file?.length}
                                type="primary"
                                onClick={() => submitAssignment(true)}
                              >
                                submit
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {assignmentSubmission?.isSuccess &&
                            assignmentSubmission?.data[0]?.score ? (
                              <Button
                                disabled={true}
                                type="warning"
                                onClick={() => setOpenModal(true)}
                              >
                                Evaluated
                              </Button>
                            ) : (
                              <Button
                                disabled={
                                  assignmentSubmission?.isSuccess &&
                                  !assignmentSubmission?.data[0]
                                    ?.enable_resubmission
                                }
                                type={
                                  assignmentSubmission?.isSuccess &&
                                  assignmentSubmission?.data[0]
                                    ?.enable_resubmission
                                    ? "primary"
                                    : "danger"
                                }
                                onClick={() => submitAssignment(true)}
                              >
                                {assignmentSubmission?.data[0]
                                  ?.enable_resubmission
                                  ? "Re submit"
                                  : "Due Date Passed"}
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-2xl font-bold text-primary-500 ">
                    Overview
                  </div>
                  <p
                    style={{ color: "#616161" }}
                    className="text-sm font-normal py-4"
                  >
                    {assignmentDetails.isSuccess &&
                      assignmentDetails?.data?.overview}
                  </p>
                </div>

                <div>
                  <div className="text-base font-bold text-primary-500 ">
                    Attachment
                  </div>
                  {assignmentDetails?.data?.file ? (
                    assignmentDetails?.data?.file.map((item) => (
                      <div
                        key={item?.id}
                        className="flex flex-row items-center space-x-3 py-2 "
                      >
                        <FiPaperclip />
                        <a
                          href={cloudFrontUrl + "/docs/" + item?.id}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item?.files?.name}
                        </a>
                      </div>
                    ))
                  ) : (
                    <div className="text-gray-500">No Attachments found</div>
                  )}
                </div>

                <div>
                  <div className="text-base font-bold text-primary-500 pb-3">
                    Submission{" "}
                    <span className="text-xs italic">
                      (only pdf files are allowed)
                    </span>
                  </div>
                  <div>
                    {user?.role !== "parent" && (
                      <FileUploadButton
                        accept=".pdf"
                        onUpload={(e) => handleSetFiles(e)}
                      >
                        <div className="flex items-center space-x-4">
                          <Button
                            disabled={
                              moment()
                                .subtract(1, "d")
                                .isSameOrBefore(
                                  assignmentDetails?.data?.last_date
                                )
                                ? assignmentSubmission?.data?.length === 0
                                  ? false
                                  : !assignmentSubmission?.data[0]
                                      ?.enable_resubmission
                                : true
                            }
                            type="primary"
                          >
                            Upload
                          </Button>
                        </div>
                      </FileUploadButton>
                    )}
                    <div className="space-y-3 pt-4">
                      {file &&
                        file?.map((item) => (
                          <div
                            className="font-bold flex space-x-4"
                            key={item.id}
                          >
                            <a
                              className="p-1 px-3 bg-gray-100 rounded-lg"
                              href={cloudFrontUrl + "/docs/" + item?.id}
                              target="_blank"
                              style={{ maxWidth: "50%", overflow: "hidden" }}
                              rel="noreferrer"
                            >
                              {item.files?.name}
                            </a>
                            <div>
                              <Popconfirm
                                title="Are you sure？"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => removeFileItem(item.id)}
                              >
                                <Button
                                  disabled={
                                    assignmentSubmission?.data?.length === 0
                                      ? false
                                      : !assignmentSubmission?.data[0]
                                          ?.enable_resubmission
                                  }
                                  type="danger"
                                  shape="circle"
                                  className="flex items-center justify-center"
                                >
                                  <DeleteOutlined className="cursor-pointer " />
                                </Button>
                              </Popconfirm>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-base font-bold text-primary-500 ">
                    Remarks
                  </div>
                  {assignmentSubmission?.data[0]?.remarks ? (
                    <div className="flex flex-row items-center space-x-3 py-2 ">
                      {assignmentSubmission?.data[0]?.remarks}
                    </div>
                  ) : (
                    <div className="text-gray-500">No remarks available</div>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* 
          <div className=" pb-8  ">
            <div className="bg-white rounded-2xl p-8 space-y-5">
              <div className="text-xl text-orange-500 text-medium">
                Rubric
              </div>

              <ul className="list-inside list-decimal">
                <li>Lorem ipsum dolor sit amet, </li>
                <li>Rprehenderit in voluptate</li>
                <li>Excepteur sint occaecat</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default AssignmentEvaluationView;
