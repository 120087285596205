import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";

function SubjectMain() {
  const { Search } = Input;
  const { Option } = Select;
  const history = useHistory();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [addSubject, setAddSubject] = useState(false);
  const [subjectCheckboxPayload, setSubjectCheckboxPayload] = useState({
    is_mandatory: false,
    is_language: false,
    is_co_curricular: false,
  });

  // fetch subjects
  async function fetchSubjects() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/subjects/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const subjects = useQuery("subjects", fetchSubjects);

  // fetch classes
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);
  const handleCheckbox = (event) => {
    const selectedValue = event.target.value;
    let updatedPayload = {
      is_mandatory: false,
      is_language: false,
      is_co_curricular: false,
    };

    switch (selectedValue) {
      case "mandatory":
        updatedPayload.is_mandatory = true;
        break;
      case "languages":
        updatedPayload.is_language = true;
        break;
      case "co-curricular":
        updatedPayload.is_co_curricular = true;
        break;
      default:
        break;
    }

    setSubjectCheckboxPayload(updatedPayload);
  };

  // fetch departments
  async function fetchDepartments() {
    const response = await axios.get(serverUrl + "/department", {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return response.data;
  }
  const departments = useQuery("departments", fetchDepartments);

  // Table Settings

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [searchInput, setSearchInput] = useState(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search Subject`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    // {
    //   title: "Subject",
    //   dataIndex: ["name"],
    //   width: 250,
    //   render: (record) => (
    //     <div
    //       key={record?._id}
    //       style={{ color: "#616161", width: "100px" }}
    //       className=" text-base font-semibold line-clamp-2"
    //     >
    //       {record?.name}
    //     </div>
    //   ),
    //   ...getColumnSearchProps("name"),
    // },
    {
      title: "Subject",
      dataIndex: ["name"],
      width: 250,
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#616161", width: "100px" }}
          className=" text-base font-semibold line-clamp-2"
        >
          {record?.name}
        </div>
      ),
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Class",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.class?.name}
        </div>
      ),
      filters: classes.isSuccess
        ? classes?.data?.map((c) => ({ text: c.name, value: c?._id }))
        : [],
      onFilter: (value, record) => record?.class?._id === value,
      sorter: (a, b) => {
        const classNameA = a.class?.name || "";
        const classNameB = b.class?.name || "";

        const numericPartA = classNameA.replace(/\D/g, "");
        const numericPartB = classNameB.replace(/\D/g, "");

        return numericPartA.localeCompare(numericPartB, undefined, {
          numeric: true,
        });
      },
    },
    {
      title: "Instructor Name",
      // dataIndex: "class['name']",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.teacher?.teacher?.first_name}{" "}
          {record?.teacher?.teacher?.last_name}
        </div>
      ),
    },
    {
      title: "Action",
      // dataIndex: "action",
      key: "action",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          className=" text-base font-semibold text-center text-blue-500 cursor-pointer"
          onClick={() =>
            history.push(`/dashboard/admin/menu/subject/profile/${record?._id}`)
          }
        >
          Click to view
        </div>
      ),
    },
  ];

  function createSubject(values) {
    setAddSubject(false);
    const data = { ...values, ...subjectCheckboxPayload };
    axios
      .post(serverUrl + "/subjects", data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("Subject Created");
        form.resetFields();
        queryClient.invalidateQueries("subjects");
      })
      .catch((error) => {
        if (error?.response?.data) {
          message.error(error?.response?.data.message);
        } else {
          message.error(error.message);
        }
      });
  }

  return (
    <>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex items-center justify-between">
             <div className="text-2xl text-orange-500 font-semibold">
              Manage Subjects
            </div>
            <div className=" flex space-x-5">
              <Button
                onClick={() => setAddSubject(true)}
                type="primary"
                className="h-10"
              >
                Add Subject
              </Button>

              <div compact className="flex flex-row items-center hidden">
                <Select
                  size="large"
                  defaultValue="All"
                  style={{ width: "30%" }}
                >
                  <Option value="all">All</Option>
                  <Option value="one">one</Option>
                </Select>
                <Search
                  placeholder="input search text"
                  enterButton="Search"
                  size="large"
                  loading={false}
                  style={{ backgroundColor: "#E3E3E3" }}
                  prefix={<BiSearch className="text-gray-600 " />}
                  // onSearch={(e) => setSearch(e)}
                />
              </div>
            </div>
          </div>
          <div className="py-5">
            <Table
              columns={columns}
              dataSource={
                subjects?.isSuccess
                  ? subjects?.data?.sort((a, b) =>
                      moment(a?.createdAt).isBefore(b?.createdAt) ? 1 : -1
                    )
                  : []
              }
              pagination={true}
              loading={subjects?.isLoading}
            />
          </div>
        </div>
      </div>
      {/* ----------------------------------modal------------------------------------ */}

      <Modal
        width={800}
        visible={addSubject}
        onCancel={() => {
          setAddSubject(false);
          form.resetFields();
        }}
        footer={null}
      >
        <div>
          <div className="py-2">
            <div className="text-2xl font-bold text-black pb-4 capitalize">
              Add Subject
            </div>
          </div>
          {/* ---------------------------form---------------- */}
          <div>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              onFinish={createSubject}
              autoComplete="off"
            >
              <Form.Item
                label="Subject Name"
                name="name"
                rules={[
                  { required: true, message: "Please input name for subject" },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>
              <Form.Item
                name="options"
                onChange={handleCheckbox}
                rules={[{ required: true, message: "Please select an option" }]}
              >
                <Radio.Group>
                  <Radio value="mandatory">Mandatory</Radio>
                  <Radio value="languages">Languages</Radio>
                  <Radio value="co-curricular">Co-Curricular</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Class"
                name="class"
                className="w-full "
                rules={[
                  {
                    required: true,
                    message: "Please select a class for the subject",
                  },
                ]}
              >
                <Select
                  showSearch
                  showArrow
                  allowClear
                  placeholder="Select Class"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  style={{
                    border: "6px",
                    backgroundColor: "#EBEBEB",
                  }}
                >
                  {classes.isSuccess &&
                    classes?.data?.map((data) => {
                      return (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Department"
                name="department"
                className="w-full "
                rules={[
                  {
                    required: true,
                    message: "Please select a department for the subject",
                  },
                ]}
              >
                <Select
                  showSearch
                  showArrow
                  allowClear
                  placeholder="Select Department"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  style={{
                    border: "6px",
                    backgroundColor: "#EBEBEB",
                  }}
                >
                  {departments.isSuccess &&
                    departments?.data?.map((data) => {
                      return (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <div className="flex justify-end items-center pt-6">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => {
                      setAddSubject(false);
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Add Subject
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          {/* ----------------------------------------------- */}
        </div>
      </Modal>
    </>
  );
}

export default SubjectMain;
