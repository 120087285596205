import React, { useState } from "react";

import AddEditBoarding from "./AddEditBoarding";
import AddEditBuses from "./AddEditBuses";
import AddEditDriver from "./AddEditDriver";
import AddEditRoute from "./AddEditRoute";
import TransportationStatus from "./TransportationStatus";
import AddEditDevices from "./AddEditDevices";
import AddEditUser from "./AddEditUser";

function SwitchTransportation() {
  const [step, setStep] = useState(1);

  function switchTab() {
    switch (step) {
      case 1:
        return (
          <div>
            <TransportationStatus />
          </div>
        );

      case 2:
        return (
          <div>
            <AddEditBuses />
          </div>
        );

      case 3:
        return (
          <div>
            <AddEditDriver />
          </div>
        );

      case 4:
        return (
          <div>
            <AddEditRoute />
          </div>
        );

      case 5:
        return (
          <div>
            <AddEditBoarding />
          </div>
        );
      case 6:
        return (
          <div>
            <AddEditUser />
          </div>
        );
      case 7:
        return (
          <div>
            <AddEditDevices />
          </div>
        );

      default:
        return (
          <div>
            <TransportationStatus />
          </div>
        );
    }
  }

  return (
    <>
      {/* {subject.isSuccess ? ( */}
      <div className="p-8">
        <div className="flex gap-6 bg-white rounded-lg ">
          <div
            onClick={() => setStep(1)}
            className={`iskool-tab-item ${
              step === 1 ? "bg-gray-600 text-white shadow text-left " : ""
            }`}
          >
            <div className="text-left">
              <div className=" text-left">Status</div>
              {/* <div className="text-xs text-left">Overview</div> */}
            </div>
          </div>
          <div
            onClick={() => setStep(2)}
            className={`iskool-tab-item ${
              step === 2 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Buses</div>
          </div>

          <div
            onClick={() => setStep(3)}
            className={`iskool-tab-item ${
              step === 3 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Driver</div>
          </div>
          <div
            onClick={() => setStep(4)}
            className={`iskool-tab-item ${
              step === 4 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Routes</div>
          </div>
          <div
            onClick={() => setStep(5)}
            className={`iskool-tab-item ${
              step === 5 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Onboard</div>
          </div>
          <div
            onClick={() => setStep(6)}
            className={`iskool-tab-item ${
              step === 6 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg"> User</div>
          </div>
          <div
            onClick={() => setStep(7)}
            className={`iskool-tab-item ${
              step === 7 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg"> Devices</div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------- */}
        <div>
          <div>{switchTab()}</div>
        </div>
      </div>
      {/* ) : (
        ""
      )} */}
    </>
  );
}

export default SwitchTransportation;
