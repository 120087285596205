import { Form, Input, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineFilePdf } from "react-icons/ai";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { cloudFrontUrl, serverUrl } from "../../../../nestserver";
import { selectUser } from "../../../../redux/slices/auth.slice";

const HomeWorkBroadcast = () => {
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const [subjectId, setSubjectId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const isTeacher = false;
  const messageContainerRef = React.useRef(null);
  const [classSubjectData, setClassSubjectData] = useState([]);
  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  const handleImageClick = (src) => {
    setModalImageSrc(src);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalImageSrc("");
  };

  const handleSubjectChange = (subjectId) => {
    setSubjectId(subjectId);
  };

  //fetch class subjects
  const fetchClassSubject = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/students/" + user?._id + "/subject-teacher/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    setClassSubjectData(res?.data);
    return res.data;
  };
  const classSubjectDetails = useQuery(
    ["students", user?._id],
    fetchClassSubject,
    {
      enabled: !!user?._id,
    }
  );

  //get class broadcasting
  const fetchClassConversation = async (id) => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/conversations/subject/" + id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classConversationDetails = useQuery(
    ["conversations", subjectId],
    () => fetchClassConversation(subjectId),
    { enabled: !!subjectId }
  );

  useEffect(() => {
    scrollToBottom();
  }, [classConversationDetails]);

  return (
    <div>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full screen-height "
        >
          <div className="flex gap-4 min-height">
            <div style={{ width: "30%" }} className="message-left-part">
              <Form
                form={form}
                name="dynamic_rule"
                layout="vertical"
                autoComplete="off"
                initialValues={{
                  student: `${user?.student?.first_name} ${user?.student?.last_name}`,
                }}
              >
                <Form.Item name="student" label="Student Name" className="w-30">
                  <Input readOnly />
                </Form.Item>
                <Form.Item name="subject" label="Subject" className="w-30">
                  {classSubjectData &&
                    classSubjectData?.result?.subjects?.map((data) => (
                      <div
                        className="pt-2 text-base cursor-pointer"
                        key={data?._id}
                      >
                        <div
                          onClick={() => handleSubjectChange(data?._id)}
                          type="primary"
                          size="large"
                          className={`cursor-pointer ${
                            subjectId === data?._id
                              ? "active-message"
                              : "message-card"
                          }`}
                        >
                          <div className="flex justify-between">
                            <div>{data?.name}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Form.Item>
              </Form>
            </div>
            <div style={{ width: "70%" }} className="instructor-broadcast">
              {subjectId ? (
                <>
                  <div className="flex justify-between items-center pb-4">
                    <h1 className="pb-2 text-base font-semibold">
                      Class BroadCast
                    </h1>
                    <AiOutlineClose
                      size={20}
                      onClick={() => {
                        setSubjectId(null);
                        form.resetFields(["subject", "class"]);
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div
                    className="message-chat-content-class pt-8 "
                    style={{ width: "70%", marginLeft: "auto" }}
                    ref={messageContainerRef}
                  >
                    {classConversationDetails?.data?.result?.messages ? (
                      classConversationDetails?.data?.result?.messages?.map(
                        (message) => {
                          return (
                            <div key={message?._id}>
                              <div
                                className="flex items-center gap-4 pb-4 justify-end"
                                style={{ marginRight: "12px" }}
                              >
                                <div className="flex flex-col gap-4">
                                  <span>
                                    {message?.sender?.teacher &&
                                      `${message?.sender?.teacher?.first_name} ${message?.sender?.teacher?.last_name} `}
                                  </span>
                                  <span>
                                    {moment(message?.createdAt).format(
                                      "DD-MM-YYYY HH:MM"
                                    )}
                                  </span>
                                </div>

                                <div
                                  className={`${
                                    isTeacher
                                      ? "message-box"
                                      : "message-box-other"
                                  }`}
                                >
                                  {message?.media && (
                                    <div className="media-container">
                                      {message?.media.name
                                        ?.toLowerCase()
                                        .endsWith(".jpeg") ||
                                      message?.media.name
                                        ?.toLowerCase()
                                        .endsWith(".jpg") ||
                                      message?.media.name
                                        ?.toLowerCase()
                                        .endsWith(".png") ? (
                                        <>
                                          <img
                                            src={`${cloudFrontUrl}/docs/${message?.media.id}`}
                                            alt={message?.media.name}
                                            onClick={() =>
                                              handleImageClick(
                                                `${cloudFrontUrl}/docs/${message?.media.id}`
                                              )
                                            }
                                            className="media-image cursor-pointer"
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                "fallback-image-url";
                                            }}
                                          />
                                        </>
                                      ) : message?.media.name
                                          .toLowerCase()
                                          .endsWith(".pdf") ? (
                                        <a
                                          href={`${cloudFrontUrl}/docs/${message?.media.id}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="pdf-link"
                                        >
                                          <AiOutlineFilePdf size={35} />
                                          {message?.media.name}
                                        </a>
                                      ) : null}
                                    </div>
                                  )}
                                  {message?.message}

                                  <Modal
                                    visible={isModalVisible}
                                    footer={null}
                                    onCancel={handleCancel}
                                    centered
                                  >
                                    <img
                                      src={modalImageSrc}
                                      alt="Preview"
                                      style={{
                                        width: "100%",
                                        marginTop: "20px",
                                      }}
                                    />
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <div className="flex items-center h-full">
                        No message found
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center h-full w-full">
                  No message found
                </div>
              )}
            </div>
          </div>
          <div className="flex"></div>
        </div>
      </div>
    </div>
  );
};

export default HomeWorkBroadcast;
