import { Button, Input, Modal, Table } from "antd";
import axios from "axios";
import * as moment from "moment";
import React, { useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useQuery } from "react-query";
import TransferStudentForm from "../../../../../components/forms/TransferStudentForm";
import { serverUrl } from "../../../../../nestserver";
import getStudentStatusColor from "../../../../../services/statusColor.service";

function ClassProfile({ classDetails }) {
  const [search, setSearch] = useState("");

  const [selectedStudents, setSelectedStudents] = useState(null);
  const [openTransfer, setOpenTransfer] = useState(false);
  // fetch Students
  async function getStudents() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/",
      params: {
        role: "student",
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const students = useQuery("students", getStudents);

  // tabel config

  const columns = [
    {
      title: (
        <div style={{ color: "#616161" }} className="font-semibold capitalize">
          Student Name
        </div>
      ),
      dataIndex: ["student", "first_name"],
      key: "",
      render: (value, record) => (
        <div
          key={record?._id}
          style={{ color: "#616161" }}
          className=" text-base font-semibold"
        >
          {record?.student?.first_name} {record?.student?.last_name}
        </div>
      ),
    },
    {
      title: (
        <div style={{ color: "#616161" }} className="font-semibold capitalize">
          Subjects
        </div>
      ),
      dataIndex: "class['name']",
      key: "",
      render: (item, record) => (
        <div
          key={record?._id}
          className=" text-sm text-white font-semibold text-left flex items-center space-x-3 flex-wrap"
        >
          {record?.student?.subjects?.map((s) => (
            <div
              className="rounded-lg bg-gray-100 p-2 inline-block"
              key={s._id}
            >
              <div
                className="inline-block px-2 rounded-lg capitalize"
                style={{
                  background: getStudentStatusColor(
                    record?.student?.subject_status &&
                      (record?.student?.subject_status[s?._id] ?? "on track")
                  ),
                  fontSize: "10px",
                }}
              >
                {record?.student?.subject_status
                  ? record?.student?.subject_status[s?._id] ?? "on track"
                  : "on track"}
              </div>
              <div
                style={{
                  color: getStudentStatusColor(
                    record?.student?.subject_status &&
                      (record?.student?.subject_status[s?._id] ?? "on track")
                  ),
                }}
              >
                {s.name}
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: (
        <div style={{ color: "#616161" }} className="font-semibold capitalize">
          Curricular
        </div>
      ),
      dataIndex: "class['name']",
      key: "",
      render: (item, record) => (
        <div
          key={record?._id}
          className=" text-sm  font-semibold text-left flex items-center space-x-3 flex-wrap"
        >
          {record?.student?.co_curricular?.map((s) => (
            <div
              className="rounded-lg bg-gray-100 p-2 inline-block"
              key={s._id}
              style={{
                background: "rgb(233, 196, 106)",
                color: "white",
                borderRadius: "40px",
              }}
            >
              {s?.name}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: (
        <div style={{ color: "#616161" }} className="font-semibold capitalize">
          Languages
        </div>
      ),
      dataIndex: "class['name']",
      key: "",
      render: (item, record) => (
        <div
          key={record?._id}
          className=" text-sm  font-semibold text-left flex items-center space-x-3 flex-wrap"
        >
          {record?.student?.languages?.map((s) => (
            <div
              className="rounded-lg bg-gray-100 p-2 inline-block"
              key={s._id}
              style={{
                background: "rgb(233, 196, 106)",
                color: "white",
                borderRadius: "40px",
              }}
            >
              {s?.name}
            </div>
          ))}
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      selectedRows?.length
        ? setSelectedStudents(selectedRows)
        : setSelectedStudents(null);
    },
    getCheckboxProps: (record) => ({
      // disabled: record?.student?.subject_status
      //   ? !Object.values(record?.student?.subject_status)?.every(
      //       (f) => f === "complete"
      //     )
      //   : true,
      // Column configuration not to be checked
      // name: record.name,
    }),
  };

  const { Search } = Input;

  const filteredStudents = useMemo(() => {
    let filtered = students.data;
    if (search) {
      filtered = filtered.filter(
        (f) =>
          f.student?.first_name?.toLowerCase().match(search?.toLowerCase()) ||
          f.student?.lastname_name
            ?.toLowerCase()
            .match(search?.toLowerCase()) ||
          (f.student.first_name + " " + f.student.last_name)
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }
    return filtered;
  }, [search, students?.data]);

  return (
    <>
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex items-center justify-between pb-6">
            <div className="text-2xl text-orange-500 font-semibold">
              Students
            </div>

            <div className="flex space-x-4">
              <Search
                placeholder="Search Student"
                enterButton="Search"
                // size="large"
                loading={false}
                style={{ backgroundColor: "#E3E3E3" }}
                prefix={<BiSearch className="text-gray-600 " />}
                onSearch={(e) => setSearch(e)}
                onChange={(e) => setSearch(e.target.value)}
              />
              {moment().isBetween(
                moment(classDetails?.transition_start_date),
                moment(classDetails?.transition_end_date)
              ) && (
                <div className=" inline-flex items-center justify-end">
                  <Button
                    onClick={() => setOpenTransfer(true)}
                    type="success"
                    // size="large"
                    disabled={!selectedStudents}
                  >
                    Transfer Students
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div style={{ color: "#74778C" }} className="font-normal text-lg">
            Class in Charge :{" "}
            <span>
              {classDetails?.in_charge?.teacher?.first_name}{" "}
              {classDetails?.in_charge?.teacher?.last_name}
            </span>
          </div>

          <div className="py-4">
            <Table
              bordered
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              rowKey="_id"
              columns={columns}
              dataSource={
                students?.isSuccess && filteredStudents
                  ? filteredStudents.filter(
                      (s) => s?.student?.class?._id === classDetails?._id
                    )
                  : []
              }
            />

            <Modal
              title="Transfer Students"
              visible={openTransfer}
              onCancel={() => setOpenTransfer(false)}
              footer={null}
            >
              <TransferStudentForm
                onClose={() => setOpenTransfer(false)}
                data={selectedStudents}
                classDetails={classDetails}
              />
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClassProfile;
