import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import Settings from "../instructor/pages/Settings";
import DocTemplate from "./pages/DocTemplate";
import HomePage from "./pages/HomePage";
import SwitchSchool from "./pages/SwitchSchool";
// import DocTemplate from "./pages/DocTemplate";

const SuperAdminRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/super-admin/school`}>
        <Switch>
          <Route exact path={`${url}/super-admin/school`}>
            <HomePage />
          </Route>
          <Route exact path={`${url}/super-admin/school/:id`}>
            <SwitchSchool />
          </Route>
          <Route exact path={`${url}/super-admin/school/doc/:id`}>
            <DocTemplate />
          </Route>
          <Route exact path={`${url}/super-admin/setting/`}>
            <Settings />
          </Route>
        </Switch>
      </Route>
      <Route exact path={url}>
        <Redirect to={`${url}/super-admin/school`} />
      </Route>
    </Switch>
  );
};

export default SuperAdminRoutes;
