import React, { useEffect, useState } from "react";
import ClassProfile from "./ClassProfile";
import ClassSetting from "./ClassSetting";
import ClassSubject from "./ClassSubject";
import { useParams } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "../../../../../nestserver";
import { useQuery } from "react-query";
import * as moment from "moment";

function SwitchClass() {
  const [step, setStep] = useState(1);
  const { id } = useParams();

  // fetch class
  const fetchClass = async () => {
    const res = await axios.get(serverUrl + "/classes/" + id, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classDetails = useQuery(["class", id], fetchClass);

  function switchTab() {
    switch (step) {
      case 1:
        return (
          <div>
            <ClassProfile classDetails={classDetails?.data} />
          </div>
        );

      case 2:
        return (
          <div>
            <ClassSubject classDetails={classDetails?.data} />
          </div>
        );

      case 3:
        return (
          <div>
            <ClassSetting
              classDetails={classDetails?.data}
            />
          </div>
        );

      default:
        return (
          <div>
            <ClassProfile classDetails={classDetails?.data} />
          </div>
        );
    }
  }

  return (
    <>
      {/* {subject.isSuccess ? ( */}
      <div className="sticky top-0 pt-2 px-8" style={{ zIndex: 1000 }}>
        {moment().isBetween(
          moment(classDetails?.data?.transition_start_date).subtract(7, "days"),
          moment(classDetails?.data?.transition_start_date)
        ) && (
          <div className="p-2 px-10 text-white bg-gradient-to-b from-yellow-400 to-yellow-500 rounded-lg">
            Transition Period will start from{" "}
            {moment(classDetails?.data?.transition_start_date).format(
              "dddd, MMMM Do YYYY"
            )}
            {". "}
            All the assignments, exams, modules will be drafted.
          </div>
        )}
        {moment().isBetween(
          moment(classDetails?.data?.transition_start_date),
          moment(classDetails?.data?.transition_end_date)
        ) && (
          <div className="p-2 px-10 text-white bg-gradient-to-b from-red-400 to-red-500 capitalize rounded-lg">
            Currently In Transition Period.
          </div>
        )}
      </div>
      <div className="p-8">
        <div className="grid grid-cols-5 bg-white rounded-lg ">
          <div
            onClick={() => setStep(1)}
            className={`iskool-tab-item ${
              step === 1 ? "bg-gray-600 text-white shadow text-left " : ""
            }`}
          >
            <div className="text-left">
              <div className="text-lg text-left">
                {classDetails?.data && classDetails?.data?.name}
              </div>
              <div className="text-xs text-left">Home</div>
            </div>
          </div>
          <div
            onClick={() => setStep(2)}
            className={`iskool-tab-item ${
              step === 2 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Subjects</div>
          </div>

          <div
            onClick={() => setStep(3)}
            className={`iskool-tab-item ${
              step === 3 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Settings</div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------- */}
        <div className="relative">
          <div>{switchTab()}</div>
        </div>
      </div>
    </>
  );
}

export default SwitchClass;
