import { Progress, Upload, message } from "antd";
import { useEffect, useState } from "react";
import { serverUrl } from "../../nestserver";
import { uploadDoc } from "../../services/upload.service";

export default function UploadFile({
  file,
  name,
  onUpload,
  children,
  formStatus,
  formSave,
  folder = "docs",
  isDelete,
  setIsDelete,
}) {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState(null);

  function beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 1;
    const isPdf = file.type === "application/pdf";
    if (!isLt2M) {
      message.error("File must smaller than 1MB!");
    }
    if (!isPdf) {
      message.error(`${file.name} is not a pdf file`);
    }
    return isLt2M && isPdf;
  }

  const uploadButton = (
    <div>
      {loading ? (
        <Progress type="circle" width={50} percent={progress} />
      ) : (
        children
      )}
    </div>
  );

  /* -------------------------------------------------------------------------- */
  /*                                Upload File                                */
  /* -------------------------------------------------------------------------- */

  async function upload(data) {
    try {
      const file = await uploadDoc(data, folder, setProgress);
      setLoading(false);
      await onUpload(file[0]);
      setFileName(file[0].files?.name);
      message.success("File uploaded successfully");
    } catch (error) {
      message.error(error.message);
    }
  }

  useEffect(() => {
    if (isDelete) {
      setFileName(null);
      message.success("File deleted successfully");
      setIsDelete();
    }
  }, [isDelete]);

  return (
    <div>
      <Upload
        name={name}
        action={serverUrl + "/uploads/docs"}
        showUploadList={false}
        customRequest={upload}
        beforeUpload={beforeUpload}
      >
        {fileName && formStatus === false
          ? "Uploaded File :" + fileName
          : uploadButton}
      </Upload>
    </div>
  );
}
