import { Form, Input, Modal, message } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import EditModule from "../../../../../components/forms/EditModule";
import { serverUrl } from "../../../../../nestserver";
import plusicon from "../../../../../resources/plus.svg";

function Module({ data }) {
  const { TextArea } = Input;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [createModule, setCreateModule] = useState(false);

  const onFinish = (values) => {
    axios({
      method: "post",
      url: serverUrl + "/modules/",
      data: {
        ...values,
        subject: data?._id,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Module Created");
        queryClient.invalidateQueries("modules");
        form.resetFields();
        setCreateModule(false);
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  async function getModules() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/modules/",
      params: {
        subject: data?._id,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const modules = useQuery(["modules", data?._id], getModules);

  const [moduleDetails, setModuleDetails] = useState(null);

  return (
    <div>
      {moduleDetails ? (
        <EditModule
          onCancel={() => setModuleDetails(null)}
          data={modules?.data?.find((i) => i._id === moduleDetails)}
        />
      ) : (
        <div>
          <div className=" pt-8 ">
            <div className="bg-white p-8 rounded-xl">
              <div className="grid grid-cols-4 gap-8">
                {modules.isSuccess
                  ? modules.data.map((module) => (
                      <div
                        key={module._id}
                        onClick={() => setModuleDetails(module._id)}
                        className="cursor-pointer bg-white p-3 h-56 module-card relative"
                      >
                        <div className="absolute top-1 right-0 uppercase">
                          {module?.published ? (
                            <div className="rounded-l-lg text-xs bg-primary-100 bg-opacity-50 text-primary-500 px-2 py-1">
                              Published
                            </div>
                          ) : (
                            <div className="rounded-l-lg text-xs bg-yellow-100 text-yellow-500 px-2 py-1">
                              Draft
                            </div>
                          )}
                        </div>
                        <div className="flex items-center justify-between gap-x-2">
                          <h1
                            style={{
                              color: "#00627B",
                            }}
                            className="text-base font-semibold text-left"
                          >
                            {module?.title}
                          </h1>
                        </div>
                        <p
                          style={{
                            color: "#AEADAE",
                            maxHeight: "170px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className="font-normal text-sm text-left py-2"
                        >
                          {module?.overview}
                        </p>
                      </div>
                    ))
                  : ""}

                <div
                  onClick={() => setCreateModule(true)}
                  className="cursor-pointer p-3 h-56 add-module flex items-center justify-center"
                >
                  <div className="flex flex-col items-center space-y-3">
                    <div className="flex items-center justify-center cursor-pointer">
                      <img className="w-6 h-6" src={plusicon} alt="icon" />
                    </div>
                    <div className="text-white text-sm ">
                      <div>Add new</div>
                      <div>module</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            width={800}
            visible={createModule}
            onCancel={() => {
              setCreateModule(false);
              form.resetFields();
            }}
            footer={null}
          >
            <div>
              <div className="text-xl font-bold text-black1 pb-3">
                Create Module
              </div>
              <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div>
                  <Form.Item
                    label="Module Name"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please input  Module name!",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        backgroundColor: "#E3E3E3",
                      }}
                      className="w-full py-2"
                      maxLength={130}
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    label="Description"
                    name="overview"
                    rules={[
                      {
                        required: true,
                        message: "Please input Description!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={2}
                      style={{
                        backgroundColor: "#E3E3E3",
                      }}
                      className="w-full py-1 resize-none "
                    />
                  </Form.Item>
                </div>
                <Form.Item className="mb-0">
                  <div className="flex items-center  pt-8">
                    <div className="inline-block ml-auto space-x-3">
                      <Button
                        type="dark"
                        size="large"
                        onClick={() => {
                          setCreateModule(false);
                          form.resetFields();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="primary" size="large" htmlType="submit">
                        Save
                      </Button>
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default Module;
