import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  TimePicker,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import MultipleUpload from "../../../../../components/inputs/UploadMultipleFile";
import { serverUrl } from "../../../../../nestserver";
const { Option } = Select;

const AddEditRoute = () => {
  const { Title } = Typography;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const format = "HH:mm";

  const [applyLeavePopup, setApplyLeavePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [uploadAdmissionPopup, setUploadAdmissionPopup] = useState(false);
  const [parentFileList, setParentFileList] = useState([]);
  const [otherFields, setOtherFields] = useState([]);
  const [allFields, setAllFields] = useState([]);

  const handleOtherFieldChange = (value, index, field) => {
    const newFields = [...otherFields];

    if (field === "time" && value) {
      const timeInFormat = moment(value).format("HH:mm");
      newFields[index][field] = timeInFormat;
    } else {
      newFields[index][field] = value;
    }
    setOtherFields(newFields);
    setAllFields(newFields);

  };

  const addOtherField = () => {
    if (Array.isArray(otherFields)) {
      setOtherFields([...otherFields, { name: "", time: null }]);
    } else {
      setOtherFields([{ name: "", time: null }]);
    }
  };

  // const removeOtherField = (index) => {
  //   if (index !== undefined && index >= 0) {
  //     const newFields = otherFields.filter((_, i) => i !== index);
  //     setOtherFields(newFields);
  //   }
  // };
  // const removeOtherField = (index) => {
  //   console.log('index: ' + index);
  //   if (index !== undefined && index >= 0) {
  //     const newFields = otherFields.map((field, i) => {
  //       console.log('fields:', field)
  //       if (i === index) {
  //         return { ...field, is_deleted: true };
  //       }
  //       return field;
  //     });
  //     setOtherFields(newFields);
  //   }
  // };
  const removeOtherField = (index) => {
    // console.log("index: " + index);
    if (index !== undefined && index >= 0) {
      const newFieldss = otherFields.filter((_, i) => i !== index);
      setOtherFields(newFieldss);
      const newFields = allFields.reduce((acc, field, i) => {
        // console.log("field", field);
        if (i === index) {
          if (field.route_id) {
            acc.push({ ...field, is_deleted: true });
          }
        } else {
          acc.push(field);
        }
        return acc;
      }, []);
      setAllFields(newFields);
    }
  };

  async function getFormList() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/routes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const formList = useQuery("/routes/", getFormList);

  const handleFileListChange = (fileList) => {
    setParentFileList(fileList);
  };
  async function fetchSubjects(id) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes/${id}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const busDetails = useQuery(
    ["routes", editData],
    () => editData && fetchSubjects(editData),
    {
      enabled: !!editData,
    }
  );

  //fetch bus
  async function getBusList() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/bus/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const busList = useQuery("/bus/", getBusList);

  //fetch driver
  async function getDriverList() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/driver/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const driverList = useQuery("/driver/", getDriverList);

  const columns = [
    {
      title: "Name",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {`${record?.name}`}
        </div>
      ),
    },
    {
      title: "Bus",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div
            key={record}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {record?.bus?.name}
          </div>
        );
      },
    },
    {
      title: "Driver",
      dataIndex: [],
      key: "status",
      align: "center",
      render: (record, _, index) => {
        return (
          <div
            key={index}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {`${record?.driver?.first_name} ${record?.driver?.last_name}`}
          </div>
        );
      },
    },
    {
      title: "Source",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.source}
        </div>
      ),
    },
    {
      title: "Destination",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.destination}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record, index) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center cursor-pointer text-blue-500 "
          onClick={() => {
            setIsEditMode(true);
            setEditData(record?._id);
            setApplyLeavePopup(true);
          }}
        >
          <span className="text-blue-500">Edit</span>
        </div>
      ),
    },
  ];

  const onFinish = (values) => {
    const formattedOtherFields = otherFields
      ?.filter((field) => field && field.time)
      .map((field) => {
        const time = moment(field.time, "HH:mm");
        return {
          ...field,
          time: time.isValid() ? time.format("HH:mm") : null,
        };
      });
    const updatedFormattedFields = allFields
      ?.filter((field) => field)
      .map((field) => {
        const time = moment(field.time, "HH:mm");
        return {
          ...field,
          time: time.isValid() ? time.format("HH:mm") : null,
        };
      });
    const payload = {
      name: values?.name,
      source: values?.source,
      comments: values?.comments,
      destination: values?.destination,
      bus_id: values?.bus_id,
      driver_id: values?.driver_id,
      route_map: isEditMode
        ? updatedFormattedFields
        : formattedOtherFields && formattedOtherFields.length > 0
        ? formattedOtherFields
        : [],
    };
    if (payload) {
      if (isEditMode) {
        axios
          .put(`${serverUrl}/routes/${editData ? editData : null}`, payload, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          })
          .then((res) => {
            queryClient.invalidateQueries("/routes/");
            form.resetFields();
            setApplyLeavePopup(false);
            setEditData(null);
            setIsEditMode(false);
            message.success(res?.data?.result?.message);
          })
          .catch((err) => {
            if (err?.response?.data?.error?.errors) {
              message.error(err?.response?.data?.error?.errors);
            }
          });
      } else {
        axios({
          method: "post",
          url: serverUrl + "/routes/",
          data: payload,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
          .then((res) => {
            queryClient.invalidateQueries("/routes/");
            form.resetFields();
            setApplyLeavePopup(false);
            message.success(res?.data?.result?.message);
          })
          .catch((error) => {
            message.error(error?.response?.data?.error?.errors);
          });
      }
    }
  };

  useEffect(() => {
    if (isEditMode && busDetails.data) {
      const data = busDetails.data?.result?.data;
      form.setFieldsValue({
        name: data?.name,
        bus_id: data?.bus?._id,
        driver_id: data?.driver?._id,
        source: data?.source,
        destination: data?.destination,
        comments: data?.comments,
      });
    }
  }, [isEditMode, busDetails?.data]);

  useEffect(() => {
    const routeMap = busDetails?.data?.result?.data?.route_map;
    const newOtherFields = routeMap?.map((route) => ({
      name: route?.name || "",
      time: route?.time ? moment(route?.time, format) : null,
      route_id: route?.route_id ? route?.route_id : null,
      is_deleted: route?.is_deleted,
      _id:route?._id
    }));
    setAllFields(newOtherFields);
    setOtherFields(newOtherFields);
  }, [isEditMode, busDetails?.data]);
  return (
    <div>
      {" "}
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex justify-between items-center">
            <div className="text-2xl text-orange-500 font-semibold">
              Manage Routes
            </div>
            <div className="text-right pt-4">
              <Button
                type="primary"
                size="large"
                onClick={() => setApplyLeavePopup(true)}
              >
                Add
              </Button>
            </div>
          </div>
          <div className="py-5 table-overflow">
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={
                formList?.isSuccess &&
                formList?.data?.result?.data?.length > 0 &&
                formList?.data?.result?.data
                  ? formList?.data?.result?.data
                  : []
              }
              pagination={true}
              loading={formList?.isLoading}
            />
          </div>

          <Modal
            width={800}
            visible={applyLeavePopup}
            onOk={() => setApplyLeavePopup(false)}
            onCancel={() => {
              form.resetFields();
              setApplyLeavePopup(false);
              setEditData(null);
              setIsEditMode(false);
              setParentFileList([]);
              setOtherFields([]);
              form.resetFields();
            }}
            footer={null}
            forceRender
          >
            <h3 className="text-lg font-bold pb-3">
              {isEditMode ? "Update Routes" : " Add Routes"}
            </h3>
            <div className="bg-white rounded-2xl ">
              <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
              >
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    label="Name"
                    name="name"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter name" />
                  </Form.Item>
                  <Form.Item
                    label="Bus"
                    name="bus_id"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Bus",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      showArrow
                      placeholder="Select Bus"
                      className="w-full bg-gray-300 capitalize"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {busList?.isSuccess &&
                        busList?.data?.result?.data?.map((data) => {
                          return (
                            <Option key={data._id} value={data._id}>
                              {data?.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    name="driver_id"
                    label="Driver"
                    className="w-30"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Driver",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      showArrow
                      placeholder="Select Driver"
                      className="w-full bg-gray-300 capitalize"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {driverList?.isSuccess &&
                        driverList?.data?.result?.data?.map((data) => {
                          return (
                            <Option key={data._id} value={data._id}>
                              {`${data?.first_name} ${data?.last_name}`}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="source"
                    label="Source"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Source",
                      },
                    ]}
                    className="w-full"
                  >
                    <Input placeholder="Enter source" />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <Form.Item
                    name="destination"
                    label="Destination"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter destination",
                      },
                    ]}
                    className="w-full"
                  >
                    <Input placeholder="Enter destination" />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Comment"
                  name="comments"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value || value.length <= 100) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Description must be at most 100 characters"
                        );
                      },
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={3}
                    placeholder="Type a comment..."
                    className="w-full border focus:outline-none resize-none"
                    maxLength={100}
                  />
                </Form.Item>

                <Title level={4}>Routes Map and Schedule</Title>
                {otherFields?.map((field, index) => (
                  <div key={index} className=" space-x-5 mb-2">
                    <h6 key={index} className="mb-8px capitalize">
                      Point {index + 1}
                    </h6>
                    <div className="flex ml-0px space-x-4 other-feild">
                      <Form.Item
                        label={
                          <Input
                            value={otherFields[index]?.name}
                            onChange={(e) => {
                              const value = e.target.value;
                              const capitalizedValue =
                                value.charAt(0).toUpperCase() + value.slice(1);
                              handleOtherFieldChange(
                                capitalizedValue,
                                index,
                                "name"
                              );
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "ArrowUp" ||
                                e.key === "ArrowDown"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            placeholder="Enter Source"
                            className="w-24 flex"
                            rules={[
                              {
                                required: true,
                                message: "Source is required",
                              },
                            ]}
                          />
                        }
                        className="w-30"
                      >
                        <TimePicker
                          // value={otherFields[index]?.time}
                          value={
                            otherFields[index]?.time
                              ? moment(otherFields[index]?.time, "HH:mm")
                              : null
                          }
                          onChange={(time) =>
                            handleOtherFieldChange(time, index, "time")
                          }
                          format="HH:mm"
                          className="mr-2 w-30"
                          placeholder="Select time"
                          minuteStep={5}
                        />
                      </Form.Item>
                      <Button
                        type="danger"
                        onClick={() => removeOtherField(index)}
                        className="ml-2"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ))}
                <div className="pb-4">
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={addOtherField}
                    className="mb-2"
                  >
                    Add
                  </Button>
                </div>
                <div className="text-center">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    {isEditMode ? "Update" : " Submit"}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <Modal
            width={800}
            visible={uploadAdmissionPopup}
            onOk={() => setUploadAdmissionPopup(false)}
            onCancel={() => {
              setUploadAdmissionPopup(false);
            }}
            footer={null}
          >
            <div>
              <MultipleUpload
                onFileListChange={handleFileListChange}
                parentResponse={parentFileList}
              />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddEditRoute;
