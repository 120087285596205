import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import FeeClass from "./FeeClass";
import FeeTemplate from "./FeeTemplate";

const SwitchAdmission = () => {
  const history = useHistory();
  const location = useLocation();
  const currentTab = location.pathname.split("/").pop() || "template";

  function switchTab() {
    switch (currentTab) {
      case "template":
        return (
          <div>
            <FeeTemplate />
          </div>
        );

      case "classes":
        return (
          <div>
            <FeeClass />
          </div>
        );

      default:
        return (
          <div>
            <FeeTemplate />
          </div>
        );
    }
  }

  return (
    <>
      <div className="p-8">
        <div className="grid grid-cols-5 bg-white rounded-lg ">
          <div
            onClick={() => history.push("/dashboard/admin/menu/fee/template")}
            className={`iskool-tab-item ${
              currentTab === "template" ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Template</div>
          </div>
          <div
            onClick={() => history.push("/dashboard/admin/menu/fee/classes")}
            className={`iskool-tab-item ${
              currentTab === "classes" ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Classes</div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------- */}
        <div>
          <div>{switchTab()}</div>
        </div>
      </div>
    </>
  );
};

export default SwitchAdmission;
