import moment from "moment";
import React, { useMemo, useState } from "react";
import Button from "antd-button-color";
import { SearchOutlined } from "@ant-design/icons";
import { Input, message, Modal, Select, Space, Table } from "antd";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import axios from "axios";
import { BiCheckCircle, BiCircle, BiXCircle } from "react-icons/bi";
import AttendanceHistory from "./AttendanceHistory";
import qs from "qs";
import { CSVLink, CSVDownload } from "react-csv";

const { Option } = Select;

export default function StudentsMain({ data, classId }) {
  const queryClient = useQueryClient();

  // Fetch Data
  async function getStudents() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/students/subject/" + data._id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const students = useQuery(["students", classId], getStudents);

  async function getAttendance() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/attendance/",
      params: {
        subject: data?._id,
        date: {
          from: moment().toISOString(),
          to: moment().toISOString(),
        },
      },
      paramsSerializer: qs.stringify,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const attendances = useQuery(
    ["attendances", data?._id, "today"],
    getAttendance
  );

  // Create Attendance

  function createAttendance(studentId, value) {
    axios({
      method: "post",
      url: serverUrl + "/attendance/",
      data: {
        subject: data?._id,
        student: studentId,
        date: moment().toDate(),
        attended: value,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((res) => {
      queryClient.invalidateQueries(["attendances", data?._id, "today"]);
      queryClient.invalidateQueries(["students", classId]);
    });
  }

  // Update Attendance
  function updateAttendance(attendanceId, value) {
    axios({
      method: "patch",
      url: serverUrl + "/attendance/" + attendanceId,
      data: {
        attended: value,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((res) => {
      queryClient.invalidateQueries(["attendances", data?._id, "today"]);
      queryClient.invalidateQueries(["students", classId]);
    });
  }

  // Update Student Status
  function updateStudentStatus(student, status) {
    axios({
      method: "patch",
      url: serverUrl + "/students/" + student?._id,
      data: {
        subject_status: { ...student?.subject_status, [data?._id]: status },
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((res) => {
      queryClient.invalidateQueries(["students", classId]);
      message?.success("Status Updated");
    });
  }

  // filter
  function strcmp(a, b) {
    if (a === b) {
      return 0;
    }

    if (a > b) {
      return 1;
    }

    return -1;
  }

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [searchInput, setSearchInput] = useState(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? // <Highlighter
          //   highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          //   searchWords={[searchText]}
          //   autoEscape
          //   textToHighlight={text ? text.toString() : ''}
          // />
          text
        : text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      sorter: {
        compare: (a, b) => strcmp(a.first_name, b.first_name),
        multiple: 3,
      },
      ...getColumnSearchProps("first_name"),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      sorter: {
        compare: (a, b) => strcmp(a.first_name, b.first_name),
        multiple: 3,
      },
      ...getColumnSearchProps("last_name"),
    },
    {
      title: "Status",
      render: (record) => (
        <div>
          <Select
            defaultValue="on track"
            style={{ width: 120 }}
            onChange={(status) => updateStudentStatus(record, status)}
            value={record?.subject_status?.[data?._id] ?? "on track"}
          >
            <Option value="on track">On Track</Option>
            <Option value="behind">Behind</Option>
            <Option
              value="complete"
              disabled={
                !moment().isBetween(
                  moment(data?.class?.transition_start_date),
                  moment(data?.class?.transition_end_date)
                )
              }
            >
              Complete
            </Option>
          </Select>
        </div>
      ),
    },
  ];

  const [showHistory, setShowHistory] = useState(null);

  /* -------------------------------------------------------------------------- */
  /*                           Export Student details                           */
  /* -------------------------------------------------------------------------- */
  // const [studentExportData, setStudentExportData] = useState([
  //   ["Name", "Email", "Batch", "class"],
  // ]);

  const studentExportData = useMemo(() => {
    const ExportData = [];
    students.data?.map((student) => {
      ExportData?.push({
        Name: student?.first_name + " " + student?.last_name,
        Batch: student?.batch,
        Class: student?.class?.name,
        subject: data?.name,
        status: student?.subject_status?.[data?._id] ?? "on track",
        attendance: (
          (student.attendance.filter((a) => a.attended === true)?.length *
            100) /
          student.attendance.length
        )?.toFixed(2),
      });
    });
    return ExportData;
  }, [students.data]);

  return (
    <div className="py-8">
      <div className="bg-white rounded-2xl p-6">
        <div className="flex justify-between items-center pb-3">
          <div className="text-orange-500 text-lg">
            {moment().format("dddd")}
            <span className="text-base text-gray-500 pl-4">
              {moment().format("MMMM Do YYYY")}
            </span>
          </div>
          <div className="inline-flex items-center space-x-3">
            <CSVLink
              filename={`${data?.class?.name}_students.csv`}
              data={studentExportData}
            >
              <Button>Export Students</Button>
            </CSVLink>
          </div>
        </div>

        <div className="border rounded-2xl">
          <Table
            className="rounded-2xl"
            columns={columns}
            dataSource={students.isSuccess ? students.data : []}
            rowKey={(record) => record._id}
            loading={students.isLoading}
            scroll={{ y: 330 }}
            pagination={{ position: ["bottomCenter"] }}
          />
        </div>
      </div>

      <Modal
        width={900}
        visible={showHistory}
        onOk={() => setShowHistory(false)}
        onCancel={() => setShowHistory(false)}
        footer={null}
      >
        <AttendanceHistory
          classId={classId}
          students={students.data}
          data={data}
        />
      </Modal>
    </div>
  );
}
