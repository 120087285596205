import React from "react";
import StaticAchievement from "../../../../../components/cards/StaticAchievement";
import InstructorMainSection from "../sections/InstructorMainSection";

function InstructorProfile({ data }) {
  return (
    <div className=" space-y-8 pt-8 ">
      <div>
        <InstructorMainSection data={data?.teacher} />
      </div>
      <div>
        <StaticAchievement data={data?.teacher} />
      </div>
    </div>
  );
}

export default InstructorProfile;
