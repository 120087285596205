import { Button, Checkbox, Form, Input, Select, Table, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { serverUrl } from "../../../../../nestserver";
const ViewStudentTemplate = () => {
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [studentTemplate, setStudentTemplate] = useState([]);
  const queryClient = useQueryClient();
  const fetchStudentTemplate = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/progress-card/" + id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };

  useEffect(() => {
    if (id) {
      fetchStudentTemplate()
        .then((response) => {
          setStudentTemplate(response?.result?.data);
          // Set initial updated data
          form.setFieldsValue({
            name: `${response?.result?.data?.student?.first_name} ${response?.result?.data?.student?.last_name}`,
            studentId: response?.result?.data?.student?.unique_id,
            class: response?.result?.data?.student?.class?.name,
            parent: `${response?.result?.data?.student?.parent?.first_name} ${response?.result?.data?.student?.parent?.last_name}`,
            phone: response?.result?.data?.student?.parent?.phone,
            overall_grade: response?.result?.data?.overall_grade,
            total: response?.result?.data?.total_marks,
            overall_comment: response?.result?.data?.overall_comment,
            status: response?.result?.data?.status,
          });
        })
        .catch((error) => {
          console.error("Error fetching student fee:", error);
        });
    }
  }, [id]);

  const columns = [
    {
      title: "Subject",
      dataIndex: [],
      key: "subject",
      align: "center",
      render: (subject) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          {subject?.subject?.name}
        </div>
      ),
    },
    {
      title: "Marks",
      dataIndex: "marks",
      key: "marks",
      align: "center",
      render: (marks, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center capitalize"
        >
          {marks}
        </div>
      ),
    },
    {
      title: "Grade",
      dataIndex: [],
      key: "grade",
      align: "center",
      render: (subject, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          {subject?.grade}
        </div>
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      align: "center",
      render: (comment, record, index) => (
        <div style={{ color: "#616161" }} className="text-base">
          {comment}
        </div>
      ),
    },
  ];
  const mandatoryColumns = [
    {
      title: "Subject",
      dataIndex: [],
      key: "subject",
      align: "center",
      render: (subject) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          {subject?.subject?.name}
        </div>
      ),
    },
    {
      title: "Marks",
      dataIndex: "marks",
      key: "marks",
      align: "center",
      render: (marks, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center capitalize"
        >
          {marks}
        </div>
      ),
    },
    {
      title: "Grade",
      dataIndex: [],
      key: "grade",
      align: "center",
      render: (subject, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          {subject?.grade}
        </div>
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      align: "center",
      render: (comment, record, index) => (
        <div style={{ color: "#616161" }} className="text-base">
          {comment}
        </div>
      ),
    },
  ];
  const curricularColumns = [
    {
      title: "Subject",
      dataIndex: [],
      key: "subject",
      align: "center",
      render: (subject) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          {subject?.subject?.name}
        </div>
      ),
    },
    {
      title: "Marks",
      dataIndex: "marks",
      key: "marks",
      align: "center",
      render: (marks, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center capitalize"
        >
          {marks}
        </div>
      ),
    },
    {
      title: "Grade",
      dataIndex: [],
      key: "grade",
      align: "center",
      render: (subject, record, index) => (
        <div
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          {subject?.grade}
        </div>
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      align: "center",
      render: (comment, record, index) => (
        <div style={{ color: "#616161" }} className="text-base">
          {comment}
        </div>
      ),
    },
  ];
  const OnFinish = (values) => {
    axios
      .patch(
        serverUrl + "/progress-card/" + id + "/acknowledge",
        {},
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      )
      .then((res) => {
        queryClient.refetchQueries(["progress-card", id]);
        message.success(res?.data?.result?.message);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
        }
      });
  };

  return (
    <div className="student-form-fee">
      <div className="p-8">
        <div className="bg-white p-10 rounded-2xl">
          <div className="p-4 text-right">
            <Button size="large" onClick={() => history.goBack()}>
              Back
            </Button>
          </div>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            autoComplete="off"
            onFinish={OnFinish}
          >
            <div className="form-grid">
              <Form.Item label="Student Id" name="studentId">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Name" name="name">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Class" name="class">
                <Input readOnly />
              </Form.Item>
            </div>
            <div className="form-grid">
              <Form.Item label="Parent" name="parent">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Phone" name="phone">
                <Input readOnly />
              </Form.Item>
            </div>
            <div className="form-grid">
              <Form.Item label="Overall Grade" name="overall_grade">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Total Marks" name="total">
                <Input type="number" min={0} readOnly />
              </Form.Item>
            </div>
            <Form.Item name="overall_comment" label="Overall Comment">
              <Input.TextArea
                rows={3}
                placeholder="comment"
                className="w-full border focus:outline-none resize-none"
                readOnly
              />
            </Form.Item>
            <div>
              <h6 className="text-sm pb-4">Mandatory Subject</h6>
              <div className="flex flex-col gap-3 border">
                <Table
                  columns={mandatoryColumns}
                  dataSource={
                    studentTemplate?.mandatory_subjects
                      ? studentTemplate?.mandatory_subjects
                      : []
                  }
                  pagination={false}
                />
              </div>
            </div>
            <div>
              <h6 className="text-sm pb-4 pt-4">Languages</h6>
              <div className="flex flex-col gap-3 border">
                <Table
                  columns={columns}
                  dataSource={
                    studentTemplate?.languages ? studentTemplate?.languages : []
                  }
                  pagination={false}
                />
              </div>
            </div>
            <div>
              <h6 className="text-sm pb-4 pt-4">Co Curricular</h6>
              <div className="flex flex-col gap-3 border">
                <Table
                  columns={curricularColumns}
                  dataSource={
                    studentTemplate?.co_curricular
                      ? studentTemplate?.co_curricular
                      : []
                  }
                  pagination={false}
                />
              </div>
            </div>
            <Form.Item
              label="Status"
              name="status"
              className="pt-4 "
              style={{ width: "20%" }}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="Select"
                className="capitalize"
                disabled
              ></Select>
            </Form.Item>
            {studentTemplate && studentTemplate?.status !== "acknowledged" && (
              <Form.Item
                name="acknowledge"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Please accept acknowledgement")
                          ),
                  },
                ]}
                className="text-center text-base"
              >
                <Checkbox className="text-center  pb-4">
                  <span className="text-base">
                    I acknowledge that the above information provided is
                    correct.
                  </span>
                </Checkbox>
              </Form.Item>
            )}
            {studentTemplate && studentTemplate?.status !== "acknowledged" && (
              <Form.Item className="text-center text-base">
                <Button size="large" type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ViewStudentTemplate;
