import React, { useState } from "react";
import { FiChevronRight } from "react-icons/fi";

export default function Accord(props) {
  const [open, setOpen] = useState(false);
  return (
    <div
      className={`transition duration-500 ease-in-out `}
      style={{ fontSize: "16px" }}
    >
      <div
        className="w-56 flex flex-row items-start justify-between text-white  
        text-start  cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <div
          style={{ color: "#FFFFFF" }}
          className="font-normal capitalize pb-2"
        >
          {props.title}
        </div>

        <div
          className={` uppercase transition duration-100 ease-in-out transform  ${
            open ? "rotate-90 " : ""
          } `}
        >
          <FiChevronRight
            size={25}
            className={`${open ? "text-green-400" : "text-white"}`}
          />
        </div>
      </div>
      <div
        className={`overflow-hidden text-sm font-light transition duration-500 ease-in-out ${
          open ? "block opacity-100" : "hidden opacity-0"
        }`}
      >
        {props.children}
      </div>
    </div>
  );
}
