import axios from "axios";
import { serverUrl } from "../nestserver";

export async function uploadFile(data, folder, setProgress = console.log) {
  var formData = new FormData();
  var imagefile = data.file;
  formData.append("files", imagefile);
  formData.append("folder", folder);
  const res = await axios.post(serverUrl + "/uploads", formData, {
    headers: {
      "Content-Type": null,
    },
    onUploadProgress: function (progressEvent) {
      setProgress(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
    },
  });
  return res.data;
}

export async function uploadDoc(data, folder, setProgress = console.log) {
  var formData = new FormData();
  var imagefile = data.file;
  formData.append("files", imagefile);
  formData.append("folder", folder);
  const res = await axios.post(serverUrl + "/uploads/docs", formData, {
    headers: {
      "Content-Type": null,
    },
    onUploadProgress: function (progressEvent) {
      setProgress(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
    },
  });
  return res.data;
}
// export async function uploadMultipleDoc(data, folder, setProgress = console.log) {
//   var formData = new FormData();
//   var imagefile = data.file;
//   formData.append("files", imagefile);
//   formData.append("folder", folder);
//   const res = await axios.post(serverUrl + "/uploads/docs", formData, {
//     headers: {
//       "Content-Type": null,
//     },
//     onUploadProgress: function (progressEvent) {
//       setProgress(
//         Math.round((progressEvent.loaded * 100) / progressEvent.total)
//       );
//     },
//   });
//   return res.data;
// }

export async function uploadMultipleDoc(data, folder, setProgress = console.log) {
  if (!data.files || !data.files.length) {
    throw new Error("No files selected for upload.");
  }

  const formData = new FormData();

  // Iterate through the selected files and append each with a unique name
  for (const [index, file] of data.files.entries()) {
    formData.append(`files[${index}]`, file); // Use dynamic names
  }

  formData.append("folder", folder);

  const res = await axios.post(serverUrl + "/uploads/docs", formData, {
    headers: {
      "Content-Type": null, 
    },
    onUploadProgress: function (progressEvent) {
      // Calculate overall progress, weighted by file size
      const totalBytes = Object.values(formData._parts).reduce(
        (acc, part) => acc + part[1].size,
        0
      );
      const loaded = progressEvent.loaded;

      const progress = Math.round((loaded * 100) / totalBytes);
      setProgress(`Overall Progress: ${progress}%`);
    },
  });

  return res.data;
}