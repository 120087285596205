import { Modal, message } from "antd";
import Button from "antd-button-color";
import React, { useEffect, useState } from "react";
import { BsPencil } from "react-icons/bs";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/auth.slice";
import fupload from "../../resources/feather-upload.svg";

import { HiOutlineCamera } from "react-icons/hi";
import { cloudFrontUrl, serverUrl } from "../../nestserver";
import IconButton from "../buttons/IconButton";

import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { useQueryClient } from "react-query";
import ImageUploadButton from "../inputs/ImageUploadButton";

function ParentMainProfile() {
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();

  const [bgmodal, setBgModal] = useState(false);
  const [profilemodal, setProfileModal] = useState(false);
  const [images, setImages] = useState({
    profile_image: null,
    banner_image: null,
  });

  // ---------------------------------update Profile-------------------------------

  function updateProfile() {
    axios({
      method: "patch",
      url: serverUrl + "/parent/" + user?.parent?._id,
      data: {
        ...images,
      },
      mode: "cors",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Profile Image Updated");
        queryClient.invalidateQueries("user");
        setProfileModal(false);
        setBgModal(false);
      })
      .catch((e) => {
        message.error(e.message);
      });
  }
  
  useEffect(() => {
    if (user?.parent) {
      setImages((i) => ({
        ...i,
        profile_image: user?.parent?.profile_image,
        banner_image: user?.parent?.banner_image,
      }));
    }
  }, [user]);

  return (
    <div>
      <div
        style={{ borderRadius: "10px" }}
        className=" bg-white drop-shadow-md"
      >
        <div
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            backgroundImage: `url("${
              images.banner_image
                ? cloudFrontUrl + "/" + user?._id + "/" + images.banner_image
                : "https://ui-avatars.com/api/?name=" +
                  (user?.parent?.first_name + " + " + user?.parent?.last_name)
            }")`,
          }}
          className="h-52 bg-no-repeat bg-cover relative"
        >
          <div className="absolute top-5 right-5 ">
            <IconButton icon={<BsPencil />} onClick={() => setBgModal(true)} />
          </div>

          {/* -------------------------------------------background banner modal---------------------------------- */}
          <Modal
            width={800}
            visible={bgmodal}
            onOk={() => setBgModal(false)}
            onCancel={() => setBgModal(false)}
            footer={null}
          >
            <div>
              <div className="py-2">
                <div className="text-2xl font-bold text-black pb-4 capitalize">
                  Cover Photo
                </div>

                <div className="flex items-center justify-center">
                  <div className="relative w-full h-42 rounded-lg">
                    <img
                      className="w-full h-full rounded-lg object-contain"
                      src={
                        images?.banner_image
                          ? cloudFrontUrl +
                            "/" +
                            user?._id +
                            "/" +
                            images?.banner_image
                          : "https://ui-avatars.com/api/?name=" +
                            (user?.parent?.first_name +
                              " + " +
                              user?.parent?.last_name)
                      }
                      alt="images"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center pt-3">
                <div className="flex flex-row space-x-5">
                  <ImageUploadButton
                    folder={user?._id}
                    ratio={9 / 2.5}
                    onUpload={(e) =>
                      setImages((i) => ({
                        ...i,
                        banner_image: e,
                      }))
                    }
                  >
                    <div
                      style={{ color: "#616161" }}
                      className="flex flex-row space-x-4 cursor-pointer"
                    >
                      <img src={fupload} alt="" />
                      <div className="text-lg">Upload photo</div>
                    </div>
                  </ImageUploadButton>
                  <Button
                    onClick={(e) =>
                      setImages((i) => ({
                        ...i,
                        banner_image: null,
                      }))
                    }
                    type="danger"
                    className="flex items-center"
                    icon={<DeleteOutlined />}
                  >
                    Remove
                  </Button>
                </div>

                <div className="inline-flex items-center space-x-2">
                  <Button
                    type="dark"
                    onClick={() => {
                      setBgModal(false);
                      setImages((i) => ({
                        ...i,
                        banner_image: user?.parent?.banner_image,
                      }));
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                    onClick={() => updateProfile()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

          {/* -------------------------------------------background banner modal end---------------------------------- */}
        </div>

        {/* --------------------------------------------profile image modal--------------------------------------- */}

        <Modal
          width={800}
          visible={profilemodal}
          onOk={() => setProfileModal(false)}
          onCancel={() => setProfileModal(false)}
          footer={null}
        >
          <div>
            <div className="py-2">
              <div className="text-2xl font-bold text-black pb-4 capitalize">
                profile photo
              </div>

              <div className="flex items-center justify-center">
                <div className="relative w-44 h-44 rounded-full  border-8 border-white">
                  <img
                    className="w-full h-full rounded-full"
                    src={
                      images?.profile_image
                        ? cloudFrontUrl +
                          "/" +
                          user?._id +
                          "/" +
                          images?.profile_image
                        : "https://ui-avatars.com/api/?name=" +
                          (user?.parent?.first_name +
                            " + " +
                            user?.parent?.last_name)
                    }
                    alt="images"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center pt-3">
              <div className="flex flex-row space-x-5">
                <ImageUploadButton
                  folder={user?._id}
                  onUpload={(e) =>
                    setImages((i) => ({
                      ...i,
                      profile_image: e,
                    }))
                  }
                >
                  <div
                    style={{ color: "#616161" }}
                    className="flex flex-row space-x-4 cursor-pointer"
                  >
                    <img src={fupload} alt="" />
                    <div className="text-lg">Upload photo</div>
                  </div>
                </ImageUploadButton>
                <Button
                  onClick={(e) =>
                    setImages((i) => ({
                      ...i,
                      profile_image: null,
                    }))
                  }
                  type="danger"
                  className="flex items-center"
                  icon={<DeleteOutlined />}
                >
                  Remove
                </Button>
              </div>

              <div className="inline-flex items-center space-x-2">
                <Button
                  size="large"
                  type="dark"
                  onClick={() => {
                    setProfileModal(false);
                    setImages((i) => ({
                      ...i,
                      profile_image: user?.parent?.profile_image,
                    }));
                  }}
                >
                  Cancel
                </Button>

                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="text-white"
                  onClick={() => updateProfile()}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        {/* -------------------------------------------profile image modal end------------------------------------------------------------- */}

        <div className="w-full h-full md:px-8 px-4 py-4 relative pt-16 z-0">
          <div
            className="absolute left-5"
            style={{ transform: `translateY(-110%)` }}
          >
            <div className="relative w-44 h-44 rounded-full  border-8 border-white">
              <img
                className="w-full h-full rounded-full"
                src={
                  user?.parent?.profile_image
                    ? cloudFrontUrl +
                      "/" +
                      user?._id +
                      "/" +
                      user?.parent?.profile_image
                    : "https://ui-avatars.com/api/?name=" +
                      (user?.parent?.first_name +
                        " + " +
                        user?.parent?.last_name)
                }
                alt="Profile"
              />
              <div
                className="absolute bottom-0"
                style={{
                  left: "35%",
                  transform: "translateX(-50%)",
                  transform: "translateY(50%)",
                }}
              >
                <IconButton
                  icon={<HiOutlineCamera size={20} />}
                  onClick={() => setProfileModal(true)}
                />
              </div>
            </div>
          </div>

          {/* --------------------------------------------------------------------------------------------------------------- */}
          <div
            style={{ color: "#111111" }}
            className="w-full flex md:flex-row flex-col gap-4 justify-between text-left "
          >
            <div className="space-y-3">
              <div className="md:text-xl text-lg font-bold text-left">
                {user?.parent?.first_name} {user?.parent?.last_name}
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------------------------------------background modal----------------------------------------- */}
      </div>
    </div>
  );
}

export default ParentMainProfile;
