import { Button, DatePicker, Input, Table, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { defaultStatus } from "../../../../../helper/options";
import { serverUrl } from "../../../../../nestserver";

const FeeTable = ({
  postTemplateResponse,
  feeResponse,
  setFeeResponse,
  isFeeTemplate,
  isViewStudentFee,
  fetchStudentFee,
  fetchFeeTemplate,
}) => {
  const [editedValues, setEditedValues] = useState({});
  const [editedStudentFeeValues, setEditedStudentFeeValues] = useState({});
  const [editingStudentFeeRow, setEditingStudentFeeRow] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleEdit = () => {
    setIsEditMode(true);
    const initialEditedValues = feeResponse?.installment_list.reduce(
      (acc, record, index) => {
        acc[index] = {
          name: record.name,
          dueDate: record.due_date,
          tution: record.payable_components[0].tution,
        };
        return acc;
      },
      {}
    );
    setEditedValues(initialEditedValues);
  };

  const handleUpdate = async () => {
    // try {
    //   const updatedData = feeResponse?.installment_list.map((item, index) => ({
    //     name: editedValues[index]?.name,
    //     due_date: editedValues[index]?.dueDate,
    //     _id: item._id,
    //     payable_components: [
    //       {
    //         ...item.payable_components[0],
    //         tution: editedValues[index]?.tution,
    //       },
    //     ],
    //   }));
    //   console.log("updated data", updatedData);

    //   const response = await axios.patch(
    //     `${serverUrl}/admin/fee-template/${feeResponse?._id}/installments/`,
    //     { installments: updatedData },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
    //       },
    //     }
    //   );
    //   console.log("response", response);
    //   debugger;
    //   message.success(
    //     response?.data?.result?.message || "Fee updated successfully"
    //   );
    //   const updatedStudentFee = fetchFeeTemplate().then((new) => {
    //     console.log("meraaupdate", new);
    //   });
    //   console.log("updatedData", updatedData);
    //   // setFeeResponse({ ...feeResponse, installment_list: updatedData });
    //   setIsEditMode(false);
    //   setEditedValues({});
    // } catch (error) {
    //   message.error(error?.response?.data?.error?.errors);
    //   setIsEditMode(false);
    //   setEditedValues({});
    // }

    try {
      if (!feeResponse || !feeResponse.installment_list) {
        throw new Error("Fee response or installment list is missing");
      }

      const updatedData = feeResponse.installment_list.map((item, index) => ({
        name: editedValues[index]?.name ?? item.name,
        due_date: editedValues[index]?.dueDate ?? item.due_date,
        _id: item._id,
        payable_components: [
          {
            ...item.payable_components[0],
            tution:
              editedValues[index]?.tution ?? item.payable_components[0].tution,
          },
        ],
      }));

      const response = await axios.patch(
        `${serverUrl}/admin/fee-template/${feeResponse._id}/installments/`,
        { installments: updatedData },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );
      message.success(
        response?.data?.result?.message || "Fee updated successfully"
      );
      const fetchUpdatedFeeTemplate = async () => {
        try {
          const updatedFee = await fetchFeeTemplate();
          setFeeResponse((prevState) => ({
            ...prevState,
            installment_list: updatedFee?.result?.installment_list,
          }));
        } catch (error) {
          console.error("Error fetching updated fee template:", error);
        }
      };

      fetchUpdatedFeeTemplate();
      setIsEditMode(false);
      setEditedValues({});
    } catch (error) {
      console.error("Error updating fee template:", error);
      message.error(
        error?.response?.data?.error?.errors ||
          "An error occurred while updating the fee template"
      );
      setIsEditMode(false);
      setEditedValues({});
    }
  };

  const handleStudentFeeCancel = (index) => {
    setEditingStudentFeeRow(null);
    setEditedStudentFeeValues((prev) => {
      const newValues = { ...prev };
      delete newValues[index];
      return newValues;
    });
  };

  const handleStudentFeeEdit = (record, index) => {
    setEditingStudentFeeRow(index);
    setEditedStudentFeeValues({
      ...editedStudentFeeValues,
      [index]: {
        ...record.payable_components[0],
      },
    });
  };

  function disabledDate(current) {
    return current && current < moment().startOf("day");
  }

  // handle update for name and due_date

  const handleStudentFeeUpdate = async (index) => {
    try {
      const updatedData = [...(feeResponse?.installment_list || [])];
      const updatedItem = {
        payable_components: editedStudentFeeValues[index],
        _id: updatedData[index]._id,
      };

      const response = await axios.patch(
        `${serverUrl}/admin/student-fee/${feeResponse?._id}/installments/`,
        updatedItem,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );
      message.success(response?.data?.result?.message);
      const updatedStudentFee = await fetchStudentFee();
      updatedData[index].payable_components[0] =
        updatedStudentFee.result.installment_list[index].payable_components[0];
      setFeeResponse({ ...feeResponse, installment_list: updatedData });

      // Reset editing state
      setEditingStudentFeeRow(null);
      setEditedStudentFeeValues({});
    } catch (error) {
      setEditingStudentFeeRow(null);
      setEditedStudentFeeValues({});
    }
  };
  // Table Settings

  const getDynamicColumns = (data) => {
    const columns = [
      {
        title: "Name",
        key: "name",
        align: "center",
        render: (record, _, index) => {
          if (isEditMode) {
            return (
              <Input
                value={editedValues[index]?.name}
                onChange={(e) =>
                  setEditedValues({
                    ...editedValues,
                    [index]: {
                      ...editedValues[index],
                      name: e.target.value,
                    },
                  })
                }
              />
            );
          } else {
            return <span>{record?.name}</span>;
          }
        },
      },
      {
        title: "Tution Fee",
        dataIndex: "",
        key: "tution",
        align: "center",
        render: (record, _, index) => {
          if (editingStudentFeeRow === index) {
            return (
              <Input
                value={editedStudentFeeValues[index]?.tution || ""}
                onChange={(e) =>
                  setEditedStudentFeeValues({
                    ...editedStudentFeeValues,
                    [index]: {
                      ...editedStudentFeeValues[index],
                      tution: e.target.value,
                    },
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => e.target.blur()}
                type="number"
              />
            );
          } else if (isEditMode) {
            return (
              <Input
                type="number"
                value={editedValues[index]?.tution || ""}
                onChange={(e) =>
                  setEditedValues({
                    ...editedValues,
                    [index]: {
                      ...editedValues[index],
                      tution: e.target.value,
                    },
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => e.target.blur()}
              />
            );
          } else {
            return <span>{record?.payable_components[0]?.tution}</span>;
          }
        },
      },
      {
        title: "Other",
        dataIndex: [],
        key: "",
        render: (record, _, index) => {
          if (editingStudentFeeRow === index) {
            return (
              <Input
                value={editedStudentFeeValues[index]?.other}
                onChange={(e) =>
                  setEditedStudentFeeValues({
                    ...editedStudentFeeValues,
                    [index]: {
                      ...editedStudentFeeValues[index],
                      other: e.target.value,
                    },
                  })
                }
                type="number"
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => e.target.blur()}
              />
            );
          } else {
            return <span> {record?.payable_components[0]?.other}</span>;
          }
        },
      },
      {
        title: "Total Amount",
        dataIndex: [],
        key: "",
        render: (record) => (
          <div style={{ color: "#616161" }} className=" text-base">
            {record?.payable_components[0]?.total_amount}
          </div>
        ),
      },
      {
        title: "Due Date",
        key: "due_date",
        align: "center",
        render: (record, _, index) => {
          if (isEditMode) {
            return (
              <DatePicker
                format={"DD/MM/YYYY"}
                value={
                  editedValues[index]?.dueDate
                    ? moment(editedValues[index].dueDate)
                    : null
                }
                disabledDate={disabledDate}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(date) =>
                  setEditedValues({
                    ...editedValues,
                    [index]: {
                      ...editedValues[index],
                      dueDate: date,
                    },
                  })
                }
              />
            );
          } else {
            return (
              <span>{moment(record?.due_date).format("Do MMM YYYY")}</span>
            );
          }
        },
      },
    ];

    const excludedProperties = ["tution", "other", "total_amount"];
    const payable_components = data?.map((item) => item?.payable_components[0]);
    const filteredData = payable_components.map((obj) =>
      Object.keys(obj)
        .filter((key) => !excludedProperties.includes(key))
        .reduce((acc, key) => {
          acc[key] = obj[key];
          return acc;
        }, {})
    );
    if (filteredData && filteredData.length > 0 && filteredData[0]) {
      const firstItem = filteredData[0];
      Object.entries(firstItem).forEach(([key, value]) => {
        const newColumn = {
          title: key,
          key: key,
          align: "center",
          render: (record, _, index) => {
            const fieldValue = record.payable_components[0]?.[key];
            const isEditing = editingStudentFeeRow === index;
            const initialValue =
              editedStudentFeeValues[index]?.[key] || fieldValue;
            return isEditing ? (
              <Input
                value={editedStudentFeeValues[index]?.[key]}
                onChange={(e) =>
                  setEditedStudentFeeValues({
                    ...editedStudentFeeValues,
                    [index]: {
                      ...editedStudentFeeValues[index],
                      [key]: e.target.value,
                    },
                  })
                }
              />
            ) : (
              <div
                key={record?._id}
                style={{ color: "#111111" }}
                className="text-base text-center capitalize"
                onClick={() => handleStudentFeeEdit(record, index)}
              >
                {initialValue}
              </div>
            );
          },
        };
        columns.splice(2, 0, newColumn);
      });
    }
    if (isViewStudentFee) {
      columns.push({
        title: "Action",
        key: "action",
        align: "center",
        render: (record, _, index) => {
          if (editingStudentFeeRow === index) {
            return (
              <>
                <Button
                  type="primary"
                  onClick={() => handleStudentFeeUpdate(index)}
                  style={{ marginBottom: "10px" }}
                >
                  Update
                </Button>
                <Button
                  onClick={() => handleStudentFeeCancel(index)}
                  style={{ marginLeft: "8px" }}
                >
                  Cancel
                </Button>
              </>
            );
          } else {
            return (
              <Button
                type="link"
                onClick={() => handleStudentFeeEdit(record, index)}
              >
                Edit
              </Button>
            );
          }
        },
      });
    }

    return columns;
  };
  const columns = getDynamicColumns(
    feeResponse?.installment_list ||
      postTemplateResponse?.data?.result?.data ||
      []
  );

  return (
    <>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md w-full "
        >
          <div className="flex justify-end"></div>
          {isFeeTemplate && feeResponse?.status !== defaultStatus?.applied && (
            <div className="flex justify-end p-4">
              {isEditMode ? (
                <>
                  <Button type="primary" onClick={handleUpdate}>
                    Update
                  </Button>
                  <Button
                    onClick={() => setIsEditMode(false)}
                    style={{ marginLeft: "8px" }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button type="primary" onClick={handleEdit}>
                  Edit
                </Button>
              )}
            </div>
          )}

          <div className="py-5 table-overflow">
            <Table
              columns={columns}
              dataSource={
                feeResponse?.installment_list
                  ? feeResponse?.installment_list
                  : postTemplateResponse &&
                    postTemplateResponse?.data?.result?.data
                  ? postTemplateResponse?.data?.result?.data
                  : []
              }
              pagination={false}
              loading={
                feeResponse?.installment_list?.isLoading ||
                postTemplateResponse?.data?.result?.data?.loading
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeTable;
