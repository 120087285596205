import React from "react";

export default function IconButton({
  onClick,
  icon,
  className = "bg-gray-100 hover:bg-gray-200 text-primary-500",
}) {
  return (
    <div
      onClick={onClick}
      className={`w-10 h-10 rounded-full cursor-pointer  flex items-center justify-center ${className}`}
    >
      {icon}
    </div>
  );
}
