import { Table } from "antd";
import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../nestserver";
import { selectUser } from "../../../../redux/slices/auth.slice";

const CourseBoard = () => {
  const user = useSelector(selectUser);
  const history = useHistory();

  //fetch student subjects
  function getSubjects() {
    const res = axios({
      method: "get",
      url: `${serverUrl}/students/${user?.student?._id}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.then((response) => response.data);
  }

  const {
    data: subjects,
    isLoading,
    error,
  } = useQuery("students", getSubjects);
  const columns = [
    {
      title: "Subject",
      dataIndex: [],
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.name}
        </div>
      ),
    },
    {
      title: "Instructor",
      dataIndex: [],
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {`${record?.teacher?.teacher?.first_name} ${record?.teacher?.teacher?.last_name}`}
        </div>
      ),
    },
    {
      title: "Action",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div
            key={record?._id}
            className=" text-base font-semibold text-center text-blue-500 cursor-pointer"
            onClick={() => {
              history.push(
                "/dashboard/student/" +
                  record?.name +
                  "/" +
                  record?.class +
                  "/" +
                  record?._id
              );
            }}
          >
            Click to view
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-6">
            Manage Course Board
          </div>
          <Table
            columns={columns}
            dataSource={
              subjects && subjects?.subjectArray ? subjects?.subjectArray : []
            }
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default CourseBoard;
