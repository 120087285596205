import { Button, DatePicker, Form, Input, Select, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { defaultStatus, feeType } from "../../../../../helper/options";
import { serverUrl } from "../../../../../nestserver";
import FeeTable from "./FeeTable";

const AddEditFeeTemplate = () => {
  let { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  let isFeeTemplate = true;
  const { Option } = Select;
  const [otherFields, setOtherFields] = useState([{}]);
  const [feeComponents, setFeeComponents] = useState();
  const [feeResponse, setFeeResponse] = useState();
  const [data, setData] = useState({
    tution: "",
    other: "",
  });

  const handleFeeData = (paramName, value) => {
    setData({ ...data, [paramName]: value });
  };
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);
  const handleOtherFieldChange = (value, index, field) => {
    const newFields = [...otherFields];
    newFields[index][field] = value;
    setOtherFields(newFields);
  };
  const applyFeeTemplate = async () => {
    try {
      await axios
        .patch(
          serverUrl + "/admin/fee-template/" + id + "/apply/",
          {},
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
        .then((response) => {
          message.success(response?.data?.result?.message);
        });
    } catch (err) {
      if (err?.response?.data?.error?.errors) {
        message.error(err?.response?.data?.error?.errors);
      }
    }
  };
  const fetchFeeTemplate = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/admin/fee-template/" + id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  useEffect(() => {
    if (id) {
      fetchFeeTemplate().then((response) => {
        setFeeResponse(response?.result);
        form.setFieldsValue({
          class: response?.result?.class,
          title: response?.result?.title,
          total: response?.result?.total,
          year: moment(response?.result?.year),
          due_date: moment(response?.result?.due_date),
          installment_count: response?.result?.installment_count,
          fee_components: response?.result?.fee_components,
        });
        const updatedData = response?.result.fee_components.reduce(
          (acc, curr, index) => {
            if (curr.name === feeType.tution) {
              acc.tution = curr.amount;
            } else if (curr.name === feeType.other) {
              acc.other = curr.amount;
            } else {
              // For other fields, add them to otherFields array
              acc.otherFields.push({
                label: curr.name,
                value: curr.amount,
                index,
              });
            }
            return acc;
          },
          { tution: "", other: "", otherFields: [] }
        );
        setOtherFields([...updatedData.otherFields]);
        form.setFieldsValue({
          tution: updatedData.tution,
          other: updatedData.other,
        });
        setData({
          tution: updatedData.tution,
          other: updatedData.other,
        });
        setFeeComponents(response?.result?.fee_components);
      });
    }
  }, [id]);
  return (
    <div>
      <div className="p-4 mt-8 pt-0">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md w-full p-4 "
        >
          {feeResponse?.status === defaultStatus?.pending ? (
            <div className="text-sm font-semibold">
              <span className="text-base font-semibold">CAUTION:-</span>
              Fee Template once applied for a class cannot be modified/deleted
              through-out the Academic year. Please check the fee structure and
              No terms for fee collection and proceed" Click on OK opens the
              pop-up again (Secondary Pop-up) with Apply Template and Cancel
            </div>
          ) : (
            <div className="text-sm font-semibold">
              **<span className="text-base font-semibold">NOTE:-</span>The
              Applied templates are NOT editable/deletable for the complete year
              - If you wish to modify the fee for individual student following
              the path below" Menu - Manage Fee -Classes - Select class - View
              Individual Student{" "}
            </div>
          )}
          <div>
            <div className="p-4 text-right">
              <Button size="large" onClick={() => history.goBack()}>
                Back
              </Button>
            </div>
            <div className="p-4 w-1/2">
              <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Class"
                  name="class"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please select a class",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select Class"
                    className="w-full bg-gray-300"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    disabled
                  >
                    {classes.isSuccess &&
                      classes?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Title"
                  name="title"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="tution"
                  label="Tution Fee"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Tution fee is required",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={0}
                    onChange={(e) => {
                      handleFeeData("tution", e.target.value);
                    }}
                    readOnly
                  />
                </Form.Item>
                {otherFields?.map((field, index) => (
                  <div key={index} className=" space-x-5 mb-2">
                    <h6 key={index} className="mb-8px capitalize">
                      {field?.label}
                    </h6>
                    <div className="flex ml-0px space-x-4 other-feild w-full">
                      <Form.Item className="w-30 w-full">
                        <Input
                          value={field.value}
                          onChange={(e) =>
                            handleOtherFieldChange(
                              e.target.value,
                              index,
                              "value"
                            )
                          }
                          className="mr-2"
                          placeholder="Enter value"
                          type="number"
                          min={0}
                          readOnly
                        />
                      </Form.Item>
                    </div>
                  </div>
                ))}
                <Form.Item name="other" label="Other" className="w-30">
                  <Input
                    type="number"
                    min={0}
                    onChange={(e) => {
                      handleFeeData("other", e.target.value);
                    }}
                    readOnly
                  />
                </Form.Item>

                <Form.Item name="total" label="Total Amount" className="w-30">
                  <Input readOnly/>
                </Form.Item>
                <Form.Item
                  name="year"
                  label="Year"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Year is required",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                    disabled
                  />
                </Form.Item>
                <Form.Item name="due_date" label="Due Date" className="w-30">
                  <DatePicker
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    disabled
                    className="w-full"
                  />
                </Form.Item>
                <Form.Item
                  name="installment_count"
                  label="Number of terms (Max 10)"
                  className="w-30"
                  getValueFromEvent={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (isNaN(value)) {
                      return 1; // Default value if not a number
                    } else {
                      return Math.min(Math.max(value, 1), 10); // Ensure the value is between 1 and 10
                    }
                  }}
                >
                  <Input type="number" readOnly />
                </Form.Item>
              </Form>
              {feeResponse &&
                feeResponse?.status === defaultStatus?.pending && (
                  <div className="flex justify-center items-center py-5">
                    <div className="inline-flex items-center space-x-2">
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="text-white"
                        onClick={applyFeeTemplate}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                )}
            </div>
            <FeeTable
              feeResponse={feeResponse}
              setFeeResponse={setFeeResponse}
              isFeeTemplate={isFeeTemplate}
              fetchFeeTemplate={fetchFeeTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditFeeTemplate;
