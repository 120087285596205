import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import axios from "axios";
import AchievementsCard from "../../../components/cards/AchievementsCard";
import { cloudFrontUrl, serverUrl } from "../../../nestserver";
import medal from "../../../resources/medal01.svg";

function DynamicProfile() {
  const { userId } = useParams();

  const [profileData, setProfileData] = useState([]);
  const [images, setImages] = useState({
    profile_image: null,
    banner_image: null,
  });

  useEffect(() => {
    axios({
      method: "get",
      url: serverUrl + "/users/" + userId,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        setProfileData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div>
      <div className="h-full p-10 ">
        <div>
          {}
          <div
            style={{ borderRadius: "10px" }}
            className=" bg-white drop-shadow-md"
          >
            <div
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                backgroundImage: `url("${
                  profileData[
                    profileData?.role === "instructor"
                      ? "teacher"
                      : profileData?.role
                  ]?.banner_image
                    ? cloudFrontUrl +
                      "/" +
                      profileData?._id +
                      "/" +
                      profileData[
                        profileData?.role === "instructor"
                          ? "teacher"
                          : profileData?.role
                      ]?.banner_image
                    : "https://ui-avatars.com/api/?name=" +
                      profileData[
                        profileData?.role === "instructor"
                          ? "teacher"
                          : profileData?.role
                      ]?.first_name +
                      " " +
                      profileData[
                        profileData?.role === "instructor"
                          ? "teacher"
                          : profileData?.role
                      ]?.last_name
                }")`,
              }}
              className="h-52 bg-no-repeat bg-cover relative"
            ></div>

            <div className="w-full h-full md:px-8 px-4 py-4 relative pt-16 z-0">
              <div
                className="absolute left-5"
                style={{ transform: `translateY(-110%)` }}
              >
                <div className="relative w-44 h-44 rounded-full  border-8 border-white bg-black">
                  <img
                    className="w-full h-full rounded-full"
                    src={
                      profileData[
                        profileData?.role === "instructor"
                          ? "teacher"
                          : profileData?.role
                      ]?.profile_image
                        ? cloudFrontUrl +
                          "/" +
                          profileData?._id +
                          "/" +
                          profileData[
                            profileData?.role === "instructor"
                              ? "teacher"
                              : profileData?.role
                          ]?.profile_image
                        : "https://ui-avatars.com/api/?name=" +
                          profileData[
                            profileData?.role === "instructor"
                              ? "teacher"
                              : profileData?.role
                          ]?.first_name +
                          " " +
                          profileData[
                            profileData?.role === "instructor"
                              ? "teacher"
                              : profileData?.role
                          ]?.last_name
                    }
                    alt="images"
                  />
                  <div
                    className="absolute bottom-0"
                    style={{
                      left: "35%",
                      transform: "translateX(-50%)",
                      transform: "translateY(50%)",
                    }}
                  ></div>
                </div>
              </div>

              {/* --------------------------------------------------------------------------------------------------------------- */}
              <div
                style={{ color: "#111111" }}
                className="w-full flex md:flex-row flex-col gap-4 justify-between text-left "
              >
                <div className="space-y-3">
                  <div className="md:text-xl text-lg font-bold text-left">
                    {
                      profileData[
                        profileData?.role === "instructor"
                          ? "teacher"
                          : profileData?.role
                      ]?.first_name
                    }
                    &nbsp;
                    {
                      profileData[
                        profileData?.role === "instructor"
                          ? "teacher"
                          : profileData?.role
                      ]?.last_name
                    }
                  </div>
                  <div className="font-normal text-sm">
                    {profileData?.username}
                  </div>
                  <div className="font-normal md:text-lg text-base word-break">
                    {
                      profileData[
                        profileData?.role === "instructor"
                          ? "teacher"
                          : profileData?.role
                      ]?.headline
                    }
                  </div>
                  <div className="font-bold text-sm">
                    {
                      profileData[
                        profileData?.role === "instructor"
                          ? "teacher"
                          : profileData?.role
                      ]?.region
                    }
                    ,
                    {
                      profileData[
                        profileData?.role === "instructor"
                          ? "teacher"
                          : profileData?.role
                      ]?.country
                    }
                  </div>
                </div>
                {/* <div className="flex flex-col gap-6 pt-4">
                  <div className="flex gap-3 md:ml-2">
                    <div>
                      <img
                        src="/assets/university-logo.png "
                        className="object-center"
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "#111111",
                      }}
                      className="font-bold "
                    >
                      <div>Yale University,</div>
                      <div>New Haven.</div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* ----------------------------------------------------- */}
        {/* ----------------------------------------------------- */}

        <div className="pt-10">
          {/* -------------------------------------------- */}
          {/* --------------------------------------------- */}

          <div>
            <div className="flex-grow bg-white rounded-xl pb-8  relative ">
              <div className="p-5 ">
                <div className="text-2xl font-medium text-black text-left px-5">
                  Achievements
                </div>
                <div className="flex flex-col space-y-2 divide-y">
                  {profileData[
                    profileData?.role === "instructor"
                      ? "teacher"
                      : profileData?.role
                  ]?.achievements ? (
                    profileData[
                      profileData?.role === "instructor"
                        ? "teacher"
                        : profileData?.role
                    ]?.achievements.map((item) => (
                      <AchievementsCard
                        image={medal}
                        title={item.title}
                        content={item.description}
                      />
                    ))
                  ) : (
                    <div className="flex items-center justify-center py-6 ">
                      <div className="text-xl font-light ">
                        No Achievements To Show
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* ---------------------------------------------- */}
          {/* ------------------------------------------------ */}
          {/* <Achievements /> */}
        </div>
        {/* <div className="pt-10">
          <FeedSection />
        </div> */}
      </div>
    </div>
  );
}

export default DynamicProfile;
