import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment"; // Import moment library
import React from "react";

const ScheduleTable = ({ timeTable }) => {
  const getNextDayOfWeek = (dayOfWeek) => {
    const now = moment(); // Use moment() to get current date and time
    const resultDate = now.day(dayOfWeek); // Set the day of the week using day()
    return resultDate.format("YYYY-MM-DD"); // Format to YYYY-MM-DD
  };

  const events = timeTable
    ? timeTable.map((item) => {
        const dayOfWeek = item.day.toLowerCase();
        const date = getNextDayOfWeek(dayOfWeek);
        const startTime = `${item?.fromTime?.hours}:${item?.fromTime?.minutes}`;
        const endTime = `${item?.endTime?.hours}:${item?.endTime?.minutes}`;
        return {
          title: item?.name,
          start: `${date}T${startTime}`,
          end: `${date}T${endTime}`,
          extendedProps: {
            instructorName: `${item?.subject?.teacher?.teacher?.first_name} ${item?.subject?.teacher?.teacher?.last_name}`,
            subjectName: item?.subject?.name,
            // fromTime: `${item?.fromTime?.hours}:${item?.fromTime?.minutes}`,
            // endTime: `${item?.endTime?.hours}:${item?.endTime?.minutes}`,
          },
        };
      })
    : [];

  const eventContent = (eventInfo) => {
    return (
      <>
        <div className="capitalize">
          {eventInfo?.event?.extendedProps?.instructorName}
        </div>
        <div className="capitalize">
          {eventInfo?.event?.extendedProps?.subjectName}
        </div>
      </>
    );
  };

  return (
    <div className="pt-5 react-calender">
      <div className=" text-center text-xl font-semibold pb-4">Time Table</div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        events={events}
        eventContent={eventContent}
        headerToolbar={false}
        dayHeaderFormat={{ weekday: "long" }}
        contentHeight="auto"
        allDaySlot={false}
        height={"auto"}
        slotMaxTime={"18:00:00"}
        slotMinTime={"07:00:00"}
        slotMinHeight={40}
        firstDay={1}
      />
    </div>
  );
};

export default ScheduleTable;
