import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import LoginPage from "../auth/LoginPage";
import RegisterPage from "../auth/RegisterPage";
import ForgotPassword from "../auth/password/ForgotPassword";
import ParentResetPassword from "../auth/password/parent/ParentResetPassword";
import ResetPassword from "../auth/password/reset/ResetPassword";
import AuthLayout from "../layout/AuthLayout";
import { selectUser } from "../redux/slices/auth.slice";

const AuthRoutes = () => {
  const { url } = useRouteMatch();

  const history = useHistory();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      history.replace("/dashboard");
    }
  }, [user, history]);

  return (
    <AuthLayout>
      <Switch>
        <Route path={`${url}/login`}>
          <LoginPage />
        </Route>
        <Route path={`${url}/register`}>
          <RegisterPage />
        </Route>
        <Route path={`${url}/password/forgot-password`}>
          <ForgotPassword />
        </Route>
        <Route path={`${url}/password/reset/:token`}>
          <ResetPassword />
        </Route>
        <Route path={`${url}/password/parent/:phone`}>
          <ParentResetPassword />
        </Route>
      </Switch>
    </AuthLayout>
  );
};

export default AuthRoutes;
