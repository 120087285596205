import { Button } from "antd";
import React from "react";
/**
 *
 * @param {object} props
 * @param {object} props.data module data
 * @param {object} props.subject subject data
 * @returns
 */
export default function ModuleDetails({ data, subject, goBack }) {
  return (
    <div>
      {goBack && (
        <div className="flex items-center justify-end">
          <Button onClick={goBack}>back</Button>
        </div>
      )}
      <div className="text-primary-500 font-bold">{subject?.name}</div>
      <div className="pt-4 space-y-6">
        <div className="text-lg font-medium text-gray-900">{data?.title}</div>
        <div className="text-lg font-medium text-gray-900">Overview</div>
        <div className="text-gray-900">{data?.overview}</div>
        <div className="text-lg font-medium text-gray-900">Resources</div>

        <div>
          {data?.urls.length ? (
            <div className="space-y-2 w-full flex flex-col">
              {data?.urls?.map((item) => (
                <a
                  key={item.link}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className="px-4 py-2 rounded-lg w-full underline"
                  style={{
                    boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.25)",
                    fontSize: "16px",
                  }}
                >
                  {item.name} {item.author && "by " + item.author}
                </a>
              ))}
            </div>
          ) : (
            <div className="text-sm text-gray-500 text-left">
              No Resources available
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
