import React, { useState } from "react";
import { Avatar, Form } from "antd";
import { Input, message, Button } from "antd";
import ImageUploadButton from "../../../../components/inputs/ImageUploadButton";
import gallery from "../../../../resources/gallery.svg";
import { cloudFrontUrl, serverUrl } from "../../../../nestserver";
import { useQueryClient } from "react-query";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/slices/auth.slice";

function Announcement() {
  const user = useSelector(selectUser);
  const { TextArea } = Input;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [image, setImage] = useState({
    image: "",
  });

  const onAnnounce = (values) => {
    values["image"] = image?.image;
    values["user"] = user?._id;

    axios({
      method: "post",
      url: serverUrl + "/announcement",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
      data: { ...values, content: values?.content?.trim() },
    })
      .then((res) => {
        message.success("Feed Added");
        form.resetFields();
        queryClient.invalidateQueries("announcement");
        setImage((i) => ({
          ...i,
          image: "",
        }));
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  return (
    <div>
      <div className="pt-8">
        <div className="bg-white drop-shadow-md rounded-lg">
          <Form
            name="basic"
            initialValues={{}}
            onFinish={onAnnounce}
            form={form}
          >
            <div className="p-5">
              <div
                style={{ color: "#616161" }}
                className="flex flex-row w-full space-x-3 text-left text-lg"
              >
                <div>
                  <Avatar
                    size={50}
                    src={
                      user[user?.role == "instructor" ? "teacher" : user?.role]
                        ?.profile_image
                        ? cloudFrontUrl +
                          "/" +
                          user?._id +
                          "/" +
                          user[
                            user?.role == "instructor" ? "teacher" : user?.role
                          ]?.profile_image
                        : "https://ui-avatars.com/api/?name=" +
                          user[
                            user?.role == "instructor" ? "teacher" : user?.role
                          ]?.first_name +
                          " " +
                          user[
                            user?.role == "instructor" ? "teacher" : user?.role
                          ]?.last_name
                    }
                    alt="logo"
                  ></Avatar>
                </div>

                <div className="w-full px-1">
                  <Form.Item
                    name="content"
                    rules={[
                      {
                        required: true,
                        message: "Please input the content",
                      },
                    ]}
                  >
                    <TextArea
                      rows={6}
                      placeholder=""
                      className="w-full bg-gray-300 focus:outline-none resize-none"
                    />
                  </Form.Item>
                </div>
              </div>

              {/* ---------------------------------------------------------------------- */}
              <div className="flex items-center justify-between pt-3 pl-16">
                <div className="flex items-center space-x-2">
                  <ImageUploadButton
                    folder={user?._id}
                    ratio={9 / 2.5}
                    onUpload={(e) =>
                      setImage((i) => ({
                        ...i,
                        image: e,
                      }))
                    }
                  >
                    <div
                      style={{ borderColor: "#00627B" }}
                      className="py-2 px-4 rounded border"
                    >
                      <div className="flex space-x-1">
                        <img src={gallery} />
                        <div>Upload images</div>
                      </div>
                    </div>
                  </ImageUploadButton>
                  <span>{image?.image}</span>
                </div>
                <div className="flex items-center h-full">
                  <Form.Item className="mb-0">
                    <Button size="large" type="primary" htmlType="submit">
                      Post
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Announcement;
