import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
  TimePicker,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import ScheduleTable from "./ScheduleTable";

const TimeTable = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [classId, setClassId] = useState(null);
  const [addTimetablePopup, setAddTimetablePopup] = useState();
  const [subjectId, setSubjectId] = useState();
  const [filterClassId, setFilterClassId] = useState(null);
  const [filterDay, setFilterDay] = useState(null);
  const [filterInstructorId, setFilterInstructorId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const queryClient = useQueryClient();
  const [fromTime, setFromTime] = useState(null);

  const handleFromChange = (time) => {
    setFromTime(time);
  };

  const disableToTime = (current) => {
    if (!fromTime) return false;
    return current && current <= fromTime;
  };

  //handle class Select change
  const handleSelectChange = (id) => {
    setFilterClassId(id);
  };

  const handleDayChange = (name) => {
    setFilterDay(name);
  };


  const handleInstructorChange = (id) => {
    setFilterInstructorId(id);
  };

  //fetch class
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  // fetch Instructors
  async function fetchInstructors() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/",
      params: {
        role: "instructor",
        class: filterClassId,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const instructors = useQuery("instructors", fetchInstructors);

  //fetch subject
  async function fetchSubjects(classId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/subjects/?class=${classId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const subjects = useQuery(
    ["subjects", classId],
    () => classId && fetchSubjects(classId),
    {
      enabled: !!classId, // Only fetch if classIdData is truthy
    }
  );
  //get time table
  async function fetchTimeTable(filterClassId, filterDay, filterInstructorId) {
    const res = await axios({
      method: "get",
      url: serverUrl + "/time-table/",
      params: {
        class: filterClassId,
        day: filterDay,
        instructor: filterInstructorId,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const timeTable = useQuery(
    ["time-table", filterClassId, filterDay, filterInstructorId],
    () => fetchTimeTable(filterClassId, filterDay, filterInstructorId),
    {
      enabled: !!(filterClassId || filterDay || filterInstructorId),
    }
  );
  async function fetchTimeTableData() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/time-table/class/" + filterClassId,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const weekTimeTable = useQuery(
    ["time-table", filterClassId],
    () => fetchTimeTableData(),
    {
      enabled: !!filterClassId,
    }
  );

  const handleSubject = (id) => {
    setSubjectId(id);
  };

  const columns = [
    {
      title: "Name",
      key: "",
      align: "center",
      render: (record) => (
        <div
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.name}
        </div>
      ),
    },
    {
      title: "Subject",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center "
        >
          {record?.subject?.name}
        </div>
      ),
    },
    {
      title: "Instructor",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center "
        >
          {`${record?.subject?.teacher?.teacher?.first_name} ${record?.subject?.teacher?.teacher?.last_name}`}
        </div>
      ),
    },
    {
      title: "Day",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize "
        >
          {record?.day}
        </div>
      ),
    },
    {
      title: "From",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center "
        >
          {record?.fromTime
            ? `${record?.fromTime.hours}: ${record?.fromTime.minutes} `
            : ""}
        </div>
      ),
    },
    {
      title: "To",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center "
        >
          {record?.endTime
            ? `${record?.endTime.hours}: ${record?.endTime.minutes} `
            : ""}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record, index) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center cursor-pointer"
          onClick={() => {
            setIsEditMode(true);
            setEditData({ [index?._id]: record });
            setAddTimetablePopup(true);
          }}
        >
          Edit
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (subjectId && classId) {
      subjects?.isSuccess &&
        subjects?.data &&
        subjects?.data
          ?.filter((data) => data?._id === subjectId)
          .map((data) =>
            form.setFieldsValue({
              instructor: data?.teacher?.teacher?.first_name
                ? ` ${data?.teacher?.teacher?.first_name}  ${data?.teacher?.teacher?.last_name}`
                : "",
            })
          );
    }
  }, [subjectId, classId]);

  const handleSchedule = (values) => {
    const payload = {
      day: values?.day,
      class: values?.class,
      subject: isEditMode ? subjectId : values?.subjects,
      name: values?.name,
      fromTime: {
        hours: moment(values?.time?.from).format("HH"),
        minutes: moment(values?.time?.from).format("mm"),
      },
      endTime: {
        hours: moment(values?.time?.to).format("HH"),
        minutes: moment(values?.time?.to).format("mm"),
      },
    };

    if (payload) {
      if (isEditMode) {
        axios
          .put(`${serverUrl}/time-table/${Object.keys(editData)[0]}`, payload, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          })
          .then((res) => {
            form.resetFields();
            setAddTimetablePopup(false);
            setEditData({});
            setIsEditMode(false);
            queryClient.invalidateQueries(["time-table"]);
            message.success(res?.data?.result?.message);
          })
          .catch((err) => {
            if (err?.response?.data?.error?.errors) {
              message.error(err?.response?.data?.error?.errors);
            }
          });
      } else {
        axios
          .post(`${serverUrl}/time-table`, payload, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          })
          .then((res) => {
            form.resetFields();
            setAddTimetablePopup(false);
            queryClient.invalidateQueries(["time-table"]);
            message.success(res?.data?.result?.message);
          })
          .catch((err) => {
            if (err?.response?.data?.error?.errors) {
              message.error(err?.response?.data?.error?.errors);
            }
          });
      }
    }
  };

  useEffect(() => {
    if (isEditMode) {
      form.setFieldsValue({
        name: editData[Object.keys(editData)[0]]?.name,
        day: editData[Object.keys(editData)[0]]?.day,
        time: {
          from: moment(
            `${editData[Object.keys(editData)[0]]?.fromTime?.hours}:${
              editData[Object.keys(editData)[0]]?.fromTime?.minutes
            }`,
            "HH:mm"
          ),
          to: moment(
            `${editData[Object.keys(editData)[0]]?.endTime?.hours}:${
              editData[Object.keys(editData)[0]]?.endTime?.minutes
            }`,
            "HH:mm"
          ),
        },
        class: editData[Object.keys(editData)[0]]?.class?._id,
        subjects: editData[Object.keys(editData)[0]]?.subject?.name,
        instructor: `${
          editData[Object.keys(editData)[0]]?.subject?.teacher?.teacher
            ?.first_name
        } ${
          editData[Object.keys(editData)[0]]?.subject?.teacher?.teacher
            ?.last_name
        }`,
      });
    }
  }, [isEditMode]);

  return (
    <div>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Manage Time Table
          </div>
          <div className="flex items-center gap-4">
            <div className="flex gap-4 w-273 items-center">
              <h3 className="text-base font-semibold">Class</h3>
              <Select
                showArrow
                allowClear
                style={{ width: 200 }}
                placeholder="Select Class"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={handleSelectChange}
              >
                {classes?.isSuccess &&
                  classes?.data?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {data.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <ScheduleTable
            timeTable={
              weekTimeTable?.isSuccess &&
              weekTimeTable?.data?.result &&
              weekTimeTable?.data?.result
            }
          />
          <div className="flex items-center gap-4 pt-6">
            <div className="flex gap-4 w-273 items-center">
              <h3 className="text-base font-semibold">Day</h3>
              <Select
                showArrow
                style={{ width: 200 }}
                placeholder="Select day"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={handleDayChange}
                allowClear
              >
                <Option value="sun">Sunday</Option>
                <Option value="mon">Monday</Option>
                <Option value="tue">Tuesday</Option>
                <Option value="wed">Wednesday</Option>
                <Option value="thu">Thursday</Option>
                <Option value="fri">Friday</Option>
                <Option value="sat">Saturday</Option>
              </Select>
            </div>
            <div className="flex gap-4 w-273 items-center">
              <h3 className="text-base font-semibold">Instructor</h3>
              <Select
                showSearch
                showArrow
                allowClear
                placeholder="Select Instructor"
                optionFilterProp="children"
                style={{
                  border: "6px",
                  backgroundColor: "#EBEBEB",
                }}
                className="capitalize"
                onChange={handleInstructorChange}
              >
                {instructors.isSuccess &&
                  instructors?.data?.map((data) => {
                    return (
                      <Option
                        key={data._id}
                        value={data._id}
                        className="capitalize"
                      >
                        {data?.teacher?.first_name} {data?.teacher?.last_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className="w-full text-right pt-4">
            <Button
              size="large"
              type="primary"
              htmlType="button"
              className="text-white"
              onClick={() => setAddTimetablePopup(true)}
            >
              Add
            </Button>
          </div>
          <div className="pt-4 w-full">
            <Table
              columns={columns}
              dataSource={
                timeTable?.isSuccess &&
                timeTable?.data?.result &&
                timeTable?.data?.result?.data
                  ? timeTable?.data?.result?.data
                  : []
              }
              pagination={true}
              loading={timeTable?.isLoading}
            />
          </div>
        </div>

        <Modal
          width={500}
          visible={addTimetablePopup}
          maskClosable={false}
          onOk={() => setAddTimetablePopup(false)}
          onCancel={() => {
            setAddTimetablePopup(false);
            setEditData({});
            setIsEditMode(false);
            form.resetFields();
          }}
          footer={null}
        >
          <div>
            {/* ---------------------------form---------------- */}
            <h3 className="text-lg font-bold pb-3">
              {isEditMode ? "Update Schedule" : "Add Schedule"}
            </h3>
            <Form
              layout="vertical"
              name="basic"
              form={form}
              autoComplete="off"
              onFinish={handleSchedule}
            >
              <div>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Enter the schedule name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Days"
                  name="day"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please select day",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select day"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                  >
                    <Option value="sun">Sunday</Option>
                    <Option value="mon">Monday</Option>
                    <Option value="tue">Tuesday</Option>
                    <Option value="wed">Wednesday</Option>
                    <Option value="thu">Thursday</Option>
                    <Option value="fri">Friday</Option>
                    <Option value="sat">Saturday</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Time"
                  name="time"
                  rules={[
                    {
                      required: true,
                      message: "Enter the schedule time",
                    },
                  ]}
                  className=""
                >
                  <div className="flex time-css">
                    <Form.Item
                      name={["time", "from"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Enter the start time",
                        },
                      ]}
                      className="w-full"
                    >
                      <TimePicker
                        placeholder="From"
                        className="w-full"
                        showSecond={false}
                        onChange={handleFromChange}
                      />
                    </Form.Item>
                    <Form.Item
                      name={["time", "to"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Enter the end time",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const fromTime = getFieldValue(["time", "from"]);
                            if (fromTime && value <= fromTime) {
                              return Promise.reject(
                                new Error(
                                  "To time must be greater than From time"
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      className="w-full"
                    >
                      <TimePicker
                        placeholder="To"
                        className="w-full"
                        style={{ marginLeft: "20px" }}
                        showSecond={false}
                        disabledTime={(current) => disableToTime(current)}
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                  label="Class"
                  name="class"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please select a class",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select Class"
                    className="w-full bg-gray-300"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    onChange={(e) => setClassId(e)}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownClassName="custom-dropdown"
                    onSelect={(value, option) => {
                      form.setFieldsValue({
                        subjects: undefined,
                        instructor: undefined,
                      });
                    }}
                  >
                    {classes?.isSuccess &&
                      classes?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Subject"
                  name="subjects"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please select the subjects",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select Subject"
                    className="w-full bg-gray-300"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownClassName="custom-dropdown"
                    onChange={handleSubject}
                  >
                    {subjects?.isSuccess && Array.isArray(subjects?.data) ? (
                      subjects?.data?.map((data) => {
                        return (
                          <Option key={data?._id} value={data?._id}>
                            {data?.name}
                          </Option>
                        );
                      })
                    ) : (
                      <Option value="" disabled>
                        No subjects found
                      </Option>
                    )}
                  </Select>
                </Form.Item>

                <Form.Item label="Instructor" name="instructor">
                  <Input readOnly />
                </Form.Item>
              </div>
              {/* ----------------------------------------------- */}
              <div className="flex justify-end items-center pt-6">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    onClick={() => {
                      setAddTimetablePopup(false);
                      setEditData({});
                      setIsEditMode(false);
                      form.resetFields();
                    }}
                    size="large"
                    type="dark"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    {isEditMode ? "Update" : " Submit"}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default TimeTable;
