import { Table } from "antd";
import React from "react";

function InstructorClass(props) {
  const columns = [
    {
      title: "Subject",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Classes",
      dataIndex: ["class", "name"],
      key: "name",
      align: "center",
    },
  ];

  return (
    <>
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-5 w-full "
        >
          <Table
            columns={columns}
            dataSource={props.data && props.data?.teacher?.subjects}
            pagination={false}
          />
        </div>
      </div>
    </>
  );
}

export default InstructorClass;
