import React, { useState } from "react";
import medal from "../../resources/medal01.svg";
import AchievementsCard from "./AchievementsCard";

function StaticAchievement({ data }) {
    return (
        <div>
            <div className="flex-grow bg-white rounded-xl pb-8  relative ">
                <div className="p-5 ">
                    <div className="text-2xl font-medium text-black text-left px-5">
                        Achievements
                    </div>
                    <div className="flex flex-col space-y-2 divide-y">
                        {data?.achievements ? (
                            data?.achievements?.map((item) => (
                                <AchievementsCard
                                    key={item._id}
                                    image={medal}
                                    title={item.title}
                                    content={item.description}
                                />
                            ))
                        ) : (
                            <div className="flex items-center justify-center py-6 ">
                                <div className="text-xl font-light ">
                                    No Achievements To Show
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StaticAchievement;
