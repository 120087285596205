import { DatePicker, Form, Input, Select, Table, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";

const AttendanceHome = () => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [selectedStudentId, setSelectedStudentId] = useState();
  const [selectedStudentResponse, setSelectedStudentResponse] = useState();
  const [selectedStudent, setSelectedStudent] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const handleDateRangeChange = (dates) => {
    const [fromDate, toDate] = dates;
    setDateRange({ from: fromDate, to: toDate });
  };

  async function fetchStudent() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/students/by-parent/`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data.result;
  }
  const students = useQuery("students/by-parent", fetchStudent);

  const handleSelectChange = (studentId) => {
    // debugger;
    setSelectedStudentId(studentId);
    const student =
      students?.data &&
      students?.data.length > 0 &&
      students?.data?.find((data) => data?._id === studentId);
    if (student) {
      setSelectedStudent(student?.class?.name);
    }
  };
  const fetchStudentsAttendance = async () => {
    const res = await axios({
      method: "get",
      // url: `${serverUrl}/attendance/student/${selectedStudentId}?fromDate=${fromDate}&toDate=${toDate}`,
      url: `${serverUrl}/attendance/student/${selectedStudentId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  useEffect(() => {
    if (selectedStudentId) {
      fetchStudentsAttendance()
        .then((response) => {
          setSelectedStudentResponse(response?.result);
          setFilteredData(response?.result?.attendance);
        })
        .catch((err) => {
          message.error(err?.response?.data?.error?.errors);
        });
    }
  }, [selectedStudentId]);

  useEffect(() => {
    const filteredAttendance = selectedStudentResponse?.attendance?.filter(
      (data) => {
        const attendanceMoment = moment(data.date);
        if (
          !moment(dateRange.from).isValid() ||
          !moment(dateRange.to).isValid()
        ) {
          return true;
        }

        return (
          attendanceMoment.isSameOrAfter(dateRange.from, "day") &&
          attendanceMoment.isSameOrBefore(dateRange.to, "day")
        );
      }
    );

    setFilteredData(filteredAttendance);
  }, [selectedStudentId, dateRange]);

  const columns = [
    {
      title: " Date",

      key: "",
      align: "center",
      render: (record) => (
        <div style={{ color: "#111111" }} className="text-base  text-center">
          {moment(record?.date).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      title: " Morning",
      key: "",
      align: "center",
      render: (record) => (
        <div style={{ color: "#111111" }} className="text-base  text-center">
          {record?.is_present_morning ? (
            <span className="flex justify-center">
              <BiCheckCircle className="text-green-500" size={20} />
            </span>
          ) : (
            <span className="flex justify-center">
              <BiXCircle className="text-red-500" size={20} />
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Afternoon",
      key: "",
      align: "center",
      render: (record) => (
        <div style={{ color: "#111111" }} className="text-base  text-center">
          {record?.is_present_afternoon ? (
            <span className="flex justify-center">
              <BiCheckCircle className="text-green-500" size={20} />
            </span>
          ) : (
            <span className="flex justify-center">
              <BiXCircle className="text-red-500" size={20} />
            </span>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="pt-8">
        <div className="bg-white p-10 rounded-2xl">
        <div className="text-2xl text-orange-500 font-semibold pb-4">
          Manage Attendance
        </div>
          <Form
            layout="vertical"
            name="basic"
            autoComplete="off"
            className="w-1/2"
          >
            <Form.Item
              label="Name"
              name="name"
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Please select name",
                },
              ]}
            >
              <Select
                showSearch
                showArrow
                placeholder="Select name"
                className="w-full bg-gray-300"
                style={{
                  border: "6px",
                  backgroundColor: "#EBEBEB",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => handleSelectChange(value)}
              >
                {students &&
                  students?.data?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {`${data?.first_name} ${data?.last_name}`}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label="Class" className="w-full">
              <Input readonly value={selectedStudent} />
            </Form.Item>
            <Form.Item label="Attendance %" className="w-full">
              <Input
                readOnly
                value={selectedStudentResponse?.attendancePercent}
              />
            </Form.Item>
            <Form.Item label="Choose a range" name="date" className="w-full">
              <RangePicker
                format={"DD/MM/YYYY"}
                disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))}
                className="w-full"
                onChange={handleDateRangeChange}
              />
            </Form.Item>
          </Form>
          <div className="pt-5 attendance-table">
            <Table
              columns={columns}
              className="w-1/2"
              dataSource={
                filteredData && filteredData.length > 0 ? filteredData : []
              }
              loading={filteredData?.isLoading}
              pagination={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceHome;




