import React from "react";
import { Tabs } from "antd";
import NotificationCard from "../cards/NotificationCard";
import {
  useAnnouncementNotifications,
  useNotifications,
} from "../../services/notification.service";
import { useMemo } from "react";

const { TabPane } = Tabs;

export default function NotificationWidget() {
  const notifications = useNotifications();

  const filteredNotifications = useMemo(() => {
    let data = [];
    data = notifications?.data?.filter(
      (n) => n.type === "assignment" || n.type === "exam"
    );

    return data;
  }, [notifications?.data]);
  return (
    <Tabs defaultActiveKey="1">
      {/* <TabPane tab="School" key="1">
        <div className="space-y-2">
          {announcements?.data?.length ? (
            announcements?.data?.map((announcement) => (
              <NotificationCard
                key={announcement._id}
                title={announcement?.title}
                data={announcement?.createdAt}
                description={announcement?.description}
              />
            ))
          ) : (
            <div>No notifications</div>
          )}
        </div>
      </TabPane> */}
      <TabPane tab="Classes" key="2">
        <div className="space-y-2">
          {notifications?.data?.length ? (
            filteredNotifications?.map((notification) => (
              <NotificationCard
                key={notification._id}
                title={notification?.title}
                description={notification?.description}
                date={notification?.createdAt}
              />
            ))
          ) : (
            <div>No notifications</div>
          )}
        </div>
      </TabPane>
    </Tabs>
  );
}
