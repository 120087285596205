import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";

const ParentHomePage = () => {
  const { Search } = Input;
  const { Option } = Select;
  const history = useHistory();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [addSubject, setAddSubject] = useState(false);

  // fetch subjects
  async function fetchSubjects() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/subjects/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const subjects = useQuery("subjects", fetchSubjects);

  // fetch classes
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  // fetch departments
  async function fetchDepartments() {
    const response = await axios.get(serverUrl + "/department", {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return response.data;
  }
  const departments = useQuery("departments", fetchDepartments);

  // Table Settings

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [searchInput, setSearchInput] = useState(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search Subject`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Application No.",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          DR7675
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: ["name"],
      key: "x",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#616161" }}
          className=" text-base font-semibold"
        >
          Saket
        </div>
      ),
      ...getColumnSearchProps("name"),
    },
    {
      title: "Class",
      // dataIndex: "class['name']",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          4th
        </div>
      ),
      filters: classes.isSuccess
        ? classes?.data?.map((c) => ({ text: c.name, value: c?._id }))
        : [],
      onFilter: (value, record) => record?.class?._id === value,
    },
    {
      title: "Application Date",
      dataIndex: ["applicationDate"],
      key: "createdAt",
      sorter: (a, b) => (moment(a?.createdAt).isBefore(b?.createdAt) ? 1 : -1),
      render: (text) => (
        <div style={{ color: "#616161" }} className=" text-base">
          {moment(text).format("Do MMMM YYYY")}
        </div>
      ),
    },
    {
      title: "Accepted Date",
      dataIndex: ["acceptedDate"],
      key: "createdAt",
      sorter: (a, b) => (moment(a?.accepted_at).isBefore(b?.accepted_at) ? 1 : -1),
      render: (text) => (
        <div style={{ color: "#616161" }} className=" text-base">
          {text? moment(text).format("Do MMM YYYY"):"---"}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          Pending
        </div>
      ),
    },
    {
      title: "Message",
      // dataIndex: "class['name']",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          Message
        </div>
      ),
    },
    {
      title: "Action",
      // dataIndex: "action",
      key: "action",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          className=" text-base font-semibold text-center text-blue-500 cursor-pointer"
          onClick={() =>
            history.push(`/dashboard/parent/menu/admission/${record?._id}`)
          }
        >
          View
        </div>
      ),
    },
  ];

  function createSubject(values) {
    setAddSubject(false);
    axios
      .post(serverUrl + "/subjects", values, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("Subject Created");
        form.resetFields();
        queryClient.invalidateQueries("subjects");
      })
      .catch((error) => {
        if (error?.response?.data) {
          message.error(error?.response?.data.message);
        } else {
          message.error(error.message);
        }
      });
  }

  return (
    <>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex items-center justify-between"></div>
          <div className="py-5 table-overflow ">
            <Table
              columns={columns}
              dataSource={
                subjects.isSuccess
                  ? subjects?.data?.sort((a, b) =>
                      moment(a?.createdAt).isBefore(b?.createdAt) ? 1 : -1
                    )
                  : []
              }
              pagination={false}
              loading={subjects.isLoading}
            />
          </div>
        </div>
      </div>
      {/* ----------------------------------modal------------------------------------ */}

      <Modal
        width={800}
        visible={addSubject}
        onCancel={() => {
          setAddSubject(false);
          form.resetFields();
        }}
        footer={null}
      >
        <div>
          <div className="py-2">
            <div className="text-2xl font-bold text-black pb-4 capitalize">
              Add Subject
            </div>
          </div>
          {/* ---------------------------form---------------- */}
          <div>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              onFinish={createSubject}
              autoComplete="off"
            >
              <Form.Item
                label="Subject Name"
                name="name"
                rules={[
                  { required: true, message: "Please input name for subject" },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>

              <Form.Item
                label="Class"
                name="class"
                className="w-full "
                rules={[
                  {
                    required: true,
                    message: "Please select a class for the subject",
                  },
                ]}
              >
                <Select
                  showSearch
                  showArrow
                  allowClear
                  placeholder="Select Class"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  style={{
                    border: "6px",
                    backgroundColor: "#EBEBEB",
                  }}
                >
                  {classes.isSuccess &&
                    classes?.data?.map((data) => {
                      return (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Department"
                name="department"
                className="w-full "
                rules={[
                  {
                    required: true,
                    message: "Please select a department for the subject",
                  },
                ]}
              >
                <Select
                  showSearch
                  showArrow
                  allowClear
                  placeholder="Select Department"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  style={{
                    border: "6px",
                    backgroundColor: "#EBEBEB",
                  }}
                >
                  {departments.isSuccess &&
                    departments?.data?.map((data) => {
                      return (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <div className="flex justify-end items-center pt-6">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => {
                      setAddSubject(false);
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Add Subject
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          {/* ----------------------------------------------- */}
        </div>
      </Modal>
    </>
  );
};

export default ParentHomePage;
