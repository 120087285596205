import { Button } from "antd";
import React, { useState } from "react";
import AssignmentExam from "../../../../../instructor/pages/AssignmentExam";

export default function SubjectEvaluation({ subject, onBack }) {
  const [back, setBack] = useState(true);
  return (
    <div className={back ? "rounded-lg bg-white shadow-lg p-6" : ""}>
      {back && (
        <>
          <div className="flex justify-end items-center">
            <Button onClick={onBack}>Back</Button>
          </div>
          <div className="text-orange-500 text-xl font-bold">Evaluations</div>
          <div className="pt-4">
            {subject?.name} <span className="font-bold pl-4">Class : </span>
            {subject?.class?.name}
          </div>
        </>
      )}

      <AssignmentExam
        onCallback={(e) => setBack(e)}
        canCreate={false}
        subjectId={subject?._id}
        classId={subject?.class?._id}
      />
    </div>
  );
}
