import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import Achievements from "../../../components/sections/Achievements";
import EducationList from "../../../components/sections/EducationList";
import FeedSection from "../../../components/sections/FeedSection";
import MainProfile from "../../../components/sections/MainProfile";
import { serverUrl } from "../../../nestserver";

function Profile() {
  async function getAnnouncement() {
    const data = await axios({
      method: "get",
      url: serverUrl + "/announcement",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return data.data;
  }

  const announcement = useQuery("announcement", getAnnouncement);
  return (
    <div className="h-full p-10 ">
      <MainProfile />
      <div className="pt-10">
        <EducationList />
      </div>
      <div className="pt-10">
        <Achievements />
      </div>
      <div className="py-10">
        <div className="bg-white w-full rounded-2xl bg-opacity-40 px-4 pb-4">
          <div
            style={{ color: "#E46E31" }}
            className=" text-orange-500 text-2xl font-semibold p-4 py-6"
          >
            School Post
          </div>
          <div className="space-y-5">
            {announcement?.isSuccess &&
              announcement?.data?.map((item) => {
                return <FeedSection key={item?._id} data={item} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
