import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: undefined,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser(state, action) {
      state.user = action.payload;
    },
    logoutUser(state) {
      state.user = null;
    }
  }
})

// actions
export const { loginUser, logoutUser } = authSlice.actions;

// selectors
export const selectUser = (state) => state.auth.user;

// reducer
export default authSlice.reducer;