import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Select, Space, Table } from "antd";
import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../nestserver";
const AttendanceViewHistory = ({ classId }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState("");

  const [searchedColumn, setSearchedColumn] = useState("");
  const [dateRange, setDateRange] = useState({ from: null, to: null });

  const handleDateRangeChange = (dates) => {
    if (Array.isArray(dates) && dates.length === 2) {
      const [fromDate, toDate] = dates;
      setDateRange({ from: fromDate, to: toDate });
    } else {
      setDateRange({ from: null, to: null });
    }
  };

  const [searchInput, setSearchInput] = useState(null);
  function strcmp(a, b) {
    if (a === b) {
      return 0;
    }

    if (a > b) {
      return 1;
    }

    return -1;
  }
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  //handleExport
  const handleExport = async (e) => {
    e.preventDefault();
    if (classId) {
      axios({
        url: `${serverUrl}/attendance/class/${classId}/export?fromDate=${dateRange.from?.format(
          "YYYY-MM-DD"
        )}&toDate=${dateRange.to?.format("YYYY-MM-DD")}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Accept: "application/json",
          responseType: "blob",
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }).then((response) => {
        saveAs(response.data, "attendance-list");
      });
    }
  };

  const fetchStudentData = async () => {
    const res = await axios.get(
      `${serverUrl}/attendance/students/class/${classId}?from=${dateRange.from?.format(
        "YYYY-MM-DD"
      )}&to=${dateRange.to?.format("YYYY-MM-DD")}`,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }
    );

    return res.data;
  };
  const classDetails = useQuery(["class", classId], fetchStudentData, {
    enabled: !!classId,
  });

  const attendances = classDetails?.data?.data;
  const attendanceMap = useMemo(() => {
    return (attendances || []).reduce((map, attendance) => {
      const studentId = attendance?._id;
      const attendanceRecords = attendance?.attendance || [];

      if (!map[studentId]) {
        map[studentId] = {};
      }

      attendanceRecords.forEach((record) => {
        const date = moment(record.date).format("DD-MM-YYYY");
        if (!map[studentId][date]) {
          map[studentId][date] = [];
        }
        map[studentId][date].push({
          is_present_morning: record.is_present_morning,
          is_present_afternoon: record.is_present_afternoon,
        });
      });

      return map;
    }, {});
  }, [attendances]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: [],
      width: 150,
      fixed: "left",
      sorter: {
        compare: (a, b) => strcmp(a.first_name, b.first_name),
        multiple: 3,
      },
      ...getColumnSearchProps("first_name"),
      render: (record) => (
        <div
          style={{ color: "#111111" }}
          className=" text-base font-semibold capitalize"
        >
          {`${record?.first_name}`}
        </div>
      ),
    },
    {
      title: "Last Name",
      dataIndex: [],
      width: 150,
      fixed: "left",
      sorter: {
        compare: (a, b) => strcmp(a.last_name, b.last_name),
        multiple: 3,
      },
      ...getColumnSearchProps("last_name"),
      render: (record) => (
        <div
          style={{ color: "#111111" }}
          className=" text-base font-semibold capitalize"
        >
          {` ${record?.last_name}`}
        </div>
      ),
    },
    {
      title: "Percentage",
      width: 200,
      fixed: "left",
      render: (record) => (
        <div style={{ color: "#111111" }} className=" text-base font-semibold">
          {`${(record?.attendance_percent || 0).toFixed(2)}%`}
        </div>
      ),
    },
    ...Array.from({
      length: moment(dateRange.to).diff(moment(dateRange.from), "day") + 1,
    }).map((_, index) => {
      const date = moment(dateRange.from)
        .add(index, "days")
        .format("DD-MM-YYYY");
      return {
        title: (
          <div>
            <div className="pb-3">{date}</div>
            <div className="flex justify-center gap-12px">
              <span>Morning</span>
              <span>Afternoon</span>
            </div>
          </div>
        ),
        dataIndex: "_id",
        align: "center",
        width: 200,
        className: "scroll",
        render: (id, record) => {
          const attendanceRecords = attendanceMap[record?._id]?.[date] || [];

          if (attendanceRecords.length === 0) {
            return <div>--</div>;
          }

          return (
            <div>
              {attendanceRecords?.map((record, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center gap-10"
                >
                  <span className="flex flex-col items-center gap-10">
                    {record?.is_present_morning ? (
                      <BiCheckCircle className="text-green-500" size={20} />
                    ) : (
                      <BiXCircle className="text-red-500" size={20} />
                    )}
                  </span>
                  <span className="flex flex-col items-center gap-12px">
                    {record?.is_present_afternoon ? (
                      <BiCheckCircle className="text-green-500" size={20} />
                    ) : (
                      <BiXCircle className="text-red-500" size={20} />
                    )}
                  </span>
                </div>
              ))}
            </div>
          );
        },
      };
    }),
  ];

  if (classId) {
    form.setFieldsValue({
      class_id:
        classDetails?.data &&
        classDetails?.data?.data?.length > 0 &&
        classDetails?.data?.data?.[0]?.class?.name,
    });
  }

  return (
    <div>
      <h3 className="text-lg font-bold pb-3">Student Attendance History</h3>
      <div className=" bg-white pt-5">
        <Form
          form={form}
          layout="vertical"
          name="basic"
          // onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label={<div className="text-base">Class</div>}
            name="class_id"
            className="w-full text-base"
          >
            <Input
              style={{
                width: "32%",
              }}
              readOnly
            />
          </Form.Item>
          <div className="pb-3 text-base ">Choose a Range</div>
          <div className="flex items-start justify-between pb-3">
            <Form.Item name="date">
              <RangePicker
                format={"DD/MM/YYYY"}
                onChange={handleDateRangeChange}
                disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))}
              />
            </Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="download"
              className="text-white w-9"
              onClick={(e) => handleExport(e)}
            >
              Export
            </Button>
          </div>
        </Form>
        <div>
          <Table
            scroll={{ y: 400, x: 100 }}
            className="rounded-2xl"
            columns={columns}
            dataSource={
              classDetails?.isSuccess &&
              classDetails?.data?.data &&
              classDetails?.data?.data.length > 0
                ? classDetails?.data?.data
                : []
            }
            loading={classDetails?.isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AttendanceViewHistory;
