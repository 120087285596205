import { DeleteOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  message,
} from "antd";
import axios from "axios";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { defaultStatus } from "../../../../../helper/options";
import { serverUrl } from "../../../../../nestserver";
import plusicon from "../../../../../resources/plus.svg";
import FeeTable from "./FeeTable";

const FeeTemplate = () => {
  const { Search } = Input;
  const { Option } = Select;
  const [form] = Form.useForm();
  const history = useHistory();
  const [search, setSearch] = useState(null);
  const [addTemplate, setAddTemplate] = useState(false);
  const [otherFields, setOtherFields] = useState([]);
  const [feeComponents, setFeeComponents] = useState();
  const [getTemplateData, setGetTemplateData] = useState([]);
  const [postTemplateResponse, setPostTemplateResponse] = useState();
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [data, setData] = useState({
    tution: "",
    other: "",
  });
  const dateFormat = "YYYY";

  const [searchQuery, setSearchQuery] = useState("");

  const addOtherField = () => {
    setOtherFields([...otherFields, { value: "", label: "" }]);
  };

  const removeOtherField = (index) => {
    const newFields = [...otherFields];
    newFields.splice(index, 1);
    setOtherFields(newFields);
  };

  const handleFeeData = (paramName, value) => {
    setData({ ...data, [paramName]: value });
  };
  const handleOtherFieldChange = (value, index, field) => {
    const newFields = [...otherFields];
    newFields[index][field] = value;
    setOtherFields(newFields);
  };
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  const calculateTotal = () => {
    const tution = parseFloat(data.tution) || 0;
    const other = parseFloat(data.other) || 0;
    const otherFieldsTotal = otherFields.reduce((total, field) => {
      const value = parseFloat(field.value) || 0;
      return total + value;
    }, 0);

    const total = tution + other + otherFieldsTotal;
    if (tution === 0 && other === 0 && otherFieldsTotal === 0) {
      form.setFieldsValue({ total: "" }); // Set total to empty string to clear it
    } else {
      form.setFieldsValue({ total: total.toFixed(2) });
    }
  };

  //handle search

  const handleSearch = (value) => {
    setSearch(value);
  };
  const filteredData = getTemplateData.filter(
    (item) =>
      item?.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedYear === null || item?.year === selectedYear)
  );

  useEffect(() => {
    calculateTotal();
    if (data) {
      const concatenatedData = Object.entries(data).map(([key, value]) => ({
        label: key,
        value,
      }));
      const mergedFields = [...concatenatedData, ...otherFields];
      setFeeComponents(mergedFields);
    }
  }, [otherFields, data]);
  useEffect(() => {
    if (getTemplateData && getTemplateData?.length > 0) {
      getFeeTemplate();
    }
  }, []);

  async function getFeeTemplate() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/admin/fee-template/list",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    const data = res?.data?.result;
    setGetTemplateData(data);

    return res.data;
  }
  function disabledDate(current) {
    return current && current < moment().startOf("day");
  }

  const getTemplate = useQuery("/fee-template/list", getFeeTemplate);
  const onFinish = (values) => {
    const templatePayload = {
      class: values?.class,
      title: values?.title,
      fee_components: feeComponents.map((component) => ({
        name: component.label,
        amount: component.value ? parseFloat(component.value) : 0,
      })),
      due_date: values?.due_date?.format("YYYY-MM-DD"),
      year: values?.year?.format("YYYY"),
      installment_count: values.installment_count,
    };
    axios
      .post(serverUrl + "/admin/fee-template/", templatePayload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        getFeeTemplate();
        setPostTemplateResponse(res);
        message.success(res?.data?.result?.message);
      })
      .catch((err) => {
        if (err?.response?.data?.error?.errors) {
          message.error(err?.response?.data?.error?.errors);
        }
      });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${serverUrl}/admin/fee-template/${id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      getFeeTemplate();
      message.success("Template deleted successfully");
    } catch (error) {
      message.error(error?.response?.data?.error?.errors);
    }
  };

  return (
    <>
      <div className="template">
        <div className="pt-8">
          <div className="bg-white p-10 rounded-2xl">
            <div className="text-2xl text-orange-500 font-semibold pb-8">
              Manage Fee
            </div>
            <div className="flex justify-between pb-4">
              <DatePicker
                picker="year"
                onChange={(date, dateString) => setSelectedYear(dateString)}
                defaultValue={moment()}
                format={dateFormat}
              />
              <Search
                placeholder="input search text"
                enterButton="Search"
                size="large"
                loading={false}
                style={{ backgroundColor: "#E3E3E3" }}
                prefix={<BiSearch className="text-gray-600 " />}
                onSearch={(value) => handleSearch(value)}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-310"
              />
            </div>

            <div className="flex justify-between items-start pt-4">
              <div className="flex gap-20px flex-wrap	">
                {filteredData && filteredData.length > 0 ? (
                  filteredData.map((list) => (
                    <div>
                      <div
                        className="iskool-friends-card flex flex-col space-x-3 cursor-pointer h-full p-20px"
                        key={list?._id}
                        style={{ width: "140px" }}
                      >
                        <span
                          className={clsx(
                            list?.status === defaultStatus?.applied
                              ? "bg-green"
                              : "bg-yellow",
                            "p-1 rounded-lg text-center"
                          )}
                          onClick={() =>
                            history.push(
                              `/dashboard/admin/menu/fee/template/${list?._id}`
                            )
                          }
                        >
                          {list?.status === defaultStatus?.applied
                            ? "Applied "
                            : "Pending"}
                        </span>
                        <div
                          className="text-center mt-14px"
                          onClick={() =>
                            history.push(
                              `/dashboard/admin/menu/fee/template/${list?._id}`
                            )
                          }
                        >
                          <UnorderedListOutlined size={35} />
                          <div className="pt-2">
                            <div className="font-bold h-8">{list?.name}</div>
                          </div>
                        </div>
                        <div className="flex justify-center">
                          {list?.status !== "applied" && (
                            <div className="">
                              <Popconfirm
                                title="Are you sure to delete?"
                                onConfirm={() => handleDelete(list?._id)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button
                                  type="danger flex items-center justify-center"
                                  shape="circle"
                                  icon={<DeleteOutlined />}
                                />
                              </Popconfirm>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No Template Exist</div>
                )}
              </div>
              <div
                onClick={() => setAddTemplate(true)}
                className="cursor-pointer p-28px add-module flex items-center justify-center mt-2"
              >
                <div className="flex flex-col items-center space-y-3">
                  <div className="flex items-center justify-center cursor-pointer">
                    <img className="w-6 h-6" src={plusicon} alt="icon" />
                  </div>
                  <div className="text-white text-sm ">
                    <div>Add new</div>
                    <div>Template</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----------------------------------modal------------------------------------ */}
        {/* Add Template */}
        <Modal
          width={800}
          visible={addTemplate}
          onOk={() => setAddTemplate(false)}
          onCancel={() => {
            form.resetFields();
            setAddTemplate(false);
            setOtherFields([]);
            setPostTemplateResponse(null);
            form.setFieldsValue({ total: "" });
            setFeeComponents();
          }}
          footer={null}
          forceRender
        >
          <div>
            <h3 className="text-lg font-bold pb-3">Add Template</h3>
            <div className="pt-4 w-4/5">
              <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Class"
                  name="class"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please select a class",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select Class"
                    className="w-full bg-gray-300"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {classes.isSuccess &&
                      classes?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Title"
                  name="title"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter title" />
                </Form.Item>
                <Form.Item
                  name="tution"
                  label="Tution Fee"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Tution fee is required",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={0}
                    onChange={(e) => {
                      handleFeeData("tution", e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter amount"
                  />
                </Form.Item>
                {otherFields?.map((field, index) => (
                  <div key={index} className=" space-x-5 mb-2">
                    <h6 key={index} className="mb-8px capitalize">
                      {field?.label}
                    </h6>
                    <div className="flex ml-0px space-x-4 other-feild">
                      <Form.Item
                        name={`otherField_${index}`}
                        label={
                          <Input
                            value={field.label}
                            onChange={(e) => {
                              const value = e.target.value;
                              const capitalizedValue =
                                value.charAt(0).toUpperCase() + value.slice(1);
                              handleOtherFieldChange(
                                capitalizedValue,
                                index,
                                "label"
                              );
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "ArrowUp" ||
                                e.key === "ArrowDown"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            placeholder="Enter title"
                            className="w-24 mr-2 flex"
                            rules={[
                              {
                                required: true,
                                message: "Label is required",
                              },
                            ]}
                          />
                        }
                        className="w-30"
                      >
                        <Input
                          onChange={(e) =>
                            handleOtherFieldChange(
                              e.target.value,
                              index,
                              "value"
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                          onWheel={(e) => e.target.blur()}
                          className="mr-2"
                          placeholder="Enter amount"
                          type="number"
                          min={0}
                        />
                      </Form.Item>
                      <Button
                        type="danger"
                        onClick={() => removeOtherField(index)}
                        className="ml-2"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ))}
                <div className="pb-4">
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={addOtherField}
                    className="mb-2"
                  >
                    Add
                  </Button>
                </div>
                <Form.Item name="other" label="Other" className="w-30">
                  <Input
                    type="number"
                    min={0}
                    onChange={(e) => {
                      handleFeeData("other", e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter amount"
                  />
                </Form.Item>

                <Form.Item name="total" label="Total Amount" className="w-30">
                  <Input
                  readOnly
                  />
                </Form.Item>
                <Form.Item
                  name="year"
                  label="Year"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Year is required",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  name="due_date"
                  label="Due Date"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Due Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  name="installment_count"
                  label="Number of terms (Max 10)"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Number of terms is required",
                    },
                    {
                      validator: (_, value) => {
                        if (value && (value < 1 || value > 10)) {
                          return Promise.reject(
                            "Number of terms should be between 1 and 10"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Enter number of terms"
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
                <div className="flex justify-center items-center py-5">
                  <div className="inline-flex items-center space-x-2">
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="text-white"
                    >
                      Save
                    </Button>
                    {/* <Button size="large" type="dark">
                      Cancel
                    </Button> */}
                  </div>
                </div>
              </Form>
            </div>
            <FeeTable postTemplateResponse={postTemplateResponse} />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default FeeTemplate;
