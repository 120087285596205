import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Input, Typography, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import ImageUploadButton from "../../../components/inputs/ImageUploadButton";
import { cloudFrontUrl, serverUrl } from "../../../nestserver";
import upload from "../../../resources/feather-upload.svg";
const SchoolAdminDetails = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { Title } = Typography;
  const queryClient = useQueryClient();
  const [images, setImages] = useState({
    profile_image: null,
  });

  async function fetchAdmin(id) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/school/${id}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getAdmin = useQuery(["school", id], () => fetchAdmin(id), {
    enabled: !!id,
  });
  const onFinish = (data) => {
    const payload = {
      name: data?.name,
      address: data?.address,
      district: data?.district,
      email: data?.email,
      mandal: data?.mandal,
      phone: data?.phone,
      city: data?.city,
      pincode: data?.pincode,
      refId: data?.refId,
      state: data?.state,
      website: data?.website,
      owner: {
        name: data?.owner_name,
        address: data?.owner_address,
        phone: data?.owner_phone,
        email: data?.owner_email,
      },
      head_master: {
        name: data?.headmaster_name,
        address: data?.headmaster_address,
        phone: data?.headmaster_phone,
        email: data?.headmaster_email,
      },
      logo: images?.profile_image,
    };
    if (id && payload) {
      axios
        .put(serverUrl + "/school/" + id, payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          queryClient.invalidateQueries(["school", id]);
          message.success(res?.data?.result?.message);
        })
        .error((err) => {
          message.error(err?.data?.error?.message);
        });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: getAdmin?.data?.result?.data?.name,
      address: getAdmin?.data?.result?.data?.address,
      district: getAdmin?.data?.result?.data?.district,
      email: getAdmin?.data?.result?.data?.email,
      mandal: getAdmin?.data?.result?.data?.mandal,
      city: getAdmin?.data?.result?.data?.city,
      pincode: getAdmin?.data?.result?.data?.pincode,
      phone: getAdmin?.data?.result?.data?.phone,
      refId: getAdmin?.data?.result?.data?.refId,
      state: getAdmin?.data?.result?.data?.state,
      website: getAdmin?.data?.result?.data?.website,
      owner_name: getAdmin?.data?.result?.data?.owner?.name,
      owner_phone: getAdmin?.data?.result?.data?.owner?.phone,
      owner_email: getAdmin?.data?.result?.data?.owner?.email,
      owner_address: getAdmin?.data?.result?.data?.owner?.address,
      headmaster_name: getAdmin?.data?.result?.data?.head_master?.name,
      headmaster_email: getAdmin?.data?.result?.data?.head_master?.email,
      headmaster_phone: getAdmin?.data?.result?.data?.head_master?.phone,
      headmaster_address: getAdmin?.data?.result?.data?.head_master?.address,
    });
    setImages({
      profile_image: getAdmin?.data?.result?.data?.logo,
    });
  }, [id]);

  return (
    <div>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <Form
            layout="vertical"
            name="basic"
            form={form}
            autoComplete="off"
            onFinish={onFinish}
          >
            <div className="grid grid-cols-2 gap-x-4">
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please input the name!",
                  },
                  {
                    message:
                      "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                    validator: (_, value) => {
                      if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                        );
                      }
                    },
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Please input the address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "Please input the state!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "Please input the city!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="district" label="District">
                <Input />
              </Form.Item>
              <Form.Item name="mandal" label="Mandal">
                <Input />
              </Form.Item>
              <Form.Item
                name="pincode"
                label="Pincode"
                rules={[
                  {
                    pattern: /^[0-9]{6}$/, // Adjust the regex pattern as needed
                    message: "Please enter a valid 6-digit pincode !",
                  },
                  {
                    required: true,
                    message: "Please input the pincode!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 6)}
              >
                <Input type="number" maxLength={6} min={0} />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone"
                className="w-30"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Phone is required",
                  // },
                  {
                    pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                    message: "Please enter a valid 10-digit phone number!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 10)}
              >
                <Input type="number" maxLength={10} min={0} />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="website"
                label="Website"
                rules={
                  [
                    // {
                    //   type: "url",
                    //   message: "Please enter a valid URL",
                    // },
                  ]
                }
              >
                <Input />
              </Form.Item>
              <Form.Item name="refId" label="Registration Id">
                <Input readOnly />
              </Form.Item>
            </div>
            <Title level={2}>Owner/Organization</Title>
            <div className="grid grid-cols-2 gap-x-4">
              <Form.Item name="owner_name" label="Name">
                <Input />
              </Form.Item>
              <Form.Item
                name="owner_phone"
                label="Phone"
                className="w-30"
                rules={[
                  {
                    pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                    message: "Please enter a valid 10-digit phone number!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 10)}
              >
                <Input type="number" maxLength={10} min={0} />
              </Form.Item>
              <Form.Item
                name="owner_email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="owner_address" label="Address">
                <Input />
              </Form.Item>
            </div>
            <Title level={2}>Head Master</Title>
            <div className="grid grid-cols-2 gap-x-4">
              <Form.Item name="headmaster_name" label="Name">
                <Input />
              </Form.Item>
              <Form.Item
                name="headmaster_email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="headmaster_phone"
                label="Phone"
                className="w-30"
                rules={[
                  {
                    pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                    message: "Please enter a valid 10-digit phone number!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 10)}
              >
                <Input type="number" maxLength={10} min={0} />
              </Form.Item>
              <Form.Item name="headmaster_address" label="Address">
                <Input />
              </Form.Item>
            </div>
            <div>
              <div className="py-2">
                <Title level={2}> Logo</Title>

                <div className="flex">
                  <div className="relative w-44 h-44 rounded-full  border-8 border-white">
                    <img
                      className="w-full h-full rounded-full border"
                      src={
                        images?.profile_image
                          ? `${cloudFrontUrl}/common/${images?.profile_image}`
                          : "https://ui-avatars.com/api/?name=" +
                            (getAdmin?.data?.result?.data?.name
                              ? getAdmin?.data?.result?.data?.name
                              : getAdmin?.data?.result?.data?.name)
                      }
                      alt="banner"
                    />
                  </div>
                </div>
              </div>

              <div className="flex  pt-3">
                <div className="flex flex-row space-x-5">
                  <ImageUploadButton
                    onUpload={(e) =>
                      setImages((i) => ({
                        ...i,
                        profile_image: e,
                      }))
                    }
                  >
                    <div
                      style={{ color: "#616161" }}
                      className="flex flex-row space-x-4 cursor-pointer"
                    >
                      <img src={upload} alt="upload" />
                      <div className="text-lg">Upload Logo</div>
                    </div>
                  </ImageUploadButton>
                  <Button
                    onClick={(e) => {
                      setImages((i) => ({
                        ...i,
                        profile_image: null,
                      }));
                      message.success("Logo Removed");
                    }}
                    type="danger"
                    className="flex items-center"
                    icon={<DeleteOutlined />}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </div>
            <div className="pt-8 text-center">
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="text-white"
              >
                Update
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SchoolAdminDetails;
