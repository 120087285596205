import { Form, Input, message, Modal, Table } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import React, { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../nestserver";
import AssignmentEvaluationForm from "../forms/AssignmentEvaluationForm";
import * as moment from "moment";

export default function AssignmentSubmissionTable({
  assignmentId,
  canArchive,
  subjectId,
}) {
  const [evalute, setEvalute] = useState(false);
  const queryClient = useQueryClient();

  // fetch modules
  async function fetchAssignmentSubmissions() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/assignment-submissions/",
      params: {
        assignment: assignmentId,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const assignmentSubmissions = useQuery(
    ["assignment-submissions", assignmentId],
    fetchAssignmentSubmissions,
    {
      enabled: !!assignmentId,
    }
  );

  // Students
  async function getStudents() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/students/subject/" + subjectId,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const students = useQuery(["students", subjectId], getStudents, {
    enabled: !!subjectId,
  });

  const submissions = useMemo(() => {
    if (students.data && assignmentSubmissions.data) {
      return students.data.map((student) => {
        const submission = assignmentSubmissions.data.find(
          (sub) => sub.student._id === student._id
        );
        return (
          submission ?? {
            status: "Not Submitted",
            student,
          }
        );
      });
    }
  }, [students.data, assignmentSubmissions.data]);

  const columns = [
    {
      title: "Student Name",
      dataIndex: ["student", "first_name"],
      key: "_id",
      render: (text) => (
        <div
          style={{ color: "#00627B" }}
          className="text-left text-sm font-medium capitalize"
        >
          {text}
        </div>
      ),
    },

    {
      title: "Submission Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => (
        <div style={{ color: "#00627B" }} className=" text-sm ">
          {text ? moment(text).format("DD-MM-YYYY") : "Not Available"}
        </div>
      ),
      sorter: (a, b) => (moment(a.updatedAt).isBefore(b.updatedAt) ? -1 : 1),
    },
    {
      title: "Status",
      key: "status",
      render: (record) => (
        <div className=" text-sm font-semibold">
          {record.enable_resubmission ? (
            <div className="text-blue-500">Resubmit</div>
          ) : record?.score >= 0 && record?.score != null ? (
            <div className="text-green-500">Evaluated</div>
          ) : (
            <>
              {record?.status === "Not Submitted" ? (
                <div className="text-red-500">{record?.status}</div>
              ) : (
                <div className="text-yellow-500">Submitted</div>
              )}
            </>
          )}
        </div>
      ),
      filters: [
        {
          text: "Submitted",
          value: "submitted",
        },
        {
          text: "Evaluated",
          value: "evaluated",
        },
        {
          text: "Resubmit",
          value: "resubmit",
        },
        {
          text: "Not Submitted",
          value: "Not Submitted",
        },
      ],
      onFilter: (value, record) => record?.status === value,
    },

    {
      title: "Total Score",
      dataIndex: "score",
      key: "score",
      render: (text, record) => (
        <div style={{ color: "#00627B" }} className=" text-base font-semibold ">
          {text ?? "_"} / {record.assignment?.score}
        </div>
      ),
      sorter: (a, b) => (a.score > b.score ? 1 : -1),
    },
    {
      title: "Action",
      key: "_id",
      width: "20%",
      render: (record) => (
        <div>
          {record?.status !== "Not Submitted" && (
            <div
              style={{ color: "#00627B" }}
              onClick={() => setEvalute(record)}
              className=" text-base font-semibold  pr-4 cursor-pointer"
            >
              Evaluate
            </div>
          )}
        </div>
      ),
    },
  ];

  function archiveAssignments() {
    axios({
      method: "post",
      url: serverUrl + "/assignment-submissions/archive",
      params: {
        assignment: assignmentId,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message?.success("Assignment Submissions Archived Successfully");
        queryClient.invalidateQueries(["assignment-submissions", assignmentId]);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
        }
      });
  }

  return (
    <div className="py-10">
      <div
        style={{ borderRadius: "10px", color: "#616161" }}
        className=" bg-white drop-shadow-md w-full "
      >
        <div className="h-16 ">
          <div
            style={{ backgroundColor: "#09617E" }}
            className="uppercase rounded-tl-xl text-white rounded-tr-xl h-full 
                            font-normal text-base flex items-center justify-center relative"
          >
            <h1 className="text-white font-bold">Submissions </h1>
            {canArchive && (
              <div
                // onClick={}
                className="absolute items-center justify-center top-1/4 right-6"
              >
                <Button
                  onClick={archiveAssignments}
                  className="w-40"
                  type="warning"
                >
                  Archive
                </Button>
              </div>
            )}
          </div>
        </div>

        <div>
          <Table
            loading={assignmentSubmissions.isLoading}
            columns={columns}
            dataSource={assignmentSubmissions.isSuccess ? submissions : []}
            pagination={false}
          />
        </div>

        <Modal
          width={800}
          visible={evalute}
          onOk={() => setEvalute(false)}
          onCancel={() => setEvalute(false)}
          footer={null}
        >
          <AssignmentEvaluationForm
            onClose={() => setEvalute(false)}
            data={evalute}
          />
        </Modal>
      </div>
    </div>
  );
}
