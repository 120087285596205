import { Button, Form, Modal, Tag } from "antd";
import React, { useState } from "react";
import AddEditApplication from "./AddEditApplication";

function tagRender(props) {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ margin: 5 }}
      className="flex items-center"
    >
      {label}
    </Tag>
  );
}

const AdmissionApplicationPage = () => {
  const [form] = Form.useForm();
  const [acceptAdmissionPopup, setAcceptAdmissionPopup] = useState(false);
  const [rejectAdmissionPopup, setRejectAdmissionPopup] = useState(false);

  return (
    <>
      <div className="admission mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md px-5 py-6 w-full "
        >
          <AddEditApplication onGetResponse={() => {}} />

          {/* 
    
    --------------------------------------------------------------------------------       */}
          <Modal
            width={800}
            visible={rejectAdmissionPopup}
            onOk={() => setRejectAdmissionPopup(false)}
            onCancel={() => {
              setRejectAdmissionPopup(false);
              form.resetFields();
            }}
            footer={null}
          >
            <div>
              {/* ---------------------------form---------------- */}
              <Form
                layout="vertical"
                name="basic"
                form={form}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <div>Do you want to Submit the Admission Form?</div>
                {/* ----------------------------------------------- */}
                <div className="flex justify-end items-center pt-6">
                  <div className="inline-flex items-center space-x-2">
                    <Button size="large" type="danger" htmlType="submit">
                      Yes
                    </Button>
                    <Button
                      onClick={() => {
                        setAcceptAdmissionPopup(false);
                        form.resetFields();
                      }}
                      size="large"
                      type="dark"
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AdmissionApplicationPage;
