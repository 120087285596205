import { DatePicker, Form, Input, Select, Table } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { serverUrl } from "../../../../nestserver";
import { selectUser } from "../../../../redux/slices/auth.slice";

const AttendanceHome = () => {
  const user = useSelector(selectUser);
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [studentId, setStudentId] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  useEffect(() => {
    if (user && user?.student) {
      setStudentId(user?.student?._id);
    }
  }, [user]);

  const handleDateRangeChange = (dates) => {
    const [fromDate, toDate] = dates;
    setDateRange({ from: fromDate, to: toDate });
  };

  const fetchStudentsAttendance = async () => {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/attendance/student/${studentId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const studentAttendanceDetails = useQuery(
    ["class", studentId],
    fetchStudentsAttendance,
    {
      enabled: !!studentId,
    }
  );
  useEffect(() => {
    const filteredAttendance =
      studentAttendanceDetails?.data?.result?.attendance?.filter((data) => {
        const attendanceMoment = moment(data.date);
        if (
          !moment(dateRange.from).isValid() ||
          !moment(dateRange.to).isValid()
        ) {
          return true;
        }

        return (
          attendanceMoment.isSameOrAfter(dateRange.from, "day") &&
          attendanceMoment.isSameOrBefore(dateRange.to, "day")
        );
      });

    setFilteredData(filteredAttendance);
  }, [studentAttendanceDetails?.data?.result?.attendance, dateRange]);

  const columns = [
    {
      title: " Date",

      key: "",
      align: "center",
      render: (record) => (
        <div style={{ color: "#111111" }} className="text-base  text-center">
          {moment(record?.date).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      title: " Morning",
      key: "",
      align: "center",
      render: (record) => (
        <div style={{ color: "#111111" }} className="text-base  text-center">
          {record?.is_present_morning ? (
            <span className="flex justify-center">
              <BiCheckCircle className="text-green-500" size={20} />
            </span>
          ) : (
            <span className="flex justify-center">
              <BiXCircle className="text-red-500" size={20} />
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Afternoon",
      key: "",
      align: "center",
      render: (record) => (
        <div style={{ color: "#111111" }} className="text-base  text-center">
          {record?.is_present_afternoon ? (
            <span className="flex justify-center">
              <BiCheckCircle className="text-green-500" size={20} />
            </span>
          ) : (
            <span className="flex justify-center">
              <BiXCircle className="text-red-500" size={20} />
            </span>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="pt-8">
        <div className="bg-white p-10 rounded-2xl">
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Manage Attendance
          </div>
          <Form
            layout="vertical"
            name="basic"
            autoComplete="off"
            className="w-1/2"
          >
            <Form.Item label="Attendance %" className="w-full">
              <Input
                readOnly
                value={
                  studentAttendanceDetails?.data?.result?.attendancePercent
                }
              />
            </Form.Item>
            <Form.Item label="Choose a range" name="date" className="w-full">
              <RangePicker
                format={"DD/MM/YYYY"}
                disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))}
                className="w-full"
                onChange={handleDateRangeChange}
              />
            </Form.Item>
          </Form>
          <div className="pt-5 attendance-table">
            <Table
              columns={columns}
              className="w-1/2"
              dataSource={
                filteredData && filteredData.length > 0 ? filteredData : []
              }
              loading={filteredData?.isLoading}
              pagination={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceHome;
