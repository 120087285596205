import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { serverUrl } from "../../../../../nestserver";
import StudentAttendance from "./StudentAttendance";
import StudentProfile from "./StudentProfile";
import StudentSetting from "./StudentSetting";
import StudentSubject from "./StudentSubject";

function SwitchStudent() {
  const [step, setStep] = useState(1);
  const { id } = useParams();

  //   const queryClient = useQueryClient();

  async function fetchStudent() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/" + id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const student = useQuery(["student", id], fetchStudent);

  function switchTab() {
    switch (step) {
      case 1:
        return (
          <div>
            <StudentProfile data={student?.data} />
          </div>
        );

      case 2:
        return (
          <div>
            <StudentSubject data={student?.data} />
          </div>
        );

      case 3:
        return (
          <div>
            <StudentAttendance data={student?.data} />
          </div>
        );

      case 4:
        return (
          <div>
            <StudentSetting data={student?.data} />
          </div>
        );

      default:
        return (
          <div>
            <StudentProfile data={student?.data} />
          </div>
        );
    }
  }

  return (
    <>
      {/* {subject.isSuccess ? ( */}
      <div className="p-8">
        <div className="grid grid-cols-5 bg-white rounded-lg ">
          <div
            onClick={() => setStep(1)}
            className={`iskool-tab-item ${
              step === 1 ? "bg-gray-600 text-white shadow text-left " : ""
            }`}
          >
            <div className="text-left">
              <div className="text-lg text-left truncate capitalize">
                {student?.data?.student?.first_name}{" "}
                {student?.data?.student?.last_name}
              </div>
              <div className="text-xs text-left">Profile</div>
            </div>
          </div>
          <div
            onClick={() => setStep(2)}
            className={`iskool-tab-item ${
              step === 2 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Subjects</div>
          </div>
          {/* <div
            onClick={() => setStep(3)}
            className={`iskool-tab-item ${
              step === 3 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Attendance</div>
          </div> */}
          <div
            onClick={() => setStep(4)}
            className={`iskool-tab-item ${
              step === 4 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Settings</div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------- */}
        <div>
          <div>{switchTab()}</div>
        </div>
      </div>
      {/* ) : (
        ""
      )} */}
    </>
  );
}

export default SwitchStudent;
