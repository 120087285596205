import { Button, DatePicker, Form, Input, Modal, Select, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../../../../nestserver";
import FeeTable from "./FeeTable";
import ViewTransactionHistory from "./ViewTransactionHistory";
const ViewStudentFee = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  let isViewStudentFee = true;
  const [OpenTransactionPopup, setOpenTransactionPopup] = useState(false);
  const [openTransactionHistory, setOpenTransactionHistory] = useState(false);
  const [classId, setClassId] = useState("");
  const [studentId, setStudentId] = useState("");
  const [studentFee, setStudentFee] = useState();
  const [lastPaid, setLastPaid] = useState("");
  const [transactionHistory, setTransactionHistory] = useState(null);

  useEffect(() => {
    const urlParts = window.location.pathname.split("/");
    const classIdIndex = urlParts.indexOf("class");
    const studentIdIndex = urlParts.indexOf("student");

    if (classIdIndex !== -1 && studentIdIndex !== -1) {
      setClassId(urlParts[classIdIndex + 1]);
      setStudentId(urlParts[studentIdIndex + 1]);
    }
  }, []);

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }
  const fetchStudentFee = async () => {
    await axios({
      method: "get",
      url:
        serverUrl +
        "/admin/fee-template/class/" +
        classId +
        "/student/" +
        studentId,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((response) => {
        form.setFieldsValue({
          name: `${response?.data?.result?.student?.first_name} ${response?.data?.result?.student?.last_name}`,
          studentId: response?.data?.result?.student?.unique_id,
          class: response?.data?.result?.class?.name,
          parent: `${response?.data?.result?.student?.parent?.first_name} ${response?.data?.result?.student?.parent?.last_name}`,
          phone: response?.data?.result?.student?.parent?.phone,
        });
        setLastPaid(response?.data?.result?.last_paid);
        setStudentFee(response?.data?.result);
      })
      .catch((error) => {
        console.error("Error fetching student fee:", error);
      });
  };
  useEffect(() => {
    if (classId && studentId) {
      fetchStudentFee();
    }
  }, [classId, studentId]);
  const onFinish = (values) => {
    const transactionPayload = {
      name: values?.fullName,
      type: values?.paymentType,
      amount: values?.payment,
      date: values?.date,
    };
    if (studentFee) {
      axios
        .post(
          serverUrl + "/admin/student-fee/" + studentFee?._id + "/transaction",
          transactionPayload,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
        .then((response) => {
          form.setFieldsValue({
            fullName: undefined,
            payment: undefined,
            paymentType: undefined,
            date: undefined,
          });
          message.success(response?.data?.result?.message);
          setOpenTransactionPopup(false);
          fetchStudentFeeHistory();
        })
        .catch((err) => {
          if (err?.response?.data?.error?.errors) {
            message.error(err?.response?.data?.error?.errors);
          }
        });
    }
  };
  const fetchStudentFeeHistory = async () => {
    await axios({
      method: "get",
      url: serverUrl + "/admin/student-fee/" + studentFee?._id + "/transaction",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((response) => {
        setLastPaid(
          response?.data?.result[response?.data?.result.length - 1].fee_details
            ?.last_paid
        );
        setTransactionHistory(response?.data?.result);
      })
      .catch((err) => {
        // if (err?.response?.data?.error?.errors) {
        //   message.error(err?.response?.data?.error?.errors);
        // }
        console.log("error", err);
      });
  };
  useEffect(() => {
    if (studentFee) {
      fetchStudentFeeHistory();
    }
  }, []);

  return (
    <div className="student-form-fee">
      <div className="p-8">
        <div className="bg-white p-10 rounded-2xl">
          <div className="p-4 text-right">
            <Button size="large" onClick={() => history.goBack()}>
              Back
            </Button>
          </div>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <div className="form-grid">
              <Form.Item label="Name" name="name">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Student Id" name="studentId">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Class" name="class">
                <Input readOnly />
              </Form.Item>
            </div>
            <div className="form-grid">
              <Form.Item label="Parent" name="parent">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Phone" name="phone">
                <Input readOnly />
              </Form.Item>
            </div>
          </Form>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <div>
              <div className="flex justify-end">
                <div
                  className="bg-white pt-4 rounded-2xl flex justify-between gap-4"
                  style={{ width: "50%" }}
                >
                  <h3 className="text-base font-semibold text-center">
                    Fee Summary
                  </h3>
                  <Button
                    onClick={() => {
                      setOpenTransactionPopup(true);
                      form.setFieldsValue({
                        totalFee: studentFee?.total,
                        paid: studentFee?.paid,
                        remaining: studentFee?.remaining,
                      });
                    }}
                  >
                    Add Transaction
                  </Button>
                </div>
              </div>

              <FeeTable
                feeResponse={studentFee}
                isViewStudentFee={isViewStudentFee}
                fetchStudentFee={fetchStudentFee}
              />
            </div>
          </Form>
          <div className="flex justify-between items-center pt-4">
            <h3 className="text-lg font-bold">
              Last Paid:{" "}
              <span className="font-semibold">
                {lastPaid ? moment(lastPaid).format("DD-MM-YYYY HH:mm:ss") : ""}
              </span>
            </h3>
          </div>
          <div className="flex justify-center pt-5 items-center">
            <Button
              class
              onClick={() => {
                setOpenTransactionHistory(true);
                fetchStudentFeeHistory();
              }}
            >
              View History
            </Button>
          </div>
        </div>
      </div>

      {/* Add Transaction Modal */}

      <Modal
        width={360}
        layout="vertical"
        visible={OpenTransactionPopup}
        onOk={() => setOpenTransactionPopup(false)}
        onCancel={() => {
          setOpenTransactionPopup(false);
          form.setFieldsValue({
            fullName: undefined,
            payment: undefined,
            paymentType: undefined,
            date: undefined,
          });
          // form.resetFields();
        }}
        footer={null}
      >
        <div className="">
          <h3 className="text-lg font-bold pb-3">Add Transaction</h3>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <div className="bg-white pt-2 rounded-2xl">
              <Form.Item
                label="Name"
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input placeholder="Enter name" />
              </Form.Item>
              <Form.Item label="Total Amount" name="totalFee">
                <Input type="number" min={0} readOnly />
              </Form.Item>
              <Form.Item label="Total Paid" name="paid">
                <Input type="number" min={0} readOnly />
              </Form.Item>
              <Form.Item label="Total Remaining" name="remaining">
                <Input type="number" min={0} readOnly />
              </Form.Item>
              <Form.Item
                label="Payment"
                name="payment"
                rules={[
                  {
                    required: true,
                    message: "Payment is required",
                  },
                ]}
              >
                <Input type="number" min={0} placeholder="Enter amount" />
              </Form.Item>
              <Form.Item
                label="Type"
                name="paymentType"
                rules={[
                  {
                    required: true,
                    message: "Payment Type is required",
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placeholder="Select mode"
                >
                  <Select.Option value="cash">Cash</Select.Option>
                  <Select.Option value="card">Card</Select.Option>
                  <Select.Option value="online">Online</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Date" name="date">
                <DatePicker
                  format="DD-MM-YYYY"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  className="w-full"
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <div className="flex justify-center items-center py-5">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Complete
                  </Button>
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => {
                      setOpenTransactionPopup(false);
                      form.setFieldsValue({
                        fullName: undefined,
                        payment: undefined,
                        paymentType: undefined,
                        date: undefined,
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        width={900}
        layout="vertical"
        visible={openTransactionHistory}
        onOk={() => setOpenTransactionHistory(false)}
        onCancel={() => {
          setOpenTransactionHistory(false);
        }}
        footer={null}
      >
        <ViewTransactionHistory
          transactionHistory={transactionHistory}
          studentId={studentId}
        />
      </Modal>
    </div>
  );
};

export default ViewStudentFee;
