import { Button, Form, Input, Popconfirm, Radio, Select, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";

function SubjectSetting({ subjectDetails }) {
  const { Option } = Select;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const history = useHistory();
  const [subjectCheckboxPayload, setSubjectCheckboxPayload] = useState({
    is_mandatory: subjectDetails?.is_mandatory,
    is_language: subjectDetails?.is_language,
    is_co_curricular: subjectDetails?.is_co_curricular,
  });
  const initialValues = {
    name: subjectDetails?.name,
    class: subjectDetails?.class?._id,
    department: subjectDetails?.department?._id,
    teacher: subjectDetails?.teacher?._id,
    teaching_asst: subjectDetails?.teaching_asst?._id,
    options: subjectDetails?.is_mandatory
      ? "mandatory"
      : subjectDetails?.is_language
      ? "languages"
      : subjectDetails?.is_co_curricular
      ? "co-curricular"
      : "",
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [subjectDetails, form]);

  // fetch classes
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);
  const handleCheckbox = (event) => {
    const selectedValue = event.target.value;
    setSubjectCheckboxPayload({
      is_mandatory: selectedValue === "mandatory",
      is_language: selectedValue === "languages",
      is_co_curricular: selectedValue === "co-curricular",
    });
  };
  // fetch Instructors
  async function fetchInstructors() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/",
      params: {
        role: "instructor",
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const instructors = useQuery("instructors", fetchInstructors);

  // fetch departments
  async function fetchDepartments() {
    const response = await axios.get(serverUrl + "/department", {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return response.data;
  }
  const departments = useQuery("departments", fetchDepartments);

  // Update Subject
  function updateSubject(values) {
    axios
      .patch(
        serverUrl + "/subjects/" + subjectDetails?._id,
        {
          ...values,
          ...subjectCheckboxPayload,
          teacher: values.teacher || null,
          teaching_asst: values.teaching_asst || null,
          class: values.class || null,
          department: values.department || null,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      )
      .then((res) => {
        message.success("Subject Updated");
        queryClient.invalidateQueries("subjects");
        queryClient.invalidateQueries(["subject", subjectDetails?._id]);
      });
  }

  // delete subject
  function deleteSubject() {
    axios
      .delete(serverUrl + "/subjects/" + subjectDetails?._id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("Subject Deleted");
        queryClient.invalidateQueries("subjects");
        history.push("/dashboard/admin/menu/subject");
      });
  }

  return (
    <>
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className="bg-white p-8 drop-shadow-md  w-full "
        >
          {/* ---------------------------form---------------- */}
          <div>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              onFinish={updateSubject}
              autoComplete="off"
            >
              <div className="w-3/4">
                <Form.Item
                  label="Subject Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input name for subject",
                    },
                  ]}
                >
                  <Input className="w-full" />
                </Form.Item>
                <Form.Item
                  name="options"
                  onChange={handleCheckbox}
                  rules={[
                    { required: true, message: "Please select an option" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="mandatory">Mandatory</Radio>
                    <Radio value="languages">Languages</Radio>
                    <Radio value="co-curricular">Co-Curricular</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item label="Class" name="class" className="w-full ">
                  <Select
                    showSearch
                    showArrow
                    allowClear
                    onClear={() => form.setFieldsValue({ class: null })}
                    placeholder="Select Class"
                    optionFilterProp="children"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    className="capitalize"
                  >
                    {classes.isSuccess &&
                      classes?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Department"
                  name="department"
                  className="w-full "
                >
                  <Select
                    showSearch
                    showArrow
                    allowClear
                    onClear={() => form.setFieldsValue({ department: null })}
                    placeholder="Select Department"
                    optionFilterProp="children"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    className="capitalize"
                  >
                    {departments?.isSuccess &&
                      departments?.data?.map((data) => {
                        return (
                          <Option key={data?._id} value={data?._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Instructor"
                  name="teacher"
                  className="w-full "
                >
                  <Select
                    showSearch
                    showArrow
                    allowClear
                    disabled={!!subjectDetails?.teacher?._id}
                    onClear={() => form.setFieldsValue({ teacher: null })}
                    placeholder="Select Instructor"
                    optionFilterProp="children"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    className="capitalize"
                  >
                    {instructors?.isSuccess &&
                      instructors?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data?.teacher?.first_name}{" "}
                            {data?.teacher?.last_name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                {/* <Form.Item
                  label="Assistant Instructor"
                  name="teaching_asst"
                  className="w-full"
                >
                  <Select
                    showSearch
                    showArrow
                    allowClear
                    onClear={() => form.setFieldsValue({ teaching_asst: null })}
                    placeholder="Select Instructor"
                    optionFilterProp="children"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    className="capitalize"
                  >
                    {instructors.isSuccess &&
                      instructors?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data?.teacher?.first_name}{" "}
                            {data?.teacher?.last_name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item> */}
              </div>

              <div className="flex items-center justify-between pt-10 ">
                <div>
                  <Popconfirm
                    title="Are you sure to delete this subject?"
                    onConfirm={deleteSubject}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="large" type="danger">
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
                <div className="flex flex-row space-x-4">
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => form?.resetFields()}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                    // onClick={() => updateProfile()}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          {/* ----------------------------------------------- */}
        </div>
      </div>
    </>
  );
}

export default SubjectSetting;
