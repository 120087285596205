import React, { useEffect, useState } from "react";
import { Form, Input, message, Select } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import { serverUrl } from "../../../../nestserver";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "react-query";
import {
  useCountries,
  useGetCity,
} from "../../../../services/location.service";

const { Option } = Select;
function AdminProfile({ data, closeModel }) {
  const { TextArea } = Input;
  const queryClient = useQueryClient();

  const onFinish = (values) => {
    if (data != null) {
      axios({
        method: "patch",
        url: serverUrl + "/admin/" + data._id,
        data: {
          ...values,
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
        .then((res) => {
          message.success("Profile Updated");
          queryClient.invalidateQueries("user");
          closeModel();
        })
        .catch((e) => {
          message.error(e.message);
        });
    } else {
      axios({
        method: "post",
        url: serverUrl + "/admin/",
        data: values,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
        .then((res) => {
          message.success("Profile Updated");
          queryClient.invalidateQueries("user");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const countries = useCountries();

  const [selectedCountry, setSelectedCountry] = useState(null);

  const cities = useGetCity(
    countries?.data?.find((c) => c.name === selectedCountry)?.iso2,
    {
      enabled: !!selectedCountry && !!countries?.data,
    }
  );

  useEffect(() => {
    if (data?.country) {
      setSelectedCountry(data?.country);
      queryClient?.invalidateQueries("cities");
    }
  }, [data?.country]);

  return (
    <div>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          first_name: data?.first_name,
          last_name: data?.last_name,
          headline: data?.headline,
          country: data?.country,
          region: data?.region,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className=" flex flex-col  px-5 py-6">
          <div className="grid grid-cols-2 gap-6 ">
            <Form.Item
              style={{ marginBottom: "10px" }}
              label="First name "
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input your First name!",
                },
                // {
                //   message: "Name can only include letters and numbers.",
                //   validator: (_, value) => {
                //     if (/^[a-zA-Z0-9]+$/.test(value)) {
                //       return Promise.resolve();
                //     } else {
                //       return Promise.reject(
                //         "Name can only include letters and numbers."
                //       );
                //     }
                //   },
                // },
                {
                  message:
                    "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                  validator: (_, value) => {
                    if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                      );
                    }
                  },
                },
              ]}
            >
              <Input
                style={{
                  backgroundColor: "#E3E3E3",
                }}
                className="w-full py-2"
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Last name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please input your Last name!",
                },
                {
                  message:
                    "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                  validator: (_, value) => {
                    if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                      );
                    }
                  },
                },
              ]}
            >
              <Input
                style={{
                  backgroundColor: "#E3E3E3",
                }}
                className="w-full py-2"
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Headline"
              name="headline"
              rules={[
                {
                  required: true,
                  message: "Please describe yourself",
                },
                {
                  max: 255, // Set the maximum number of characters allowed
                  message: "Maximum 255 characters allowed",
                },
              ]}
            >
              <TextArea
                rows={2}
                style={{
                  backgroundColor: "#E3E3E3",
                }}
                className="w-full py-1 resize-none"
              />
            </Form.Item>
          </div>

          <div>
            <div className="grid grid-cols-2 gap-6 ">
              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Country/Region "
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please input your Country/Region !",
                  },
                ]}
              >
                <Select
                  className="w-full"
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setSelectedCountry(value);
                  }}
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {countries?.data?.map((country) => (
                    <Option key={country?.name} value={country?.name}>
                      {country?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {selectedCountry && (
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="City/Town"
                  name="region"
                  rules={[
                    {
                      required: true,
                      message: "Please input your City/Town!",
                    },
                  ]}
                >
                  <Select
                    className="w-full"
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {cities?.data?.map((city) => (
                      <Option key={city?.id} value={city?.name}>
                        {city?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
          </div>

          <div>
            <Form.Item className="mb-0">
              <div className="flex items-center pt-3">
                <div className="inline-flex ml-auto space-x-2">
                  <Button
                    key="back"
                    type="dark"
                    size="large"
                    onClick={closeModel}
                  >
                    Cancel
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="text-white"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default AdminProfile;
