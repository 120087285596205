import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import StudentMainProfile from "../../components/sections/StudentMainProfile";
import DynamicProfile from "../instructor/pages/DynamicProfile";
import HelpPage from "../instructor/pages/HelpPage";
import Menu from "./StudentMenu";
import FriendsPage from "./pages/FriendsPage";
import Index from "./pages/Index";
import NotificationList from "./pages/NotificationList";
import Settings from "./pages/Settings";
import SwitchAttendance from "./pages/attendance/SwitchAttendance";
import ViewLeaveDetails from "./pages/attendance/ViewLeaveDetails";
import CourseBoard from "./pages/courseboard";
import AssignmentEvaluationView from "./pages/courseboard/AssignmentEvaluationView";
import ExamEvaluationView from "./pages/courseboard/ExamEvaluationView";
import StudentSwitchTab from "./pages/courseboard/studentSwitchTab";
import HomeWorkBroadcast from "./pages/message/HomeWorkBroadcast";
import TemplateList from "./pages/reportcard/TemplateList";
import ViewStudentTemplate from "./pages/reportcard/ViewStudentTemplate";
import Schedule from "./pages/schedule";
import TransportationStatus from "./pages/transportation/TransportationStatus";

const StudentRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/student`}>
        <Switch>
          <Route exact path={`${url}/student/menu`}>
            <Menu />
          </Route>
          <Route exact path={`${url}/student/menu/profile`}>
            <StudentMainProfile />
          </Route>
          <Route exact path={`${url}/student/menu/friends`}>
            <FriendsPage />
          </Route>
          <Route exact path={`${url}/student/menu/friends/:userId`}>
            <DynamicProfile />
          </Route>
          <Route exact path={`${url}/student/help`}>
            <HelpPage />
          </Route>

          <Route exact path={`${url}/student/menu/notifications`}>
            <NotificationList />
          </Route>
          <Route exact path={`${url}/student/menu/assignment/:id`}>
            <AssignmentEvaluationView />
          </Route>
          <Route exact path={`${url}/student/menu/exam/:id`}>
            <ExamEvaluationView />
          </Route>
          <Route exact path={`${url}/student/menu/setting/`}>
            <Settings />
          </Route>
          <Route exact path={`${url}/student/menu/courses-board`}>
            <CourseBoard />
          </Route>
          <Route exact path={`${url}/student/:className/:classId/:subjectId`}>
            <StudentSwitchTab />
          </Route>
          <Route exact path={`${url}/student/menu/attendance`}>
            <SwitchAttendance />
          </Route>
          <Route exact path={`${url}/student/menu/student/leave/:id`}>
            <ViewLeaveDetails />
          </Route>
          <Route exact path={`${url}/student/menu/template/report-card/:id`}>
            <ViewStudentTemplate />
          </Route>
          <Route exact path={`${url}/student/menu/report-card`}>
            <TemplateList />
          </Route>
          <Route exact path={`${url}/student/menu/schedule/`}>
            <Schedule />
          </Route>
          <Route exact path={`${url}/student/menu/homework/`}>
            <HomeWorkBroadcast />
          </Route>
          <Route exact path={`${url}/student/menu/transportation/`}>
            <TransportationStatus />
          </Route>
          <Route path={`${url}/student/home`} component={Index} />
        </Switch>

        {/* All other routes here */}
      </Route>

      <Route exact path={url}>
        <Redirect to={`${url}/student/home`} />
      </Route>
    </Switch>
  );
};

export default StudentRoutes;
