import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Select, Skeleton, Space, Table } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import SubjectEvaluation from "../instructor/evaluations/SubjectEvaluation";
import SubjectAttendance from "./SubjectAttendance";

function ClassSubject({ classDetails }) {
  const { Option } = Select;
  const [subject, setSubject] = useState(null);
  const [evaluationSubject, setEvaluationSubject] = useState(null);
  const [searchText, setSearchText] = useState("");

  //Table Options

  // Search Filter
  const [searchedColumn, setSearchedColumn] = useState("");

  const [searchInput, setSearchInput] = useState(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? // <Highlighter
          //   highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          //   searchWords={[searchText]}
          //   autoEscape
          //   textToHighlight={text ? text.toString() : ''}
          // />
          text
        : text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: (
        <div style={{ color: "#616161" }} className="font-semibold capitalize">
          Subject
        </div>
      ),
      width: "30%",
      dataIndex: ["name"],
      ...getColumnSearchProps("name"),
    },
    {
      title: (
        <div
          style={{ color: "#616161" }}
          className="font-semibold capitalize text-center"
        >
          Type
        </div>
      ),
      render: (value, record) => (
        <div
          style={{ color: "#616161" }}
          className=" text-base font-semibold text-center"
        >
          {record?.is_co_curricular
            ? "Co-curricular"
            : record?.is_language
            ? "Language"
            : record?.is_mandatory
            ? "Mandatory"
            : "--"}
        </div>
      ),
    },
    {
      title: (
        <div style={{ color: "#616161" }} className="font-semibold capitalize">
          Instructors
        </div>
      ),
      align: "center",
      dataIndex: ["teacher", "teacher", "first_name"],
      width: "30%",
      render: (value, record) => (
        <div
          style={{ color: "#616161" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.teacher?.teacher?.first_name}{" "}
          {record?.teacher?.teacher?.last_name}
        </div>
      ),
    },
    // {
    //   title: (
    //     <div
    //       style={{ color: "#616161" }}
    //       className="font-semibold text-center capitalize"
    //     >
    //       Attendance
    //     </div>
    //   ),
    //   align: "center",
    //   render: (record) => (
    //     <div className=" text-base text-blue-500 text-center cursor-pointer">
    //       <div onClick={() => setSubject(record)}>View</div>
    //     </div>
    //   ),
    // },
    {
      title: (
        <div style={{ color: "#616161" }} className="font-semibold capitalize">
          Evaluations
        </div>
      ),
      align: "center",
      render: (record) => (
        <div className="text-center ">
          <div
            onClick={() => setEvaluationSubject(record)}
            className="text-blue-500 cursor-pointer text-base"
          >
            View
          </div>
        </div>
      ),
    },
  ];

  async function getAttendance() {
    const data = await axios({
      method: "get",
      url: serverUrl + "/attendance/subjects",
      params: {
        subject: classDetails?.subjects?.map((subject) => subject._id),
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return data.data;
  }

  const attendances = useQuery(
    ["attendance", classDetails?._id],
    getAttendance
  );

  const attendanceExportData = useMemo(() => {
    const ExportData = [];
    attendances?.data?.forEach((attendance) => {
      ExportData?.push({
        Name:
          attendance?.student?.first_name +
          " " +
          attendance?.student?.last_name,
        Student_ID: attendance?.student?._id,
        Class: classDetails?.name,
        Subject: attendance?.subject?.name,
        Attendance: attendance?.attended ? "Present" : "Absent",
        Date: moment(attendance?.date)?.format("MMMM Do YYYY"),
        // status: student?.student?.subject_status?.[data?._id] ?? "on track",
      });
    });

    return ExportData;
  }, [classDetails?.subjects, attendances?.data]);

  return (
    <>
      {classDetails ? (
        <div className="pt-8">
          {evaluationSubject ? (
            <>
              <SubjectEvaluation
                onBack={() => setEvaluationSubject(null)}
                subject={evaluationSubject}
              />
            </>
          ) : (
            <div
              style={{ borderRadius: "10px", color: "#616161" }}
              className=" bg-white drop-shadow-md p-8 w-full "
            >
              <div className="flex items-center justify-between">
                <div className="text-2xl text-orange-500 font-semibold">
                  Subjects
                </div>
                {/* {attendanceExportData && (
                  <CSVLink
                    filename={`${classDetails?.name}_attendance.csv`}
                    data={attendanceExportData}
                  >
                    <Button>Export Attendance</Button>
                  </CSVLink>
                )} */}
              </div>

              <div className="py-4">
                <Table
                  bordered
                  pagination={true}
                  // rowSelection={{
                  //   type: "checkbox",
                  //   ...rowSelection,
                  // }}
                  columns={columns}
                  dataSource={
                    classDetails?.subjects ? classDetails?.subjects : []
                  }
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="pt-8">
          <Skeleton active />
        </div>
      )}

      <Modal
        width={900}
        visible={subject}
        onCancel={() => setSubject(null)}
        footer={null}
      >
        <SubjectAttendance subjectDetails={subject} />
      </Modal>
    </>
  );
}

export default ClassSubject;
