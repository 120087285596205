import { DeleteOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Form, Input, Popconfirm, message } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { cloudFrontUrl, serverUrl } from "../../nestserver";
import FileUploadButton from "../inputs/FileUploadButton";

export default function EditModule({ data, onCancel }) {
  const { TextArea } = Input;
  const [editField, setEditField] = useState({
    save: false,
    edit: false,
  });
  const [pdf, setPdf] = useState([]);
  const [form] = Form.useForm();

  function handleSetPdf(file) {
    // setPdf((f) => [...f, ...file]);

    // append files to current file so that all the files have distinct id
    setPdf((pdf) => {
      return [
        ...pdf,
        ...file.filter((f) => pdf.findIndex((f2) => f2.id == f.id) == -1),
      ];
    });
  }

  function removePdfItem(id) {
    const newPdf = pdf.filter((f) => f.id !== id);
    setPdf(newPdf);
  }

  const queryClient = useQueryClient();

  function publishModule(value) {
    axios({
      method: "patch",
      url: serverUrl + "/modules/" + data?._id,
      data: {
        published: value,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Module Published");
        queryClient.invalidateQueries("modules");
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  const onFinish = (values) => {
    axios({
      method: "patch",
      url: serverUrl + "/modules/" + data?._id,
      data: {
        ...values,
        files: pdf,
      },
      mode: "cors",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Module Updated");
        queryClient.invalidateQueries("user");
        queryClient.invalidateQueries("modules");
        setEditField((e) => ({
          ...e,
          save: false,
          edit: false,
        }));
      })
      .catch((e) => {
        console.log(e);
        message.error(e.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const deleteModule = () => {
    axios({
      method: "delete",
      url: serverUrl + "/modules/" + data?._id,

      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Module Removed");
        queryClient.invalidateQueries("modules");

        onCancel(false);
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  useEffect(() => {
    setPdf(data?.files);
  }, [data]);

  return (
    <div>
      <div>
        <div className=" py-8 ">
          <div className="bg-white rounded-2xl p-8">
            <div className="w-full flex items-center justify-end pb-3">
              <Button type="" onClick={() => onCancel(false)}>
                {editField.save ? "Cancel" : "Back"}
              </Button>
            </div>
            <Form
              layout="vertical"
              name="basic"
              form={form}
              initialValues={data}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              {/* -------------------------------------------------sec 1---------------------------- */}
              <div className="flex items-center justify-between ">
                <div className="text-sm font-bold text-primary-500 ">
                  Module Details
                </div>
                <Form.Item className="mb-0">
                  <div className="flex items-center">
                    <div className="inline-block ml-auto space-x-3">
                      <Popconfirm
                        placement="top"
                        title="Are you sure?"
                        onConfirm={deleteModule}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          disabled={data?.published}
                          type="danger"
                          // onClick={() => deleteModule()}
                        >
                          Delete
                        </Button>
                      </Popconfirm>

                      <Button
                        type="success"
                        onClick={() =>
                          editField.save
                            ? form.submit()
                            : setEditField((e) => ({
                                ...e,
                                save: true,
                                edit: true,
                              }))
                        }
                        disabled={data?.published}
                      >
                        {editField.save ? "Save" : "Edit"}
                      </Button>
                      {data?.published ? (
                        <Button
                          type="warning"
                          onClick={() => publishModule(false)}
                        >
                          Unpublish
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() => publishModule(true)}
                          disabled={editField.save}
                        >
                          Publish
                        </Button>
                      )}
                    </div>
                  </div>
                </Form.Item>
              </div>

              <div className="relative z-1 py-4">
                <div>Module Name</div>
                {/* <div className="right-5 top-2 absolute cursor-pointer">
                  {editField.edit ? (
                    <IconButton
                      className="bg-green-500 hover:bg-green-400 text-white"
                      icon={<BsCheck size={20} />}
                      onClick={() =>
                        setEditField((e) => ({
                          ...e,
                          save: true,
                          edit: false,
                        }))
                      }
                    />
                  ) : (
                    <IconButton
                      icon={<BsPencil />}
                      onClick={() =>
                        setEditField((e) => ({
                          ...e,
                          edit: true,
                        }))
                      }
                    />
                  )}
                </div> */}
              </div>

              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Module need a title",
                  },
                ]}
              >
                <Input
                  className="w-full py-2"
                  maxLength={130}
                  disabled={!editField.edit}
                />
              </Form.Item>

              <Form.Item
                label="Overview"
                name="overview"
                rules={[
                  {
                    required: true,
                    message: "Please provide a module overview",
                  },
                ]}
              >
                <TextArea
                  rows={2}
                  className="w-full py-1 resize-none"
                  disabled={!editField.edit}
                />
              </Form.Item>
              <div className="text-lg  pb-6">Reading Resources</div>

              {editField.edit ? (
                <Form.List
                  style={{
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  label="urls"
                  name="urls"
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <div className="grid grid-cols-10">
                          <div className="col-span-9 space-y-2">
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              fieldKey={[fieldKey, "name"]}
                              label="Resource Name"
                              className="mb-0 w-full"
                              rules={[
                                {
                                  required: true,
                                  message: "Resource need a name",
                                },
                              ]}
                            >
                              <Input
                                className="w-full py-2"
                                placeholder="Title"
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "author"]}
                              fieldKey={[fieldKey, "author"]}
                              label="Resource Author"
                              className="mb-0 w-full"
                            >
                              <Input
                                className="w-full py-2"
                                placeholder="Author"
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "link"]}
                              fieldKey={[fieldKey, "link"]}
                              label="Link"
                              rules={[
                                {
                                  required: true,
                                  message: "Please add a resource link",
                                },
                              ]}
                              className="mb-0 w-full"
                            >
                              <Input
                                className="w-full py-2"
                                placeholder="Link"
                              />
                            </Form.Item>
                          </div>
                          <div className=" h-full col-span-1 px-2">
                            <div
                              onClick={() => remove(name)}
                              className="flex items-center justify-center h-full transition-all ease-in-out duration-500 bg-red-400 opacity-100 hover:opacity-80 rounded-lg text-white cursor-pointer"
                            >
                              <MinusCircleOutlined className="text-xl" />
                            </div>
                          </div>
                        </div>
                      ))}
                      <Form.Item>
                        <div className="flex items-center justify-center pt-8">
                          <Button size="large" onClick={() => add()}>
                            Add Reading Resource
                          </Button>
                        </div>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              ) : (
                <>
                  {data?.urls.length ? (
                    <div className="space-y-2 w-full flex flex-col">
                      {data?.urls?.map((item) => (
                        <a
                          key={item.link}
                          href={item.link}
                          target="_blank"
                          className="px-4 py-2 rounded-lg w-full underline"
                          style={{
                            boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.25)",
                            fontSize: "16px",
                          }}
                          rel="noreferrer"
                        >
                          {item.name} {item.author && "by " + item.author}
                        </a>
                      ))}
                    </div>
                  ) : (
                    <div className="text-lg text-gray-500 p-6 text-center">
                      No Resources available
                    </div>
                  )}
                </>
              )}

              <div className="text-lg  pb-6">Resource Attachments</div>
              <FileUploadButton
                // file={file}
                onUpload={(e) => handleSetPdf(e)}
              >
                <div className="flex items-center space-x-4">
                  <Button disabled={!editField.edit} type="primary">
                    Upload
                  </Button>
                </div>
              </FileUploadButton>
              <div className="space-y-3 pt-4">
                {pdf &&
                  pdf?.map((item) => (
                    <div className="font-bold flex space-x-4" key={item.id}>
                      <a
                        className="p-1 px-3 bg-gray-100 rounded-lg"
                        href={cloudFrontUrl + "/docs/" + item?.id}
                        target="_blank"
                        rel="noreferer noreferrer"
                      >
                        {item.files?.name}
                      </a>
                      <div>
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => removePdfItem(item.id)}
                        >
                          <Button
                            disabled={!editField.edit}
                            type="danger"
                            shape="circle"
                            className="flex items-center justify-center"
                          >
                            <DeleteOutlined className="cursor-pointer " />
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  ))}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
