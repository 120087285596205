import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { serverUrl } from "../nestserver";

/* -------------------------------------------------------------------------- */
/*                                 Create Location                               */
/* -------------------------------------------------------------------------- */

export function useCreateLocation(mutationOptions) {
  // axios post
  function createLocation(data) {
    return axios.post(
      serverUrl + "/comments",
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }
    );
  }

  return useMutation(createLocation, mutationOptions);
}

/* -------------------------------------------------------------------------- */
/*                               Get Single Location                             */
/* -------------------------------------------------------------------------- */

export function useGetLocation(id, queryOptions) {
  // get comments
  async function getLocation() {
    const res = await axios.get(serverUrl + "/comments/" + id, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  return useQuery(["comment", id], getLocation, queryOptions);
}
/* -------------------------------------------------------------------------- */
/*                                Get All Locations                               */
/* -------------------------------------------------------------------------- */

/**
 * Get all comments
 * @param {import("react-query").UseQueryOptions} queryOptions
 * @returns {import("react-query").UseQueryResult<any>}
 */
export function useCountries(queryOptions) {
  // get comments
  async function getCountries() {
    const res = await axios.get(
      "https://api.countrystatecity.in/v1/countries",
      {
        headers: {
          "X-CSCAPI-KEY": `WFAwT2ZDSEtZWnhkellna3Q0c1BHa2NPVWxtRWVCM1BIdG1kT29Sbw==`,
        },
      }
    );
    return res.data;
  }

  return useQuery("countries", getCountries, queryOptions);
}

/* -------------------------------------------------------------------------- */
/*                               Get City by Country                             */
/* -------------------------------------------------------------------------- */

export function useGetCity(id, queryOptions) {
  // get comments
  async function getComment() {
    const res = await axios.get(
      `https://api.countrystatecity.in/v1/countries/${id}/cities`,
      {
        headers: {
          "X-CSCAPI-KEY": `WFAwT2ZDSEtZWnhkellna3Q0c1BHa2NPVWxtRWVCM1BIdG1kT29Sbw==`,
        },
      }
    );
    return res.data;
  }

  return useQuery(["cities", id], getComment, queryOptions);
}
