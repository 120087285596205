import React from 'react'
import MainProfile from './MainProfile'

const Index = () => {
  return (
    <div className="h-full px-10 py-8">
     <MainProfile />
    </div>
  )
}

export default Index
