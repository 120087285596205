import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  Tooltip,
  message,
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import { BsPencil } from "react-icons/bs";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../../../nestserver";

export default function HelpPage() {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const [help, setHelp] = useState(null);
  const [editHelp, setEditHelp] = useState(null);

  async function getHelp() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/help/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const helps = useQuery("help", getHelp);

  // Operations

  function createHelp(values) {
    setHelp(false);
    axios
      .post(serverUrl + "/help", values, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("Help Created");
        form.resetFields();
        queryClient.invalidateQueries("help");
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  }

  // Delete Help
  function deleteHelp(id) {
    axios
      .delete(serverUrl + "/help/" + id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("Help Deleted");
        queryClient.invalidateQueries("help");
      });
  }

  // Update Help
  function updateHelp(values) {
    setEditHelp(null);
    axios
      .patch(serverUrl + "/help/" + editHelp._id, values, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("Help Updated");
        queryClient.invalidateQueries("help");
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  }

  const handleRecord = (rec) => {
    setEditHelp(rec);
    form.setFieldsValue({
      question: rec.question,
      answer: rec.answer,
    });
  };

  // Table Data
  const columns = [
    {
      title: "Question",
      dataIndex: ["question"],
      key: "question",
    },
    {
      title: "Answer",
      dataIndex: ["answer"],
      key: "answer",
    },
    {
      title: "Action",
      // dataIndex: "action",
      key: "action",
      width: "200px",
      render: (record) => (
        <div className="flex items-center space-x-2 justify-end">
          <Tooltip title="edit">
            <Button
              onClick={() => handleRecord(record)}
              type="primary flex items-center justify-center"
              shape="circle"
              icon={<BsPencil />}
            />
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteHelp(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="danger flex items-center justify-center"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="p-8">
        <div className="bg-white p-6 rounded-2xl space-y-6">
          <div className="flex items-center justify-between">
            <div className="text-xl text-orange-500 text-medium">
              Frequently Asked Questions
            </div>
            <Button onClick={() => setHelp(true)} type="primary">
              ADD
            </Button>
          </div>

          <div>
            <Table
              columns={columns}
              dataSource={helps?.isSuccess && helps?.data}
              pagination={false}
              loading={helps?.isLoading}
            />
          </div>
        </div>
      </div>

      {/* ------------------------Create----------------------- */}
      <Modal
        width={800}
        visible={help}
        onOk={() => setHelp(false)}
        onCancel={() => {
          setHelp(false);
          form.resetFields();
        }}
        footer={null}
      >
        <div>
          <div className="py-2">
            <div className="text-2xl font-bold text-black pb-4 capitalize">
              Create Help / Faq
            </div>
          </div>
          {/* ---------------------------form---------------- */}
          <div>
            <Form
              layout="vertical"
              name="create-Help"
              onFinish={createHelp}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Question"
                name="question"
                rules={[
                  {
                    required: true,
                    message: "Please input question!",
                  },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>
              <Form.Item
                label="Answer"
                name="answer"
                rules={[
                  {
                    required: true,
                    message: "Please input answer!",
                  },
                ]}
              >
                <Input.TextArea className="w-full" />
              </Form.Item>
              <div className="flex justify-end items-center pt-6">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => {
                      setHelp(false);
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Create
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          {/* ----------------------------------------------- */}
        </div>
      </Modal>
      {/* ----------------------------------------------- */}

      {/* ------------------------Update----------------------- */}
      <Modal
        width={800}
        visible={editHelp}
        onCancel={() => setEditHelp(null)}
        footer={null}
      >
        <div>
          <div className="py-2">
            <div className="text-2xl font-bold text-black pb-4 capitalize">
              Create Help / Faq
            </div>
          </div>
          {/* ---------------------------form---------------- */}
          <div>
            <Form
              layout="vertical"
              name="create-department"
              onFinish={updateHelp}
              // onFinishFailed={onFinishFailed}
              initialValues={editHelp}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label="Question"
                name="question"
                rules={[
                  {
                    required: true,
                    message: "Please input question!",
                  },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>
              <Form.Item
                label="Answer"
                name="answer"
                rules={[
                  {
                    required: true,
                    message: "Please input answer!",
                  },
                ]}
              >
                <Input.TextArea className="w-full" />
              </Form.Item>
              <div className="flex justify-end items-center pt-6">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => setEditHelp(null)}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Update
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          {/* ----------------------------------------------- */}
        </div>
      </Modal>
      {/* ----------------------------------------------- */}
    </div>
  );
}
