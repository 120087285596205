import { Table } from "antd";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../../../../nestserver";
import getStatusColor from "../../../../../services/tagColor.service";

export default function StudentAssignmentSubmissionsTable({
  student,
  subject,
}) {
  const history = useHistory();

  async function fetchAssignments() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/assignment/submissions",
      params: {
        subject: subject,
        published: true,
        student: student,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const assignments = useQuery(
    ["assignments", student, subject],
    fetchAssignments
  );

  const columns = [
    {
      title: "Assignment Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <div style={{ color: "#00627B" }} className="text-left text-sm">
          {text}
        </div>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "last_date",
      key: "last_date",
      render: (text) => (
        <div style={{ color: "#00627B" }} className=" text-base">
          {moment(text).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "Submitted Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div style={{ color: "#00627B" }} className=" text-base ">
          {record?.submissions[0]?.submitted_date
            ? moment(record?.submissions[0]?.submitted_date).format(
                "DD MMM YYYY"
              )
            : moment(record?.submissions[0]?.createdAt).format("DD MMM YYYY")}
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div className=" text-sm font-semibold">
          <div
            className={
              record?.submissions[0]?.status
                ? getStatusColor(record?.submissions[0]?.status)
                : "text-gray-600"
            }
          >
            {record?.submissions[0]?.status ?? "Not Submitted"}
          </div>
        </div>
      ),
    },
    {
      title: "Total Score",
      dataIndex: "score",
      key: "score",
      render: (text, record) => (
        <div
          style={{ color: "#00627B" }}
          className=" text-base font-semibold capitalize "
        >
          {record.submissions[0]?.score ?? "_"} / {text}
        </div>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (text, record) => (
    //     <div className="flex flex-row items-center  space-x-6 pr-4">
    //       <Button
    //         shape="circle"
    //         className="flex items-center justify-center"
    //         onClick={() =>
    //           history.push("/dashboard/student/assignment/" + record._id)
    //         }
    //       >
    //         <BsEyeFill className="cursor-pointer text-blue-500 " />
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={
          assignments.isSuccess && (assignments.data ? assignments.data : [])
        }
        pagination={false}
      />
    </div>
  );
}
