import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  message,
} from "antd";
import axios from "axios";
import * as moment from "moment";
import React, { useState } from "react";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";

function ClassMain() {
  const queryClient = useQueryClient();
  const { Search } = Input;
  const { Option } = Select;
  const history = useHistory();
  const [form] = Form.useForm();

  const [addClass, setAddClass] = useState(false);
  const [academicLastDate, setAcademicLastDate] = useState(null);
  const [transitionStartDate, setTransitionStart] = useState(moment());
  // fetch Students
  async function getStudents() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/",
      params: {
        role: "student",
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const students = useQuery("students", getStudents);

  // fetch Instructors
  async function fetchInstructors() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/",
      params: {
        role: "instructor",
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const instructors = useQuery("instructors", fetchInstructors);

  // fetch classes
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  //Table Options

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [searchInput, setSearchInput] = useState(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search Class`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? // <Highlighter
          //   highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          //   searchWords={[searchText]}
          //   autoEscape
          //   textToHighlight={text ? text.toString() : ''}
          // />
          text
        : text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Class",
      dataIndex: ["name"],
      key: "x",
      sorter: (a, b) => {
        const classNameA = a?.name || "";
        const classNameB = b?.name || "";

        const numericPartA = classNameA.replace(/\D/g, "");
        const numericPartB = classNameB.replace(/\D/g, "");

        return numericPartA.localeCompare(numericPartB, undefined, {
          numeric: true,
        });
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "Students",
      // dataIndex: "class['name']",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#616161" }}
          className="font-semibold text-center"
        >
          {students?.isSuccess &&
            students?.data?.filter((s) => s.student?.class?._id === record._id)
              ?.length}
        </div>
      ),
    },
    {
      title: "Instructors",
      // dataIndex: "class['name']",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#616161" }}
          className=" text-base font-semibold text-center"
        >
          {instructors?.isSuccess &&
            instructors?.data
              ?.filter((i) => !i.blocked)
              ?.filter((s) =>
                s.teacher?.subjects?.some((sub) =>
                  record?.subjects?.find((cs) => cs._id === sub._id)
                )
              )?.length}
        </div>
      ),
    },
    {
      title: "Roll Number Generated",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.is_roll_number_generated ? (
            <span className="flex justify-center">
              <BiCheckCircle className="text-green-500" size={20} />
            </span>
          ) : (
            <span className="flex justify-center">
              <BiXCircle className="text-red-500" size={20} />
            </span>
          )}
        </div>
      ),
    },
    // {
    //   title: "Created At",
    //   dataIndex: ["createdAt"],
    //   key: "createdAt",
    //   sorter: (a, b) => (moment(a?.createdAt).isBefore(b?.createdAt) ? 1 : -1),
    //   render: (text) => (
    //     <div style={{ color: "#616161" }} className=" text-base">
    //       {moment(text).format("Do MMMM YYYY")}
    //     </div>
    //   ),
    // },
    {
      title: "Action",
      // dataIndex: "action",
      key: "action",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          className=" text-base font-semibold text-center text-blue-500 cursor-pointer"
          onClick={() =>
            history.push(`/dashboard/admin/menu/class/profile/${record?._id}`)
          }
        >
          Click to view
        </div>
      ),
    },
  ];

  // Create Class
  function createClass(values) {
    axios
      .post(serverUrl + "/classes/", values, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        setAddClass(false);
        message.success("Class Created");
        form.resetFields();
        queryClient.invalidateQueries("classes");
      })
      .catch((error) => {
        if (error?.response?.data) {
          message.error(error?.response?.data.message);
        } else {
          message.error(error.message);
        }
      });
  }

  return (
    <>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex items-center justify-between">
            <div className="text-2xl text-orange-500 font-semibold">
              Manage Classes
            </div>
            <div className=" flex space-x-5">
              <Button onClick={() => setAddClass(true)} type="primary">
                Add New Class
              </Button>
            </div>
          </div>
          <div className="py-5">
            <Table
              columns={columns}
              dataSource={
                classes?.isSuccess
                  ? classes?.data?.sort((a, b) =>
                      moment(a?.createdAt).isBefore(b?.createdAt) ? 1 : -1
                    )
                  : []
              }
              pagination={true}
              loading={classes?.isLoading}
            />
          </div>
        </div>
      </div>
      {/* ----------------------------------modal------------------------------------ */}

      <Modal
        width={800}
        visible={addClass}
        onCancel={() => {
          setAddClass(false);
          form.resetFields();
        }}
        footer={null}
      >
        <div>
          <div className="py-2">
            <div className="text-2xl font-bold text-black pb-4 capitalize">
              Add class
            </div>
          </div>
          {/* ---------------------------form---------------- */}
          <div>
            <Form
              layout="vertical"
              name="basic"
              form={form}
              onFinish={createClass}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Class Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please input class name" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Class In Charge"
                    name="in_charge"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Select allowClear>
                      {instructors?.isSuccess
                        ? instructors?.data
                            ?.filter((t) => !t.blocked)
                            ?.map((i) => (
                              <Option value={i?._id}>
                                {i?.teacher?.first_name} {i?.teacher?.last_name}
                              </Option>
                            ))
                        : []}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Academic Start Date"
                    name="academic_start_date"
                    rules={[
                      { required: true, message: "Please input class name" },
                    ]}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      className="w-full"
                      disabledDate={(d) =>
                        d.isBefore(moment().subtract(1, "d"))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Academic End Date"
                    name="academic_end_date"
                    rules={[
                      { required: true, message: "Please input class name" },
                    ]}
                  >
                    <DatePicker
                      className="w-full"
                      disabledDate={(d) => d.isBefore(moment())}
                      onChange={(e) => {
                        setAcademicLastDate(e);
                      }}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                </Col>

                {academicLastDate && (
                  <>
                    <Col span={12}>
                      <Form.Item
                        label="Transition Start Date"
                        name="transition_start_date"
                        tooltip="Transistion period is the phase where Instructor/Admin can complete the Evalauations and Transfers to the next class"
                        rules={[
                          {
                            required: true,
                            message: "Please input class name",
                          },
                        ]}
                      >
                        <DatePicker
                          className="w-full"
                          disabledDate={(d) =>
                            d.isBefore(moment(academicLastDate).add(1, "d"))
                          }
                          onChange={(d) => setTransitionStart(d)}
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Transition End Date"
                        name="transition_end_date"
                        tooltip="Transistion period is the phase where Instructor/Admin can complete the Evalauations and Transfers to the next class"
                        rules={[
                          {
                            required: true,
                            message: "Please input class name",
                          },
                        ]}
                      >
                        <DatePicker
                          className="w-full"
                          disabledDate={(d) => d.isBefore(transitionStartDate)}
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
              <div className="flex justify-end items-center pt-6">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => {
                      setAddClass(false);
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          {/* ----------------------------------------------- */}
        </div>
      </Modal>
    </>
  );
}

export default ClassMain;
