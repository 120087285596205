import { DeleteOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Select,
  Tag,
  message,
} from "antd";
import Button from "antd-button-color";
import axios from "axios";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FileUploadButton from "../../../components/inputs/FileUploadButton";
import AssignmentSubmissionTable from "../../../components/tables/AssignmentSubmissionTable";
import { cloudFrontUrl, serverUrl } from "../../../nestserver";
import { selectUser } from "../../../redux/slices/auth.slice";

function tagRender(props) {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
      className="flex items-center"
    >
      {label}
    </Tag>
  );
}

function EditAssignment({ id, onBack }) {
  const user = useSelector(selectUser);
  const history = useHistory();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [disabled, setDisabled] = useState(true);
  const [editable, setEditable] = useState(false);
  const [draftModules, setDraftModules] = useState(false);
  const [modulesSelected, setModulesSelected] = useState(null);

  const { Option } = Select;
  const onFinish = (values) => {
    axios({
      method: "patch",
      url: serverUrl + "/assignment/" + id,
      data: {
        ...values,
        file: pdf,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Assignment Updated");
        queryClient.invalidateQueries("assignments");
        queryClient.invalidateQueries(["assignments", id]);
        setEditable(false);
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  // Publish/unpublish assignment
  const changeStatus = (value) => {
    if (value && draftModules) {
      message.error("Assignments with unpublished modules cannot be published");
    } else {
      axios({
        method: "patch",
        url: serverUrl + "/assignment/" + id,
        data: {
          published: value,
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
        .then((res) => {
          message.success(
            `Assignment ${res?.data?.published ? "Published" : "UnPublished"}`
          );
          queryClient.invalidateQueries("assignments");
          queryClient.invalidateQueries(["assignments", id]);
        })
        .catch((e) => {
          message.error(e.message);
        });
    }
  };

  function deleteAssignment() {
    axios({
      method: "delete",
      url: serverUrl + "/assignment/" + id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Assignment Deleted");
        queryClient.invalidateQueries("assignments");
        queryClient.invalidateQueries(["assignments", id]);
        history.push("/dashboard/instructor/assignments-and-exams");
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  const [classId, setClassId] = useState(null);
  const [subjectId, setSubjectId] = useState(null);
  const [pdf, setPdf] = useState(null);

  // fetch Class
  const fetchAssignment = async () => {
    const res = await axios.get(serverUrl + "/assignment/" + id);
    return res.data;
  };
  const assignment = useQuery(["assignments", id], fetchAssignment, {
    enabled: !!id,
  });

  // fetch Class
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  // fetch subjects
  async function fetchSubjects() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/subjects/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const subjects = useQuery("subjects", fetchSubjects);

  // fetch modules
  async function fetchModules() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/modules/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const modules = useQuery("modules", fetchModules);

  function handleSetPdf(files) {
    setPdf((file) => {
      return [
        ...pdf,
        ...files.filter((f) => file.findIndex((f2) => f2.id == f.id) == -1),
      ];
    });
  }

  function removePdfItem(id) {
    const newPdf = pdf.filter((f) => f.id !== id);
    setPdf(newPdf);
  }

  useEffect(() => {
    form.resetFields();
    setClassId(assignment.data?.class?._id);
    setSubjectId(assignment.data?.subject?._id);
    setPdf(assignment.data?.file);
    setDisabled(!editable || assignment.data?.published);
  }, [assignment.data]);

  useEffect(() => {
    setDisabled(!editable);
  }, [editable]);

  useEffect(() => {
    if (modulesSelected) {
      const set = modules?.data?.filter(
        (s) => modulesSelected.includes(s?._id) && s?.published !== true
      );
      if (set?.length > 0) {
        setDraftModules(true);
      }
    }
  }, [modulesSelected]);

  return (
    <>
      <div className="py-8">
        <div className="bg-white p-8 rounded-2xl">
          <div className="pb-4">
            <div className="flex items-center justify-end">
              <Button onClick={onBack}>Back</Button>
            </div>
          </div>
          <div className="flex items-center justify-between py-5">
            <h1 className="text-2xl text-primary capitalize">
              {assignment.isSuccess && assignment.data?.name}
            </h1>
            <div className="flex flex-row items-center space-x-4">
              <Popconfirm
                placement="bottom"
                title="Are you sure ?"
                onConfirm={deleteAssignment}
                okText="Yes"
                cancelText="No"
                disabled={assignment.data?.published}
              >
                <Button disabled={assignment.data?.published} type="danger">
                  Delete
                </Button>
              </Popconfirm>
              {!editable ? (
                <Button
                  disabled={assignment.data?.published}
                  onClick={() => setEditable(true)}
                  type="success"
                >
                  Edit
                </Button>
              ) : (
                <Button
                  disabled={disabled}
                  onClick={() => form.submit()}
                  type="success"
                >
                  Save
                </Button>
              )}

              {assignment.data?.published ? (
                <Button
                  disabled={editable}
                  onClick={() => changeStatus(false)}
                  type="warning"
                >
                  Un Publish
                </Button>
              ) : (
                <Button
                  disabled={
                    editable ||
                    !assignment?.data?.score ||
                    !assignment?.data?.overview ||
                    !assignment?.data?.assigned_date ||
                    !assignment?.data?.last_date
                  }
                  onClick={() => changeStatus(true)}
                  type="primary"
                >
                  Publish
                </Button>
              )}
            </div>
          </div>

          <div>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              disabled={disabled}
              initialValues={{
                ...assignment.data,
                class: assignment?.data?.class?._id,
                subject: assignment?.data?.subject?._id,
                modules: assignment?.data?.modules?.map((item) => item._id),
                assigned_date:
                  assignment?.data?.assigned_date &&
                  moment(assignment?.data?.assigned_date),
                last_date:
                  assignment?.data?.last_date &&
                  moment(assignment?.data?.last_date),
              }}
            >
              <Form.Item
                label="Assignment Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input  Assignment Name !",
                  },
                ]}
              >
                <Input
                  maxLength={130}
                  showCount={true}
                  disabled={disabled}
                  className="w-1/2 py-2"
                />
              </Form.Item>

              <div className="flex flex-row space-x-5 w-full">
                <Form.Item
                  label="Select Class"
                  name="class"
                  rules={[
                    {
                      required: true,
                      message: "Please input Select Class!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select Class"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    className="w-full bg-gray-300"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    onChange={(e) => setClassId(e)}
                    disabled={true}
                  >
                    {classes.isSuccess &&
                      classes?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                {classId && (
                  <>
                    <Form.Item label="Subject" name="subject">
                      <Select
                        disabled={true}
                        showSearch
                        showArrow
                        tagRender={tagRender}
                        placeholder="Select Subject"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        className="w-3/4"
                        onChange={(e) => setSubjectId(e)}
                      >
                        {subjects.isSuccess &&
                          subjects?.data
                            ?.filter((s) => s.class?._id === classId)
                            ?.map((data) => {
                              return (
                                <Option key={data._id} value={data._id}>
                                  {data.name}
                                </Option>
                              );
                            })}
                      </Select>
                    </Form.Item>
                  </>
                )}
              </div>

              {subjectId && (
                <Form.Item
                  label="Select Modules"
                  name="modules"
                  rules={[
                    {
                      required: true,
                      message: "Please Select atleast one Module",
                    },
                  ]}
                >
                  <Select
                    disabled={disabled}
                    mode="multiple"
                    showArrow
                    tagRender={tagRender}
                    placeholder="Select modules"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    className="w-3/4"
                    onChange={setModulesSelected}
                  >
                    {modules.isSuccess &&
                      modules?.data
                        ?.filter((s) => s.subject === subjectId)
                        ?.map((data) => {
                          return (
                            <Option key={data._id} value={data._id}>
                              {data.title}
                            </Option>
                          );
                        })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                label="Overview"
                name="overview"
                rules={[
                  {
                    required: true,
                    message: "Please add an overview for the assignment",
                  },
                ]}
              >
                <Input.TextArea disabled={disabled} rows={4}></Input.TextArea>
              </Form.Item>
              <div className="flex flex-row space-x-16 pt-5">
                <Form.Item
                  label="Assigned Date "
                  name="assigned_date"
                  rules={[
                    {
                      required: true,
                      message: "Please input  date !",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={disabled}
                    disabledDate={(d) => d.isBefore(moment().subtract(1, "d"))}
                    format={"YYYY/MM/DD"}
                  />
                </Form.Item>
                <Form.Item
                  label="Due Date"
                  name="last_date"
                  rules={[
                    {
                      required: true,
                      message: "Please input  Due Date !",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={disabled}
                    disabledDate={(d) => d.isBefore(moment().subtract(1, "d"))}
                    format={"YYYY/MM/DD"}
                  />
                </Form.Item>
                <Form.Item
                  label="Score"
                  name="score"
                  rules={[
                    {
                      required: true,
                      message: "Please input  score !",
                    },
                    {
                      pattern: /^[1-9]\d*$/,
                      message: "Please enter a positive number",
                    },
                    {
                      validator: (_, value) => {
                        if (value && (value = 0 || value > 200)) {
                          return Promise.reject(
                            "score should not more than 200"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    disabled={disabled}
                    className="w-36"
                    placeholder="Eg: 50"
                  />
                </Form.Item>
              </div>
            </Form>
          </div>

          <div>
            <FileUploadButton onUpload={(e) => handleSetPdf(e)}>
              <div className="flex items-center space-x-4">
                <Button disabled={disabled} type="primary">
                  Upload
                </Button>
              </div>
            </FileUploadButton>
            <div className="space-y-3 pt-4">
              {pdf &&
                pdf?.map((item) => (
                  <div className="font-bold flex space-x-4" key={item.id}>
                    <a
                      className="p-1 px-3 bg-gray-100 rounded-lg"
                      href={cloudFrontUrl + "/docs/" + item?.id}
                      target="_blank"
                      rel="noreferer noreferrer"
                      style={{ maxWidth: "50%", overflow: "hidden" }}
                    >
                      {item.files?.name}
                    </a>
                    <div>
                      <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => removePdfItem(item.id)}
                      >
                        <Button
                          disabled={disabled}
                          type="danger"
                          shape="circle"
                          className="flex items-center justify-center"
                        >
                          <DeleteOutlined className="cursor-pointer" />
                        </Button>
                      </Popconfirm>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {id && (
          <AssignmentSubmissionTable
            canArchive={
              user?.role === "instructor" &&
              moment().isBetween(
                moment(assignment?.data?.class?.transition_start_date),
                moment(assignment?.data?.class?.transition_end_date)
              )
            }
            assignmentId={id}
            subjectId={assignment.data?.subject?._id}
          />
        )}
      </div>
    </>
  );
}

export default EditAssignment;
