import { Upload, message, Progress } from "antd";
import ImgCrop from "antd-img-crop";
import { useState } from "react";
import axios from "axios";
import { serverUrl } from "../../nestserver";
import { uploadFile } from "../../services/upload.service";

export default function ImageUploadButton({
  image,
  name,
  onUpload,
  ratio = 1,
  children,
  folder = "common",
}) {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadButton = (
    <div>
      {loading ? (
        <Progress type="circle" width={50} percent={progress} />
      ) : (
        children
      )}
    </div>
  );

  // async function uploadFile(data) {
  //   setLoading(true);
  //   var formData = new FormData();
  //   var imagefile = data.file;
  //   formData.append("file", imagefile);
  //   await axios
  //     .post(serverUrl + "/uploads", formData, {
  //       headers: {
  //         "Content-Type": null,
  //       },
  //       onUploadProgress: function (progressEvent) {
  //         setProgress(
  //           Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //         );
  //       },
  //     })
  //     .then((res) => {
  //       setLoading(false);
  //       onUpload(res.data.filename);
  //       return res.data.filename;
  //     });
  // }

  /* -------------------------------------------------------------------------- */
  /*                                Upload Image                                */
  /* -------------------------------------------------------------------------- */

  async function uploadImage(data) {
    try {
      const imageId = await uploadFile(data, folder, setProgress);
      setLoading(false);
      await onUpload(imageId[0]?.id);
      // await onFinish({ image: imageId[0]?.id });
      message.success("Image uploaded successfully");
    } catch (error) {
      message.error(error.message);
    }
  }

  return (
    <div>
      <ImgCrop modalTitle="Adjust Image" aspect={ratio}>
        <Upload
          name={name}
          action={serverUrl + "/uploads"}
          showUploadList={false}
          customRequest={uploadImage}
          beforeUpload={beforeUpload}
        >
          {image ? (
            <img src={image} alt="image" style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </ImgCrop>
    </div>
  );
}
