import { Button, Form, Input, Modal, Select, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { defaultStatus } from "../../../../../helper/options";
import { serverUrl } from "../../../../../nestserver";
import AddEditApplication from "./AddEditApplication";

const AdmissionProfile = ({ data }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [acceptAdmissionPopup, setAcceptAdmissionPopup] = useState(false);
  const [rejectAdmissionPopup, setRejectAdmissionPopup] = useState(false);
  const [getResponseFromChild, setGetResponseFromChild] = useState(null);
  const [status, setStatus] = useState(null);
  const [reason, setReason] = useState("");
  const [classId, setClassId] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [subjectListing, setSubjectListing] = useState({
    subjects: [],
  });

  const { id } = useParams();

  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  const handleStatusChange = (value) => {
    setStatus(value);
  };
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleRejection = async () => {
    const data = {
      status: status,
      message: reason,
    };
    try {
      await axios
        .patch(serverUrl + "/admin/application/" + id + "/status/", data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          setRejectAdmissionPopup(false);
          res.data.success === true
            ? message.success(res.data.message)
            : message.error(res.data.message);
          form.resetFields();
          if (status === defaultStatus.rejected) {
            setRejectAdmissionPopup(false);
          } else if (
            status === defaultStatus.rejected &&
            res.data.success === true
          ) {
            setRejectAdmissionPopup(false);
          }
          fetchApplication();
        });
    } catch (err) {
      if (err?.response?.data?.message) {
        message.error(err?.response?.data?.message);
      }
    }
  };
  const handleRegister = async (data) => {
    try {
      const payload = {
        class: data?.class,
        subjects: [...subjectListing.subjects],
        languages: data?.language ? data?.language : [],
        co_curricular: data?.curricular ? data?.curricular : [],
        password: data?.password,
        parent_phone: data?.parent_phone,
        parent_name: data?.parent_name,
        parent_password: data?.parent_password,
        parent_id: getResponseFromChild?.parent?._id,
        student_id: getResponseFromChild?.student?._id,
      };
      await axios
        .post(serverUrl + "/admin/application/" + id + "/register", payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })

        .then((res) => {
          fetchApplication();
          form.resetFields();
          message.success(res?.data?.message);
          setAcceptAdmissionPopup(false);
        });
    } catch (err) {
      if (err?.response?.data?.message) {
        message.error(err?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
    if (getResponseFromChild && getResponseFromChild.message) {
      setReason(getResponseFromChild.message);
    }
  }, [getResponseFromChild]);

  const classIdData = getResponseFromChild?.class?._id;
  async function fetchSubjects(classId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/subjects/?class=${classId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const subjects = useQuery(
    ["subjects", classIdData],
    () => classIdData && fetchSubjects(classIdData),
    {
      enabled: !!classIdData, // Only fetch if classIdData is truthy
    }
  );

  const handleSaveOnClick = () => {
    if (
      (reason && status === defaultStatus.rejected) ||
      (reason && status === defaultStatus.resubmit)
    ) {
      setRejectAdmissionPopup(true);
    } else if (status === defaultStatus.approved) {
      handleRejection();
    }
  };

  const fetchApplication = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/admin/application/" + id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    setGetResponseFromChild(res.data);
  };

  useEffect(() => {
    fetchApplication();
  }, []);

  useEffect(() => {
    if (getResponseFromChild?.class?._id && subjects?.isSuccess) {
      const mandatorySubjects = subjects?.data?.filter(
        (s) =>
          s?.class?._id === getResponseFromChild?.class?._id && s?.is_mandatory
      );

      setSubjectListing({
        subjects: mandatorySubjects?.map((s) => s?._id),
      });
    }
  }, [getResponseFromChild?.class?._id, subjects?.data]);
  return (
    <>
      <div className="p-8 admission">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md px-5 py-6 w-full "
        >
          <AddEditApplication formData={getResponseFromChild} />
          <div className="border border-solid my-4"></div>
          <div className="admin-view">
            <h6 className="py-5">Admin View</h6>
            <Form>
              <div className="flex">
                <span>Change Status:</span>
                <Form>
                  <Form.Item className="w-96" name="status">
                    {getResponseFromChild &&
                    getResponseFromChild?.status === "pending" ? (
                      <div className="w-full">
                        <Select
                          allowClear
                          placeholder="Change Status"
                          optionFilterProp="children"
                          onChange={handleStatusChange}
                          className="w-full"
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          <Option
                            value={defaultStatus.approved}
                            className="text-green-600 w-full"
                          >
                            Approved
                          </Option>
                          <Option
                            value={defaultStatus.rejected}
                            className="text-red-600"
                          >
                            Rejected
                          </Option>
                          <Option
                            value={defaultStatus.resubmit}
                            className="text-yellow-300"
                          >
                            Resubmit
                          </Option>
                        </Select>
                      </div>
                    ) : (
                      <>
                        {getResponseFromChild &&
                        getResponseFromChild?.status === "approved" ? (
                          <div className="status-check">Approved</div>
                        ) : getResponseFromChild?.status === "rejected" ? (
                          <div className="status-check">Rejected</div>
                        ) : getResponseFromChild?.status === "withdraw" ? (
                          <div className="status-check">Withdraw</div>
                        ) : (
                          <div className="status-check">ReSubmit</div>
                        )}
                      </>
                    )}
                  </Form.Item>
                </Form>
              </div>
              <div className="flex">
                <span>Reason:</span>
                <Form.Item
                  rules={[
                    {
                      required:
                        status === defaultStatus.rejected ||
                        status === defaultStatus.resubmit
                          ? true
                          : false,
                      message: "Please input the reason",
                    },
                  ]}
                  className="w-full"
                >
                  <TextArea
                    rows={3}
                    placeholder="Reason"
                    className="w-full border focus:outline-none resize-none"
                    value={reason}
                    onChange={handleReasonChange}
                    disabled={
                      status === defaultStatus.approved ||
                      (getResponseFromChild?.message &&
                        getResponseFromChild?.status === "resubmit") ||
                      getResponseFromChild?.status === defaultStatus.approved
                    }
                  />
                </Form.Item>
              </div>

              <div className="flex justify-center items-center py-5">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="medium"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                    onClick={handleSaveOnClick}
                    disabled={!status}
                  >
                    Save
                  </Button>
                  <Button
                    size="medium"
                    type="dark"
                    htmlType="reset"
                    onClick={() => {
                      setAcceptAdmissionPopup(false);
                      setRejectAdmissionPopup(false);
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                  {getResponseFromChild?.status === defaultStatus.approved &&
                    !getResponseFromChild?.student?.is_registered && (
                      <Button
                        size="medium"
                        type="primary"
                        htmlType="reset"
                        onClick={() => {
                          setAcceptAdmissionPopup(true);
                          setStudentId(
                            getResponseFromChild?.student?.unique_id
                          );
                          form.setFieldsValue({
                            username: getResponseFromChild?.student?.username,
                            parent_username:
                              getResponseFromChild?.parent?.username,
                            parent_name: `${getResponseFromChild?.parent?.parent?.first_name} ${getResponseFromChild?.parent?.parent?.last_name} `,
                            parent_phone: getResponseFromChild?.parent?.phone,
                            class: getResponseFromChild?.class?._id,
                          });
                        }}
                      >
                        Register
                      </Button>
                    )}
                </div>
              </div>
            </Form>
          </div>
          {/* 
    
    --------------------------------------------------------------------------------       */}
          <Modal
            width={800}
            visible={acceptAdmissionPopup}
            maskClosable={false}
            onOk={() => setAcceptAdmissionPopup(false)}
            onCancel={() => {
              setAcceptAdmissionPopup(false);
              form.resetFields();
            }}
            footer={null}
          >
            <div>
              {/* ---------------------------form---------------- */}
              <Form
                layout="vertical"
                name="basic"
                form={form}
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={handleRegister}
              >
                <div>
                  <div className="popup-header">
                    <h4 className="text-base font-bold pb-2">
                      Student Id:{" "}
                      <span>
                        {""}
                        {getResponseFromChild?.student?.student?.unique_id}
                      </span>
                    </h4>
                  </div>
                  <div className="grid grid-cols-2 gap-x-4">
                    <Form.Item
                      label="Class"
                      name="class"
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: "Please select a class",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        showArrow
                        placeholder="Select Class"
                        className="w-full bg-gray-300"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        onChange={(e) => setClassId(e)}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        dropdownClassName="custom-dropdown"
                        disabled
                      >
                        {classes.isSuccess &&
                          classes?.data?.map((data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="User Name" name="username">
                      <Input readOnly />
                    </Form.Item>
                  </div>

                  <div className="grid grid-cols-2 gap-x-4">
                    <Form.Item
                      label="Password"
                      name="password"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input your password!",
                      //   },

                      // ]}
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                        {
                          min: 6,
                          message:
                            "Password must be at least 6 characters long!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <div>
                      <div className="pb-2"> Mandatory</div>
                      <div className="flex flex-wrap gap-3 pb-4">
                        {subjects?.isSuccess &&
                          subjects?.data
                            ?.filter(
                              (s) =>
                                s.class?._id ===
                                  getResponseFromChild?.class?._id &&
                                s.is_mandatory === true
                            )
                            ?.map((data) => (
                              <div
                                key={data._id}
                                className="bg-gray-200 px-4 py-2 rounded-md text-gray-700 iskool-friends-card capitalize"
                              >
                                {data.name}
                              </div>
                            ))}
                      </div>
                    </div>
                    <Form.Item name="language" label="Languages">
                      <Select
                        mode="multiple"
                        showArrow
                        placeholder="Select language"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        className="w-full"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {subjects.isSuccess &&
                          subjects?.data
                            ?.filter(
                              (s) =>
                                s?.class?._id ===
                                  getResponseFromChild?.class?._id &&
                                s?.is_language === true
                            )
                            ?.map((data) => {
                              return (
                                <Option key={data._id} value={data._id}>
                                  {data?.name}
                                </Option>
                              );
                            })}
                      </Select>
                    </Form.Item>
                    <Form.Item name="curricular" label="Co-Curricular">
                      <Select
                        mode="multiple"
                        showArrow
                        placeholder="Select co-Curricular"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        className="w-full"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {subjects.isSuccess &&
                          subjects?.data
                            ?.filter(
                              (s) =>
                                s?.class?._id ===
                                  getResponseFromChild?.class?._id &&
                                s?.is_co_curricular === true
                            )
                            ?.map((data) => {
                              return (
                                <Option key={data._id} value={data._id}>
                                  {data?.name}
                                </Option>
                              );
                            })}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="popup-header">
                    <h4 className="text-base font-bold pb-2">Parent</h4>
                  </div>

                  <div className="grid grid-cols-2 gap-x-4">
                    <Form.Item
                      name="parent_name"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input the name!",
                        },
                      ]}
                    >
                      <Input type="text" readOnly />
                    </Form.Item>
                    <Form.Item name="parent_username" label="Username">
                      <Input readOnly />
                    </Form.Item>
                  </div>
                  <div className="grid grid-cols-2 gap-x-4">
                    <Form.Item label="Phone" name="parent_phone">
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item
                      label="Password"
                      name="parent_password"
                      rules={[
                        {
                          required:
                            !getResponseFromChild?.parent?.is_registered &&
                            true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      {getResponseFromChild?.parent?.is_registered ? (
                        <Input.Password
                          defaultValue={"*******************"}
                          readOnly={getResponseFromChild?.parent?.is_registered}
                        />
                      ) : (
                        <Input.Password />
                      )}
                    </Form.Item>
                  </div>
                </div>
                {/* ----------------------------------------------- */}
                <div className="flex justify-end items-center pt-6">
                  <div className="inline-flex items-center space-x-2">
                    <Button
                      onClick={() => {
                        setAcceptAdmissionPopup(false);
                        form.resetFields();
                      }}
                      size="large"
                      type="dark"
                    >
                      Cancel
                    </Button>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="text-white"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>
          <Modal
            width={800}
            visible={rejectAdmissionPopup}
            onOk={() => {
              setRejectAdmissionPopup(false);
              setReason("");
            }}
            onCancel={() => {
              setRejectAdmissionPopup(false);
              form.resetFields();
            }}
            footer={null}
          >
            <div>
              {/* ---------------------------form---------------- */}
              <Form
                layout="vertical"
                name="basic"
                form={form}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <div>
                  Do you want to {status === "rejected" ? "reject" : "resubmit"}{" "}
                  the Admission?
                </div>
                {/* ----------------------------------------------- */}
                <div className="flex justify-end items-center pt-6">
                  <div className="inline-flex items-center space-x-2">
                    <Button
                      size="large"
                      type="danger"
                      htmlType="submit"
                      onClick={handleRejection}
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() => {
                        setRejectAdmissionPopup(false);
                      }}
                      size="large"
                      type="dark"
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AdmissionProfile;
