import React from "react";

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const CustomWeekCheckBox = ({ weekdayStates, setWeekdayStates }) => {
  const handleWeekdayChange = (dayName, checked) => {
    setWeekdayStates((prevStates) => ({
      ...prevStates,
      [dayName]: checked,
    }));
  };

  return (
    <div className="week-calender">
      <div className="custom-checkbox-container">
        {weekdays.map((day) => (
          <label key={day} className="custom-checkbox-label">
            <input
              type="checkbox"
              checked={weekdayStates[day]}
              onChange={(e) => handleWeekdayChange(day, e.target.checked)}
              className="custom-checkbox-input"
            />
            <span className="custom-checkbox-checkmark">
              <span className="weekday">{day}</span>
              <span className="check-cross">
                {weekdayStates[day] ? (
                  <div className="green">✓</div>
                ) : (
                  <div className="red">✗</div>
                )}
              </span>
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default CustomWeekCheckBox;
