import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaGithub,
  FaLink,
} from "react-icons/fa";

export default function SocialButton({ data }) {
  function renderButton(data) {
    switch (data?.name) {
      case "facebook":
        return (
          <a href={data?.link} target="_blank" rel="no referal">
            <FaFacebookF className="text-2xl" />
          </a>
        );

      case "instagram":
        return (
          <a href={data?.link} target="_blank" rel="no referal">
            <FaInstagram className="text-2xl" />
          </a>
        );

      case "twitter":
        return (
          <a href={data?.link} target="_blank" rel="no referal">
            <FaTwitter className="text-2xl" />
          </a>
        );

      case "github":
        return (
          <a href={data?.link} target="_blank" rel="no referal">
            <FaGithub className="text-2xl" />
          </a>
        );

      default:
        return (
          <a href={data?.link} target="_blank" rel="no referal">
            <FaLink className="text-2xl" />
          </a>
        );
    }
  }

  return <div>{renderButton(data)}</div>;
}
