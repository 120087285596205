// servers
const isDev = process.env.NODE_ENV === "development";
// const isDev = false;
const host = "localhost";
// export const serverUrl = isDev
//   ? `http://${host}:3030`
//   : `https://api.is-kool.com`;
// export const serverUrl = isDev ? `http://${host}:3030` : `https://api.is-kool.com`;
// export const cloudFrontUrl = isDev
//   ? `https://d3n2ne2tgz4s0t.cloudfront.net`
//   : `https://cdn.is-kool.com`;
export const cloudFrontUrl = process.env.REACT_APP_CLOUD_FRONT_URL;
export const serverUrl = process.env.REACT_APP_SERVER_URL;
