import React from "react";
import { useHistory, useParams } from "react-router";

const SchoolDocs = () => {
  const history = useHistory();
  const { id } = useParams();

  const docs = [
    { id: 1, name: "Transfer Certificate" },
    { id: 2, name: "Study Certificate" },
    { id: 3, name: "Conduct Certificate" },
  ];
  return (
    <div>
      <div className="mt-8 document-section">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className=" flex gap-4">
            {docs.map((doc, index) => {
              return (
                <div key={index} className="iskool-friends-card hover-effect">
                  <div>
                    <h1
                      className="text-base cursor-pointer"
                      onClick={() =>
                        history.push({
                          pathname: `/dashboard/super-admin/school/doc/${doc?.id}`,
                          state: { additionalData: id },
                        })
                      }
                    >
                      {doc?.name}
                    </h1>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolDocs;
