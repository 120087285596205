import { MinusCircleOutlined } from "@ant-design/icons";
import { Form, Input, message } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import React from "react";
import { useQueryClient } from "react-query";
import { serverUrl } from "../../../../../../nestserver";

function CreditScore({ data }) {
  const queryClient = useQueryClient();
  const onFinish = (values) => {
    axios({
      method: "patch",
      url: serverUrl + "/subjects/" + data?._id,
      data: {
        ...values,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Credits Updated");
        queryClient.invalidateQueries("subject");
      })
      .catch((e) => {
        console.log(e);
        message.error(e.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div>
        <div className="bg-white w-full rounded-2xl">
          <div className="p-8 ">
            <div
              style={{ color: "#E46E31" }}
              className="text-2xl font-semibold text-left"
            >
              Credit Score
            </div>

            <div>
              <Form
                layout="vertical"
                name="basic"
                initialValues={data}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                {/* -------------------------------------------------sec 1---------------------------- */}

                <Form.List
                  style={{ marginBottom: "10px", width: "100%" }}
                  label="Credits"
                  name="credits"
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <div className="border-b py-4  flex">
                          <div className="w-0 flex-grow flex items-center space-x-4">
                            <Form.Item
                              {...restField}
                              name={[name, "title"]}
                              fieldKey={[fieldKey, "title"]}
                              label="Credit Title"
                              className="mb-0 w-full"
                              rules={[
                                {
                                  required: true,
                                  message: "Credit need a title",
                                },
                              ]}
                              //   label="Title"
                            >
                              <Input
                                className="w-full py-2"
                                placeholder="Title"
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "score"]}
                              fieldKey={[fieldKey, "score"]}
                              label="Score"
                              rules={[
                                {
                                  required: true,
                                  message: "Credit need a score",
                                },
                                {
                                  pattern: /^[1-9]\d*$/,
                                  message: "Please enter a positive number ",
                                },
                                {
                                  validator: (_, value) => {
                                    if (value && (value = 0 || value > 200)) {
                                      return Promise.reject(
                                        "score should not more than 200"
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                              className="mb-0 w-full"
                            >
                              <Input
                                className="w-full py-2"
                                placeholder="Score"
                              />
                            </Form.Item>
                          </div>
                          <div className="w-10 flex items-center justify-center">
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </div>
                        </div>
                      ))}
                      <Form.Item>
                        <div className="flex items-center justify-center pt-8">
                          <Button size="large" onClick={() => add()}>
                            Add Credit
                          </Button>
                        </div>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item className="mb-0">
                  <div className="flex items-center  pt-8">
                    <div className="inline-block ml-auto space-x-3">
                      <Button type="primary" size="large" htmlType="submit">
                        Save
                      </Button>
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreditScore;
