import { UnorderedListOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Modal, Select, message } from "antd";
import axios from "axios";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { defaultStatus } from "../../../../helper/options";
import { serverUrl } from "../../../../nestserver";
import plusicon from "../../../../resources/plus.svg";

const ApplyLeave = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const queryClient = useQueryClient();
  const { RangePicker } = DatePicker;
  const history = useHistory();
  const [applyLeavePopup, setApplyLeavePopup] = useState(false);
  async function getLeavesList() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/permission-request/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const leaveList = useQuery("/permission-request/", getLeavesList);
  useEffect(() => {
    getLeavesList();
  }, []);

  const onFinish = (values) => {
    const payload = {
      from: moment(values?.date[0]).format("YYYY-MM-DD"),
      to: moment(values?.date[1]).format("YYYY-MM-DD"),
      student: null,
      type: values?.type,
      title: values?.title,
      description: values?.description,
    };
    axios({
      method: "post",
      url: serverUrl + "/permission-request/",
      data: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        queryClient.invalidateQueries("/permission-request/");
        form.resetFields();
        setApplyLeavePopup(false);
        message.success(res?.data?.result?.message);
      })
      .catch((error) => {
        message.error(error?.response?.data?.error?.errors);
      });
  };

  return (
    <div className="">
      <div className="pt-8">
        <div className="flex justify-between items-start pt-4 bg-white p-10 rounded-2xl">
          <div className="flex gap-20px flex-wrap	">
            {leaveList?.status === "success" &&
            leaveList?.data?.result &&
            leaveList?.data?.result?.data?.length > 0 ? (
              leaveList?.data?.result?.data?.map((list) => (
                <div>
                  <div
                    className="iskool-friends-card  flex flex-col space-x-3 cursor-pointer h-full p-20px"
                    key={list?._id}
                  >
                    <span
                      className={clsx(
                        list?.status === defaultStatus?.approved
                          ? "bg-greenn"
                          : list?.status === defaultStatus?.pending
                          ? "bg-yellowe"
                          : list?.status === defaultStatus?.withdraw
                          ? "bg-gray"
                          : "bg-redd",
                        "p-1 rounded-lg text-center w-82px capitalize"
                      )}
                      onClick={() =>
                        history.push(
                          `/dashboard/student/menu/student/leave/${list?._id}`
                        )
                      }
                    >
                      {list?.status}
                    </span>
                    <div
                      className="text-center mt-14px"
                      onClick={() =>
                        history.push(
                          `/dashboard/student/menu/student/leave/${list?._id}`
                        )
                      }
                    >
                      <UnorderedListOutlined size={35} />
                      <div className="pt-2">
                        <div className="font-bold text-base h-8 capitalize">
                          {list?.title}
                        </div>
                        <div className="font-bold h-8 flex flex-col">
                          {moment(list?.createdAt).format("DD-MM-YYYY")}
                          {/* <span className="text-xs font-normal">(Applied Date)</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>No Applied leave Exists</div>
            )}
          </div>
          <div
            onClick={() => setApplyLeavePopup(true)}
            className="cursor-pointer p-28px add-module flex items-center justify-center mt-2"
          >
            <div className="flex flex-col items-center space-y-3">
              <div className="flex items-center justify-center cursor-pointer">
                <img className="w-6 h-6" src={plusicon} alt="icon" />
              </div>
              <div className="text-white text-sm  text-center">
                <div>Apply Leave</div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          width={450}
          visible={applyLeavePopup}
          onOk={() => setApplyLeavePopup(false)}
          onCancel={() => {
            setApplyLeavePopup(false);
            form.resetFields();
          }}
          footer={null}
          forceRender
        >
          <h3 className="text-lg font-bold pb-3">Apply Leave</h3>
          <div className="bg-white rounded-2xl ">
            <Form
              form={form}
              layout="vertical"
              name="dynamic_rule"
              onFinish={onFinish}
              autoComplete="off"
              // className="w-1/2"
            >
              <Form.Item
                label="Date"
                name="date"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <RangePicker
                  format={"DD/MM/YYYY"}
                  disabledDate={(d) =>
                    !d || d.isBefore(moment().startOf("day"))
                  }
                  className="w-full"
                />
              </Form.Item>
              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Payment Type is required",
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placeholder="Select type"
                >
                  <Select.Option value="Sick">Sick</Select.Option>
                  <Select.Option value="Personal">Personal</Select.Option>
                  <Select.Option value="Vacation">Vacation</Select.Option>
                  <Select.Option value="Family Reason">
                    Family Reasons
                  </Select.Option>
                  <Select.Option value="Partial Day Exception">
                    Partial Day Exception
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Title"
                name="title"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                  },
                  {
                    min: 1,
                    max: 15,
                    message: "Title must be between 1 and 15 characters",
                  },
                ]}
              >
                <Input maxLength={15} />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length <= 150) {
                        return Promise.resolve(); // Validation passed
                      }
                      return Promise.reject(
                        "Description must be at most 150 characters"
                      );
                    },
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Reason"
                  className="w-full border focus:outline-none resize-none"
                  maxLength={150} // Limit the input to 150 characters visually
                />
              </Form.Item>
              <div className="text-center">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="text-white"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ApplyLeave;
