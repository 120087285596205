import { Button, Form, Input, Modal, Select, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import FeeTable from "../../../../admin/pages/components/managefee/FeeTable";
import ViewTransactionHistory from "../../../../admin/pages/components/managefee/ViewTransactionHistory";
const ViewFeeDetails = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [OpenTransactionPopup, setOpenTransactionPopup] = useState(false);
  const [openTransactionHistory, setOpenTransactionHistory] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState();
  const [selectedStudentId, setSelectedStudentId] = useState();
  const [selectedStudentResponse, setSelectedStudentResponse] = useState();
  const [year, setYear] = useState("");
  const [isYear, setIsYear] = useState();

  const handleYearChange = (e) => {
    setYear(e.target.value);
    setIsYear(e.target.value?.toString().slice(0, 4).length === 4);
  };

  async function fetchStudent() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/students/by-parent/`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data.result;
  }
  const students = useQuery("students/by-parent", fetchStudent);

  const handleSelectChange = (studentId) => {
    if (studentId) {
      setSelectedStudentId(studentId);

      const fetchClassStudents = async () => {
        if (year) {
          const res = await axios({
            method: "get",
            url: `${serverUrl}/student-fee/${studentId}?year=${year}`,
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          });
          return res.data;
        }
      };
      fetchClassStudents()
        .then((response) => {
          form.resetFields(["studentId", "class", "parent", "phone"]);
          setSelectedStudentResponse(response?.result);
          form.setFieldsValue({
            studentId: response?.result?.student?.unique_id,
            class: response?.result?.class?.name,
            parent: `${response?.result?.student?.parent?.first_name} ${response?.result?.student?.parent?.last_name}`,
            phone: response?.result?.student?.parent?.phone,
          });
        })
        .catch((err) => {
          message.error(err?.response?.data?.error?.errors);
          form.resetFields(["studentId", "class", "parent", "phone"]);
          setSelectedStudentResponse();
          console.log("err", err?.response?.data?.error?.errors);
        });
    }
  };
  useEffect(() => {
    if (!isYear) {
      setSelectedStudentResponse();
      form.resetFields(["name", "studentId", "class", "parent", "phone"]);
    }
  }, [!isYear]);

  const fetchStudentFeeHistory = async () => {
    if (selectedStudentResponse?._id) {
      const res = await axios({
        method: "get",
        url: `${serverUrl}/student-fee/${selectedStudentResponse._id}/transaction`,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      return res.data;
    }
  };
  useEffect(() => {
    if (selectedStudentResponse?._id) {
      fetchStudentFeeHistory()
        .then((response) => {
          setTransactionHistory(response?.result);
        })
        .catch((err) => {
          // if (err?.response?.data?.error?.errors) {
          //   message.error(err?.response?.data?.error?.errors);
          // }
          console.log("error", err);
        });
    }
  }, [selectedStudentResponse]);

  return (
    <div className="student-form-fee">
      <div className="p-8">
        <div className="bg-white p-10 rounded-2xl">
          <div className="text-2xl text-orange-500 font-semibold pb-4">
            Manage Fee
          </div>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <div className="form-grid">
              <Form.Item
                label="Year"
                name="year"
                rules={[
                  {
                    pattern: /^\d{4}$/,
                    message: "Enroll year must be a 4-digit number!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 4)}
              >
                <Input
                  placeholder="Please enter year to select student"
                  onChange={handleYearChange}
                  type="number"
                  min={0}
                  maxLength={4}
                />
              </Form.Item>
              {year && isYear && (
                <>
                  <Form.Item label="Name" name="name">
                    <Select
                      showSearch
                      showArrow
                      placeholder="Select name"
                      className="w-full bg-gray-300"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(value) => handleSelectChange(value)}
                    >
                      {students &&
                        students?.data?.map((data) => {
                          return (
                            <Option key={data._id} value={data._id}>
                              {`${data?.first_name} ${data?.last_name}`}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Student Id" name="studentId">
                    <Input readOnly />
                  </Form.Item>
                </>
              )}
            </div>
            {year && isYear && (
              <div className="form-grid">
                <Form.Item label="Class" name="class">
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="Parent" name="parent">
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="Phone" name="phone">
                  <Input readOnly />
                </Form.Item>
              </div>
            )}
          </Form>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <div>
              <div className="bg-white pt-4 rounded-2xl">
                <h3 className="text-base font-semibold text-center">
                  Fee Summary
                </h3>
              </div>

              <FeeTable feeResponse={isYear && selectedStudentResponse} />
            </div>
          </Form>
          <div className="flex justify-between items-center pt-4">
            <h3 className="text-lg font-bold">
              Last Paid:{" "}
              <span className="font-semibold">
                {selectedStudentResponse?.last_paid
                  ? moment(selectedStudentResponse?.last_paid).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  : ""}
              </span>
            </h3>
            <Button
              onClick={() => {
                setOpenTransactionPopup(true);
              }}
              disabled
            >
              Pay Now
            </Button>
          </div>
          <div className="flex justify-center pt-5 items-center">
            <Button
              class
              onClick={() => {
                setOpenTransactionHistory(true);
              }}
            >
              View History
            </Button>
          </div>
        </div>
      </div>

      <Modal
        width={360}
        layout="vertical"
        visible={OpenTransactionPopup}
        onOk={() => setOpenTransactionPopup(false)}
        onCancel={() => {
          setOpenTransactionPopup(false);
        }}
        footer={null}
      >
        <div className="pt-4">pay</div>
      </Modal>
      <Modal
        width={1100}
        layout="vertical"
        visible={openTransactionHistory}
        onOk={() => setOpenTransactionHistory(false)}
        onCancel={() => {
          setOpenTransactionHistory(false);
        }}
        footer={null}
      >
        <ViewTransactionHistory
          transactionHistory={isYear && transactionHistory}
        />
      </Modal>
    </div>
  );
};

export default ViewFeeDetails;
