import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Select, Space, Table, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../../../../nestserver";

const AttendancePermission = () => {
  const queryClient = useQueryClient();
  const [searchInput, setSearchInput] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { Option } = Select;
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [classId, setClassId] = useState();

  const handleApprove = async () => {
    try {
      const res = await axios.patch(
        `${serverUrl}/permission-request/${selectedRecord._id}`,
        { status: "approved" },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );
      message.success(res?.data?.result?.message);
      queryClient.invalidateQueries("/permission-request/");
      setModalVisible(false);
    } catch (err) {
      message.error(err?.response?.data?.error?.errors);
    }
  };

  const handleReject = async () => {
    try {
      const res = await axios.patch(
        `${serverUrl}/permission-request/${selectedRecord._id}`,
        { status: "rejected" },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );
      queryClient.invalidateQueries("/permission-request/");
      message.success(res?.data?.result?.message);
      setModalVisible(false);
    } catch (err) {
      message.error(err?.response?.data?.error?.errors);
    }
  };
  //fetch class
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  function strcmp(a, b) {
    if (a === b) {
      return 0;
    }

    if (a > b) {
      return 1;
    }

    return -1;
  }

  async function getLeavesList() {
    const params = classId ? `?classId=${classId}` : "";
    const res = await axios({
      method: "get",
      url: `${serverUrl}/permission-request/${params}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const leaveList = useQuery(["/permission-request/", classId], () =>
    getLeavesList(classId)
  );

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });
  const columns = [
    {
      title: "Date Requested",
      dataIndex: [],
      width: 150,
      fixed: "left",
      render: (record) => {
        return <div>{moment(record?.createdAt).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      title: "Name",
      dataIndex: [],
      width: 150,
      fixed: "left",
      ...getColumnSearchProps("student"),
      render: (record) => {
        return <div>{record?.student}</div>;
      },
    },
    {
      title: "Date Range",
      width: 200,
      fixed: "left",
      render: (record) => {
        return (
          <div>{`${moment(record?.from).format("DD-MM-YYYY")} to ${moment(
            record?.to
          ).format("DD-MM-YYYY")}`}</div>
        );
      },
    },
    {
      title: "Reason",
      width: 200,
      fixed: "left",
      render: (record) => {
        return <div className="capitalize">{record?.type}</div>;
      },
    },
    {
      title: "Status",
      width: 200,
      fixed: "left",
      ...getColumnSearchProps("status"),
      sorter: (a, b) => strcmp(a.status, b.status),
      render: (record) => {
        return <div className="capitalize">{record?.status}</div>;
      },
    },
    {
      title: "Action",
      width: 200,
      fixed: "left",
      render: (text, record) => {
        return (
          <Button
            onClick={() => {
              setSelectedRecord(record);
              setModalVisible(true);
            }}
            disabled={record?.status !== "pending"}
          >
            Action
          </Button>
        );
      },
    },
  ];
  return (
    <div className="attendance">
      <div className="pt-8">
        <div className="bg-white p-10 rounded-2xl ">
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Manage Permissions
          </div>
          <div className="flex gap-10px">
            <h6 className=" font-semibold text-base">Class: &nbsp;</h6>
            <Select
              showSearch
              showArrow
              allowClear
              placeholder="Select Class"
              className="w-1/2 bg-gray-300"
              style={{
                border: "6px",
                backgroundColor: "#EBEBEB",
                width: "33%",
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => setClassId(value)}
            >
              <>
                {classes.isSuccess &&
                  classes?.data?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {data.name}
                      </Option>
                    );
                  })}
              </>
            </Select>
          </div>
          <Table
            className="rounded-2xl pt-6"
            columns={columns}
            pagination={true}
            dataSource={
              leaveList?.data?.result?.data
                ? leaveList?.data?.result?.data?.sort((a, b) =>
                    moment(a?.createdAt).isBefore(b?.createdAt) ? 1 : -1
                  )
                : []
            }
          />
        </div>
      </div>
      <Modal
        title="Approve/Reject Permission"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="reject" onClick={handleReject}>
            Reject
          </Button>,
          <Button key="approve" type="primary" onClick={handleApprove}>
            Approve
          </Button>,
        ]}
      >
        <p>Are you sure you want to take this action?</p>
      </Modal>
    </div>
  );
};

export default AttendancePermission;
