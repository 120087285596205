import React from "react";
import Header from "../components/inc/Header";
import LeftSidebar from "../components/inc/LeftSidebar";
import RightSideBar from "../components/inc/RightSideBar";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { serverUrl } from "../nestserver";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { loginUser, selectUser } from "../redux/slices/auth.slice";
import { message } from "antd";

const SplashScreen = () => {
  return (
    <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
      <div style={{ margin: "auto", fontSize: "1.5rem" }}>Loading...</div>
    </div>
  );
};

const DashboardLayout = ({ children }) => {
  const user = useSelector(selectUser);
  const history = useHistory();
  const dispatch = useDispatch();

  const autoLogin = () => {
    return axios({
      method: "post",
      url: serverUrl + "/auth/me",
      data: {},
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
  };

  const userDetails = useQuery("user", autoLogin, {
    onSuccess(data) {
      dispatch(loginUser(data.data));
    },
    onError(data) {
      if (window.localStorage.getItem("jwt-token")) {
        message.error("Session Expired !");
      }
      dispatch(loginUser(null));
      history.push("/auth/login");
    },
    retry: 1,
  });

  return (
    <>
      {user === undefined ? (
        <SplashScreen />
      ) : (
        <div
          style={{ backgroundColor: "#E5E5E5" }}
          className="flex flex-col h-screen overflow-y-scroll"
        >
          <div className="h-16 w-full bg-left">
            <Header />
          </div>

          <div className="h-0 flex-grow flex overflow-y-hidden w-full">
            <div className="w-28 bg-white relative" style={{ zIndex: 1000 }}>
              <LeftSidebar />
            </div>

            <div className="w-0 flex-grow h-full overflow-auto z-50">
              {children}
            </div>

            <div className="w-80 h-full bg-white rounded-2xl mt-8 mr-10 overflow-y-auto overflow-x-hidden">
              <RightSideBar />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardLayout;
