import { DownloadOutlined, FileDoneOutlined } from "@ant-design/icons";
import React from "react";
import { cloudFrontUrl, serverUrl } from "../../../../../nestserver";

function SubjectSyllabus({ subjectDetails }) {
  return (
    <>
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div>
            {subjectDetails?.syllabus?.id ? (
              <div>
                {subjectDetails?.syllabus?.files?.name?.split(".")?.pop() ===
                "pdf" ? (
                  <iframe
                    src={
                      cloudFrontUrl + "/docs/" + subjectDetails?.syllabus?.id
                    }
                    width="100%"
                    height="1000px"
                  ></iframe>
                ) : (
                  <div
                    className="flex items-center justify-between p-3 rounded"
                    style={{ border: "1px solid gray" }}
                  >
                    <div className="flex items-center space-x-6">
                      <FileDoneOutlined className="text-xl" />
                      <p>
                        {subjectDetails?.name +
                          "-" +
                          subjectDetails?.class?.name +
                          " Syllabus"}
                      </p>
                    </div>
                    <a
                      href={
                        serverUrl +
                        "/uploads/docs" +
                        subjectDetails?.syllabus?.filename
                      }
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      <DownloadOutlined className="text-xl cursor-pointer" />
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-xl font-light text-gray-800 text-center">
                Syllabus not available
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SubjectSyllabus;
