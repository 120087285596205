import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import FeedSection from "../../../components/sections/FeedSection";
import StudentMainProfile from "../../../components/sections/StudentMainProfile";
import StudentsAchieve from "../../../components/sections/StudentsAchieve";
import { serverUrl } from "../../../nestserver";

const Index = () => {
  async function getAnnouncement() {
    const data = await axios({
      method: "get",
      url: serverUrl + "/announcement",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return data.data;
  }

  const announcement = useQuery("announcement", getAnnouncement);

  return (
    <div className="h-full px-10 py-8">
      <StudentMainProfile />
      <div className="pt-10">
        <StudentsAchieve />
      </div>
      <div className="py-10">
        <div className="bg-white w-full rounded-2xl bg-opacity-40 px-4 pb-4">
          <div
            style={{ color: "#E46E31" }}
            className=" text-orange-500 text-2xl font-semibold p-4 py-6"
          >
            School Post
          </div>
          <div className="space-y-5">
            {announcement?.isSuccess &&
              announcement?.data.map((item, index) => {
                return <FeedSection key={item?._id} data={item} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
