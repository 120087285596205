import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../nestserver";

const { Panel } = Collapse;

export default function HelpPage() {
  async function getHelp() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/help/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const helps = useQuery("help", getHelp);

  return (
    <div className="p-8">
      <div className="bg-white p-6 rounded-2xl space-y-6">
        <div className="flex items-center justify-between">
          <div className="text-xl text-orange-500 text-medium">
            Frequently Asked Questions
          </div>
        </div>

        <div>
          <Collapse
            expandIconPosition="right"
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? (
                <MinusOutlined className="text-primary-500" />
              ) : (
                <PlusOutlined />
              )
            }
            accordion
            className="iskool-accord"
          >
            {helps.isSuccess &&
              helps.data.map((help) => (
                <Panel header={help.question} key={help._id}>
                  <p>{help.answer}</p>
                </Panel>
              ))}
          </Collapse>
        </div>

        <div className="flex items-center justify-between">
          <div>
            <div className="text-xl text-orange-500 text-medium">
              Contact Info
            </div>
            <div className="text-gray-500 ">Phone: +91 95913 45370</div>
            <div className="text-gray-500 ">
              Email Id: contactus@twinbotz.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
