import { DeleteOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Tag,
  message,
} from "antd";
import axios from "axios";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";
import plusicon from "../../../../../resources/plus.svg";

const TemplateCard = () => {
  const { Search } = Input;
  const { Option } = Select;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [search, setSearch] = useState(null);
  const [addTemplate, setAddTemplate] = useState(false);
  const [classId, setClassId] = useState(null);
  const [getClassId, setGetClassId] = useState(null);
  const [filterLanguageSubject, setFilterLanguageSubject] = useState();
  const [filterCurricularSubject, setFilterCurricularSubject] = useState();
  const [mandataryPayload, setMandataryPayload] = useState([]);
  const [languagePayload, setLanguagePayload] = useState([]);
  const [curricularPayload, setCurricularPayload] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  function tagRender(props) {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
        className="flex items-center"
      >
        {label}
      </Tag>
    );
  }
  function disabledDate(current) {
    return current && current < moment().startOf("day");
  }

  // fetch class
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  const handleGetClass = (id) => {
    setGetClassId(id);
  };
  // fetch subject
  async function fetchSubjects() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/subjects/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const subjects = useQuery("subjects", fetchSubjects, {
    enabled: !!classId,
  });

  const removeSubjectsData = () => {
    queryClient.invalidateQueries("subjects", { data: [] });
  };
  const handleSelectLanguage = (selectedLanguages) => {
    const filteredSubjects = subjects?.isSuccess
      ? subjects?.data.filter(
          (s) => selectedLanguages.includes(s?._id) && s?.is_language === true
        )
      : [];
    setFilterLanguageSubject(filteredSubjects);
    return filteredSubjects;
  };
  const handleSelectCurricular = (selectedCurricular) => {
    const filteredSubjects = subjects?.isSuccess
      ? subjects?.data.filter(
          (s) =>
            selectedCurricular.includes(s?._id) && s?.is_co_curricular === true
        )
      : [];
    setFilterCurricularSubject(filteredSubjects);
    return filteredSubjects;
  };
  useEffect(() => {}, [filterLanguageSubject, filterCurricularSubject]);
  //fetch exam
  async function fetchExam() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/exam/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const exam = useQuery("exam", fetchExam);

  //handle search

  const handleSearch = (value) => {
    setSearch(value);
  };
  async function fetchTemplate(getClassId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/progress-card-template/?class=${getClassId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getTemplate = useQuery(
    ["progress-card-template", getClassId],
    () => fetchTemplate(getClassId),
    {
      enabled: !!getClassId,
    }
  );
  const filteredData =
    getTemplate?.isSuccess &&
    getTemplate?.data?.result.length > 0 &&
    getTemplate?.data?.result?.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const handleMandataryExam = (examId, subjectId) => {
    if (examId) {
      setMandataryPayload([
        ...mandataryPayload.filter((item) => item.subject !== subjectId),
        {
          subject: subjectId,
          exam: examId,
        },
      ]);
    } else {
      setMandataryPayload(
        mandataryPayload.filter((item) => item.subject !== subjectId)
      );
    }
  };

  const handleLanguageExam = (examId, subjectId) => {
    if (examId) {
      setLanguagePayload([
        ...languagePayload,
        {
          subject: subjectId,
          exam: examId,
        },
      ]);
    } else {
      setLanguagePayload(
        languagePayload.filter((item) => item.subject !== subjectId)
      );
    }
  };
  const handleCurricularExam = (examId, subjectId) => {
    if (examId) {
      setCurricularPayload([
        ...curricularPayload,
        {
          subject: subjectId,
          exam: examId,
        },
      ]);
    } else {
      setCurricularPayload(
        curricularPayload.filter((item) => item.subject !== subjectId)
      );
    }
  };

  const onFinish = (values) => {
    const payload = {
      class: classId,
      year: values?.year?.format("YYYY"),
      name: values?.title,
      mandatory_subjects: mandataryPayload,
      languages: languagePayload,
      co_curricular: curricularPayload,
    };
    axios
      .post(serverUrl + "/progress-card-template/", payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        form.resetFields();
        setMandataryPayload([]);
        setLanguagePayload([]);
        setCurricularPayload([]);
        setClassId("");
        setAddTemplate(false);
        queryClient.refetchQueries(["progress-card-template", getClassId]);
        message.success(res?.data?.result?.message);
      })
      .catch((err) => {
        if (err?.response?.data?.error?.errors) {
          message.error(err?.response?.data?.error?.errors);
        }
      });
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${serverUrl}/progress-card-template/${id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      queryClient.refetchQueries(["progress-card-template", getClassId]);
      message.success("Template deleted successfully");
    } catch (error) {
      message.error(error?.response?.data?.error?.errors);
    }
  };
  useEffect(() => {
    form.resetFields(["language", "curricular"]);
  }, [classId, form]);
  return (
    <>
      <div className="template">
        <div className="pt-8">
          <div className="bg-white p-10 rounded-2xl">
            <div className="text-2xl text-orange-500 font-semibold pb-8">
              Manage Template
            </div>
            <div className="flex justify-between pb-4">
              <div>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a class"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={handleGetClass}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {classes?.isSuccess &&
                    classes?.data?.map((c) => (
                      <Option key={c?._id} value={c?._id}>
                        {c?.name}
                      </Option>
                    ))}
                </Select>
              </div>
              <Search
                placeholder="input search text"
                enterButton="Search"
                size="large"
                loading={false}
                style={{ backgroundColor: "#E3E3E3" }}
                prefix={<BiSearch className="text-gray-600 " />}
                onSearch={(value) => handleSearch(value)}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-310"
              />
            </div>
            <div className="flex justify-between items-start pt-4">
              <div className="flex gap-20px flex-wrap	">
                {filteredData && filteredData ? (
                  filteredData?.map((list) => (
                    <div>
                      <div
                        className="iskool-friends-card flex flex-col space-x-3 cursor-pointer h-full p-20px"
                        key={list?._id}
                        style={{ width: "190px" }}
                      >
                        <div
                          className="text-center mt-14px"
                          onClick={() => {
                            history.push(
                              `/dashboard/admin/menu/report-template/${list?._id}`
                            );
                          }}
                        >
                          <UnorderedListOutlined size={35} />
                          <div className="pt-2">
                            <div className="font-bold capitalize">
                              {list?.class?.name}
                            </div>
                            <div className="font-bold capitalize line-clamp-1">
                              {list?.name}
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center pt-2">
                          <Popconfirm
                            title="Are you sure to delete?"
                            onConfirm={() => handleDelete(list?._id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              type="danger flex items-center justify-center"
                              shape="circle"
                              icon={<DeleteOutlined />}
                            />
                          </Popconfirm>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No Template Exist</div>
                )}
              </div>
              <div
                onClick={() => setAddTemplate(true)}
                className="cursor-pointer p-28px add-module flex items-center justify-center mt-2"
              >
                <div className="flex flex-col items-center space-y-3">
                  <div className="flex items-center justify-center cursor-pointer">
                    <img className="w-6 h-6" src={plusicon} alt="icon" />
                  </div>
                  <div className="text-white text-sm ">
                    <div>Add New</div>
                    <div>Template</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----------------------------------modal------------------------------------ */}
        {/* Add Template */}
        <Modal
          width={800}
          visible={addTemplate}
          onOk={() => setAddTemplate(false)}
          onCancel={() => {
            setClassId(null);
            form.resetFields();
            setAddTemplate(false);
            setMandataryPayload([]);
            setLanguagePayload([]);
            setCurricularPayload([]);
            removeSubjectsData();
          }}
          footer={null}
          forceRender
        >
          <div>
            <h3 className="text-lg font-bold pb-3">Add Template</h3>
            <div className="pt-4 w-4/5">
              <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Template Name"
                  name="title"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Template Name is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter name" />
                </Form.Item>
                <Form.Item
                  name="year"
                  label="Year"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Year is required",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Class"
                  name="class"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please select a class",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select Class"
                    className="w-full bg-gray-300"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(e) => {
                      setClassId(e);
                    }}
                  >
                    {classes?.isSuccess &&
                      classes?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Mandatory Subject"
                  name="mandatorySubject"
                  className="w-full"
                >
                  {classId ? (
                    <div>
                      <div
                        className={clsx(
                          subjects?.isSuccess &&
                            subjects?.data &&
                            subjects?.data?.length > 0
                            ? "flex flex-col gap-3 border"
                            : "flex flex-col gap-3",
                          ""
                        )}
                      >
                        {subjects?.isSuccess &&
                          subjects?.data
                            ?.filter(
                              (s) =>
                                s.class?._id === classId &&
                                s?.is_mandatory === true
                            )
                            ?.map((data) => (
                              <div
                                key={data._id}
                                className="bg-gray-200 px-4 py-2 border-b border-gray-500 last-border text-gray-700  capitalize flex items-center flex-row justify-between"
                              >
                                {data?.name}
                                <Select
                                  showArrow
                                  tagRender={tagRender}
                                  placeholder="Select exam"
                                  style={{
                                    border: "6px",
                                    backgroundColor: "#EBEBEB",
                                    width: "50%",
                                  }}
                                  className=""
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                  }
                                  onChange={(examId) =>
                                    handleMandataryExam(examId, data?._id)
                                  }
                                  onClear={() =>
                                    handleMandataryExam(null, data?._id)
                                  } //
                                  allowClear
                                >
                                  {exam?.isSuccess &&
                                    exam?.data
                                      ?.filter(
                                        (s) =>
                                          s?.class?._id === classId &&
                                          s?.subject?.is_mandatory === true &&
                                          s?.subject?._id === data?._id
                                      )
                                      ?.map((data) => {
                                        return (
                                          <Option
                                            key={data?._id}
                                            value={data?._id}
                                          >
                                            {data?.name}
                                          </Option>
                                        );
                                      })}
                                </Select>
                              </div>
                            ))}
                      </div>
                    </div>
                  ) : (
                    <div className="text-sm text-black text-center">
                      No data
                    </div>
                  )}
                </Form.Item>
                <Form.Item name="language" label="Language">
                  <Select
                    mode="multiple"
                    showArrow
                    tagRender={tagRender}
                    placeholder="Select language"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    className="w-full mb-15px"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(selectedLanguages) =>
                      handleSelectLanguage(selectedLanguages)
                    }
                  >
                    {subjects?.isSuccess &&
                      subjects.data
                        .filter(
                          (s) =>
                            s?.class?._id === classId && s?.is_language === true
                        )
                        .map((data) => (
                          <Option key={data?._id} value={data?._id}>
                            {data?.name}
                          </Option>
                        ))}
                  </Select>
                  <div>
                    <div
                      className={clsx(
                        filterLanguageSubject &&
                          filterLanguageSubject?.length > 0
                          ? "flex flex-col gap-3 border"
                          : "flex flex-col gap-3",
                        ""
                      )}
                    >
                      {filterLanguageSubject &&
                        filterLanguageSubject.length > 0 &&
                        filterLanguageSubject
                          ?.filter(
                            (s) =>
                              s.class?._id === classId &&
                              s?.is_language === true
                          )
                          ?.map((data) => (
                            <div
                              key={data._id}
                              className="bg-gray-200 px-4 py-2 border-b border-gray-500 last-border text-gray-700  capitalize flex items-center flex-row justify-between"
                            >
                              {data?.name}
                              <Select
                                showArrow
                                tagRender={tagRender}
                                placeholder="Select exam"
                                style={{
                                  border: "6px",
                                  backgroundColor: "#EBEBEB",
                                  width: "50%",
                                }}
                                className=""
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                onChange={(examId) =>
                                  handleLanguageExam(examId, data?._id)
                                }
                                allowClear
                              >
                                {exam.isSuccess &&
                                  exam?.data
                                    ?.filter(
                                      (s) =>
                                        s?.class?._id === classId &&
                                        s?.subject?.is_language === true &&
                                        s?.subject?._id === data?._id
                                    )
                                    ?.map((data) => {
                                      return (
                                        <Option key={data._id} value={data._id}>
                                          {data?.name}
                                        </Option>
                                      );
                                    })}
                              </Select>
                            </div>
                          ))}
                    </div>
                  </div>
                </Form.Item>
                <Form.Item name="curricular" label="Co-Curricular">
                  <Select
                    mode="multiple"
                    showArrow
                    tagRender={tagRender}
                    placeholder="Select co-curricular"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    className="w-full mb-15px"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(selectedLanguages) =>
                      handleSelectCurricular(selectedLanguages)
                    }
                  >
                    {subjects?.isSuccess &&
                      subjects.data
                        .filter(
                          (s) =>
                            s?.class?._id === classId &&
                            s?.is_co_curricular === true
                        )
                        .map((data) => (
                          <Option key={data?._id} value={data?._id}>
                            {data?.name}
                          </Option>
                        ))}
                  </Select>
                  <div>
                    <div
                      className={clsx(
                        filterCurricularSubject &&
                          filterCurricularSubject?.length > 0
                          ? "flex flex-col gap-3 border"
                          : "flex flex-col gap-3",
                        ""
                      )}
                    >
                      {filterCurricularSubject &&
                        filterCurricularSubject.length > 0 &&
                        filterCurricularSubject
                          ?.filter(
                            (s) =>
                              s.class?._id === classId &&
                              s.is_co_curricular === true
                          )
                          ?.map((data) => (
                            <div
                              key={data._id}
                              className="bg-gray-200 px-4 py-2 border-b border-gray-500 last-border text-gray-700  capitalize flex items-center flex-row justify-between"
                            >
                              {data?.name}
                              <Select
                                showArrow
                                tagRender={tagRender}
                                placeholder="Select exam"
                                style={{
                                  border: "6px",
                                  backgroundColor: "#EBEBEB",
                                  width: "50%",
                                }}
                                className=""
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                onChange={(examId) =>
                                  handleCurricularExam(examId, data?._id)
                                }
                                allowClear
                              >
                                {exam.isSuccess &&
                                  exam?.data
                                    ?.filter(
                                      (s) =>
                                        s?.class?._id === classId &&
                                        s?.subject?.is_co_curricular === true &&
                                        s?.subject?._id === data?._id
                                    )
                                    ?.map((data) => {
                                      return (
                                        <Option key={data._id} value={data._id}>
                                          {data?.name}
                                        </Option>
                                      );
                                    })}
                              </Select>
                            </div>
                          ))}
                    </div>
                  </div>
                </Form.Item>

                <div className="flex justify-center items-center py-5">
                  <div className="inline-flex items-center space-x-2">
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="text-white"
                    >
                      Save
                    </Button>
                    <Button
                      size="large"
                      type="dark"
                      onClick={() => {
                        form.resetFields();
                        setClassId(null);
                        setAddTemplate(false);
                        removeSubjectsData();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default TemplateCard;
