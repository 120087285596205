import { Popconfirm, Table } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import React, { useEffect } from "react";
import { BsEye } from "react-icons/bs";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../nestserver";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/auth.slice";

export default function ExamTable({ subjectId, onClick }) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const user = useSelector(selectUser);

  // fetch modules
  async function fetchExams() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/exam/",
      params: {
        subject: subjectId ?? user?.teacher?.subjects?.map((s) => s._id),
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const exams = useQuery(["exams", subjectId], fetchExams);

  const columns = [
    {
      title: "Exam Name & Module",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div style={{ color: "#00627B" }} className="text-left text-sm">
          <div>{text}</div>
          <div className="text-xs font-light pt-2 flex flex-wrap space-x-1 space-y-1">
            {record?.modules?.map((module) => (
              <div
                className="px-2 bg-gray-400 text-white rounded-full"
                key={module?._id}
              >
                {module.title}
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: "Class & Subject",
      dataIndex: "class",
      key: "class",
      render: (text, record) => (
        <div style={{ color: "#00627B" }} className=" text-sm">
          {record.class?.name} {record.subject?.name}
        </div>
      ),
    },
    {
      title: "Assigned Date",
      dataIndex: "assigned_date",
      key: "assigned_date",
      render: (text) => (
        <div style={{ color: "#00627B" }} className=" text-base ">
          {text && moment(text).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "last_date",
      key: "last_date",
      render: (text) => (
        <div style={{ color: "#00627B" }} className=" text-base">
          {text && moment(text).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "published",
      key: "_id",
      render: (text) => (
        <div className=" text-sm uppercase font-medium">
          {text ? (
            <div className="text-green-500">Published</div>
          ) : (
            <div className="text-yellow-500">Draft</div>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div className="flex flex-row items-center  space-x-6 pr-4">
          <Button
            shape="circle"
            className="flex items-center justify-center"
            onClick={() => onClick({ id: record._id, type: "exam" })}
          >
            <BsEye className="cursor-pointer " />
          </Button>

          {/* <Popconfirm
            placement="bottomRight"
            title="Are you sure you want to delete?"
            onConfirm={() => deleteExam(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              shape="circle"
              className="flex items-center justify-center"
              type="danger"
              // onClick={() =>
              //   history.push("/dashboard/instructor/edit-assignment")
              // }
            >
              <RiDeleteBin6Fill />
            </Button>
          </Popconfirm> */}
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={exams.isSuccess && (exams.data ? exams.data : [])}
      pagination={false}
    />
  );
}
