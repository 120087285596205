import { Button } from "antd";
import React from "react";
import { cloudFrontUrl } from "../../../../nestserver";

export default function StudentEditModule({ data, subject, goBack }) {
  return (
    <div>
      <div>
        <div className="py-4">
          {goBack && (
            <div className="flex items-center justify-end">
              <Button onClick={goBack}>back</Button>
            </div>
          )}
        </div>
        <div className=" pb-8 ">
          <div className="bg-white rounded-2xl p-8 space-y-5">
            {/* -------------------------------------------------sec 1---------------------------- */}
            <div>
              <div className="text-2xl font-bold text-primary-500 ">
                {data?.title}
              </div>
            </div>
            <div>
              <div className="text-2xl font-bold text-primary-500 ">
                Overview
              </div>
              <p
                style={{ color: "#616161" }}
                className="text-sm font-normal py-4"
              >
                {data?.overview}
              </p>
            </div>

            <div>
              <div className="text-2xl font-bold text-primary-500 ">
                Reading resources
              </div>
              <div>
                {data?.urls?.length ? (
                  <div className="space-y-2 w-full flex flex-col">
                    {data?.urls?.map((item) => (
                      <a
                        key={item.link}
                        href={item.link}
                        target="_blank"
                        className="px-4 py-2 rounded-lg w-full underline"
                        style={{
                          boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.25)",
                          fontSize: "16px",
                        }}
                        rel="noreferrer"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                ) : (
                  <div className="text-sm text-gray-500 text-left">
                    No Resources available
                  </div>
                )}
              </div>
              <div className="pt-6">
                {data?.files && (
                  <div className="text-2xl font-bold text-primary-500 pb-4">
                    Resource Files
                  </div>
                )}
                {data?.files &&
                  data?.files?.map((item) => (
                    <div className="font-bold flex space-x-4" key={item.id}>
                      <a
                        className="p-1 px-3 bg-gray-100 rounded-lg"
                        href={cloudFrontUrl + "/docs/" + item?.id}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.files?.name}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
