import React from "react";
import { cloudFrontUrl } from "../../../../../nestserver";

function InstructorMainSection({ data }) {
  return (
    <>
      <div className="h-full">
        <div>
          <div
            style={{ borderRadius: "10px" }}
            className=" bg-white drop-shadow-md"
          >
            <div
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                backgroundImage: `url("${
                  data?.banner_image
                    ? cloudFrontUrl + "/" + data?._id + "/" + data?.banner_image
                    : "https://ui-avatars.com/api/?name=" +
                      data?.first_name +
                      " " +
                      data?.last_name
                }")`,
              }}
              className="h-52 bg-no-repeat bg-cover relative"
            ></div>

            <div className="w-full h-full md:px-8 px-4 py-4 relative pt-16 z-0">
              <div
                className="absolute left-5"
                style={{ transform: `translateY(-110%)` }}
              >
                <div className="relative w-44 h-44 rounded-full  border-8 border-white bg-black">
                  <img
                    className="w-full h-full rounded-full"
                    src={
                      data?.profile_image
                        ? cloudFrontUrl +
                          "/" +
                          data?._id +
                          "/" +
                          data?.profile_image
                        : "https://ui-avatars.com/api/?name=" +
                          data?.first_name +
                          " " +
                          data?.last_name
                    }
                    alt="banner"
                  />
                  <div
                    className="absolute bottom-0"
                    style={{
                      left: "35%",
                      transform: "translateX(-50%)",
                      transform: "translateY(50%)",
                    }}
                  ></div>
                </div>
              </div>

              {/* --------------------------------------------------------------------------------------------------------------- */}
              <div
                style={{
                  color: "#111111",
                }}
                className=" w-full flex md:flex-row flex-col gap-4 justify-between text-left "
              >
                <div className="space-y-3 flex-1">
                  <div className="md:text-xl text-lg font-bold text-left capitalize">
                    {data?.first_name} {data?.last_name}
                  </div>

                  <div className="font-normal md:text-lg text-base">
                    {data?.title} {data?.title && <br />}
                  </div>
                  <div className="font-normal md:text-lg text-base">
                    {data?.department?.name}
                  </div>
                  <div className="font-normal md:text-lg text-base w-full flex-1 word-break ">
                    {data?.headline}
                  </div>
                  {data?.class?.name && (
                    <div className="font-bold md:text-sm text-base">
                      {data?.class?.name && "Class : " + data?.class?.name}
                    </div>
                  )}
                  {data?.admission_number && (
                    <div className="font-bold md:text-sm text-base">
                      {data?.admission_number &&
                        "Admission Number : " + data?.admission_number}
                    </div>
                  )}
                  {data?.unique_id && (
                    <div className="font-bold md:text-sm text-base">
                      {data?.unique_id && data?.unique_id}
                    </div>
                  )}
                  {data?.roll_number && (
                    <div className="font-bold md:text-sm text-base">
                      Roll Number : {data?.roll_number}
                    </div>
                  )}

                  {data?.region && (
                    <div className="font-bold text-sm">
                      {data?.region}, {data?.country}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InstructorMainSection;
