import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import HelpPage from "../instructor/pages/HelpPage";
import Menu from "./ParentMenu";
import Index from "./pages/Index";
import Settings from "./pages/components/Settings";
import AdmissionProfile from "./pages/components/admission/AdmissionProfile";
import SwitchAdmission from "./pages/components/admission/SwitchAdmission";
import SwitchAttendance from "./pages/components/attendance/SwitchAttendance";
import ViewLeaveDetails from "./pages/components/attendance/ViewLeaveDetails";
import CourseBoard from "./pages/components/courseboard";
import StudentSwitchTab from "./pages/components/courseboard/studentSwitchTab";
import ViewFeeDetails from "./pages/components/fee/ViewFeeDetails";
import ParentForm from "./pages/components/form";
import ParentManageForm from "./pages/components/manageform";
import HomeWorkBroadcast from "./pages/components/message/HomeWorkBroadcast";
import InstructorConverstion from "./pages/components/message/InstructorConverstion";
import TemplateList from "./pages/components/reportcard/TemplateList";
import ViewTemplate from "./pages/components/reportcard/ViewTemplate";
import Schedule from "./pages/components/schedule";
import TransportationStatus from "./pages/components/transportation/TransportationStatus";

const ParentRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}/parent`}>
        <Switch>
          <Route exact path={`${url}/parent/menu`}>
            <Menu />
          </Route>
          <Route exact path={`${url}/parent/menu`}>
            <Menu />
          </Route>
          <Route exact path={`${url}/parent/menu/admission`}>
            <SwitchAdmission />
          </Route>
          <Route exact path={`${url}/parent/menu/admission/:id`}>
            <AdmissionProfile />
          </Route>
          <Route exact path={`${url}/parent/menu/fee`}>
            <ViewFeeDetails />
          </Route>
          <Route exact path={`${url}/parent/menu/attendance`}>
            <SwitchAttendance />
          </Route>
          <Route exact path={`${url}/parent/menu/student/leave/:id`}>
            <ViewLeaveDetails />
          </Route>
          <Route exact path={`${url}/parent/menu/report-card/:id`}>
            <ViewTemplate />
          </Route>
          <Route exact path={`${url}/parent/menu/report-card`}>
            <TemplateList />
          </Route>
          <Route exact path={`${url}/parent/menu/template/report-card/:id`}>
            <ViewTemplate />
          </Route>
          <Route exact path={`${url}/parent/menu/form/`}>
            <ParentForm />
          </Route>
          <Route exact path={`${url}/parent/menu/course-board`}>
            <CourseBoard />
          </Route>
          <Route exact path={`${url}/parent/:className/:classId/:subjectId`}>
            <StudentSwitchTab />
          </Route>
          <Route exact path={`${url}/parent/menu/schedule/`}>
            <Schedule />
          </Route>
          <Route exact path={`${url}/parent/menu/manage-form/`}>
            <ParentManageForm />
          </Route>
          <Route exact path={`${url}/parent/help`}>
            <HelpPage />
          </Route>
          <Route exact path={`${url}/parent/menu/setting/`}>
            <Settings />
          </Route>
          <Route exact path={`${url}/parent/menu/homework/`}>
            <HomeWorkBroadcast />
          </Route>
          <Route exact path={`${url}/parent/menu/message/`}>
            <InstructorConverstion />
          </Route>
          <Route exact path={`${url}/parent/menu/transportation/`}>
            <TransportationStatus />
          </Route>

          <Route path={`${url}/parent/home`} component={Index} />
        </Switch>
      </Route>
      <Route exact path={url}>
        <Redirect to={`${url}/parent/menu/admission`} />
      </Route>
    </Switch>
  );
};

export default ParentRoutes;
