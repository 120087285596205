import React from "react";
import { motion } from "framer-motion";
import { useRouteMatch } from "react-router-dom";
export default function FadeRight({ children }) {
  const { url } = useRouteMatch();
  return (
    <motion.div
      key={url}
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, x: -10 }}
    >
      {children}
    </motion.div>
  );
}
