import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Tag,
  Typography,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";
import edit from "../../../../../resources/edit.svg";
const { Option } = Select;

function tagRender(props) {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ margin: 5 }}
      className="flex items-center"
    >
      {label}
    </Tag>
  );
}
const GenderSelect = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Gender"
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <Option value="male">Male</Option>
      <Option value="female">Female</Option>
      <Option value="others">Other</Option>
    </Select>
  );
};
const BloodGroupSelect = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Blood Group"
      getPopupContainer={(trigger) => trigger.parentNode}
      allowClear
    >
      <Option value="A+">A+</Option>
      <Option value="B+">B+</Option>
      <Option value="O+">O+</Option>
      <Option value="A-">A-</Option>
      <Option value="B-">B-</Option>
      <Option value="O-">O-</Option>
      <Option value="AB+">AB+</Option>
      <Option value="AB-">AB-</Option>
    </Select>
  );
};

function StudentSetting({ data }) {
  const { Title } = Typography;
  const { Option } = Select;
  const { confirm } = Modal;
  const history = useHistory();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [emailDisable, setEmailDisable] = useState(true);
  const [classId, setClassId] = useState(null);
  const [subjectListing, setSubjectListing] = useState({
    subjects: [],
    co_curricular: [],
    languages: [],
  });

  const dateFormat = "YYYY-MM-DD";

  const [transportationCheckbox, setTransportationCheckbox] = useState(false);
  const [routeId, setRouteId] = useState();

  //get routes
  async function fetchRoute() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routes = useQuery(["routes"], () => fetchRoute());

  async function fetchRouteDetails(routeId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes/${routeId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routesDetail = useQuery(
    ["routes", routeId],
    () => routeId && fetchRouteDetails(routeId),
    {
      enabled: !!routeId,
    }
  );

  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  async function fetchSubjects() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/subjects/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const subjects = useQuery("subjects", fetchSubjects);

  const onFinish = async (values) => {
    try {
      const studentData = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        class: values?.class,
        nationality: values?.nationality,
        birth_place: values?.birth_place,
        religion: values?.religion,
        category: values?.category,
        ethnicity: values?.ethnicity,
        aadhar: values?.aadhar,
        subjects: [...subjectListing.subjects],
        languages: values?.language || [],
        co_curricular: values?.curricular || [],
        admission_number: values?.admissionNumber,
        gender: values?.gender,
        phone: values?.phone,
        roll_number: values?.rollNumber,
        admission_date: moment(values?.admissionDate).format("YYYY-MM-DD"),
        dob: moment(values?.dob).format("YYYY-MM-DD"),
        blood_group: values?.blood_group,
        is_transportation_required: transportationCheckbox,
        route_id: values.route_id,
        route_entry_point_id: values?.route_entry_point_id,
        primary_contact: {
          first_name: values?.primary_contact_first_name,
          last_name: values?.primary_contact_last_name,
          relation: values?.primary_contact_relation,
          contact: values?.primary_contact_phone,
          email: values?.primary_contact_email,
        },
        secondary_contact: {
          first_name: values?.secondary_contact_first_name,
          last_name: values?.secondary_contact_last_name,
          relation: values?.secondary_contact_relation,
          contact: values?.secondary_contact_phone,
          email: values?.secondary_contact_email,
        },
      };

      const userData = {
        blocked: values.blocked,
        email: values.email,
      };

      const updatePromises = [];

      if (values.password) {
        const loginData = {
          email: values.email,
          password: values.password,
        };

        updatePromises.push(
          axios({
            method: "patch",
            url: serverUrl + "/users/password/" + data?._id,
            data: loginData,
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          })
        );
      }

      updatePromises.push(
        axios.patch(
          serverUrl + "/students/" + (data.students?._id || data.student?._id),
          studentData,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
      );

      updatePromises.push(
        axios.patch(serverUrl + "/users/" + data._id, userData, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
      );

      await Promise.all(updatePromises);

      message.success("Profile updated successfully");

      queryClient.invalidateQueries(["student", data?._id]);
    } catch (err) {
      console.error(err);
      if (err?.response?.data?.message) {
        message.error(err.response.data.message);
      } else {
        message.error("An error occurred while updating");
      }
    }
  };

  const confirmDelete = () => {
    confirm({
      title: "Do you want to delete the student?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteUser();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteUser = () => {
    axios({
      method: "delete",
      url: serverUrl + "/users/" + data?._id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((res) => {
      message.success("Deleted");
      history.push(`/dashboard/admin/menu/student`);
    });
  };

  useEffect(() => {
    if (data?.student?.class) {
      setClassId(data?.student?.class?._id);
    }
  }, [data?.student?.class]);

  useEffect(() => {
    if (classId && classId !== data?.student?.class?._id) {
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        subjects: [],
      });
    }
  }, [classId]);
  const validateAlphabetsOnly = (_, value) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(value)) {
      return Promise.reject("Please enter alphabets only");
    }
    return Promise.resolve();
  };
  useEffect(() => {
    if (classId && classId !== data?.student?.class?._id) {
      form.setFieldsValue({
        language: [],
        curricular: [],
      });
    }
  }, [classId, data?.student?.class?._id, form]);
  useEffect(() => {
    if (classId && subjects?.isSuccess) {
      const mandatorySubjects = subjects?.data?.filter(
        (s) => s?.class?._id === classId && s?.is_mandatory
      );
      const coCurricularSubjects = subjects?.data?.filter(
        (s) => s?.class?._id === classId && s?.is_co_curricular
      );
      const languageSubjects = subjects?.data?.filter(
        (s) => s?.class?._id === classId && s?.is_language
      );

      setSubjectListing({
        subjects: mandatorySubjects?.map((s) => s?._id),
        co_curricular: coCurricularSubjects?.map((s) => s?._id),
        languages: languageSubjects?.map((s) => s?._id),
      });
    }
  }, [classId, subjects?.data]);

  useEffect(() => {
    form.setFieldsValue({
      email: data?.email,
      first_name: data?.student?.first_name,
      last_name: data?.student?.last_name,
      class: data?.student?.class?._id,
      subjects: data?.student?.subjects.map((s) => s._id),
      blocked: data?.blocked,
      nationality: data?.student?.nationality,
      birth_place: data?.student?.birth_place,
      religion: data?.student?.religion,
      category: data?.student?.category,
      ethnicity: data?.student?.ethnicity,
      aadhar: data?.student?.aadhar,
      gender: data?.student?.gender,
      phone: data?.phone,
      rollNumber: data?.student?.roll_number,
      unique_id: data?.student?.unique_id,
      blood_group: data?.student?.blood_group,
      language: data?.student?.languages?.map((lang) => lang._id),
      curricular: data?.student?.co_curricular?.map((cur) => cur._id),
      admissionNumber: data?.student ? data.student.admission_number : null,
      admissionDate:
        data?.student?.admission_date &&
        moment(data?.student?.admission_date, dateFormat),
      dob: data?.student?.dob && moment(data?.student?.dob, dateFormat),
      primary_contact_first_name: data?.student?.primary_contact?.first_name,
      primary_contact_last_name: data?.student?.primary_contact?.last_name,
      primary_contact_relation: data?.student?.primary_contact?.relation,
      primary_contact_phone: data?.student?.primary_contact?.contact,
      primary_contact_email: data?.student?.primary_contact?.email,
      secondary_contact_first_name:
        data?.student?.secondary_contact?.first_name,
      secondary_contact_last_name: data?.student?.secondary_contact?.last_name,
      secondary_contact_relation: data?.student?.secondary_contact?.relation,
      secondary_contact_phone: data?.student?.secondary_contact?.contact,
      secondary_contact_email: data?.student?.secondary_contact?.email,
      is_transportation_required: data?.is_transportation_required,
      route_id: data?.route_id?._id,
      route_entry_point_id: data?.route_entry_point_id?._id,
    });
    setRouteId(data?.route_id?._id);
    setTransportationCheckbox(data?.is_transportation_required);
  }, [data]);

  return (
    <>
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          {data && (
            <Form
              form={form}
              layout="vertical"
              name="basic"
              initialValues={{
                email: data?.email,
                first_name: data?.student?.first_name,
                last_name: data?.student?.last_name,
                class: data?.student?.class?._id,
                subjects: data?.student?.subjects.map((s) => s._id),
                blocked: data?.blocked,
                nationality: data?.student?.nationality,
                birth_place: data?.student?.birth_place,
                religion: data?.student?.religion,
                category: data?.student?.category,
                ethnicity: data?.student?.ethnicity,
                aadhar: data?.student?.aadhar,
                gender: data?.student?.gender,
                phone: data?.phone,
                rollNumber: data?.student?.roll_number,
                unique_id: data?.student?.unique_id,
                blood_group: data?.student?.blood_group,
                language: data?.student?.languages?.map((lang) => lang._id),
                curricular: data?.student?.co_curricular?.map((cur) => cur._id),
                admissionNumber: data?.student
                  ? data.student.admission_number
                  : null,
                admissionDate:
                  data?.student?.admission_date &&
                  moment(data?.student?.admission_date, dateFormat),
                dob:
                  data?.student?.dob && moment(data?.student?.dob, dateFormat),
                primary_contact_first_name:
                  data?.student?.primary_contact?.first_name,
                primary_contact_last_name:
                  data?.student?.primary_contact?.last_name,
                primary_contact_relation:
                  data?.student?.primary_contact?.relation,
                primary_contact_phone: data?.student?.primary_contact?.contact,
                primary_contact_email: data?.student?.primary_contact?.email,
                secondary_contact_first_name:
                  data?.student?.secondary_contact?.first_name,
                secondary_contact_last_name:
                  data?.student?.secondary_contact?.last_name,
                secondary_contact_relation:
                  data?.student?.secondary_contact?.relation,
                secondary_contact_phone:
                  data?.student?.secondary_contact?.contact,
                secondary_contact_email:
                  data?.student?.secondary_contact?.email,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <div className="grid grid-cols-2 gap-x-4">
                <div className="flex flex-row items-center space-x-8 w-full">
                  <Form.Item
                    label="Email Address"
                    name="email"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input your Email!",
                    //   },
                    // ]}
                    className="w-full"
                  >
                    <Input disabled={emailDisable} />
                  </Form.Item>
                  <div>
                    <img
                      src={edit}
                      alt="edit-button"
                      className="cursor-pointer"
                      onClick={() => setEmailDisable(false)}
                    />{" "}
                  </div>
                </div>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      min: 6,
                      message: "Password must be at least 6 characters long!",
                    },
                  ]}
                >
                  <div className="flex flex-row items-center space-x-8">
                    <Input.Password placeholder="********" className="w-full" />
                    <div>
                      {" "}
                      <img
                        src={edit}
                        alt="edit-button"
                        className="cursor-pointer"
                      />{" "}
                    </div>
                  </div>
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input className="w-full " />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name!",
                    },
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input className="w-full " />
                </Form.Item>
                <Form.Item label="Student Id" name="unique_id">
                  <Input className="w-full" readOnly />
                </Form.Item>
                <Form.Item
                  name="dob"
                  label="Date Of Birth"
                  rules={[
                    {
                      required: true,
                      message: "Date of Birth  is required",
                    },
                  ]}
                  className="w-full"
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    placeholder="Please select your date of birth"
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                  />
                </Form.Item>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Gender is required",
                    },
                  ]}
                  className="w-30"
                >
                  <GenderSelect />
                </Form.Item>
                <Form.Item
                  name="admissionDate"
                  label="Admission Date"
                  rules={[
                    {
                      required: true,
                      message: "Admission date  is required",
                    },
                  ]}
                  className="w-30"
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    placeholder="Please select"
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                  />
                </Form.Item>
                <Form.Item
                  name="admissionNumber"
                  label="Admission Number"
                  className="w-30"
                  getValueFromEvent={(e) => e.target.value}
                  rules={[
                    {
                      required: true,
                      message: "Admission number  is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="aadhar"
                  label="Aadhar"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please enter your aadhar id",
                    },
                    {
                      pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                      message: "Please enter a valid 12-digit Number!",
                    },
                  ]}
                  getValueFromEvent={(e) => e.target.value.slice(0, 12)}
                >
                  <Input maxLength={12} type="number" min={0} />
                </Form.Item>
                <Form.Item label="Roll Number" name="rollNumber">
                  <Input className="w-full " />
                </Form.Item>
                <div className="pb-2">
                  <Form.Item
                    label="Class"
                    name="class"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please select a class",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      showArrow
                      placeholder="Select Class"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      className="w-full bg-gray-300"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      onChange={(e) => setClassId(e)}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {classes.isSuccess &&
                        classes?.data?.map((data) => {
                          return (
                            <Option key={data._id} value={data._id}>
                              {data.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                {classId && (
                  <>
                    <div>
                      <div className="pb-2"> Mandatory</div>
                      <div className="flex fle flex-wrap gap-3 pb-4">
                        {subjects.isSuccess &&
                          subjects?.data
                            ?.filter(
                              (s) =>
                                s.class?._id === classId &&
                                s.is_mandatory === true
                            )
                            ?.map((data) => (
                              <div
                                key={data._id}
                                className="bg-gray-200 px-4 py-2 rounded-md text-gray-700 iskool-friends-card capitalize"
                              >
                                {data.name}
                              </div>
                            ))}
                      </div>
                    </div>

                    <Form.Item name="language" label="Languages">
                      <Select
                        mode="multiple"
                        showArrow
                        tagRender={tagRender}
                        placeholder="Select language"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        className="w-full"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {subjects?.isSuccess &&
                          subjects?.data
                            ?.filter(
                              (s) =>
                                s?.class?._id === classId &&
                                s?.is_language === true &&
                                s?._id !== null // Add this line to filter out null values
                            )
                            ?.map((data) => (
                              <Option key={data._id} value={data._id}>
                                {data?.name}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>

                    <Form.Item name="curricular" label="Co-Curricular">
                      <Select
                        mode="multiple"
                        showArrow
                        tagRender={tagRender}
                        placeholder="Select co-Curricular"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        className="w-full"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {subjects.isSuccess &&
                          subjects?.data
                            ?.filter(
                              (s) =>
                                s?.class?._id === classId &&
                                s?.is_co_curricular === true &&
                                s?._id !== null // Add this line to filter out null values
                            )
                            ?.map((data) => (
                              <Option key={data._id} value={data._id}>
                                {data?.name}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </>
                )}
                <Form.Item
                  name="phone"
                  label="Phone"
                  className="w-30"
                  rules={[
                    {
                      pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                      message: "Please enter a valid 10-digit phone number!",
                    },
                  ]}
                  getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                >
                  <Input type="number" maxLength={10} min={0} />
                </Form.Item>
                <Form.Item
                  name="birth_place"
                  label="Birth Place"
                  className="w-30"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="category"
                  label="Category"
                  className="w-30"
                  rules={[
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="religion"
                  label="Religion"
                  className="w-30"
                  rules={[
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="nationality"
                  label="Nationality"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Nationality is required",
                    },
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="ethnicity" label="Ethnicity" className="w-30">
                  <Input />
                </Form.Item>
                <Form.Item
                  name="blood_group"
                  label="Blood Group"
                  className="w-30"
                >
                  <BloodGroupSelect />
                </Form.Item>
              </div>
              <Title level={2}>Primary Contact</Title>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="primary_contact_first_name"
                  label="First Name"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "First name is required",
                    },
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="primary_contact_last_name"
                  label="Last Name"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Last name is required",
                    },
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="primary_contact_phone"
                  label="Phone"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Phone is required",
                    },
                    {
                      pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                      message: "Please enter a valid 10-digit phone number!",
                    },
                  ]}
                  getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                >
                  <Input type="number" maxLength={10} min={0} />
                </Form.Item>
                <Form.Item
                  name="primary_contact_email"
                  label="Email"
                  className="w-30"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="primary_contact_relation"
                  label="Relation"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Relation is required",
                    },
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <Title level={2}>Secondary Contact</Title>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="secondary_contact_first_name"
                  label="First Name"
                  className="w-30"
                  rules={[
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                  >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="secondary_contact_last_name"
                  label="Last Name"
                  className="w-30"
                  rules={[
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="secondary_contact_phone"
                  label="Phone"
                  className="w-30"
                  rules={[
                    {
                      pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                      message: "Please enter a valid 10-digit phone number!",
                    },
                  ]}
                  getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                >
                  <Input type="number" maxLength={10} min={0} />
                </Form.Item>
                <Form.Item
                  name="secondary_contact_email"
                  label="Email"
                  className="w-30"
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="secondary_contact_relation"
                  label="Relation"
                  className="w-30"
                  rules={[
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <Title level={2}>Transportation</Title>
              <Form.Item
                name="is_transportation_required"
                valuePropName="checked"
              >
                <Checkbox
                  onChange={(e) => {
                    setTransportationCheckbox(e.target.checked);
                    form.resetFields(["route_id", "route_entry_point_id"]);
                  }}
                  checked={transportationCheckbox}
                >
                  Transportation require?
                </Checkbox>
              </Form.Item>
              {transportationCheckbox && (
                <div className="admission-form">
                  <Form.Item label="Route" name="route_id" className="w-full">
                    <Select
                      showSearch
                      showArrow
                      placeholder="Select Route"
                      className=" bg-gray-300"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(value) => {
                        setRouteId(value);
                        form.resetFields(["route_entry_point_id"]);
                      }}
                    >
                      {routes?.isSuccess &&
                        routes?.data?.result?.data?.map((data) => {
                          return (
                            <Option key={data._id} value={data._id}>
                              {data?.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Route Entry Point"
                    name="route_entry_point_id"
                    className="w-full"
                  >
                    <Select
                      showSearch
                      showArrow
                      placeholder="Select Route Entry Point"
                      className="w-full bg-gray-300"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {routesDetail?.isSuccess &&
                        routesDetail?.data?.result?.data?.route_map?.map(
                          (data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data.name}
                              </Option>
                            );
                          }
                        )}
                    </Select>
                  </Form.Item>
                </div>
              )}

              <div className="pb-2 w-96 ">
                <div
                  style={{ color: "#1B1818" }}
                  className="flex flex-row items-center justify-between py-2"
                >
                  <div>Student Status </div>
                </div>
                <Form.Item name="blocked">
                  <Select
                    showSearch
                    showArrow
                    className="w-3/4"
                    placeholder="Student Status"
                    optionFilterProp="children"
                  >
                    <Option value={false} className="text-green-600">
                      Active
                    </Option>
                    <Option value={true} className="text-red-600">
                      Suspend
                    </Option>
                  </Select>
                </Form.Item>
              </div>

              <div className="flex items-center justify-between pt-10 ">
                <div>
                  <Button size="large" type="danger" onClick={confirmDelete}>
                    Delete
                  </Button>
                </div>
                <div className="flex flex-row space-x-4">
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                    // onClick={() => updateProfile()}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    </>
  );
}

export default StudentSetting;
