import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { serverUrl } from "../../../../nestserver";
import StudentEvaluation from "./StudentEvaluation";
import StudentModule from "./StudentModule";
import StudentSubjectAbout from "./StudentSubjectAbout";
import StudentSyllabus from "./StudentSyllabus";

function StudentSwitchTab() {
  const [step, setStep] = useState(1);

  const { className, classId, subjectId } = useParams();

  async function getSubject() {
    const data = await axios({
      method: "get",
      url: serverUrl + "/subjects/" + subjectId,
      data: {},
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return data.data;
  }

  const subject = useQuery(["subject", subjectId], getSubject);

  function switchTab() {
    switch (step) {
      case 1:
        return (
          <div>
            <StudentSubjectAbout subjectDetails={subject?.data} />
          </div>
        );

      case 2:
        return (
          <div>
            <StudentSyllabus subjectDetails={subject?.data} />
          </div>
        );

      case 3:
        return (
          <div>
            <StudentModule data={subject?.data} />
          </div>
        );

      case 4:
        return (
          <div>
            <StudentEvaluation subjectDetails={subject?.data} />
          </div>
        );

      default:
        return (
          <div>
            <StudentSubjectAbout />
          </div>
        );
    }
  }

  return (
    <>
      {subject.isSuccess ? (
        <div className="p-8">
          <div className="grid grid-cols-4 bg-white rounded-lg">
            <div
              onClick={() => setStep(1)}
              className={`iskool-tab-item ${
                step === 1 ? "bg-gray-600 text-white shadow" : ""
              }`}
            >
              <div>
                <div className="text-xs">{className}</div>
                <div className="text-lg">{subject.data?.name}</div>
              </div>
            </div>
            <div
              onClick={() => setStep(2)}
              className={`iskool-tab-item ${
                step === 2 ? "bg-gray-600 text-white shadow" : ""
              }`}
            >
              <div className="text-lg">Syllabus</div>
            </div>
            <div
              onClick={() => setStep(3)}
              className={`iskool-tab-item ${
                step === 3 ? "bg-gray-600 text-white shadow" : ""
              }`}
            >
              <div className="text-lg">Module</div>
            </div>
            <div
              onClick={() => setStep(4)}
              className={`iskool-tab-item ${
                step === 4 ? "bg-gray-600 text-white shadow" : ""
              }`}
            >
              <div className="text-lg">Evaluation</div>
            </div>
          </div>
          {/* ------------------------------------------------------------------------------------------- */}
          <div>
            <div>{switchTab()}</div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default StudentSwitchTab;
