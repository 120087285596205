import { Button, Form, Modal, Select } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../nestserver";
import AttendanceTable from "./AttendanceTable";
import AttendanceViewHistory from "./AttendanceViewHistory";

const AttendanceHome = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [ViewHistoryPopup, setViewHistoryPopup] = useState(false);
  const [classId, setClassId] = useState();
  const handleSelectChange = (id) => {
    setClassId(id);
  };

  // fetch classes
  async function fetchClasses() {
    // debugger;
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  //fetch student attendance
  const fetchStudentData = async () => {
    const res = await axios.get(
      serverUrl + "/attendance/students/class/" + classId,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }
    );

    return res.data;
  };
  const classDetails = useQuery(["class", classId], fetchStudentData, {
    enabled: !!classId,
  });

  return (
    <div className="attendance">
      <div className="pt-8">
        <div className="bg-white p-10 rounded-2xl">
          <div className="flex justify-between items-center pb-8">
            <div className="text-2xl text-orange-500 font-semibold">
              Manage Attendance
            </div>
            <Button size="large" onClick={() => setViewHistoryPopup(true)}>
              View History
            </Button>
          </div>
          <div className="flex gap-10px pb-4">
            <h6 className=" font-semibold text-base">Class: &nbsp;</h6>
            <Select
              showSearch
              showArrow
              allowClear
              placeholder="Select Class"
              className="w-1/2 bg-gray-300"
              style={{
                border: "6px",
                backgroundColor: "#EBEBEB",
                width: "33%",
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => handleSelectChange(value)}
            >
              <>
                {classes?.isSuccess &&
                  classes?.data?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {data.name}
                      </Option>
                    );
                  })}
              </>
            </Select>
          </div>
          <div className="flex justify-between items-center">
            <h6 className="text-base font-semibold">
              Date: &nbsp;
              <span className="text-base font-medium">
                {classDetails?.data?.markedAt &&
                  moment(classDetails?.data?.markedAt).format("DD MMM YYYY hh:mm")}
     
              </span>
            </h6>
          </div>
          <div>
            <AttendanceTable classId={classId} classDetails={classDetails} />
          </div>

          {/* View History Modal */}
          <Modal
            width={900}
            visible={ViewHistoryPopup}
            onOk={() => setViewHistoryPopup(false)}
            onCancel={() => {
              setViewHistoryPopup(false);
            }}
            footer={null}
          >
            <AttendanceViewHistory classId={classId} />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AttendanceHome;
