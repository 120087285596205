import React from "react";
import {
  AiOutlineApartment,
  AiOutlineCalendar,
  AiOutlineCar,
  AiOutlineClockCircle,
  AiOutlineContainer,
  AiOutlineDollarCircle,
  AiOutlineFileDone,
  AiOutlineFileText,
  AiOutlineFundProjectionScreen,
  AiOutlineMessage,
  AiOutlineTeam,
} from "react-icons/ai";
import { GoBook } from "react-icons/go";
import {
  HiOutlineAcademicCap,
  HiOutlineOfficeBuilding,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { useHistory, useLocation } from "react-router";

const Menu = () => {
  const history = useHistory();
  const location = useLocation();
  const adminSidemenu = [
    {
      title: "Manage Instructors",
      icon: <AiOutlineTeam size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/instructor"),
      url: "/dashboard/admin/menu/instructor",
    },
    {
      title: "Manage Admissions",
      icon: <HiOutlineOfficeBuilding size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/admission"),
      url: "/dashboard/admin/menu/admission",
    },
    {
      title: "Manage Students",
      icon: <HiOutlineAcademicCap size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/student"),
      url: "/dashboard/admin/menu/student",
    },
    {
      title: "Manage Subjects",
      icon: <GoBook size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/subject"),
      url: "/dashboard/admin/menu/subject",
    },
    {
      title: "Manage Classes",
      icon: <AiOutlineFundProjectionScreen size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/class"),
      url: "/dashboard/admin/menu/class",
    },
    {
      title: "Manage Departments",
      icon: <AiOutlineApartment size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/department"),
      url: "/dashboard/admin/menu/department",
    },
    {
      title: "Manage Fee",
      icon: <AiOutlineDollarCircle size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/fee/template"),
      url: "/dashboard/admin/menu/fee",
    },
    {
      title: "Manage Attendance",
      icon: <AiOutlineCalendar size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/attendance"),
      url: "/dashboard/admin/menu/attendance",
    },
    {
      title: "Manage Parent",
      icon: <HiOutlineUserGroup size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/parent"),
      url: "/dashboard/admin/menu/parent",
    },
    {
      title: "Manage Report Card",
      icon: <AiOutlineFileText size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/report"),
      url: "/dashboard/admin/menu/report",
    },
    {
      title: "Manage Schedule",
      icon: <AiOutlineClockCircle size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/schedule"),
      url: "/dashboard/admin/menu/schedule",
    },
    {
      title: "Manage Form",
      icon: <AiOutlineFileDone size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/form"),
      url: "/dashboard/admin/menu/form",
    },
    {
      title: "Manage Homework",
      icon: <AiOutlineContainer size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/home-work"),
      url: "/dashboard/admin/menu/home-work",
    },
    {
      title: "Manage Message",
      icon: <AiOutlineMessage size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/message"),
      url: "/dashboard/admin/menu/message",
    },
    {
      title: "Manage Transportation",
      icon: <AiOutlineCar size={35} />,
      onClick: () => history.push("/dashboard/admin/menu/transportation"),
      url: "/dashboard/admin/menu/transportation",
    },
  ];
  return (
    <div>
      <div className="p-8">
        <div className="bg-white p-10 rounded-2xl min-h-40">
          <div className="grid-property">
            {adminSidemenu.map((item) => (
              <div
                onClick={item.onClick}
                key={item.title}
                className={`flex items-center space-y-1 p-4 cursor-pointer border-l-4 border-transpagent hover:border-red-400 hover:shadow transition-all duration-100 ease-in-out ${
                  location.pathname === item.url
                    ? "border-red-400 shadow w-fit-content"
                    : ""
                }`}
              >
                <span className="mr-2">{item.icon}</span>
                <div className="text-lg text-center line-clamp-2 menu-spacing font-bold">
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
