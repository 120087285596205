import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import RegisterPage from "./auth/RegisterPage";
import SuperAdminLogin from "./auth/SuperAdminLogin";
import AuthRoutes from "./routes/AuthRoutes";
import DashboardRoutes from "./routes/DashboardRoutes";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth">
          <AuthRoutes />
        </Route>
        <Route path="/dashboard">
          <DashboardRoutes />
        </Route>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <Route exact path="/admission-form">
          <RegisterPage />
        </Route>
        <Route exact path="/super-admin">
          <Redirect to="/super-admin/login" />
        </Route>
        <Route exact path="/super-admin/login">
          <SuperAdminLogin />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
