import { DeleteOutlined } from "@ant-design/icons";
import { message, Popconfirm } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { FiPaperclip } from "react-icons/fi";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import FileUploadButton from "../../../../components/inputs/FileUploadButton";
import { cloudFrontUrl, serverUrl } from "../../../../nestserver";
import { selectUser } from "../../../../redux/slices/auth.slice";

function ExamEvaluationView({ id, onBack }) {
  const [openmodal, setOpenModal] = useState(false);
  const user = useSelector(selectUser);
  const [file, setFile] = useState(null);
  const queryClient = useQueryClient();

  // Exam Submission
  async function getExamSubmission() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/exam-submissions/",
      params: {
        exam: id,
        student: user?._id,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const examSubmission = useQuery(["exam-submissions", id], getExamSubmission, {
    enabled: !!id,
  });

  // Fetch Exam Details
  async function fetchExam() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/exam/" + id,

      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const examDetails = useQuery(["exams", id], fetchExam, {
    enabled: !!id,
  });

  // Submit Exam
  function submitExam() {
    const values = {
      exam: id,
      student: user?._id,
      file,
      submitted_date: new Date().toISOString(),
    };
    if (examSubmission.data[0]?._id) {
      axios
        .patch(
          serverUrl + "/exam-submissions/" + examSubmission.data[0]._id,
          {
            file,
            status: "submitted",
            enable_resubmission: false,
            request_resubmission: false,
            submitted_date: new Date().toISOString(),
          },
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
        .then((res) => {
          setOpenModal(false);
          message.success("Exam Submitted Successfully");
          queryClient.invalidateQueries(["exam-submissions", id]);
        })
        .catch((error) => {
          message.error("Error Submitting Exam");
        });
    } else {
      axios
        .post(serverUrl + "/exam-submissions", values, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          setOpenModal(false);
          message.success("Exam Submitted Successfully");
          queryClient.invalidateQueries("exam-submissions");
        })
        .catch((error) => {
          message.error("Error Submitting Exam");
        });
    }
  }

  function handleSetFiles(files) {
    // append files to current file so that all the files have distinct id
    setFile((file) => {
      return [
        ...file,
        ...files.filter((f) => file.findIndex((f2) => f2.id == f.id) == -1),
      ];
    });
  }

  function removeFileItem(id) {
    const newFile = file.filter((f) => f.id !== id);
    setFile(newFile);
  }

  useEffect(() => {
    examSubmission?.data && setFile(examSubmission?.data[0]?.file ?? []);

    return () => {
      setFile([]);
    };
  }, [examSubmission?.data]);

  return (
    <>
      <div>
        <div>
          <div className="pt-4 px-8">
            <div className="flex items-center justify-end">
              <Button onClick={onBack}>back</Button>
            </div>
          </div>
          <div className=" p-8 pt-0">
            {examDetails.isSuccess && examSubmission.isSuccess && (
              <div className="bg-white rounded-2xl p-8 space-y-5">
                {/* -------------------------------------------------sec 1---------------------------- */}
                <div>
                  <div className="flex flex-row items-start justify-between">
                    <div>
                      <div className="text-xl text-orange-500 text-medium">
                        {examDetails?.data?.name}
                      </div>
                      <div className="pt-4 pb-2">
                        <h6 className="text-black font-normal text-base">
                          {examDetails?.data?.modules?.map((module) => (
                            <span className="pr-2">{module?.title}</span>
                          ))}
                        </h6>
                        <p
                          style={{ color: "#616161" }}
                          className="text-sm font-normal pt-2"
                        >
                          Due for{" "}
                          {examDetails?.data?.last_date &&
                            moment(examDetails?.data?.last_date).format(
                              "Do MMMM YYYY"
                            )}
                        </p>
                      </div>
                    </div>

                    <div className="space-y-3">
                      <div className="font-normal text-sm text-black text-right">
                        Score:{" "}
                        {examSubmission?.isSuccess &&
                          (examSubmission?.data[0]?.score ?? "_") +
                            " / " +
                            (examDetails?.data?.score ?? "_")}
                      </div>
                      <div className="flex flex-row items-center justify-center space-x-3">
                        {examSubmission?.isSuccess &&
                          examSubmission?.data[0]?.submitted_date && (
                            <p className="text-black font-normal text-sm">
                              Last Submitted:{" "}
                              {examSubmission?.data[0]?.submitted_date &&
                                moment(
                                  examSubmission?.data[0]?.submitted_date
                                ).format("Do MMMM YYYY")}
                            </p>
                          )}
                        {moment()
                          .subtract(1, "d")
                          .isSameOrBefore(examDetails?.data?.last_date) ? (
                          <>
                            {examSubmission?.isSuccess &&
                            examSubmission?.data?.length > 0 ? (
                              <Button
                                disabled={
                                  !examSubmission?.data[0]
                                    ?.enable_resubmission || !file?.length
                                }
                                type="primary"
                                onClick={() => submitExam()}
                              >
                                {examSubmission?.data[0]?.enable_resubmission
                                  ? "Re submit"
                                  : "Submitted"}
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                onClick={() => submitExam()}
                                disabled={!file?.length}
                              >
                                submit
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {examSubmission?.isSuccess &&
                            examSubmission?.data[0]?.score ? (
                              <Button
                                disabled={true}
                                type="warning"
                                onClick={() => setOpenModal(true)}
                              >
                                Evaluated
                              </Button>
                            ) : (
                              <Button
                                disabled={
                                  examSubmission?.isSuccess &&
                                  !examSubmission?.data[0]?.enable_resubmission
                                }
                                type={
                                  examSubmission?.isSuccess &&
                                  examSubmission?.data[0]?.enable_resubmission
                                    ? "primary"
                                    : "danger"
                                }
                                onClick={() => submitExam()}
                              >
                                {examSubmission?.data[0]?.enable_resubmission
                                  ? "Re submit"
                                  : "Due Date Passed"}
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-2xl font-bold text-primary-500 ">
                    Overview
                  </div>
                  <p
                    style={{ color: "#616161" }}
                    className="text-sm font-normal py-4"
                  >
                    {examDetails.isSuccess && examDetails?.data?.overview}
                  </p>
                </div>

                <div>
                  <div className="text-base font-bold text-primary-500 ">
                    Attachment
                  </div>
                  {examDetails?.data?.file ? (
                    examDetails?.data?.file?.map((item) => (
                      <div className="flex flex-row items-center space-x-3 py-2 ">
                        <FiPaperclip />
                        <a
                          href={cloudFrontUrl + "/docs/" + item?.id}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item?.files?.name}
                        </a>
                      </div>
                    ))
                  ) : (
                    <div className="text-gray-500">No Attachments found</div>
                  )}
                </div>

                <div>
                  <div className="text-base font-bold text-primary-500 ">
                    Submission{" "}
                    <span className="text-xs italic">
                      (only pdf files are allowed)
                    </span>
                  </div>
                  {user?.role !== "parent" && (
                    <FileUploadButton onUpload={(e) => handleSetFiles(e)}>
                      <div className="flex items-center space-x-4">
                        <Button
                          disabled={
                            moment()
                              .subtract(1, "d")
                              .isSameOrBefore(examDetails?.data?.last_date)
                              ? examSubmission?.data?.length === 0
                                ? false
                                : !examSubmission?.data[0]?.enable_resubmission
                              : true
                          }
                          type="primary"
                        >
                          Upload
                        </Button>
                      </div>
                    </FileUploadButton>
                  )}
                  <div className="space-y-3 pt-4">
                    {file &&
                      file?.map((item) => (
                        <div className="font-bold flex space-x-4" key={item.id}>
                          <a
                            className="p-1 px-3 bg-gray-100 rounded-lg"
                            href={cloudFrontUrl + "/docs/" + item?.id}
                            target="_blank"
                            rel="noreferer noreferrer"
                            style={{ maxWidth: "50%", overflow: "hidden" }}
                          >
                            {item.files?.name}
                          </a>
                          <div>
                            <Popconfirm
                              title="Are you sure？"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => removeFileItem(item.id)}
                            >
                              <Button
                                disabled={
                                  examSubmission?.data?.length === 0
                                    ? false
                                    : !examSubmission?.data[0]
                                        ?.enable_resubmission
                                }
                                type="danger"
                                shape="circle"
                                className="flex items-center justify-center"
                              >
                                <DeleteOutlined className="cursor-pointer " />
                              </Button>
                            </Popconfirm>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div>
                  <div className="text-base font-bold text-primary-500 ">
                    Remarks
                  </div>
                  {examSubmission?.data[0]?.remarks ? (
                    <div className="flex flex-row items-center space-x-3 py-2 ">
                      {examSubmission?.data[0]?.remarks}
                    </div>
                  ) : (
                    <div className="text-gray-500">No remarks available</div>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* 
          <div className=" pb-8  ">
            <div className="bg-white rounded-2xl p-8 space-y-5">
              <div className="text-xl text-orange-500 text-medium">
                Rubric
              </div>

              <ul className="list-inside list-decimal">
                <li>Lorem ipsum dolor sit amet, </li>
                <li>Rprehenderit in voluptate</li>
                <li>Excepteur sint occaecat</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ExamEvaluationView;
