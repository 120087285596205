import React, { useState } from "react";
import ManageFormRequest from "./ManageFormRequest";
import ManageFormTemplate from "./ManageFormTemplate";
import MangeFormHistory from "./MangeFormHistory";

const SwitchForm = () => {
  const [step, setStep] = useState(1);

  function switchTab() {
    switch (step) {
      case 1:
        return (
          <div>
            <ManageFormRequest />
          </div>
        );

      case 2:
        return (
          <div>
            <ManageFormTemplate />
          </div>
        );
      case 3:
        return (
          <div>
            <MangeFormHistory />
          </div>
        );

      default:
        return (
          <div>
            <ManageFormRequest />
          </div>
        );
    }
  }

  return (
    <>
      <div className="p-8">
        <div className="grid grid-cols-5 bg-white rounded-lg ">
          <div
            onClick={() => setStep(1)}
            className={`iskool-tab-item ${
              step === 1 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Requests</div>
          </div>
          <div
            onClick={() => setStep(2)}
            className={`iskool-tab-item ${
              step === 2 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">Template</div>
          </div>
          <div
            onClick={() => setStep(3)}
            className={`iskool-tab-item ${
              step === 3 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg">History</div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------- */}
        <div>
          <div>{switchTab()}</div>
        </div>
      </div>
    </>
  );
};

export default SwitchForm;
