import { Button, Checkbox, message, Modal, Select, Table } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";
const { Option } = Select;

const AddEditBoarding = () => {
  const [openStudentPopup, setStudentPopup] = useState(false);
  const [routeMapId, setRouteMapId] = useState();
  const [routeId, setRouteId] = useState();
  const [passengers, setPassengers] = useState([]);
  const [isReturn, setIsReturn] = useState(false);

  //fetch route
  async function fetchRoute() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routes = useQuery(["routes"], () => fetchRoute());

  async function fetchRouteDetails(routeId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes/${routeId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routesDetail = useQuery(
    ["routes", routeId],
    () => routeId && fetchRouteDetails(routeId),
    {
      enabled: !!routeId,
    }
  );

  //route map id
  async function fetchRouteMapDetails(routeMapId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes/entry-points/${routeMapId}/passengers/`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routesMapDetail = useQuery(
    ["/routes/entry-points", routeMapId],
    () => routeMapId && fetchRouteMapDetails(routeMapId),
    {
      enabled: !!routeMapId,
      onSuccess: (data) => {
        setPassengers(data?.result?.data || []);
      },
    }
  );

  const handleCheckInOut = (passengerId) => {
    setPassengers((prevPassengers) =>
      prevPassengers.map((passenger) =>
        passenger.user._id === passengerId
          ? {
              ...passenger,
              onboarding_data: {
                ...passenger.onboarding_data,
                [isReturn ? "is_checked_out" : "is_checked_in"]:
                  !passenger.onboarding_data[
                    isReturn ? "is_checked_out" : "is_checked_in"
                  ],
              },
            }
          : passenger
      )
    );
  };
  const handleNotify = async () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toTimeString().split(" ")[0].slice(0, 5);

    const payload = {
      type: isReturn ? "deboard" : "onboard",
      date: currentDate,
      user_onboard_deboard:
        passengers &&
        passengers?.map((passenger) => ({
          user: passenger.user._id,
          checked_in_at:
            !isReturn && passenger?.onboarding_data.is_checked_in
              ? currentTime
              : null,
          checked_out_at:
            isReturn && passenger.onboarding_data.is_checked_out
              ? currentTime
              : null,
          _id: passenger._id,
          is_checked_in: !isReturn && passenger?.onboarding_data.is_checked_in,
          is_checked_out: isReturn && passenger?.onboarding_data.is_checked_out,
        })),
    };
    if (routeMapId) {
      axios
        .post(
          serverUrl + "/routes/entry-points/" + routeMapId + "/notify",
          payload,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
        .then((res) => {
          message.success(res?.data?.result?.message);
          setStudentPopup(false);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          console.log("err", err);
        });
    }

    console.log("payload", payload);
  };
  const columns = [
    {
      title: "Route Map",

      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.name}
        </div>
      ),
    },
    {
      title: "Passenger",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div
            className="text-base font-semibold text-center text-blue-500 cursor-pointer"
            onClick={() => {
              setRouteMapId(record?._id);
              setStudentPopup(true);
            }}
          >
            View
          </div>
        );
      },
    },
  ];

  const routStudentData = [
    {
      title: "Passengers",
      key: "",
      render: (record) => (
        <div className="flex items-center">
          {`${record?.user?.first_name} ${record?.user?.last_name}`}
        </div>
      ),
    },
    {
      title: isReturn ? "Check-out" : "Check-in",
      key: "",
      align: "center",
      render: (_, record) => (
        <div className="flex items-center justify-center">
          <input
            type="checkbox"
            checked={
              isReturn
                ? record.onboarding_data?.is_checked_out
                : record.onboarding_data?.is_checked_in
            }
            className="text-center"
            onChange={() => handleCheckInOut(record?.user?._id)}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      {" "}
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold">
            Manage Onboard
          </div>
          <div className="flex gap-10px pt-8 items-center">
            <h6 className=" font-semibold text-base">Route: &nbsp;</h6>
            <Select
              showSearch
              showArrow
              placeholder="Select Route"
              className=" bg-gray-300"
              style={{
                border: "6px",
                backgroundColor: "#EBEBEB",
                width: "24%",
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => setRouteId(value)}
            >
              {routes?.isSuccess &&
                routes?.data?.result?.data?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data?.name}
                    </Option>
                  );
                })}
            </Select>
            <div>
              <Checkbox
                onChange={(e) => {
                  setIsReturn(e.target.checked);
                }}
                checked={isReturn}
                className="pl-4 text-base"
              >
                Return
              </Checkbox>
            </div>
          </div>

          <div className="py-5 table-overflow">
            <Table
              columns={columns}
              dataSource={
                routesDetail.isSuccess
                  ? routesDetail?.data?.result?.data.route_map
                  : []
              }
              pagination={true}
              loading={routesDetail?.isLoading}
            />
          </div>
          {openStudentPopup && (
            <Modal
              width={800}
              visible={openStudentPopup}
              onCancel={() => {
                setStudentPopup(false);
              }}
              footer={null}
            >
              <h3 className="text-lg font-semibold mb-4">
                Passengers Check-in/out
              </h3>
              <div className="py-5 table-overflow">
                <Table
                  columns={routStudentData}
                  dataSource={passengers ? passengers : []}
                  pagination={true}
                />
                {passengers && passengers?.length > 0 && (
                  <div className="text-center">
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="text-white"
                      onClick={handleNotify}
                    >
                      Notify
                    </Button>
                  </div>
                )}
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddEditBoarding;
