import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Select, Space, Table, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";

const GenerateCard = () => {
  const { Option } = Select;
  const history = useHistory();
  const queryClient = useQueryClient();

  const [classId, setClassId] = useState();
  const [searchInput, setSearchInput] = useState(null);
  const [progressIds, setProgressIds] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [templateId, setTemplateId] = useState();

  //handle class Select change
  const handleSelectChange = (classId) => {
    setClassId(classId);
  };
  const handleTemplateChange = (templateId, studentId) => {
    setTemplateId(templateId);
    const updatedTemplates = selectedTemplates?.map((template) => {
      if (template?.studentId === studentId) {
        return {
          studentId,
          templateId,
          [studentId]: templateId,
        };
      }
      return template;
    });

    setSelectedTemplates(updatedTemplates);
  };

  // fetch student
  async function fetchStudent(classId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/progress-card/?class=${classId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getStudent = useQuery(
    ["progress-card", classId],
    () => fetchStudent(classId),
    {
      enabled: !!classId,
    }
  );
  getStudent?.data?.result?.forEach((student) => {
    if (student?.progress_card) {
      selectedTemplates.push({
        studentId: student._id,
        templateId: student?.progress_card?.template?._id,
        [student._id]: student?.progress_card?.template?._id,
      });
    }
  });

  //fetch template
  async function fetchTemplate(classId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/progress-card-template/?class=${classId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const getTemplate = useQuery(
    ["progress-card-template", classId],
    () => fetchTemplate(classId),
    {
      enabled: !!classId,
    }
  );

  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);
  const handleNotify = () => {
    const payload = {
      progress_card_ids: progressIds,
    };
    if (progressIds && progressIds?.length > 0) {
      axios
        .patch(serverUrl + "/progress-card/notify", payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          queryClient.refetchQueries(["progress-card", classId]);
          message.success(res?.data?.result?.message);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            message.error(err?.response?.data?.message);
          }
        });
    }
  };

  // Table Settings

  // Search Filter

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search Subject`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = [
    {
      title: "Student Id",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center"
        >
          {record?.unique_id}
        </div>
      ),
    },
    {
      title: "Name",
      key: "name",
      align: "center",
      ...getColumnSearchProps(["first_name", "last_name"]),
      render: (record, index) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.first_name + " " + record?.last_name}
        </div>
      ),
      onFilter: (value, record) =>
        (
          record?.first_name?.toLowerCase() +
          " " +
          record?.last_name?.toLowerCase()
        ).includes(value.toLowerCase()),
    },
    {
      title: "Template",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div style={{ color: "#111111" }} className="text-base text-center">
          <Select
            showSearch
            showArrow
            placeholder="Select template"
            className="w-full bg-gray-300 capitalize"
            style={{
              border: "6px",
              backgroundColor: "#EBEBEB",
            }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => {
              handleTemplateChange(value, record?._id);
            }}
            defaultValue={
              record?.progress_card?.template
                ? record?.progress_card.template?.name
                : null
            }
          >
            {getTemplate?.isSuccess &&
              getTemplate?.data?.result?.map((data) => {
                const matchingTemplate = record?.progress_cards?.find(
                  (template) =>
                    template?.template?._id === data?._id &&
                    template?.status === "notified"
                );
                return !matchingTemplate ? (
                  <Option
                    key={data?._id}
                    value={data?._id}
                    className="capitalize"
                  >
                    {data?.name}
                  </Option>
                ) : null;
              })}
          </Select>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: [],
      key: "status",
      align: "center",
      render: (record, _, index) => {
        const selectedTemplate = selectedTemplates.find(
          (template) => template.studentId === record._id
        );

        const recData = selectedTemplate
          ? record?.progress_cards?.find(
              (data) => data?.template?._id === selectedTemplate?.templateId
            )
          : null;

        return (
          <div
            key={index}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {recData ? recData?.status : "unready"}
          </div>
        );
      },
    },
    {
      title: "Applied Template",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize cursor-pointer"
          onClick={() => {
            setSelectedRecord(record?.progress_cards);
            setIsModalVisible(true);
          }}
        >
          {record?.progress_cards?.length}
        </div>
      ),
    },
    {
      title: "Last Report",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.progress_card?.status === "notified" &&
          record?.progress_card?.notified_at
            ? moment(record?.progress_card?.notified_at).format(
                "DD MMMM YYYY,hh:mm A"
              )
            : "--"}
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        const selectedTemplate = selectedTemplates.find(
          (template) => template.studentId === record._id
        );

        const recData = record?.progress_cards?.find(
          (data) => data?.template?._id === selectedTemplate?.templateId
        );
        const isUnready = !recData || recData?.status === "unready";

        return (
          <div
            className="text-base font-semibold text-center text-blue-500 cursor-pointer"
            key={record}
          >
            {isUnready ? (
              <Button
                onClick={() =>
                  history.push(
                    `/dashboard/admin/menu/student/${record?._id}/template/${
                      selectedTemplate?.templateId || templateId
                    }`
                  )
                }
              >
                Generate
              </Button>
            ) : (
              <Button
                onClick={() =>
                  history.push(
                    `/dashboard/admin/menu/student/${record?._id}/template/${selectedTemplate?.templateId}/report/${recData?._id}`
                  )
                }
              >
                View
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
      selectedRows &&
        selectedRows?.length > 0 &&
        setProgressIds(
          selectedRows
            ?.map((item) => item?.progress_card?._id)
            .filter((id) => id !== null && id !== undefined) // Filter out null and undefined values
        );
    },
    getCheckboxProps: (record) => ({}),
  };

  useEffect(() => {
    const updatedProgressIds = getStudent?.data?.result
      .filter((student) => selectedRowKeys?.includes(student?._id))
      .filter(
        (item) =>
          item?.progress_card?.status === "ready" ||
          item?.progress_card?.status === "notified"
      )
      .map((student) => student?.progress_card?._id)
      .filter((id) => id !== null && id !== undefined); // Filter out null and undefined values
    setProgressIds(updatedProgressIds);
  }, [getStudent?.data, selectedRowKeys]);

  return (
    <>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Generate Report Card
          </div>
          <div className="flex gap-4 w-310 items-center">
            <h3 className="text-base font-semibold">Class</h3>
            <Select
              showSearch
              showArrow
              style={{ width: 200 }}
              placeholder="Select Class"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => handleSelectChange(value)}
            >
              {classes?.isSuccess &&
                classes?.data?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="py-5 table-overflow">
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              rowKey="_id"
              columns={columns}
              dataSource={
                getStudent?.isSuccess &&
                getStudent?.data?.result.length > 0 &&
                getStudent?.data?.result
                  ? getStudent?.data?.result
                  : []
              }
              pagination={true}
              loading={getStudent?.isLoading}
            />
            {classId && (
              <div className="flex justify-center items-center py-5">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                    onClick={handleNotify}
                    disabled={progressIds?.length === 0}
                  >
                    Notify
                  </Button>
                </div>
              </div>
            )}
          </div>
          <Modal
            title="Applied Template"
            visible={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
          >
            <ul>
              {selectedRecord
                ?.slice()
                .reverse()
                .map((template, index) => (
                  <li key={template?.template?._id} className="capitalize">
                    {" "}
                    {`${index + 1}. ${template?.template?.name}`}
                  </li>
                ))}
            </ul>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default GenerateCard;
