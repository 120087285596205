import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import Menu from "./InstructorMenu";
import AssignmentExam from "./pages/AssignmentExam";
import SwitchAttendance from "./pages/Attendance/SwitchAttendance";
import SwitchCourseTab from "./pages/CourseBoard/SwitchCourseTab";
import CourseBoard from "./pages/CourseBoard/index.js";
import DynamicProfile from "./pages/DynamicProfile";
import EditAssignment from "./pages/EditAssignment";
import EditExam from "./pages/EditExam";
import FriendsPage from "./pages/FriendsPage";
import HelpPage from "./pages/HelpPage";
import Profile from "./pages/Profile";
import AddEditGenerate from "./pages/ProgressCard/AddEditGenerate";
import SwitchReportCard from "./pages/ProgressCard/SwitchReportCard";
import ViewEditProgressCard from "./pages/ProgressCard/ViewEditProgressCard";
import ViewReportTemplate from "./pages/ProgressCard/ViewReportTemplate.js";
import ViewTemplateHistory from "./pages/ProgressCard/ViewTemplateHistory";
import Schedule from "./pages/Schedule/index.js";
import Settings from "./pages/Settings/index.js";
import InstructorMessagePage from "./pages/message/index.js";
import InstructorClassBroadcast from "./pages/message/InstructorClassBroadcast.js";
import TransportationStatus from "./pages/Transportation/TransportationStatus.js";

const InstructorRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/instructor`}>
        <Switch>
          <Route exact path={`${url}/instructor/menu`}>
            <Menu />
          </Route>
          <Route exact path={`${url}/instructor/menu/profile`}>
            <Profile />
          </Route>
          <Route exact path={`${url}/instructor/menu/friends`}>
            <FriendsPage />
          </Route>
          <Route exact path={`${url}/instructor/menu/friends/:userId`}>
            <DynamicProfile />
          </Route>
          <Route exact path={`${url}/instructor/help`}>
            <HelpPage />
          </Route>
          <Route exact path={`${url}/instructor/assignments-and-exams`}>
            <AssignmentExam />
          </Route>
          <Route exact path={`${url}/instructor/assignment/edit/:id`}>
            <EditAssignment />
          </Route>
          <Route exact path={`${url}/instructor/exam/edit/:id`}>
            <EditExam />
          </Route>
          <Route exact path={`${url}/instructor/menu/course-board`}>
            <CourseBoard />
          </Route>
          <Route
            exact
            path={`${url}/instructor/:className/:classId/:subjectId`}
          >
            <SwitchCourseTab />
          </Route>
          <Route exact path={`${url}/instructor/menu/attendance`}>
            <SwitchAttendance />
          </Route>
          <Route exact path={`${url}/instructor/menu/report/`}>
            <SwitchReportCard />
          </Route>
          <Route exact path={`${url}/instructor/menu/report/template/:id`}>
            <ViewReportTemplate />
          </Route>
          <Route exact path={`${url}/instructor/menu/student/:id/template/:id`}>
            <AddEditGenerate />
          </Route>
          <Route
            exact
            path={`${url}/instructor/menu/student/template-history/:id`}
          >
            <ViewTemplateHistory />
          </Route>
          <Route
            exact
            path={`${url}/instructor/menu/student/:id/template/:id/report/:id/`}
          >
            <ViewEditProgressCard />
          </Route>
          <Route exact path={`${url}/instructor/menu/schedule/`}>
            <Schedule />
          </Route>
          <Route exact path={`${url}/instructor/menu/setting/`}>
            <Settings />
          </Route>
          <Route path={`${url}/instructor/home`} component={Profile} />
          <Route exact path={`${url}/instructor/menu/homework/`}>
            <InstructorClassBroadcast />
          </Route>
          <Route exact path={`${url}/instructor/menu/message/`}>
            <InstructorMessagePage />
          </Route>
          <Route exact path={`${url}/instructor/menu/transportation/`}>
            <TransportationStatus />
          </Route>
        </Switch>

        {/* All other routes here */}
      </Route>
      <Route exact path={url}>
        <Redirect to={`${url}/instructor/home`} />
      </Route>
    </Switch>
  );
};

export default InstructorRoutes;
