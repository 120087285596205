import { DeleteOutlined } from "@ant-design/icons";
import { Form, Modal, Popconfirm, Table, Tooltip, message } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import React, { useState } from "react";
import { BsPencil } from "react-icons/bs";
import { useQuery, useQueryClient } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import DepartmentForm from "../../form/DepartmentForm";

function DepartmentMain() {
  const [department, setDepartment] = useState(null);
  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  // fetch departments
  async function fetchDepartments() {
    const response = await axios.get(serverUrl + "/department", {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return response.data;
  }
  const departments = useQuery("departments", fetchDepartments);

  // Delete department
  function deleteDepartment(id) {
    axios
      .delete(serverUrl + "/department/" + id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("Department Deleted");
        queryClient.invalidateQueries("departments");
      });
  }

  const columns = [
    {
      title: "Department Name",
      dataIndex: ["name"],
      key: "name",
    },
    {
      title: (
        <div
          style={{ color: "#616161" }}
          className="font-semibold text-center uppercase"
        >
          Action
        </div>
      ),
      // dataIndex: "action",
      key: "action",
      width: "200px",
      render: (record) => (
        <div className="flex items-center space-x-2 justify-end">
          <Tooltip title="edit">
            <Button
              onClick={() => setDepartment(record)}
              type="primary flex items-center justify-center"
              shape="circle"
              icon={<BsPencil />}
            />
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteDepartment(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="danger flex items-center justify-center"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex items-center justify-between">
            <div className="text-2xl text-orange-500 font-semibold">
              Manage Departments
            </div>

            <Button
              onClick={() => setDepartment(true)}
              type="primary"
              className="h-10"
            >
              Add Department
            </Button>
          </div>

          <div className="py-5">
            <Table
              columns={columns}
              dataSource={departments?.isSuccess && departments?.data}
              pagination={false}
              loading={departments?.isLoading}
            />
          </div>

          {/* ------------------------Create / update ----------------------- */}
          <Modal
            width={800}
            visible={department}
            onOk={() => setDepartment(false)}
            onCancel={() => {
              setDepartment(false);
              form.resetFields();
            }}
            footer={null}
          >
            <div>
              {/* ---------------------------form---------------- */}

              <DepartmentForm
                department={department}
                setDepartment={setDepartment}
              />

              {/* ----------------------------------------------- */}
            </div>
          </Modal>
          {/* ----------------------------------------------- */}
        </div>
      </div>
    </>
  );
}

export default DepartmentMain;
