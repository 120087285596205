import React from "react";
import StaticAchievement from "../../../../../components/cards/StaticAchievement";
import InstructorMainSection from "../sections/InstructorMainSection";

function StudentProfile({ data }) {
  return (
    <>
      <div className=" space-y-8 pt-8 capitalize">
        <div>
          <InstructorMainSection data={data?.student} />
        </div>
        <div>
          <StaticAchievement data={data?.student} />
        </div>
      </div>
    </>
  );
}

export default StudentProfile;
