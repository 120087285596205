import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { serverUrl } from "../nestserver";

/* -------------------------------------------------------------------------- */
/*                                 Create Discussion                               */
/* -------------------------------------------------------------------------- */

export function useCreateDiscussion(mutationOptions) {
  // axios post
  function createDiscussion(data) {
    return axios.post(
      serverUrl + "/discussion",
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }
    );
  }

  return useMutation(createDiscussion, mutationOptions);
}

/* -------------------------------------------------------------------------- */
/*                               Get Single Discussion                             */
/* -------------------------------------------------------------------------- */

export function useGetDiscussion(id, queryOptions) {
  // get discussions
  async function getDiscussion() {
    const res = await axios.get(serverUrl + "/discussion/" + id, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  return useQuery(["discussion", id], getDiscussion, queryOptions);
}
/* -------------------------------------------------------------------------- */
/*                                Get All Discussions                               */
/* -------------------------------------------------------------------------- */

/**
 * Get all discussions
 * @param {import("react-query").UseQueryOptions} queryOptions
 * @returns {import("react-query").UseQueryResult<any>}
 */
export function useDiscussions(queryOptions) {
  // get discussions
  async function getDiscussions() {
    const res = await axios.get(serverUrl + "/discussion/mine", {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  return useQuery("discussions", getDiscussions, queryOptions);
}

/* -------------------------------------------------------------------------- */
/*                                Get All Discussions                               */
/* -------------------------------------------------------------------------- */

/**
 * Get all discussions
 * @param {import("react-query").UseQueryOptions} queryOptions
 * @returns {import("react-query").UseQueryResult<any>}
 */
export function useDiscussionsBySubject(subject, queryOptions) {
  // get discussions
  async function getDiscussions() {
    const res = await axios.get(serverUrl + "/discussion", {
      params: {
        subject: subject,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  return useQuery(["discussions", subject], getDiscussions, queryOptions);
}

/* -------------------------------------------------------------------------- */
/*                             Update Discussion                                   */
/* -------------------------------------------------------------------------- */

export function useUpdateDiscussion(mutationOptions) {
  // axios patch
  function updateDiscussion([id, data]) {
    return axios.patch(serverUrl + "/discussion/" + id, data, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
  }

  return useMutation(updateDiscussion, mutationOptions);
}

/* -------------------------------------------------------------------------- */
/*                                 Delete Discussion                               */
/* -------------------------------------------------------------------------- */

export function useDeleteDiscussion(mutationOptions) {
  // axios post
  function deleteDiscussion(id) {
    return axios.delete(serverUrl + "/discussion/" + id, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
  }

  return useMutation(deleteDiscussion, mutationOptions);
}
