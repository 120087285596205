export default function getStudentStatusColor(status) {
  switch (status) {
    case "on track":
      return "#e9c46a";

    case "behind":
      return "#e76f51";

    case "complete":
      return "#2a9d8f";

    default:
      return "#e9c46a";
  }
}
