import { Checkbox, Form, Input, Radio, Select, Typography } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const { Option } = Select;

const DownloadApplication = () => {

  const [formFields, setFormFields] = useState([{}]);
  const [form] = Form.useForm();
  const { Title } = Typography;

  const validateAlphabetsOnly = (_, value) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(value)) {
      return Promise.reject("Please enter alphabets only");
    }
    return Promise.resolve();
  };

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    relation: "",
    occupation: "",
    education: "",
    annualIncome: "",
    phone: "",
    email: "",
    aadhar: "",
    address: "",
    emergencyContact: "",
  });
  const [applicationNumber, setApplicationNumber] = useState();
  const onFinish = (values) => {};
  return (
    <div>
      <Form
        form={form}
        name="dynamic_rule"
        onFinish={onFinish}
        autoComplete="off"
        id="applicationForm"
      >
        <div className="form-header">
          <h3 className="">Form</h3>
          <div>
            <h4>
              Application Number: <span>{applicationNumber}</span>
            </h4>
            <h4>
              Admission Number: <span></span>
            </h4>
          </div>
        </div>
        <div>
          <Title level={2}>Student Details</Title>

          <div className="admission-form ">
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "First Name is required",
                },
              ]}
              className="w-30"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Last Name is required",
                },
              ]}
              className="w-30"
            >
              <Input />
            </Form.Item>
            <Form.Item name="dob" label="Date of Birth" className="w-30">
              <Input />
            </Form.Item>
          </div>
          <div className="admission-form ">
            <Form.Item
              name="gender"
              label="Gender"
              rules={[
                {
                  required: true,
                  message: "Dob is required",
                },
              ]}
              className="w-30"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="nationality"
              label="Nationality"
              className="w-30"
              rules={[
                {
                  required: true,
                  message: "Nationality is required",
                },
                {
                  validator: validateAlphabetsOnly,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="aadhar"
              label="Aadhar"
              className="w-30"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter your aadhar id",
                },
                {
                  pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                  message: "Please enter a valid 12-digit Number!",
                },
              ]}
              getValueFromEvent={(e) => e.target.value.slice(0, 12)}
            >
              <Input maxLength={12} type="number" min={0} />
            </Form.Item>
          </div>
          <div className="admission-form">
            <Form.Item
              label="Class"
              name="class"
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Please select a class",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="enrollment_year"
              label="Enroll Year"
              className="w-30"
              rules={[
                {
                  pattern: /^\d{4}$/, // Ensures exactly 4 digits
                  message: "Enroll year must be a 4-digit number!",
                },
              ]}
              getValueFromEvent={(e) => e.target.value.slice(0, 4)}
            >
              <Input type="number" maxLength={4} min={0} />
            </Form.Item>
            <Form.Item
              name="mother_tongue"
              label="Mother Tongue"
              className="w-30"
              rules={[
                {
                  validator: validateAlphabetsOnly,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="admission-form ">
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
              className="w-30"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone"
              className="w-30"
              rules={[
                {
                  pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                  message: "Please enter a valid 10-digit phone number!",
                },
              ]}
              getValueFromEvent={(e) => e.target.value.slice(0, 10)}
            >
              <Input type="number" maxLength={10} min={0} />
            </Form.Item>
            <Form.Item
              name="birth_place"
              label="Birth Place"
              className="w-30"
            >
              <Input />
            </Form.Item>
          </div>
          <div className="admission-form ">
            <Form.Item
              name="category"
              label="Category"
              className="w-30"
              rules={[
                {
                  validator: validateAlphabetsOnly,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="religion"
              label="Religion"
              className="w-30"
              rules={[
                {
                  validator: validateAlphabetsOnly,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="ethnicity"
              label="Ethnicity"
              className="w-30"
              rules={[
                {
                  validator: validateAlphabetsOnly,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
        <div>
          <Title level={2}>Health Details</Title>
          <div className="admission-form ">
            <Form.Item name="blood_group" label="Blood Group" className="w-30">
              <Input />
            </Form.Item>
            <div className="group-radio flex">
              <h5 className="text-sm font-normal">Disabled:</h5>
              <Radio.Group name="is_disabled">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        <div>
          <Title level={2}>Existing Sibling</Title>
          <div className="pb-3">
            {formFields.map((field, index) => (
              <div
                key={index}
                className={`admission-form ${index === 0 ? "w-92" : ""}`}
              >
                <Form.Item
                  name={`name${index + 1}`}
                  label="Name"
                  className="w-92"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={`student_id${index + 1}`}
                  label="Student Id"
                  className="w-30"
                >
                  <Input />
                </Form.Item>
              </div>
            ))}
          </div>
        </div>
        <div>
          <Title level={2}>Previous School</Title>
          <div className="admission-form ">
            <Form.Item name="school_name" label="School Name" className="w-30">
              <Input />
            </Form.Item>
            <Form.Item
              name="class_completed"
              label="Class Completed"
              className="w-30"
            >
              <Input />
            </Form.Item>
            <Form.Item name="grade" label="Overall Grade" className="w-30">
              <Input />
            </Form.Item>
          </div>
          <div className="admission-form ">
            <Form.Item
              name="graduated_year"
              label="Year Completed"
              className="w-30"
              rules={[
                {
                  pattern: /^\d{4}$/, // Ensures exactly 4 digits
                  message: "Enroll year must be a 4-digit number!",
                },
              ]}
              getValueFromEvent={(e) => e.target.value.slice(0, 4)}
            >
              <Input type="number" maxLength={4} min={0} />
            </Form.Item>
            <Form.Item
              name="reference"
              label="Reference Phone/Email"
              className="w-30"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      !/^(\+\d{1,3}[- ]?)?\d{10}$/.test(value) &&
                      !/^\S+@\S+\.\S+$/.test(value)
                    ) {
                      return Promise.reject(
                        "Please enter a valid phone number or email"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div>
            <div className="text-base font-normal pb-2">Reason For Change:</div>
            <Form.Item name="change_reason">
              <Input.TextArea
                rows={3}
                className="w-full border focus:outline-none resize-none"
              />
            </Form.Item>
          </div>
        </div>
        <div>
          <Title level={2}>Primary Contact</Title>
          <div className="admission-form ">
            <Form.Item
              name="primary_contact_first_name"
              label="First Name"
              className="w-30"
              rules={[
                {
                  required: true,
                  message: "First name is required",
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  setData({ ...data, firstName: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              name="primary_contact_last_name"
              label="Last Name"
              className="w-30"
              rules={[
                {
                  required: true,
                  message: "Last name is required",
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  setData({ ...data, lastName: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              name="primary_contact_relation"
              label="Relation"
              className="w-30"
              rules={[
                {
                  required: true,
                  message: "Relation is required",
                },
                {
                  validator: validateAlphabetsOnly,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  setData({ ...data, relation: e.target.value });
                }}
              />
            </Form.Item>
          </div>
          <div className="admission-form ">
            <Form.Item
              name="primary_contact_occupation"
              label="Occupation"
              className="w-30"
            >
              <Input
                onChange={(e) => {
                  setData({ ...data, occupation: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              name="primary_contact_education"
              label="Education"
              className="w-30"
            >
              <Input
                onChange={(e) => {
                  setData({ ...data, education: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              name="primary_contact_annual_income"
              label="Annual Income"
              className="w-30"
            >
              <Input
                onChange={(e) => {
                  setData({ ...data, annualIncome: e.target.value });
                }}
              />
            </Form.Item>
          </div>
          <div className="admission-form ">
            <Form.Item
              name="primary_contact_phone"
              label="Phone"
              className="w-30"
              rules={[
                {
                  required: true,
                  message: "Phone is required",
                },
                {
                  pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                  message: "Please enter a valid 10-digit phone number!",
                },
              ]}
              getValueFromEvent={(e) => e.target.value.slice(0, 10)}
            >
              <Input
                onChange={(e) => {
                  setData({ ...data, phone: e.target.value });
                }}
                type="number"
                maxLength={10}
                min={0}
              />
            </Form.Item>
            <Form.Item
              name="primary_contact_email"
              label="Email"
              className="w-30"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              name="primary_contact_aadhar"
              label="Aadhar"
              className="w-30"
              rules={[
                {
                  pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                  message: "Please enter a valid 12-digit Number!",
                },
              ]}
              getValueFromEvent={(e) => e.target.value.slice(0, 12)}
            >
              <Input
                onChange={(e) => {
                  setData({ ...data, aadhar: e.target.value });
                }}
                maxLength={12}
                type="number"
                min={0}
              />
            </Form.Item>
          </div>
          <div className="admission-form ">
            <Form.Item
              name="primary_contact_address"
              label="Address"
              className="w-30"
              rules={[
                {
                  required: true,
                  message: "Address is required",
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  setData({ ...data, address: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              name="primary_emergency_contact"
              label="Emergency Contact"
              className="w-30"
              rules={[
                {
                  pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                  message: "Please enter a valid 10-digit phone number!",
                },
              ]}
              getValueFromEvent={(e) => e.target.value.slice(0, 10)}
            >
              <Input
                onChange={(e) => {
                  setData({ ...data, emergencyContact: e.target.value });
                }}
                type="number"
                maxLength={10}
                min={0}
              />
            </Form.Item>
          </div>
        </div>
        <div>
          <Title level={2}>Secondary Contact</Title>
          <Form.Item name="addSameAsAbove" valuePropName="checked">
            <Checkbox>Add Same as above</Checkbox>
          </Form.Item>
          <div className="admission-form ">
            <Form.Item
              name="secondary_contact_first_name"
              label="First Name"
              className="w-30"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="secondary_contact_last_name"
              label="Last Name"
              className="w-30"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="secondary_contact_relation"
              label="Relation"
              className="w-30"
              rules={[
                {
                  validator: validateAlphabetsOnly,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="admission-form ">
            <Form.Item
              name="secondary_contact_occupation"
              label="Occupation"
              className="w-30"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="secondary_contact_education"
              label="Education"
              className="w-30"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="secondary_contact_annual_income"
              label="Annual Income"
              className="w-30"
            >
              <Input />
            </Form.Item>
          </div>
          <div className="admission-form ">
            <Form.Item
              name="secondary_contact_phone"
              label="Phone"
              className="w-30"
              rules={[
                {
                  pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                  message: "Please enter a valid 10-digit phone number!",
                },
              ]}
              getValueFromEvent={(e) => e.target.value.slice(0, 10)}
            >
              <Input type="number" maxLength={10} min={0} />
            </Form.Item>
            <Form.Item
              name="secondary_contact_email"
              label="Email"
              className="w-30"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="secondary_contact_aadhar"
              label="Aadhar"
              className="w-30"
              rules={[
                {
                  pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                  message: "Please enter a valid 12-digit Number!",
                },
              ]}
              getValueFromEvent={(e) => e.target.value.slice(0, 12)}
            >
              <Input maxLength={12} type="number" min={0} />
            </Form.Item>
          </div>
          <div className="admission-form ">
            <Form.Item
              name="secondary_contact_address"
              label="Address"
              className="w-30"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="secondary_emergency_contact"
              label="Emergency Contact"
              className="w-30"
              rules={[
                {
                  pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                  message: "Please enter a valid 10-digit phone number!",
                },
              ]}
              getValueFromEvent={(e) => e.target.value.slice(0, 10)}
            >
              <Input type="number" maxLength={10} min={0} />
            </Form.Item>
          </div>
          <div className="flex justify-between items-start">
            <div className="flex space-x-6">
              <Form.Item name="referral" label="Referral" className="w-30">
                <Input />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default DownloadApplication;