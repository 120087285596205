import { Button, DatePicker, Form, Input, Select, message } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { serverUrl } from "../../../../nestserver";

const ViewLeaveDetails = () => {
  let { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [response, setResponse] = useState();

  const fetchLeaveDetails = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/permission-request/" + id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  useEffect(() => {
    if (id) {
      fetchLeaveDetails()
        .then((response) => {
          setResponse(response?.result?.data);
          const startDate = response?.result?.data?.from
            ? moment(response?.result?.data?.from)
            : null;
          const endDate = response?.result?.data?.to
            ? moment(response?.result?.data?.to)
            : null;
          form.setFieldsValue({
            name: `${response?.result?.data?.student?.first_name} ${response?.result?.data?.student?.last_name}`,
            class: response?.result?.data?.class?.name,
            date: startDate && endDate ? [startDate, endDate] : null,
            type: response?.result?.data?.type,
            title: response?.result?.data?.title,
            description: response?.result?.data?.description,
          });
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
        });
    }
  }, [id]);
  const onFinish = (values) => {
    const payload = {
      from: moment(values?.date[0]).format("YYYY-MM-DD"),
      to: moment(values?.date[1]).format("YYYY-MM-DD"),
      student: null,
      type: values?.type,
      title: values?.title,
      description: values?.description,
    };
    if (id) {
      axios({
        method: "put",
        url: serverUrl + "/permission-request/" + id,
        data: payload,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
        .then((res) => {
          queryClient.invalidateQueries("/permission-request/");
          message.success(res?.data?.result?.message);
        })
        .catch((error) => {
          message.error(error?.response?.data?.error?.errors);
        });
    }
  };
  const handleWithdraw = () => {
    const status = "withdraw";
    axios({
      method: "patch",
      url: serverUrl + "/permission-request/" + id + "/withdraw/",
      data: status,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        queryClient.invalidateQueries("/permission-request/");
        message.success(res?.data?.result?.message);
      })
      .catch((error) => {
        message.error(error?.response?.data?.error?.errors);
      });
  };
  return (
    <div className="m-33px">
      <div className="bg-white p-6 rounded-2xl ">
        <div className="pb-4 flex justify-end">
          <Button size="large" onClick={() => history.goBack()}>
            Back
          </Button>
        </div>
        <Form
          form={form}
          layout="vertical"
          name="dynamic_rule"
          autoComplete="off"
          className="w-1/2"
          onFinish={onFinish}
        >
          <Form.Item
            label="Date"
            name="date"
            className="w-full"
            rules={[
              {
                required: true,
                message: "Please select date",
              },
            ]}
          >
            <RangePicker
              format={"YYYY/MM/DD"}
              disabledDate={(d) => !d || d.isBefore(moment().startOf("day"))}
              className="w-full"
              disabled={response?.status === "withdraw"}
            />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: "Application type is required",
              },
            ]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder="Select type"
              className="w-full"
              disabled={response?.status === "withdraw"}
            >
              <Select.Option value="Sick">Sick</Select.Option>
              <Select.Option value="Personal">Personal</Select.Option>
              <Select.Option value="Vacation">Vacation</Select.Option>
              <Select.Option value="Family Reason">
                Family Reasons
              </Select.Option>
              <Select.Option value="Partial Day Exception">
                Partial Day Exception
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            className="w-full"
            rules={[
              {
                required: true,
                message: "Title is required",
              },
              {
                min: 1,
                max: 15,
                message: "Title must be between 1 and 15 characters",
              },
            ]}
          >
            <Input
              maxLength={15}
              className="capitalize"
              readOnly={response?.status === "withdraw"}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length <= 150) {
                    return Promise.resolve(); // Validation passed
                  }
                  return Promise.reject(
                    "Description must be at most 150 characters"
                  );
                },
              },
            ]}
          >
            <Input.TextArea
              rows={3}
              placeholder="Reason"
              className="w-full border focus:outline-none resize-none"
              maxLength={150}
              readOnly={response?.status === "withdraw"}
            />
          </Form.Item>
          <div className="flex justify-end items-center py-5">
            <div className="inline-flex items-center space-x-2">
              {response?.status !== "withdraw" && (
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="text-white"
                >
                  Save
                </Button>
              )}
              {response?.status !== "withdraw" &&
                response?.status !== "rejected" && (
                  <Button size="large" type="dark" onClick={handleWithdraw}>
                    Withdraw
                  </Button>
                )}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ViewLeaveDetails;
